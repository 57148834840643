import { backendApi } from "@/services/backend/api";
import { ProcessRepresentative } from "@/services/backend/representatives/processrepresentative/process-representative";

const processRepresentativeService = backendApi.injectEndpoints({
  endpoints: (build) => ({
    representativesProcessRepresentativePut: build.mutation<
      ProcessRepresentative,
      ProcessRepresentative
    >({
      query: (request) => ({
        url: "/representatives/process-representative",
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessRepresentative", id: request.id },
        { type: "ProcessRepresentative", id: "LIST" },
      ],
    }),
    representativesProcessRepresentativeShow: build.query<
      ProcessRepresentative,
      ShowRequest
    >({
      query: (request) => ({
        url: "/representatives/process-representative",
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _error, request) => [
        { type: "ProcessRepresentative", id: request.id },
      ],
    }),
    representativesProcessRepresentativeDelete: build.mutation<
      ProcessRepresentative,
      DeleteRequest
    >({
      query: (request) => ({
        url: "/representatives/process-representative",
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessRepresentative", id: request.id },
        { type: "ProcessRepresentative", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useRepresentativesProcessRepresentativePutMutation,
  useRepresentativesProcessRepresentativeShowQuery,
  useRepresentativesProcessRepresentativeDeleteMutation,
} = processRepresentativeService;

export interface ShowRequest {
  id: string;
}

export interface DeleteRequest {
  id: string;
}
