import { useCustomersCustomerShowQuery } from "@/services/backend/customers/service";
import t from "../../../../lang/lang";

export function CustomerName(props: { customerId: string }) {
  const { customerId } = props;

  const {
    data: customer,
    isLoading,
    isError,
  } = useCustomersCustomerShowQuery({ id: customerId });
  if (isLoading) {
    return (
      <span className="animate-pulse">
        <span className="block h-2 rounded-full bg-gray-200" />
      </span>
    );
  }

  if (isError) {
    return <span>{t("Fehler beim Laden des Kundennamen")}</span>;
  }

  return <span>{customer?.name}</span>;
}
