import { Navigate, RouteObject } from "react-router-dom";
import { PositionCatalogRoute } from "@/routes/base-data/processes/htz/position-catalogs/[positionCatalogId]/position-catalog-route";
import { PositionCatalogsRoute } from "@/routes/base-data/processes/htz/position-catalogs/position-catalogs-route";
import { AhuComponentCatalogsRoute } from "@/routes/base-data/processes/htz/ahu-component-catalogs/ahu-component-catalogs-route";
import { AhuComponentCatalogRoute } from "@/routes/base-data/processes/htz/ahu-component-catalogs/[ahuComponentCatalogId]/ahu-component-catalog-route";
import { StructuralAssessmentCatalogsRoute } from "@/routes/base-data/processes/htz/structural-assessment-catalogs/structural-assessment-catalogs-route";
import { VisualAssessmentCatalogsRoute } from "@/routes/base-data/processes/htz/visual-assessment-catalogs/visual-assessment-catalogs-route";
import { StructuralAssessmentCatalogRoute } from "@/routes/base-data/processes/htz/structural-assessment-catalogs/[structuralAssessmentCatalogId]/structural-assessment-catalog-route";
import { VisualAssessmentCatalogRoute } from "@/routes/base-data/processes/htz/visual-assessment-catalogs/[visualAssessmentCatalogId]/visual-assessment-catalog-route";

export const htzBaseDataRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="position-catalogs" replace />,
  },
  {
    path: "position-catalogs",
    element: <PositionCatalogsRoute />,
  },
  {
    path: "position-catalogs/:positionCatalogId",
    element: <PositionCatalogRoute />,
  },
  {
    path: "ahu-component-catalogs",
    element: <AhuComponentCatalogsRoute />,
  },
  {
    path: "ahu-component-catalogs/:ahuComponentCatalogId",
    element: <AhuComponentCatalogRoute />,
  },
  {
    path: "structural-assessment-catalogs",
    element: <StructuralAssessmentCatalogsRoute />,
  },
  {
    path: "structural-assessment-catalogs/:structuralAssessmentCatalogId",
    element: <StructuralAssessmentCatalogRoute />,
  },
  {
    path: "visual-assessment-catalogs",
    element: <VisualAssessmentCatalogsRoute />,
  },
  {
    path: "visual-assessment-catalogs/:visualAssessmentCatalogId",
    element: <VisualAssessmentCatalogRoute />,
  },
];
