import { Configuration, PublicClientApplication } from "@azure/msal-browser";

// Configuration in this file follows the docs from:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#initialization-of-msal

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_MSAL_APPLICATION_ID,
    authority: import.meta.env.VITE_MSAL_AUTHORITY,
    redirectUri: import.meta.env.VITE_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: import.meta.env.BASE_URL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // The following can be helpful in case of errors in the library.
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message)
  //           return
  //         case LogLevel.Info:
  //           console.info(message)
  //           return
  //         case LogLevel.Verbose:
  //           console.debug(message)
  //           return
  //         case LogLevel.Warning:
  //           console.warn(message)
  //           return
  //         default:
  //           return
  //       }
  //     },
  //   },
  // },
};

// only one instance of PublicClientApplication should be created:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#use-a-single-publicclientapplication-instance
export const msalInstance = new PublicClientApplication(msalConfig);
// awaiting the initialization is important, otherwise the
// instance may not be ready and cause unexpected errors.
await msalInstance.initialize();
