import { backendApi } from "@/services/backend/api";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { Laboratory } from "@/services/backend/samples/lab/laboratory";

const labApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesLaboratory"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesLaboratoryPut: build.mutation<Laboratory, PutRequest>({
        query: (request) => ({
          url: `/samples/laboratory`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesLaboratory", id: request.id },
          "SamplesLaboratory",
        ],
      }),
      samplesLaboratoryShow: build.query<Laboratory, ShowRequest>({
        query: (request) => ({
          url: `/samples/laboratory`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("SamplesLaboratory"),
      }),
      samplesLaboratoryList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/laboratory/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.labs, "SamplesLaboratory"),
      }),
      samplesLaboratoryDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/samples/laboratory`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["SamplesLaboratory"],
      }),
    }),
  });

export const {
  useSamplesLaboratoryPutMutation,
  useSamplesLaboratoryShowQuery,
  useSamplesLaboratoryListQuery,
  useSamplesLaboratoryDeleteMutation,
} = labApi;

export interface PutRequest extends Laboratory {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  limit?: number;
  offset?: number;
}

export interface List {
  labs: Laboratory[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
