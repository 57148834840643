import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import {
  NavLink,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Loader2, Search } from "lucide-react";
import {
  useQpmRequirementCreateMutation,
  useQpmRequirementListQuery,
} from "@/services/backend/qpm/requirements/service";
import { useEffect } from "react";
import {
  Requirement as QpmRequirement,
  RequirementList,
} from "@/services/backend/qpm/requirements/types";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { useCustomersCustomerShowQuery } from "@/services/backend/customers/service";
import { RequirementContextType } from "@/routes/gemex/processes/qpm/requirements/requirements-route";
import { createDocumentIdentifier } from "@/routes/gemex/processes/qpm/shared/utils/document-identifier";
import { ArtefactDocType } from "@/services/backend/qpm/shared/enums";

export function RequirementsOverview() {
  const { processId } = useParams();
  const requirementContext = useOutletContext<RequirementContextType>();
  const {
    data: list,
    isLoading,
    error,
  } = useQpmRequirementListQuery({
    ownerType: {
      active: false,
      values: [],
    },
    process: {
      active: true,
      values: [processId!],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: true,
      values: [requirementContext.customerId],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  if (!processId) {
    return <div>Fehler beim Laden des Vorgangs!</div>;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Bisherige Bestandsaufnahmmen")}</CardTitle>
        <CardDescription>
          {t("Eine Liste von bisher erstellten Bestandsaufnahmen.")}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-8">
        <CreateNewRequirement
          processId={processId}
          totalRequirementsCount={list?.count || 0}
        />
        {isLoading && <RequirementHistoryTableSkeleton />}
        {!isLoading && list && (
          <RequirementHistoryTable requirementList={list} />
        )}
      </CardContent>
    </Card>
  );
}

type CreateNewRequirementProps = {
  processId: string;
  totalRequirementsCount: number;
};

function CreateNewRequirement({
  processId,
  totalRequirementsCount,
}: CreateNewRequirementProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const requirementContext = useOutletContext<RequirementContextType>();
  const [createRequirement, { isLoading, data, error, reset }] =
    useQpmRequirementCreateMutation();

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    error: errorCustomer,
  } = useCustomersCustomerShowQuery({ id: requirementContext.customerId });

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error) });
      reset();
    }
  }, [error, toast, reset]);

  useEffect(() => {
    if (data) {
      const requirement: QpmRequirement = data;
      navigate(requirement.id);
    }
  }, [data, navigate]);

  const onClick = () => {
    if (isLoading || !customer) return;
    const id = v4();

    createRequirement({
      id,
      processId,
      artefactMetaId: id,
      customerId: customer.id,
      identifier: createDocumentIdentifier(
        customer.name,
        ArtefactDocType.Requirement,
        totalRequirementsCount,
      ),
    });
  };

  if (errorCustomer) {
    return <RTKQueryErrorAlert error={errorCustomer} />;
  }

  if (isLoadingCustomer || isLoading) {
    return <Loader2 className="animate-spin" />;
  }
  return (
    <Button onClick={onClick} disabled={isLoading || isLoadingCustomer}>
      Erstellen
    </Button>
  );
}

export default CreateNewRequirement;

function RequirementHistoryTable({
  requirementList,
}: {
  requirementList: RequirementList;
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Uhrzeit")}</TableHead>
          <TableHead className="">{t("Kennung")}</TableHead>
          <TableHead className="w-full">{t("Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {requirementList?.requirements.map((requirement) => (
          <TableRow key={requirement.id}>
            <TableCell>{formatDate(requirement.createdAt)}</TableCell>
            <TableCell>{formatTime(requirement.createdAt)}</TableCell>
            <TableCell className="text-nowrap">
              {requirement.identifier}
            </TableCell>
            <TableCell>{requirement.name}</TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`${requirement.id}`}>
                  <Button variant="ghost">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function RequirementHistoryTableSkeleton() {
  return (
    <Table className="animate-pulse">
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
    </Table>
  );
}
