import { Position } from "@/services/backend/rlt/treatments/position";
import { HTMLAttributes, useEffect, useState } from "react";
import { cn } from "@/shared/lib/utils";
import { CheckCircle, ChevronDown, Plus, Trash2 } from "lucide-react";
import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state-context";
import { OfferPriceCell } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/offer-price-cell";
import {
  ALL_COLUMNS,
  Column,
} from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";
import { BasePriceCell } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/base-price-cell";
import { CycleCell } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/cycle-cell";
import { CycleDiscountCell } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/cycle-discount-cell";
import { ContractPriceCell } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/contract-price-cell";
import { TipCommissionCell } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/tip-commission-cell";
import { Button } from "@/shared/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import t from "@/lang/lang";
import {
  useRltTreatmentAddPositionMutation,
  useRltTreatmentDeletePositionMutation,
} from "@/services/backend/rlt/treatments/service";
import { Kind } from "@/services/backend/rlt/treatments/component";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { CopyPositionDialog } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/copy-position-dialog";
import { AssignedWorkOrderCell } from "./assigned-work-order-cell";
import { DeletePositionDialog } from "./delete-position-dialog";
import { CommentCell } from "./comment-cell";
import { CleanTimeCell } from "./clean-time-cell";
import { FormulaCell } from "./formula-cell";
import { ComponentCell } from "./component-cell";
import { KindCell } from "./kind-cell";

export function PositionRow({
  treatmentId,
  processId,
  position,
}: {
  treatmentId: string;
  processId: string;
  position: Position;
}) {
  const { state } = useTreatmentComponentState();
  const { columns } = state;

  return (
    <>
      <tr className="border-t align-top">
        {ALL_COLUMNS.map((column) => {
          if (!columns.includes(column)) {
            return null;
          }
          return (
            <Cell
              key={column}
              column={column}
              treatmentId={treatmentId}
              processId={processId}
              position={position}
            />
          );
        })}
      </tr>
      {[...(position.children ?? [])].sort(byKind).map((child) => (
        <tr className="align-top" key={child.id}>
          {ALL_COLUMNS.map((column) => {
            if (!columns.includes(column)) {
              return null;
            }
            return (
              <ChildCell
                key={column}
                column={column}
                treatmentId={treatmentId}
                position={child}
              />
            );
          })}
        </tr>
      ))}
      {position.component.canHaveChildren && (
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <td colSpan={ALL_COLUMNS.length - 1} className="px-6 pb-3 pt-2">
            <AddPositionDropdown treatmentId={treatmentId} parent={position} />
          </td>
        </tr>
      )}
    </>
  );
}

function byKind(a: Position, b: Position) {
  const aKind = t(a.component.kind);
  const bKind = t(b.component.kind);
  return aKind.localeCompare(bKind);
}

function Cell({
  treatmentId,
  processId,
  column,
  position,
}: {
  treatmentId: string;
  processId: string;
  column: Column;
  position: Position;
}) {
  switch (column) {
    case Column.Position:
      return (
        <TD className="font-bold">
          <span className="flex h-6 items-center">{position.number}</span>
        </TD>
      );
    case Column.Completed:
      return (
        <TD className="flex justify-center">
          {position.completed ? <CheckCircle className="h-5 w-5" /> : null}
        </TD>
      );
    case Column.AssignedWorkOrder:
      return (
        <TD className="whitespace-nowrap font-mono">
          <AssignedWorkOrderCell
            treatmentId={treatmentId}
            processId={processId}
            positionId={position.id}
            workOrderId={position.assignedWorkOrderId}
          />
        </TD>
      );
    case Column.ComponentKind:
      return (
        <TD>
          <KindCell kind={position.component.kind} />
        </TD>
      );
    case Column.ComponentData:
      return (
        <TD>
          <ComponentCell
            treatmentId={treatmentId}
            positionId={position.id}
            component={position.component}
          />
        </TD>
      );
    case Column.Cycle:
      return (
        <TD className="text-right">
          <CycleCell treatmentId={treatmentId} position={position} />
        </TD>
      );
    case Column.Formula:
      return (
        <TD>
          <FormulaCell
            treatmentId={treatmentId}
            positionId={position.id}
            component={position.component}
          />
        </TD>
      );
    case Column.CleanTime:
      return (
        <TD className="text-right">
          <CleanTimeCell position={position} />
        </TD>
      );
    case Column.BasePrice:
      return (
        <TD className="text-right">
          <BasePriceCell position={position} />
        </TD>
      );
    case Column.TipCommission:
      return (
        <TD className="text-right">
          <TipCommissionCell treatmentId={treatmentId} position={position} />
        </TD>
      );
    case Column.OfferPrice:
      return (
        <TD className="text-right">
          <OfferPriceCell position={position} />
        </TD>
      );
    case Column.CycleDiscount:
      return (
        <TD className="text-right">
          <CycleDiscountCell treatmentId={treatmentId} position={position} />
        </TD>
      );
    case Column.ContractPrice:
      return (
        <TD className="text-right">
          <ContractPriceCell position={position} />
        </TD>
      );
    case Column.Comment:
      return (
        <TD>
          <CommentCell
            treatmentId={treatmentId}
            positionId={position.id}
            comment={position.comment}
          />
        </TD>
      );
    case Column.Actions:
      return (
        <TD className="flex justify-end space-x-2 px-3 py-2">
          <CopyPositionDialog treatmentId={treatmentId} position={position} />
          <DeletePositionDialog treatmentId={treatmentId} position={position} />
        </TD>
      );
    default:
      return null;
  }
}

interface TDProps extends HTMLAttributes<HTMLTableCellElement> {}

function TD({ children, className }: TDProps) {
  return <th className={cn("px-6 py-3 font-normal", className)}>{children}</th>;
}

function ChildCell({
  treatmentId,
  column,
  position,
}: {
  treatmentId: string;
  column: Column;
  position: Position;
}) {
  switch (column) {
    case Column.Position:
      return <TD />;
    case Column.Completed:
      return <TD />;
    case Column.AssignedWorkOrder:
      return <TD />;
    case Column.ComponentKind:
      return (
        <TD className="flex h-9 items-center justify-between py-1.5">
          <KindCell kind={position.component.kind} className="font-normal" />
          <DeleteChildPositionDialog
            treatmentId={treatmentId}
            position={position}
          />
        </TD>
      );
    case Column.ComponentData:
      return (
        <TD className="py-1.5">
          <ComponentCell
            treatmentId={treatmentId}
            positionId={position.id}
            component={position.component}
          />
        </TD>
      );
    case Column.Cycle:
      return <TD />;
    case Column.Formula:
      return (
        <TD className="py-1.5">
          <FormulaCell
            treatmentId={treatmentId}
            positionId={position.id}
            component={position.component}
          />
        </TD>
      );
    case Column.CleanTime:
      return (
        <TD className="py-1.5 text-right">
          <CleanTimeCell position={position} className="font-normal" />
        </TD>
      );
    case Column.BasePrice:
      return (
        <TD className="py-1.5 text-right">
          <BasePriceCell position={position} className="font-normal" />
        </TD>
      );
    case Column.TipCommission:
      return <TD />;
    case Column.OfferPrice:
      return (
        <TD className="py-1.5 text-right">
          <OfferPriceCell position={position} className="font-normal" />
        </TD>
      );
    case Column.CycleDiscount:
      return <TD />;
    case Column.ContractPrice:
      return (
        <TD className="py-1.5 text-right">
          <ContractPriceCell position={position} className="font-normal" />
        </TD>
      );
    case Column.Comment:
      return <TD />;
    case Column.Actions:
      return <TD />;
    default:
      return null;
  }
}

function AddPositionDropdown({
  treatmentId,
  parent,
}: {
  treatmentId: string;
  parent: Position;
}) {
  const [add, { isLoading, error, reset }] =
    useRltTreatmentAddPositionMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const doAdd = (kind: Kind) => {
    if (isLoading) {
      return;
    }

    add({
      id: treatmentId,
      positionId: v4(),
      parentId: parent.id,
      segment: parent.segmentKind,
      area: parent.area,
      componentKind: kind,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="secondary">
          <span>{t("Bauteil hinzufügen")}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuLabel>{t("Bauteil hinzufügen")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {[...parent.component.permittedChildrenKind]
            .sort(byTranslation)
            .map((kind) => (
              <DropdownMenuItem
                key={kind}
                className="cursor-pointer items-center"
                onClick={() => doAdd(kind)}
              >
                <Plus className="mr-2 h-4 w-4" />
                <span>{t(kind)}</span>
              </DropdownMenuItem>
            ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byTranslation(a: Kind, b: Kind) {
  const aT = t(a);
  const bT = t(b);
  return aT.localeCompare(bT);
}

function DeleteChildPositionDialog({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [deletePosition, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentDeletePositionMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const doDelete = () => {
    if (isLoading) {
      return;
    }

    deletePosition({
      id: treatmentId,
      positionId: position.id,
    });
  };

  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    if (isLoading) {
      setPersistenceState(PersistenceState.SAVING);
    } else if (error) {
      setPersistenceState(PersistenceState.ERROR);
    } else {
      setPersistenceState(PersistenceState.SAVED);
    }
  }, [isLoading, error, setPersistenceState]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t(`${t(position.component.kind)} wirklich löschen?`)}
          </DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
