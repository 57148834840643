import {
  BuildingAddress,
  CreateBuildingAddressRequest,
} from "@/services/backend/qpm/buildings/types";
import { useEffect, useState } from "react";
import { useQpmBuildingAddressAddMutation } from "@/services/backend/qpm/buildings/service";
import { v4 } from "uuid";
import { CustomerAddressCard } from "@/shared/components/domain/addresses/customer-address-card";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import t from "@/lang/lang";

export type AddressComponentProps = {
  buildingAddress?: BuildingAddress;
  customerId: string;
  onAddressSaved: (address: BuildingAddress) => void;
};

export function BuildingAddressComponent({
  buildingAddress = undefined,
  customerId,
  onAddressSaved,
}: AddressComponentProps) {
  const [editModeActive, setEditModeActive] = useState<boolean>(false);
  const [request, setRequest] = useState<CreateBuildingAddressRequest>({
    id: buildingAddress ? buildingAddress.id : v4(),
    customerId,
    address: buildingAddress
      ? buildingAddress.address
      : {
          company: "",
          department: "",
          contact: "",
          street: "",
          postbox: "",
          postalCode: "",
          city: "",
          country: "",
          comment: "",
        },
  });

  const [
    createOrUpdateAddress,
    { data: dataSavedAddress, error, isLoading, isSuccess, reset },
  ] = useQpmBuildingAddressAddMutation();

  useEffect(() => {
    if (!buildingAddress) {
      setEditModeActive(true);
    }
  }, [buildingAddress, editModeActive]);

  useEffect(() => {
    if (dataSavedAddress) {
      onAddressSaved(dataSavedAddress);
    }
  }, [dataSavedAddress, onAddressSaved]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    createOrUpdateAddress,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <CustomerAddressCard
      customerAddress={request.address}
      onCustomerAddressChange={(addr) =>
        setRequest({ ...request, address: addr })
      }
      description={t(
        "Die Adresse der Gebäude des Unternehmens an der der Service durchgeführt wird.",
      )}
      // immutable={!editModeActive}
    />
  );
}
