import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import t from "@/lang/lang";
import {
  ParseProcessKind,
  ProcessKind,
  ProcessKindList,
} from "@/services/backend/processes/process/kinds";
import { Filter } from "@/shared/lib/filter/filter";

export interface ProcessKindFilterProps {
  filter: Filter<ProcessKind>;
  onChange: (filter: Filter<ProcessKind>) => void;
}

export function ProcessKindFilter({
  filter,
  onChange,
}: ProcessKindFilterProps) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
        >
          <span>{t("Dienstleistungen")}</span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={t("Dienstleistung suchen")} />
          <CommandEmpty>{t("Dienstleistung nicht gefunden")}</CommandEmpty>
          <CommandGroup>
            {ProcessKindList.map((processKind) => (
              <CommandItem
                key={processKind}
                value={processKind}
                onSelect={(currentValue) => {
                  const currentPK = ParseProcessKind(currentValue)!;
                  const values = filter.values!.includes(currentPK)
                    ? filter.values!.filter((v) => v !== currentPK)
                    : [...filter.values!, currentPK];
                  if (values.length === 0) {
                    onChange({ active: false, values: [] });
                  } else {
                    onChange({ active: true, values });
                  }
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    filter.values!.includes(processKind)
                      ? "opacity-100"
                      : "opacity-0",
                  )}
                />
                {processKind}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
