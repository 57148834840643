import { useRltWorkOrderDeleteMutation } from "@/services/backend/rlt/work-orders/service";
import { useNavigate } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import t from "@/lang/lang";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";

export function DeleteWorkOrderDialog(props: { workOrderId: string }) {
  const { workOrderId } = props;
  const [deleteWorkSlip, { isLoading, error, isSuccess, reset }] =
    useRltWorkOrderDeleteMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteWorkSlip({ id: workOrderId });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Arbeitsschein gelöscht"),
        variant: "success",
      });
      navigate(`..`);
      reset();
    }
  }, [isSuccess, navigate, reset, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button type="button" variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Arbeitsschein wirklich löschen")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
