import { useEffect } from "react";

export interface DownloadPdfResponse {
  objectURL: string;
  filename: string;
}

export function downloadPdf(response: DownloadPdfResponse) {
  const anchorElement = document.createElement("a");
  anchorElement.href = response.objectURL;
  anchorElement.type = "application/pdf";
  anchorElement.target = "_blank";
  anchorElement.download = response.filename;
  anchorElement.click();
  setTimeout(() => {
    anchorElement.remove();
    window.URL.revokeObjectURL(response.objectURL);
  }, 2000);
}

export function useDownloadPdf(
  data?: DownloadPdfResponse,
  cleanup?: () => void,
) {
  useEffect(() => {
    if (data) {
      downloadPdf(data);
      if (cleanup) {
        cleanup();
      }
    }
  }, [data, cleanup]);
}
