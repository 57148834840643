import { RouteObject } from "react-router-dom";
import { CertificateRoute } from "@/routes/gesec/processes/[processId]/rlt/certificates/[certificateId]/certificate-route";
import { CertificatesIndexRoute } from "@/routes/gesec/processes/[processId]/rlt/certificates/_compontens/certificates-index-route";

export const certificatesRoutes: RouteObject[] = [
  {
    index: true,
    element: <CertificatesIndexRoute />,
  },
  {
    path: ":certificateId",
    element: <CertificateRoute />,
  },
];
