import {
  addComponent,
  AirHandlingUnit,
  newFromTemplate,
} from "@/services/backend/htz/ahu/air-handling-unit";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown } from "lucide-react";
import t from "@/lang/lang";
import {
  AhuComponentCatalog,
  Item,
} from "@/services/backend/htz/ahu/ahu-component-catalog";

export function AddComponentDropdown({
  ahu,
  onAhuChange,
  catalog,
}: {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
  catalog: AhuComponentCatalog;
}) {
  const withoutGroup = catalog.items.filter((temp) => temp.group === "");
  const groups = catalog.items.reduce((prev, cur) => {
    // if without group, do nothing
    if (cur.group === "") {
      return prev;
    }

    // init group
    if (!prev.has(cur.group)) {
      prev.set(cur.group, []);
    }

    // add template
    prev.set(cur.group, [...prev.get(cur.group)!, cur]);

    return prev;
  }, new Map<string, Item[]>());

  const add = (template: Item) => {
    const component = newFromTemplate(template);
    onAhuChange(addComponent(ahu, component));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{t("Komponente hinzufügen")}</span>
          <ChevronDown className="ml-2 h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup className="grid grid-cols-3 gap-x-2">
          {withoutGroup.map((template) => (
            <DropdownMenuItem onClick={() => add(template)} key={template.id}>
              {template.name}
            </DropdownMenuItem>
          ))}
          {Array.from(groups.keys()).map((group) => (
            <DropdownMenuSub key={group}>
              <DropdownMenuSubTrigger>{group}</DropdownMenuSubTrigger>
              <DropdownMenuSubContent className="grid grid-cols-2 gap-x-2">
                {groups.get(group)?.map((template) => (
                  <DropdownMenuItem
                    onClick={() => add(template)}
                    key={template.id}
                  >
                    {template.name}
                    {template.details && `, ${template.details}`}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
