import { Navigate, RouteObject } from "react-router-dom";
import { ConfirmationIndexRoute } from "@/routes/gesec/processes/[processId]/htz/confirmations/_components/confirmation-index-route";
import { ConfirmationRoute } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/confirmation-route";
import { DataRoute } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/data/data-route";
import { PositionsRoute } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/positions/positions-route";
import { AddressesRoute } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/addresses/addresses-route";
import { RepresentativeRoute } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/representative/representative-route";

export const htzConfirmationsRoutes: RouteObject[] = [
  {
    index: true,
    element: <ConfirmationIndexRoute />,
  },
  {
    path: ":confirmationId",
    element: <ConfirmationRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <DataRoute />,
      },
      {
        path: "positions",
        element: <PositionsRoute />,
      },
      {
        path: "addresses",
        element: <AddressesRoute />,
      },
      {
        path: "representative",
        element: <RepresentativeRoute />,
      },
    ],
  },
];
