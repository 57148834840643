import { useAuth } from "@/shared/lib/authorization/auth-context";
import { hasRole } from "./user";

export function useGuard(request: string) {
  const { user } = useAuth();

  let canExecute: boolean;

  if (!user) {
    canExecute = false;
  } else {
    // TODO request to backend to check if user has permission
    // * not only RBAC! but also other associated permissions
    switch (request) {
      case "Processes.State.ForceRunningRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Calculation.UpdateParametersRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Confirmation.UpdateIncidentalCostUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Confirmation.UpdateTreatmentUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Confirmation.UpdateFinalDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Contract.UpdateSalesDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Contract.UpdateAbsoluteDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Offer.UpdateIncidentalCostUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Offer.UpdateHourlyRateRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Offer.UpdateRatePerAccessPanelRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Offer.UpdateSalesDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Offer.UpdateTreatmentUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "RLT.Treatment.UpdateCycleDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Calculation.UpdateParametersRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Confirmation.UpdateIncidentalCostUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Confirmation.UpdateTreatmentUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Confirmation.UpdateTreatmentIgnoreOfferDiscountsRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Confirmation.UpdateFinalDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Contract.UpdateSalesDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Contract.UpdateAbsoluteDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Offer.UpdateIncidentalCostUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Offer.UpdateHourlyRateRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Offer.UpdateRatePerAccessPanelRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Offer.UpdateSalesDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Offer.UpdateTreatmentUseCalculationRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      case "VBS.Treatment.UpdateCycleDiscountRequest":
        canExecute = ["Admin", "Director"].some((r) => hasRole(user, r));
        break;
      default:
        canExecute = false;
    }
  }

  return { canExecute };
}
