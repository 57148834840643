import { CustomerAddressCard } from "@/shared/components/domain/addresses/customer-address-card";
import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import { Address } from "@/services/backend/addresses/address/address";
import {
  Order,
  updatePostalAddress,
  updateServiceAddress,
} from "@/services/backend/htz/orders/order";
import t from "@/lang/lang";
import {
  fieldErrorsForPrefix,
  resetField,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { useParams } from "react-router-dom";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  useHtzOrderPutMutation,
  useHtzOrderShowQuery,
} from "@/services/backend/htz/orders/service";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";

export function AddressesRoute() {
  const { orderId } = useParams();
  const {
    data: order,
    isLoading,
    error,
  } = useHtzOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return <Addresses order={order!} />;
}

function Addresses({ order }: { order: Order }) {
  const { notReadyError, setNotReadyError } = useErrArtefactNotReady();

  const [request, setRequest] = useState({ ...order });
  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzOrderPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const validationError =
    notReadyError ?? (rtkErrIsValidationError(error) ? error.data : null);

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setNotReadyError(resetField(notReadyError, prefixedField));
  };

  const onCustomerAddressChange = (customerAddress: Address) => {
    setRequest(updatePostalAddress(request, customerAddress));
  };

  const onServiceAddressChange = (serviceAddress: Address) => {
    setRequest(updateServiceAddress(request, serviceAddress));
  };

  return (
    <div className="space-y-4">
      <CustomerAddressCard
        customerAddress={request.postalAddress}
        onCustomerAddressChange={onCustomerAddressChange}
        description={t("Adresse an welche das Angebot geschickt werden soll.")}
        fieldErrors={fieldErrorsForPrefix(validationError, "postalAddress.")}
        resetFieldError={resetValidationErrorField("postalAddress.")}
      />
      <ServiceAddressCard
        serviceAddress={request.serviceAddress}
        onServiceAddressChange={onServiceAddressChange}
        fieldErrors={fieldErrorsForPrefix(validationError, "serviceAddress.")}
        resetFieldError={resetValidationErrorField("serviceAddress.")}
      />
    </div>
  );
}
