import { Button } from "@/shared/components/ui/button";
import { Edit } from "lucide-react";
import { NavLink } from "react-router-dom";
import { useDocumentationPath } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/documentation/[documentationId]/_components/use-documentation-path";

export function EditItemButton({
  itemId,
  documentationId,
  workOrderId,
}: {
  documentationId: string;
  itemId: string;
  workOrderId: string;
}) {
  const docuPath = useDocumentationPath(workOrderId, documentationId);
  if (docuPath === "") {
    return null;
  }

  const to = `${docuPath}/${itemId}`;

  return (
    <NavLink to={to}>
      <Button size="sm" variant="outline">
        <Edit className="h-4 w-4" />
      </Button>
    </NavLink>
  );
}
