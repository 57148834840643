import { msalInstance } from "@/shared/lib/authorization/msal-instance";
import {
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";

/**
 * acquireBackendAccessToken is a wrapper for acquireAccessToken with the scopes
 * correctly configured.
 */
export const acquireBackendAccessToken = async () => {
  const scopes = splitEnvScopes(import.meta.env.VITE_MSAL_BACKEND_SCOPES);
  return acquireAccessToken(scopes);
};

/**
 * acquireMSGraphAccessToken is a wrapper for acquireAccessToken with the scopes
 * correctly configured.
 */
export const acquireMSGraphAccessToken = async () => {
  const scopes = splitEnvScopes(import.meta.env.VITE_MSAL_MS_GRAPH_SCOPES);
  return acquireAccessToken(scopes);
};

/**
 * acquireAccessToken can be used to get a token outside of a react component.
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component
 * @param scopes allows to specify the scopes for the resource for which the accessToken is to be acquired.
 *               Keep in mind, that scopes can only be for the same resource. If scopes for multiple resources
 *               are requested, the returned accessToken will only be valid for the first scope/resource.
 *               Further, token caching will not work if scopes from different resources are requested.
 *               https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md#working-with-multiple-resources
 */
const acquireAccessToken = async (scopes: string[]) => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside the context of MsalProvider
     */
    throw new Error("no accounts signed in to msal instance");
  }

  const request: SilentRequest = {
    scopes,
    account: accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch (e) {
    // This is needed in case the refresh token is expired or no session data is set
    if (e instanceof InteractionRequiredAuthError) {
      // Any code after this will not be executed. See method docs.
      // Do not await this method. See method docs.
      msalInstance.acquireTokenRedirect(request);
    }
    // this should not happen, if msal is correctly configured
    return "";
  }
};

function splitEnvScopes(env: string): string[] {
  if (!env || env === "") {
    return [];
  }
  return env.split(",");
}
