import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import {
  fieldErrorsForPrefix,
  hasFieldError,
  resetField,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { Label } from "@/shared/components/ui/label";
import { DatePicker } from "@/shared/components/ui/date-picker";
import {
  updateAddress,
  updateBeginDate,
  updateComment,
  updateContact,
  updateEndDate,
  updateRepresentative,
  updateTeamLeader,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import { Address } from "@/services/backend/addresses/address/address";
import { ContactCard } from "@/shared/components/domain/contacts/contact-card";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { RepresentativeCard } from "@/shared/components/domain/representatives/representative-card";

export function DataCard() {
  return (
    <div className="space-y-2">
      <BaseDataCard />
      <AddressCard />
      <div className="grid gap-2 md:grid-cols-2">
        <ServiceContactCard />
        <ServiceRepresentativeCard />
      </div>
    </div>
  );
}

function BaseDataCard() {
  const { workOrder, onWorkOrderChange, validationError, setValidationError } =
    useWorkOrderContext();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Arbeitsschein")}</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-2 gap-4">
          <BeginDateInput
            workOrder={workOrder}
            onWorkOrderChange={onWorkOrderChange}
            validationError={validationError}
            setValidationError={setValidationError}
          />
          <EndDateInput
            workOrder={workOrder}
            onWorkOrderChange={onWorkOrderChange}
            validationError={validationError}
            setValidationError={setValidationError}
          />
        </div>
        <TeamLeaderInput
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
          validationError={validationError}
          setValidationError={setValidationError}
        />
        <CommentInput
          workOrder={workOrder}
          onWorkOrderChange={onWorkOrderChange}
          validationError={validationError}
          setValidationError={setValidationError}
        />
      </CardContent>
    </Card>
  );
}

function AddressCard() {
  const { workOrder, onWorkOrderChange, validationError, setValidationError } =
    useWorkOrderContext();

  const onServiceAddressChange = (serviceAddress: Address) => {
    onWorkOrderChange(updateAddress(workOrder, serviceAddress));
  };

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  return (
    <ServiceAddressCard
      serviceAddress={workOrder.address}
      onServiceAddressChange={onServiceAddressChange}
      fieldErrors={fieldErrorsForPrefix(validationError, "serviceAddress.")}
      resetFieldError={resetValidationErrorField("serviceAddress.")}
    />
  );
}

interface DateInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
}

function BeginDateInput({
  workOrder,
  onWorkOrderChange,
  validationError,
  setValidationError,
}: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onWorkOrderChange(updateBeginDate(workOrder, date));
  };

  const formattedDate = workOrder.beginDate
    ? new Date(workOrder.beginDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
    setValidationError(resetField(validationError, "beginDate"));
  };

  const markError = hasFieldError(validationError, "beginDate");

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Arbeitsbeginn")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={workOrder.immutable}
      />
    </div>
  );
}

function EndDateInput({
  workOrder,
  onWorkOrderChange,
  validationError,
  setValidationError,
}: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onWorkOrderChange(updateEndDate(workOrder, date));
  };

  const formattedDate = workOrder.endDate
    ? new Date(workOrder.endDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
    setValidationError(resetField(validationError, "endDate"));
  };

  const markError = hasFieldError(validationError, "endDate");

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Arbeitsende")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={workOrder.immutable}
      />
    </div>
  );
}

interface TeamLeaderInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
}

function TeamLeaderInput({
  workOrder,
  onWorkOrderChange,
  validationError,
  setValidationError,
}: TeamLeaderInputProps) {
  const onTeamLeaderChange = (teamLeader: string) => {
    onWorkOrderChange(updateTeamLeader(workOrder, teamLeader));
    setValidationError(resetField(validationError, "teamLeader"));
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Teamleiter")}</Label>
      <Input
        type="text"
        value={workOrder.teamLeader}
        onChange={(e) => onTeamLeaderChange(e.target.value)}
        disabled={workOrder.immutable}
      />
      <InputValidationErrors error={validationError} field="teamLeader" />
    </div>
  );
}

interface CommentInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
}

function CommentInput({
  workOrder,
  onWorkOrderChange,
  validationError,
  setValidationError,
}: CommentInputProps) {
  const onCommentChange = (comment: string) => {
    onWorkOrderChange(updateComment(workOrder, comment));
    setValidationError(resetField(validationError, "comment"));
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Kommentar (intern)")}</Label>
      <Textarea
        value={workOrder.comment}
        onChange={(e) => onCommentChange(e.target.value)}
        disabled={workOrder.immutable}
      />
      <InputValidationErrors error={validationError} field="comment" />
    </div>
  );
}

function ServiceContactCard() {
  const { workOrder, onWorkOrderChange, validationError, setValidationError } =
    useWorkOrderContext();

  const onContactChange = (contact: Contact) => {
    onWorkOrderChange(updateContact(workOrder, contact));
  };

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  return (
    <ContactCard
      contact={workOrder.contact}
      onContactChange={onContactChange}
      fieldErrors={fieldErrorsForPrefix(validationError, "contact.")}
      resetFieldError={resetValidationErrorField("contact.")}
    />
  );
}

function ServiceRepresentativeCard() {
  const { workOrder, onWorkOrderChange, validationError, setValidationError } =
    useWorkOrderContext();

  const onRepresentativeChange = (rep: Representative) => {
    onWorkOrderChange(updateRepresentative(workOrder, rep));
  };

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  return (
    <RepresentativeCard
      representative={workOrder.representative}
      onRepresentativeChange={onRepresentativeChange}
      fieldErrors={fieldErrorsForPrefix(validationError, "representative.")}
      resetFieldError={resetValidationErrorField("representative.")}
    />
  );
}
