export enum SegmentKind {
  AirHandlingUnits = "AirHandlingUnits",
  Ductwork = "Ductwork",
  ExhaustHoods = "ExhaustHoods",
  VentilatedCeilings = "VentilatedCeilings",
  Extras = "Extras",
}

// The order is intentional, it reflects the common approach
// when collecting treatments
export const SegmentKindList = [
  SegmentKind.ExhaustHoods,
  SegmentKind.VentilatedCeilings,
  SegmentKind.Ductwork,
  SegmentKind.AirHandlingUnits,
  SegmentKind.Extras,
];
