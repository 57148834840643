import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { Contract } from "@/services/backend/vbs/contracts/contract";
import { useVbsContractCopyFromOfferMutation } from "@/services/backend/vbs/contracts/service";
import { useEffect } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

interface Props {
  /**
   * The contract to display the positions for.
   */
  contract: Contract;
  /**
   * The offerId of the offer to which the contract may belong.
   * Passing it will enable the "Copy from offer" button and
   * some information text.
   */
  offerId?: string;
}

export function ContractPositions({ contract, offerId = undefined }: Props) {
  return (
    <Card id="positions">
      <CardHeader>
        <div className="flex items-start justify-between">
          <div>
            <CardTitle>{t("Vertragsleistungen")}</CardTitle>
            <CardDescription>
              {offerId &&
                t(
                  "Die Positionen welche im Vertrag enthalten sein sollen. Diese sollten Grundsätzlich mit dem Angebot übereinstimmen. Wenn notwendig, können sie aber hier angepasst werden. Beispielsweise, wenn im ersten Angebot besondere Leistungen enthalten waren, die später nicht mehr relevant sein werden.",
                )}
            </CardDescription>
          </div>
          {offerId && (
            <CopyCalculationFromOfferButton
              contract={contract}
              offerId={offerId}
            />
          )}
        </div>
      </CardHeader>
      <TreatmentComponent
        treatmentId={contract.treatmentId}
        availableColumns={[
          Column.Position,
          Column.ComponentKind,
          Column.BasePrice,
          Column.Cycle,
          Column.CycleDiscount,
          Column.ContractPrice,
          Column.Comment,
          Column.Actions,
        ]}
        className="mb-8 rounded-none border-l-0 border-r-0 shadow-none"
      />
    </Card>
  );
}

function CopyCalculationFromOfferButton({
  contract,
  offerId,
}: {
  contract: Contract;
  offerId: string;
}) {
  const [copy, { isLoading, error, reset }] =
    useVbsContractCopyFromOfferMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const onClick = () => {
    if (isLoading) {
      return;
    }
    copy({
      contractId: contract.id,
      offerId,
      copyCalculation: true,
      copyDiscounts: false,
      copyAddresses: false,
    });
  };

  return (
    <Button variant="outline" onClick={onClick} disabled={isLoading}>
      {t("Leistungen aus Angebot kopieren")}
    </Button>
  );
}
