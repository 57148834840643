import { backendApi } from "@/services/backend/api";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { Sequence } from "@/services/backend/numbers/artefact-number/sequence";
import { ArtefactNumber } from "@/services/backend/numbers/artefact-number/artefact-number";

const artefactNumberApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["NumbersArtefactNumberSequence"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      numbersArtefactNumberCreateSequence: build.mutation<
        Sequence,
        CreateSequenceRequest
      >({
        query: (request) => ({
          url: `/numbers/artefact-number/sequence`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["NumbersArtefactNumberSequence"],
      }),
      numbersArtefactNumberShowSequence: build.query<
        Sequence,
        ShowSequenceRequest
      >({
        query: (request) => ({
          url: `/numbers/artefact-number/sequence`,
          method: "GET",
          params: request,
        }),
        providesTags: (_result, _e, request) => [
          {
            type: "NumbersArtefactNumberSequence",
            id: request.artefactKind + request.companyId,
          },
        ],
      }),
      numbersArtefactNumberShow: build.query<ArtefactNumber, ShowRequest>({
        query: (request) => ({
          url: `/numbers/artefact-number`,
          method: "GET",
          params: request,
        }),
      }),
    }),
  });

export const {
  useNumbersArtefactNumberCreateSequenceMutation,
  useNumbersArtefactNumberShowSequenceQuery,
  useNumbersArtefactNumberShowQuery,
} = artefactNumberApi;

export interface CreateSequenceRequest {
  artefactKind: string;
  companyId: string;
  start: number;
}

export interface ShowSequenceRequest {
  artefactKind: ArtefactKind;
  companyId: string;
}

export interface ShowRequest {
  artefactId: string;
}
