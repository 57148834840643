import { backendApi } from "@/services/backend/api";
import { Customer } from "@/services/backend/customers/customer";
import { Filter } from "@/shared/lib/filter/filter";

const customersApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    customersCustomerShow: build.query<Customer, ShowRequest>({
      query: (request) => ({
        url: `/customers/customer`,
        method: "GET",
        params: request,
      }),
    }),
    customersCustomerList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/customers/customer/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
    }),
  }),
});

export const { useCustomersCustomerShowQuery, useCustomersCustomerListQuery } =
  customersApi;

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  customers: Customer[];
  count: number;
}
