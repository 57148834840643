import { backendApi } from "@/services/backend/api";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { DustDensitySample } from "@/services/backend/samples/sample/dust-density-sample";
import { Filter } from "@/shared/lib/filter/filter";
import { EntityId } from "@/shared/nidavellir/types/entity-id";

const dustDensitySampleApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesDustDensitySample"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesDustDensitySamplePut: build.mutation<
        DustDensitySample,
        PutRequest
      >({
        query: (request) => ({
          url: `/samples/dust-density`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesDustDensitySample", id: request.id },
          "SamplesDustDensitySample",
        ],
      }),
      samplesDustDensitySampleUpdateLab: build.mutation<
        DustDensitySample,
        UpdateLabRequest
      >({
        query: (request) => ({
          url: `/samples/dust-density/lab`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesDustDensitySample", id: request.id },
          "SamplesDustDensitySample",
        ],
      }),
      samplesDustDensitySampleShow: build.query<DustDensitySample, ShowRequest>(
        {
          query: (request) => ({
            url: `/samples/dust-density`,
            method: "GET",
            params: request,
          }),
          providesTags: providesEntity("SamplesDustDensitySample"),
        },
      ),
      samplesDustDensitySampleList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/dust-density/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.samples, "SamplesDustDensitySample"),
      }),
      samplesDustDensitySampleDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/samples/dust-density`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["SamplesDustDensitySample"],
      }),
    }),
  });

export const {
  useSamplesDustDensitySamplePutMutation,
  useSamplesDustDensitySampleUpdateLabMutation,
  useSamplesDustDensitySampleShowQuery,
  useSamplesDustDensitySampleListQuery,
  useSamplesDustDensitySampleDeleteMutation,
} = dustDensitySampleApi;

export interface PutRequest extends DustDensitySample {}

export interface UpdateLabRequest {
  id: string;
  labId: EntityId;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  lab?: Filter<string>;
  artefact?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  samples: DustDensitySample[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
