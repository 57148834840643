import { Position } from "@/services/backend/vbs/treatments/position";
import {
  UpdateTipCommissionRequest,
  useVbsTreatmentUpdateTipCommissionMutation,
} from "@/services/backend/vbs/treatments/service";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { FloatNumberInput } from "@/routes/gesec/processes/_components/ui/number-input";

export function TipCommissionCell({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [request, setRequest] = useState<UpdateTipCommissionRequest>({
    id: treatmentId,
    positionId: position.id,
    rate: position.tipCommission,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentUpdateTipCommissionMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div className="flex items-center justify-end">
      <span className="mr-2 flex h-6 items-center">%</span>
      <FloatNumberInput
        value={request.rate}
        onChange={(rate) => setRequest({ ...request, rate })}
      />
    </div>
  );
}
