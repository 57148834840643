import { Position } from "@/services/backend/rlt/treatments/position";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useState } from "react";
import { useRltDefaultsShowQuery } from "@/services/backend/rlt/defaults/service";
import { Key } from "@/services/backend/rlt/defaults/defaults";
import { Contract } from "@/services/backend/rlt/defaults/contract";
import {
  UpdateCycleRequest,
  useRltTreatmentUpdateCycleMutation,
} from "@/services/backend/rlt/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";

export function CycleCell({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [request, setRequest] = useState<UpdateCycleRequest>({
    id: treatmentId,
    positionId: position.id,
    cycle: position.cycle,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentUpdateCycleMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  return (
    <div className="whitespace-nowrap">
      <CycleSelector
        cycle={request.cycle}
        onCycleChange={(cycle) => setRequest({ ...request, cycle })}
      />
    </div>
  );
}

function CycleSelector({
  cycle,
  onCycleChange,
}: {
  cycle: number;
  onCycleChange: (cycle: number) => void;
}) {
  const { data } = useRltDefaultsShowQuery({
    key: Key.Contract,
  });
  const defaultData = data?.data as Contract;
  const defaultCycles =
    defaultData?.cycleDiscounts.map((discount) => discount.cycle) ?? [];

  return (
    <Select
      value={cycle.toString()}
      onValueChange={(newValue) => onCycleChange(Number(newValue))}
    >
      <SelectTrigger className="h-6">
        <SelectValue />
      </SelectTrigger>
      <SelectContent align="end">
        <SelectGroup>
          {defaultCycles.map((f) => (
            <SelectItem key={f} value={f.toString()}>
              {f.toString()}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
