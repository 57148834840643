import {
  Confirmation,
  updateDate,
  updateOfferReference,
  updateOrderDate,
  updateOrderReference,
  updateSpecialAgreements,
} from "@/services/backend/htz/confirmations/confirmation";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { DatePicker } from "@/shared/components/ui/date-picker";
import { useConfirmationContext } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/_components/confirmation-context";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  hasFieldError,
  resetField,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { Separator } from "@/shared/components/ui/separator";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import {
  ErrArtefactNotReady,
  errorsFor,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { AlertCircle } from "lucide-react";
import { OrderIdentifier } from "@/shared/components/domain/htz/order/order-identifier";

export function DataCard() {
  const {
    confirmation,
    onConfirmationChange,
    validationError,
    setValidationError,
  } = useConfirmationContext();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Auftragsbestätigung")}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <OrderReferenceSelect
          confirmation={confirmation}
          onConfirmationChange={onConfirmationChange}
        />
        <Separator className="my-4" />
        <div className="grid gap-4 md:grid-cols-3">
          <OrderDateInput
            confirmation={confirmation}
            onConfirmationChange={onConfirmationChange}
            validationError={validationError}
            setValidationError={setValidationError}
          />
          <OfferReferenceSelect
            confirmation={confirmation}
            onConfirmationChange={onConfirmationChange}
            validationError={validationError}
          />
          <DateInput
            confirmation={confirmation}
            onConfirmationChange={onConfirmationChange}
            validationError={validationError}
            setValidationError={setValidationError}
          />
        </div>
        <Separator className="my-4" />
        <SpecialAgreementsInput
          confirmation={confirmation}
          onConfirmationChange={onConfirmationChange}
        />
      </CardContent>
    </Card>
  );
}

interface DateInputProps {
  confirmation: Confirmation;
  onConfirmationChange: (confirmation: Confirmation) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
}

function DateInput({
  confirmation,
  onConfirmationChange,
  validationError,
  setValidationError,
}: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onConfirmationChange(updateDate(confirmation, date));
  };

  const formattedDate = confirmation.date
    ? new Date(confirmation.date)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
    setValidationError(resetField(validationError, "date"));
  };

  const markError = hasFieldError(validationError, "date");

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Auftragsbestätigungsdatum")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={confirmation.immutable}
      />
    </div>
  );
}

function OrderDateInput({
  confirmation,
  onConfirmationChange,
  validationError,
  setValidationError,
}: DateInputProps) {
  const onOrderDateChange = (date: string | null) => {
    onConfirmationChange(updateOrderDate(confirmation, date));
  };

  const formattedDate = confirmation.orderDate
    ? new Date(confirmation.orderDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onOrderDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onOrderDateChange(dateTime.toISOString());
    setValidationError(resetField(validationError, "orderDate"));
  };

  const markError = hasFieldError(validationError, "orderDate");

  const disabled = confirmation.immutable || confirmation.orderId !== null;

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Datum des Auftragseingangs")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={disabled}
      />
    </div>
  );
}

interface OrderReferenceSelectProps {
  confirmation: Confirmation;
  onConfirmationChange: (confirmation: Confirmation) => void;
}

function OrderReferenceSelect({
  confirmation,
  onConfirmationChange,
}: OrderReferenceSelectProps) {
  const { orders, offers } = useConfirmationContext();

  const disabled = confirmation.immutable;

  const onValueChange = (v: string) => {
    if (disabled) {
      return;
    }

    const order = orders.find((ord) => ord.id === v);
    const offer = offers.find((off) => off.id === order?.offerId);
    onConfirmationChange(
      updateOrderReference(confirmation, order ?? null, offer ?? null),
    );
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Bestätigt Auftragseingang")}</Label>
      <Select
        value={confirmation.orderId ?? undefined}
        onValueChange={onValueChange}
        disabled={disabled}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Auftragseingang auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {orders.map((order) => (
            <SelectItem value={order.id} key={order.id}>
              <OrderIdentifier orderId={order.id} />
            </SelectItem>
          ))}
          <SelectSeparator />
          <SelectItem value="00000000-0000-0000-0000-000000000000">
            {t("Kein Auftragseingang")}
          </SelectItem>
        </SelectContent>
      </Select>
      <div className="text-xs text-muted-foreground">
        {t(
          "Die Auswahl des Auftragseingangs überschreibt alle verbundenen Daten: Datum des Auftragseingangs, Referenz zum Angebot, Leistungen, Adressen, Betreuer, Adressen, Betreuer. Wenn ein Auftragseingang ausgewählt ist, können das Datum des Auftragseingangs und die Referenz zum Angebot nicht mehr bearbeitet werden.",
        )}
      </div>
    </div>
  );
}

interface OfferReferenceSelectProps {
  confirmation: Confirmation;
  onConfirmationChange: (confirmation: Confirmation) => void;
  validationError: ValidationError | null;
}

function OfferReferenceSelect({
  confirmation,
  onConfirmationChange,
  validationError,
}: OfferReferenceSelectProps) {
  const { offers } = useConfirmationContext();

  const disabled = confirmation.immutable || confirmation.orderId !== null;

  const onValueChange = (v: string) => {
    if (disabled) {
      return;
    }

    const offer = offers.find((ord) => ord.id === v);
    onConfirmationChange(updateOfferReference(confirmation, offer ?? null));
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Bezieht sich auf Angebot")}</Label>
      <Select
        value={confirmation.offerId ?? undefined}
        onValueChange={onValueChange}
        disabled={disabled}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Angebot auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {offers.map((offer) => (
            <SelectItem value={offer.id} key={offer.id}>
              <ArtefactNumberById artefactId={offer.id} />
            </SelectItem>
          ))}
          <SelectItem value="00000000-0000-0000-0000-000000000000">
            {t("Kein Angebot")}
          </SelectItem>
        </SelectContent>
      </Select>
      <ConfirmationNotReadyWarning field="offerId" error={validationError} />
      <div className="text-xs text-muted-foreground">
        {t(
          "Die Auswahl eines Angebots überschreibt die Leistungen der Auftragsbestätigung mit denen des Angebots.",
        )}
      </div>
    </div>
  );
}

function ConfirmationNotReadyWarning({
  field,
  error,
}: {
  field: string;
  error: ErrArtefactNotReady | null;
}) {
  if (!error) {
    return null;
  }

  const fieldErrors = errorsFor(error, field);

  if (fieldErrors) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <AlertCircle className="inline-flex h-4 w-4 text-red-500" />
          </TooltipTrigger>
          <TooltipContent align="end">
            <div className="px-2 py-1">
              <div>{t("Angebot nicht fertig:")}</div>
              <Separator className="mb-2" />
              {fieldErrors.map((msg) => (
                <div key={msg} className="text-sm text-red-700">
                  {msg}
                </div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
}

interface SpecialAgreementsInputProps {
  confirmation: Confirmation;
  onConfirmationChange: (confirmation: Confirmation) => void;
}

function SpecialAgreementsInput({
  confirmation,
  onConfirmationChange,
}: SpecialAgreementsInputProps) {
  const onAgreementsChange = (text: string) => {
    onConfirmationChange(updateSpecialAgreements(confirmation, text));
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Besondere Vereinbarungen")}</Label>
      <Textarea
        value={confirmation.specialAgreements}
        onChange={(e) => onAgreementsChange(e.target.value)}
        disabled={confirmation.immutable}
      />
    </div>
  );
}
