import { Kind } from "@/services/backend/rlt/treatments/component";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";

export function KindCell({
  kind,
  className = "",
}: {
  kind: Kind;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "flex h-6 items-center whitespace-nowrap font-bold",
        className,
      )}
    >
      {t(kind)}
    </div>
  );
}
