import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";

export interface LastMinuteRiskAnalysis extends Artefact, Immutability {
  processId: string;
  customerId: string;
  companyId: string;
  conductedBy: string;
  confirmed: boolean;
  confirmedBy: string;
  confirmedAt: string;
  checks: Check[];
}

export interface Check {
  id: string;
  number: number;
  description: string;
  checked: boolean;
  options: string[];
  result: number;
  comment: string;
  children: Check[];
  childrenIfResultOneOf: number[];
}

export function checkRequiresChildren(check: Check): boolean {
  if (!check.checked) {
    return false;
  }
  if (check.childrenIfResultOneOf == null) {
    return false;
  }
  return check.childrenIfResultOneOf.includes(check.result);
}
