import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { useState } from "react";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useHtzAhuComponentCatalogDeleteMutation } from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";

export function DeleteComponentCatalogDialog({
  catalog,
}: {
  catalog: AhuComponentCatalog;
}) {
  const [open, setOpen] = useState(false);
  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useHtzAhuComponentCatalogDeleteMutation();

  if (isSuccess) {
    reset();
    setOpen(false);
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    doDelete({ id: catalog.id });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Katalog löschen?")}</DialogTitle>
          <DialogDescription>
            {t("Katalog: ")}
            {catalog.name}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Close")}</Button>
          </DialogClose>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
