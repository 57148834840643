import { backendApi } from "@/services/backend/api";
import {
  ListPestCategoryRecordsRequest,
  ListPestTypeRecordsRequest,
  PestCategoryList,
  PestCategoryRecordsList,
  PestTypeList,
  PestTypeRecordsList,
} from "@/services/backend/qpm/pests/types";

const qpmPestServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "QpmPestCategory",
      "QpmPestType",
      "QpmPestCategoryRecord",
      "QpmPestTypeRecord",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmPestCategoryList: build.query<PestCategoryList, null>({
        query: () => ({
          url: `/qpm/pest-category/list`,
          method: "GET",
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmPestCategory", id: "LIST" }] : [],
      }),
      qpmPestTypeList: build.query<PestTypeList, null>({
        query: () => ({
          url: `/qpm/pest-type/list`,
          method: "GET",
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmPestCategory", id: "LIST" }] : [],
      }),
      qpmPestCategoryRecordList: build.query<
        PestCategoryRecordsList,
        ListPestCategoryRecordsRequest
      >({
        query: (request) => ({
          url: `/qpm/pest-category-record/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmPestCategoryRecord", id: "LIST" }] : [],
      }),
      qpmPestTypeRecordList: build.query<
        PestTypeRecordsList,
        ListPestTypeRecordsRequest
      >({
        query: (request) => ({
          url: `/qpm/pest-type-record/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmPestTypeRecord", id: "LIST" }] : [],
      }),
    }),
  });

export const {
  useQpmPestCategoryListQuery,
  useQpmPestCategoryRecordListQuery,
  useQpmPestTypeListQuery,
  useQpmPestTypeRecordListQuery,
} = qpmPestServiceApi;
