import { Card, CardContent, CardHeader } from "@/shared/components/ui/card";
import { H2, H3 } from "@/shared/components/ui/typography";
import {
  useQpmBuildingAddressListQuery,
  useQpmBuildingDeleteMutation,
  useQpmBuildingListQuery,
} from "@/services/backend/qpm/buildings/service";
import { useParams } from "react-router-dom";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import {
  Building,
  BuildingAddress,
} from "@/services/backend/qpm/buildings/types";
import { AddBuildingComponent } from "@/routes/gemex/processes/qpm/shared/components/add-building-component";
import { BuildingAddressComponent } from "@/routes/gemex/processes/qpm/building/_components/building-address-component";
import { BuildingLevelsComponent } from "@/routes/gemex/processes/qpm/building/_components/building-levels-component";
import { useCallback, useEffect, useState } from "react";
import { BuildingSelector } from "@/routes/gemex/processes/qpm/shared/components/building-selector-component";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/shared/components/ui/alert-dialog";

export default function ManageBuildingRoute() {
  const { toast } = useToast();
  const { processId } = useParams();
  const [selectedBuilding, setSelectedBuilding] = useState<Building>();
  const [currentAddress, setCurrentAddress] = useState<BuildingAddress | null>(
    null,
  );
  const [currentBuildingId, setCurrentBuildingId] = useState<string | null>();

  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  const { data: addressList, error: addressListError } =
    useQpmBuildingAddressListQuery(
      {
        customerId: process?.customerId as string,
      },
      {
        skip: !process?.customerId,
      },
    );

  const {
    data: buildingList,
    error: errorBuildingList,
    isLoading: buildingIsLoading,
  } = useQpmBuildingListQuery(
    {
      customerId: process?.customerId as string,
    },
    {
      skip: !process?.customerId,
    },
  );

  const [
    deleteBuilding,
    {
      error: errorDeleteBuilding,
      reset: resetDeleteBuilding,
      isSuccess: isSuccessDeleteBuilding,
    },
  ] = useQpmBuildingDeleteMutation();

  useEffect(() => {
    if (addressList && !currentAddress) {
      setCurrentAddress(addressList.addresses[0]);
    }
  }, [addressList, currentAddress]);

  useEffect(() => {
    if (buildingList) {
      const building = buildingList.buildings.find(
        (b) => b.id === currentBuildingId,
      );
      if (building) {
        setSelectedBuilding(building);
        setCurrentBuildingId(building.id);
      } else {
        setSelectedBuilding(buildingList.buildings[0]);
      }
    }
  }, [buildingList, currentBuildingId]);

  useEffect(() => {
    if (
      !selectedBuilding &&
      buildingList &&
      buildingList.buildings.length > 0
    ) {
      setSelectedBuilding(buildingList.buildings[0]);
      setCurrentBuildingId(buildingList.buildings[0].id);
    }
  }, [buildingList, selectedBuilding]);

  useEffect(() => {
    if (errorDeleteBuilding) {
      toast({ ...parseRTKQueryError(errorDeleteBuilding) });
      resetDeleteBuilding();
    }
  }, [errorDeleteBuilding, resetDeleteBuilding, toast]);

  useEffect(() => {
    if (isSuccessDeleteBuilding) {
      if (!buildingList || buildingList.buildings.length <= 0) {
        setSelectedBuilding(undefined);
        setCurrentBuildingId(undefined);
      } else {
        setSelectedBuilding(buildingList.buildings[0]);
        setCurrentBuildingId(buildingList.buildings[0].id);
      }
    }
  }, [
    isSuccessDeleteBuilding,
    setSelectedBuilding,
    setCurrentBuildingId,
    buildingList,
  ]);

  const handleBuildingSelect = useCallback(
    (buildingId: string) => {
      if (!buildingList) {
        return;
      }
      const building = buildingList.buildings.find((b) => b.id === buildingId);
      if (building) {
        setSelectedBuilding(building);
        setCurrentBuildingId(building.id);
      }
    },
    [buildingList],
  );

  const handleNewBuildingCreateCallback = useCallback(
    (building: Building) => {
      if (selectedBuilding && selectedBuilding.id === building.id) {
        return;
      }
      setSelectedBuilding(building);
      setCurrentBuildingId(building.id);
    },
    [selectedBuilding, setCurrentBuildingId],
  );

  const onAddressedSaved = useCallback(
    (address: BuildingAddress) => {
      setCurrentAddress(address);
    },
    [setCurrentAddress],
  );

  const handleDelete = useCallback(
    (buildingId: string) => {
      deleteBuilding({ id: buildingId });
    },
    [deleteBuilding],
  );

  if (processDataIsLoading) {
    return <div>Loading Process data... </div>;
  }

  if (buildingIsLoading) {
    return <div>Loading...</div>;
  }

  if (processDataError) {
    return <RTKQueryErrorAlert error={processDataError} />;
  }

  if (addressListError) {
    return <RTKQueryErrorAlert error={addressListError} />;
  }

  if (errorBuildingList) {
    return <RTKQueryErrorAlert error={errorBuildingList} />;
  }

  if (!process) {
    return <div>{t("Fehler beim Laden der Vorgangsdaten!")}</div>;
  }

  if (!addressList || !addressList.addresses) {
    return <div>{t("Fehler beim laden der Addressdaten")}</div>;
  }

  return (
    <div className="space-y-4">
      <PersistenceContextProvider>
        <div className="flex items-end justify-between">
          <H2>{t("Gebäudeverwaltung")}</H2>
          <PersistenceStateUsingContext className="mr-2" />
        </div>
        <BuildingAddressComponent
          buildingAddress={addressList.addresses[0]}
          customerId={process.customerId}
          onAddressSaved={onAddressedSaved}
        />
      </PersistenceContextProvider>
      <Card>
        <CardHeader>
          <H3>{t("Gebäude und Bereiche")}</H3>
        </CardHeader>
        <CardContent>
          <PersistenceContextProvider>
            {currentAddress && (
              <>
                <div className="flex items-end justify-between">
                  <div className="flex items-end gap-2">
                    <BuildingSelector
                      buildingList={buildingList}
                      selectedBuilding={selectedBuilding}
                      handleBuildingSelect={handleBuildingSelect}
                      customerId={process!.customerId}
                      addressId={currentAddress.id}
                      onBuildingCreateCallback={handleNewBuildingCreateCallback}
                    />
                    {selectedBuilding &&
                      selectedBuilding.components &&
                      selectedBuilding.components.length === 0 && (
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <Button variant="destructive" size="icon">
                              <Trash2 className="h-5 w-5" />
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>
                                {t("Gebäude löschen?")}
                              </AlertDialogTitle>
                              <AlertDialogDescription>
                                {t(
                                  `Möchten Sie das Gebäude (${selectedBuilding.name}) unwiderruflich löschen?`,
                                )}
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>
                                {t("Abbruch")}
                              </AlertDialogCancel>
                              <AlertDialogAction
                                onClick={() =>
                                  handleDelete(selectedBuilding.id)
                                }
                                className="bg-destructive hover:bg-destructive/90"
                              >
                                {t("Bestätigen")}
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      )}
                  </div>
                  <PersistenceStateUsingContext className="mr-2" />
                </div>
                {selectedBuilding && (
                  <div
                    key={selectedBuilding.id}
                    className="mt-3 flex flex-col gap-2"
                  >
                    <div className="overflow-clip rounded-md border">
                      <BuildingLevelsComponent
                        buildingId={selectedBuilding.id}
                        levelList={selectedBuilding.components}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {addressList &&
              addressList.addresses.length > 0 &&
              (!buildingList ||
                !buildingList.buildings ||
                (buildingList.buildings.length <= 0 && (
                  <div className="flex flex-col gap-2 p-2">
                    <span>
                      {t(
                        "Es sind keine Gebäude vorhanden. Bitte legen Sie zuerst ein Gebäude an.",
                      )}
                    </span>
                    <AddBuildingComponent
                      customerId={process!.customerId}
                      addressId={addressList.addresses[0].id}
                      onBuildingCreate={handleNewBuildingCreateCallback}
                    />
                  </div>
                )))}
          </PersistenceContextProvider>
        </CardContent>
      </Card>
    </div>
  );
}
