import { Defaults, Key } from "@/services/backend/vbs/defaults/defaults";
import {
  PutRequest,
  useVbsDefaultsPutMutation,
  useVbsDefaultsShowQuery,
} from "@/services/backend/vbs/defaults/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Input } from "@/shared/components/ui/input";
import {
  Contract,
  CycleDiscount,
} from "@/services/backend/vbs/defaults/contract";
import { Button } from "@/shared/components/ui/button";
import { Plus, Trash2 } from "lucide-react";
import { Separator } from "@/shared/components/ui/separator";

export function ContractRoute() {
  const { data, isLoading, error } = useVbsDefaultsShowQuery({
    key: Key.Contract,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading || !data) {
    return (
      <Card className="animate-pulse">
        <CardHeader>
          <CardTitle>{t("Vertragsdaten")}</CardTitle>
          <CardDescription>
            {t(
              "Festlegung von allgemeinen Daten die für VBS Verträge genutzt werden sollen.",
            )}
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return <ContractDefaults defaults={data} />;
}

function ContractDefaults({ defaults }: { defaults: Defaults }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Vertragsdaten")}</CardTitle>
        <CardDescription>
          {t(
            "Festlegung von allgemeinen Daten die für VBS Verträge genutzt werden sollen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="pl-6">{t("Parameter")}</TableHead>
              <TableHead>{t("Einheit")}</TableHead>
              <TableHead className="pr-6">{t("Standardwert")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <CycleDiscounts defaults={defaults} />
            <PaymentTerms defaults={defaults} />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function CycleDiscounts({ defaults }: { defaults: Defaults }) {
  const [request, setRequest] = useState<PutRequest>(defaults);
  const [put, { isLoading, error, isSuccess, reset }] =
    useVbsDefaultsPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { toast } = useToast();
  const [newDiscount, setNewDiscount] = useState<CycleDiscount>({
    cycle: 0,
    discount: 0,
  });

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const data = request.data as Contract;
  const setData = (contract: Contract) => {
    setRequest({
      ...request,
      data: contract,
    });
  };

  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top" colSpan={2}>
        {t("Rabatte für Folgebehandlung")}
      </TableCell>
      <TableCell className="pr-6">
        <div className="flex space-x-2">
          <div className="w-24">{t("Turnus")}</div>
          <div className="w-24">{t("Rabatt %")}</div>
        </div>
        {[...data.cycleDiscounts].sort(byCycle).map((discount) => (
          <div
            key={discount.cycle}
            className="mt-2 flex items-center space-x-2"
          >
            <div className="h-8 w-24 pr-4 pt-1 text-right">
              {discount.cycle} Wochen
            </div>
            <Input
              className="h-8 w-24 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              type="number"
              value={discount.discount}
              onChange={(e) =>
                setData({
                  ...data,
                  cycleDiscounts: data.cycleDiscounts.map((d) => {
                    if (d.cycle === discount.cycle) {
                      return {
                        ...d,
                        discount: e.target.valueAsNumber,
                      };
                    }
                    return d;
                  }),
                })
              }
            />
            <Button
              className="h-8 w-8 p-0"
              variant="destructive"
              onClick={() =>
                setData({
                  ...data,
                  cycleDiscounts: data.cycleDiscounts.filter(
                    (d) => d.cycle !== discount.cycle,
                  ),
                })
              }
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        ))}
        <div className="mt-2 flex items-center space-x-2 border-t pt-2">
          <Input
            className="h-8 w-24 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            type="number"
            value={newDiscount.cycle}
            onChange={(e) =>
              setNewDiscount({
                ...newDiscount,
                cycle: e.target.valueAsNumber,
              })
            }
          />
          <Input
            className="h-8 w-24 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            type="number"
            value={newDiscount.discount}
            onChange={(e) =>
              setNewDiscount({
                ...newDiscount,
                discount: e.target.valueAsNumber,
              })
            }
          />
          <Button
            className="h-8 w-8 p-0"
            onClick={() => {
              setData({
                ...data,
                cycleDiscounts: [...data.cycleDiscounts, newDiscount],
              });
              setNewDiscount({ cycle: 0, discount: 0 });
            }}
          >
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}

function byCycle(a: { cycle: number }, b: { cycle: number }) {
  return a.cycle - b.cycle;
}

function PaymentTerms({ defaults }: { defaults: Defaults }) {
  const [request, setRequest] = useState<PutRequest>(defaults);
  const [put, { isLoading, error, isSuccess, reset }] =
    useVbsDefaultsPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const data = request.data as Contract;
  const setData = (contract: Contract) => {
    setRequest({
      ...request,
      data: contract,
    });
  };

  const [newTerm, setNewTerm] = useState("");

  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top" colSpan={2}>
        {t("Zahlungsbedingungen")}
      </TableCell>
      <TableCell className="w-full pr-6">
        <div className="space-y-2">
          <div className="font-medium">
            {t("Optionen (erste Option ist Standardwert):")}
          </div>
          <div className="text-muted-foreground">
            {t(
              "Hinweis: Die Formulierungen werden so 1:1 in den Vertrag übernommen.",
            )}
          </div>
          {data.paymentTerms.map((term, i) => (
            <div key={term} className="flex items-center space-x-4">
              <Input
                className="w-full"
                value={term}
                onChange={(e) =>
                  setData({
                    ...data,
                    paymentTerms: data.paymentTerms.map((ter, j) =>
                      i === j ? e.target.value : ter,
                    ),
                  })
                }
              />
              <Button
                variant="destructive"
                onClick={() => {
                  setData({
                    ...data,
                    paymentTerms: data.paymentTerms.filter((_, j) => i !== j),
                  });
                }}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))}
          <Separator />
          <div className="flex items-center space-x-4">
            <Input
              className="w-full"
              value={newTerm}
              placeholder={t("Neue Zahlungsbedingung")}
              onChange={(e) => setNewTerm(e.target.value)}
            />
            <Button
              onClick={() => {
                setData({
                  ...data,
                  paymentTerms: [...data.paymentTerms, newTerm],
                });
                setNewTerm("");
              }}
            >
              <Plus className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
