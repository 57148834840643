import { Navigate, RouteObject } from "react-router-dom";
import { WorkOrderIndexRoute } from "@/routes/gesec/processes/[processId]/htz/work-orders/_components/work-order-index-route";
import { WorkOrderRoute } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/work-order-route";
import { DataRoute } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/data/data-route";
import { PositionsRoute } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/positions-route";
import { LaboratoryRoute } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/laboratory/laboratory-route";

export const htzWorkOrdersRoutes: RouteObject[] = [
  {
    index: true,
    element: <WorkOrderIndexRoute />,
  },
  {
    path: ":workOrderId",
    element: <WorkOrderRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <DataRoute />,
      },
      {
        path: "positions",
        element: <PositionsRoute />,
      },
      {
        path: "laboratory",
        element: <LaboratoryRoute />,
      },
    ],
  },
];
