import { NavLink, useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import { Button } from "@/shared/components/ui/button";
import { Terminal } from "lucide-react";
import { useRltWorkOrderShowQuery } from "@/services/backend/rlt/work-orders/service";
import {
  useRltTreatmentShowProcessTreatmentQuery,
  useRltTreatmentShowQuery,
} from "@/services/backend/rlt/treatments/service";
import { Position } from "@/services/backend/rlt/treatments/position";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import t from "@/lang/lang";
import { PositionListItem } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/_components/position-list-item";
import { useShowCompanyQuery } from "@/services/backend/company/company";

export function WorkOrderPositionsRoute() {
  const { workOrderId } = useParams();
  const {
    data: workOrder,
    isLoading,
    error,
  } = useRltWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!workOrder) {
    return <div />;
  }

  return <PositionList workOrder={workOrder} />;
}

export function PositionList(props: { workOrder: WorkOrder }) {
  const { workOrder } = props;

  const {
    data: processTreatment,
    isLoading: ptIsLoading,
    error: ptError,
  } = useRltTreatmentShowProcessTreatmentQuery({
    processId: workOrder.processId,
  });
  const {
    data: treatment,
    isLoading: tmIsLoading,
    error: tmError,
  } = useRltTreatmentShowQuery({
    id: processTreatment?.treatmentId ?? "",
  });

  if (ptIsLoading || tmIsLoading) {
    return <div />;
  }

  const workOrderPositions =
    treatment?.positions.filter(
      (position) => position.assignedWorkOrderId === workOrder.id,
    ) ?? [];

  const areas = workOrderPositions
    .map((position) => position.area)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a.localeCompare(b));

  if (areas?.length === 0) {
    return <NoPositionsAlert workOrder={workOrder} />;
  }

  if (ptError) {
    return <RTKQueryErrorAlert error={ptError} />;
  }

  if (tmError) {
    return <RTKQueryErrorAlert error={tmError} />;
  }

  return (
    <div className="grid grid-cols-1 gap-4">
      {areas?.map((area) => (
        <AreaCard
          workOrder={workOrder}
          treatmentId={treatment!.id}
          positions={workOrderPositions}
          area={area}
          key={area}
        />
      ))}
    </div>
  );
}

function NoPositionsAlert({ workOrder }: { workOrder: WorkOrder }) {
  const { data } = useShowCompanyQuery({ id: workOrder.companyId });
  if (!data) {
    return null;
  }

  const to = `/tenant/${data.portalRoute}/processes/vbs/${workOrder.processId}/work-orders/overview/treatment`;
  return (
    <Alert>
      <Terminal className="h-4 w-4" />
      <AlertTitle>{t("Keine Leistungen zugewiesen")}</AlertTitle>
      <AlertDescription className="space-y-4">
        <div>
          {t("Diesem Arbeitsschein wurden noch keine Leistungen zugewiesen.")}
        </div>
        <NavLink to={to} className="block">
          <Button variant="secondary">
            <span>{t("Leistungen zuweisen")}</span>
          </Button>
        </NavLink>
      </AlertDescription>
    </Alert>
  );
}

function AreaCard(props: {
  treatmentId: string;
  workOrder: WorkOrder;
  positions: Position[];
  area: string;
}) {
  const { treatmentId, workOrder, positions, area } = props;
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t(area)}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-2 font-bold">{t("Arbeiten im Bereich:")}</div>
        <div className="divide-y border-b border-t">
          {positions
            .filter((position) => position.area === area)
            .sort(byPositionNumber)
            .map((position) => (
              <PositionListItem
                key={position.id}
                treatmentId={treatmentId}
                workOrder={workOrder}
                position={position}
              />
            ))}
        </div>
      </CardContent>
    </Card>
  );
}

function byPositionNumber(a: Position, b: Position) {
  return a.number.localeCompare(b.number);
}
