import {
  useErrArtefactNotReady,
  useSetErrArtefactNotReadyFromRtk,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Separator } from "@/shared/components/ui/separator";
import { useDeleteCompanyMatchMutation } from "@/services/backend/dynamics/company-match";
import {
  ArtefactNotReadyAlert,
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsDownload,
  ArtefactSheetActionsHeader,
  ArtefactSheetActionsLock,
  ArtefactSheetContent,
  ArtefactSheetHeader,
  ArtefactSheetImmutabilityWarning,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { Download } from "lucide-react";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useRltCertificateDownloadPDFMutation,
  useRltCertificatePreviewPDFQuery,
} from "@/services/backend/rlt/certificates/service";

export function CertificatePdf({ certificateId }: { certificateId: string }) {
  const { data, isLoading, error } = useRltCertificatePreviewPDFQuery({
    id: certificateId,
  });

  const { notReadyError } = useErrArtefactNotReady();

  return (
    <ArtefactSheet>
      <ArtefactSheetTrigger asChild>
        <Button variant="outline">PDF</Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="max-h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>{t("Download PDF")}</ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <ArtefactSheetPreview
            objectURL={data?.objectURL}
            isLoading={isLoading}
            error={error}
          />
          <ArtefactSheetActions>
            <ArtefactSheetActionsHeader
              title={t("Zertifikat")}
              artefactNumber={null}
            />
            <ArtefactSheetActionsContent>
              <ArtefactNotReadyAlert
                error={notReadyError}
                artefact={t("Zertifikat")}
              />
              <ArtefactSheetImmutabilityWarning
                title={t("Hinweis")}
                description={t(
                  "Wenn ein Zertifikat heruntergeladen wird, wird es festgeschrieben. Es kann dann nicht mehr geändert werden.",
                )}
              />
              <Separator />
              <ArtefactSheetActionsDownload>
                <DownloadDocumentButton certificateId={certificateId} />
              </ArtefactSheetActionsDownload>
              <Separator />
              <ArtefactSheetActionsLock
                mutation={useDeleteCompanyMatchMutation}
                request={{ companyId: certificateId }}
              />
            </ArtefactSheetActionsContent>
          </ArtefactSheetActions>
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function DownloadDocumentButton({ certificateId }: { certificateId: string }) {
  const [open, setOpen] = useState(false);
  const [download, { isLoading, error, data, reset }] =
    useRltCertificateDownloadPDFMutation();
  const { user } = useAuth();

  useDownloadPdf(data, reset);

  useSetErrArtefactNotReadyFromRtk(error, () => {
    setOpen(false);
    reset();
  });

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: certificateId,
      downloadBy: user?.id ?? "",
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="justify-start">
          <Download className="mr-4 h-5 w-5" />
          <span>{t("Zertifikat")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Zertifikat herunterladen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Wenn ein Zertifikat heruntergeladen wird, wird es festgeschrieben. Esx kann dann nicht mehr geändert werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDownload} disabled={isLoading}>
            {isLoading ? (
              <Download className="mr-4 h-5 w-5 animate-bounce" />
            ) : (
              <Download className="mr-4 h-5 w-5" />
            )}
            <span>{t("Herunterladen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
