import { RepresentativeCard } from "@/shared/components/domain/representatives/representative-card";
import { updateRepresentative } from "@/services/backend/htz/offers/offer";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { useOfferContext } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";
import {
  fieldErrorsForPrefix,
  resetField,
} from "@/shared/app-lib/errors/validation-error";

export function RepresentativeRoute() {
  const { offer, onOfferChange, validationError, setValidationError } =
    useOfferContext();

  const onRepresentativeChange = (representative: Representative) => {
    onOfferChange(updateRepresentative(offer, representative));
  };

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  return (
    <div className="space-x-4">
      <RepresentativeCard
        representative={offer.representative}
        onRepresentativeChange={onRepresentativeChange}
        fieldErrors={fieldErrorsForPrefix(validationError, "representative.")}
        resetFieldError={resetValidationErrorField("representative.")}
        disabled={offer.immutable}
      />
    </div>
  );
}
