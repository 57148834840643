import { backendApi } from "@/services/backend/api";

const commentsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    commentsPut: build.mutation<Comment, Partial<Comment>>({
      query: (comment) => ({
        url: `/comments/comment`,
        method: "PUT",
        body: comment,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "CommentsComment", id: request.id },
        { type: "CommentsComment", id: "LIST" },
      ],
    }),
    commentsList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/comments/comment/list`,
        params: request,
      }),
      providesTags: (result) =>
        result && result.comments.length > 0
          ? [
              ...result.comments.map((comment) => ({
                type: "CommentsComment" as const,
                id: comment.id,
              })),
              { type: "CommentsComment", id: "LIST" },
            ]
          : [{ type: "CommentsComment", id: "LIST" }],
    }),
    commentsDelete: build.mutation<void, DeleteRequest>({
      query: (request) => ({
        url: `/comments/comment`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: [{ type: "CommentsComment", id: "LIST" }],
    }),
    commentsDeleteList: build.mutation<void, DeleteListRequest>({
      query: (request) => ({
        url: `/comments/comment/list`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: [{ type: "CommentsComment", id: "LIST" }],
    }),
  }),
});

export const {
  useCommentsPutMutation,
  useCommentsListQuery,
  useCommentsDeleteMutation,
  useCommentsDeleteListMutation,
} = commentsApi;

export interface Comment {
  id: string;
  ownerId: string;
  ownerType: string;
  comment: string;
  createdBy: string;
  createdAt: string;
  editedBy: string;
  editedAt: string;
}

export interface List {
  ownerId: string;
  comments: Comment[];
  count: number;
}

export interface ListRequest {
  ownerId: string;
}

export interface DeleteRequest {
  id: string;
}

export interface DeleteListRequest {
  ownerId: string;
}
