import { HTMLAttributes } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";
import t from "../../../../lang/lang";

interface RTKQueryErrorAlertProps extends HTMLAttributes<HTMLDivElement> {
  error: unknown;
  ignoreValidationError?: boolean;
}

export function RTKQueryErrorAlert({
  error,
  className,
  ignoreValidationError = true,
  ...props
}: RTKQueryErrorAlertProps) {
  if (error === undefined || error === null) {
    return null;
  }

  // default values
  let title = t("Fehler");
  let description = t("Ein Fehler ist aufgetreten.");

  const err = parseRTKQueryError(error, ignoreValidationError);
  if (err == null) {
    return null;
  }
  title = err.title;
  description = err.description;

  return (
    <Alert variant="destructive" className={className} {...props}>
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        <span>{description}</span>
        <FieldErrors rtkError={error} />
      </AlertDescription>
    </Alert>
  );
}

function FieldErrors({ rtkError }: { rtkError: unknown }) {
  if (!rtkErrIsValidationError(rtkError)) {
    return null;
  }

  const error = rtkError.data;

  return (
    <div className="mt-2">
      {Object.keys(error.errors).map((field) => {
        const errors = error.errors[field];
        return (
          <>
            <div className="font-bold">{field}</div>
            <ul className="ml-6 list-disc">
              {errors.map((fieldError) => (
                <li>{fieldError}</li>
              ))}
            </ul>
          </>
        );
      })}
    </div>
  );
}
