import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash } from "lucide-react";
import t from "@/lang/lang";
import { Kind } from "@/services/backend/contacts/contact-kind/kind";
import { useContactsContactKindDeleteMutation } from "@/services/backend/contacts/contact-kind/service";
import { useEffect, useState } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { P } from "@/shared/components/ui/typography";

export function DeleteContactKindDialog({ kind }: { kind: Kind }) {
  const [open, setOpen] = useState(false);
  const [deleteKind, { isLoading, error, isSuccess, reset }] =
    useContactsContactKindDeleteMutation();
  const { toast } = useToast();

  const doDelete = () => {
    if (isLoading) {
      return;
    }
    deleteKind({
      id: kind.id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      reset();
      toast({
        title: t("Kontaktart gelöscht"),
      });
    }
  }, [isSuccess, setOpen, reset, toast]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <Trash className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Kontaktart löschen")}</DialogTitle>
        </DialogHeader>
        <P>
          {t(`Möchten Sie die Kontaktart "${kind.name}" wirklich löschen?`)}
        </P>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t("Abbrechen")}
          </Button>
          <Button onClick={doDelete} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
