import { NavLink, useRouteError } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { Terminal } from "lucide-react";
import { Button } from "@/shared/components/ui/button";

export function RootErrorBoundary() {
  const error = useRouteError();

  let title = "Unbekannter Fehler";
  let description =
    "Ein Unbekannter Fehler ist aufgetreten. Bitte Laden Sie die Seite neu.";
  let route = "/";
  let buttonText = "Neu Laden";

  if (is404(error)) {
    title = "404";
    description = "Die angeforderte Seite konnte nicht gefunden werden.";
    route = "/";
    buttonText = "Startseite";
  }

  return (
    <div className="flex-col space-y-4 px-28 pt-28">
      <Alert>
        <Terminal className="h-4 w-4" />
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>
      </Alert>
      <Button>
        <NavLink to={route}>{buttonText}</NavLink>
      </Button>
    </div>
  );
}

function is404(error: unknown): boolean {
  return (
    error != null &&
    typeof error === "object" &&
    "status" in error &&
    error.status === 404
  );
}
