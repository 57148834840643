import { useVbsWorkOrderShowQuery } from "@/services/backend/vbs/work-orders/service";
import { useShowCompanyQuery } from "@/services/backend/company/company";

export function useDocumentationPath(
  workOrderId: string,
  documentationId: string,
): string {
  const { data: workOrder } = useVbsWorkOrderShowQuery({ id: workOrderId });
  const { data: company } = useShowCompanyQuery({
    id: workOrder?.companyId ?? "",
  });

  if (!company) {
    return "";
  }

  return `/tenant/${company.portalRoute}/processes/vbs/${workOrder!.processId}/work-orders/${workOrderId}/documentation/${documentationId}`;
}
