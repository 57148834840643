import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { NewContactKindDialog } from "@/routes/base-data/contacts/contact-kind/components/new-contact-kind-dialog";
import { ContactKindList } from "@/routes/base-data/contacts/contact-kind/components/contact-kind-list";

export function ContactKindCard() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Kontaktarten")}</CardTitle>
        <div className="text-sm text-muted-foreground">
          <div>
            {t(
              "Kontaktarten werden genutzt um Kontakten eine Bedeutung zu geben. Da Kontakte aktuell nicht direkt im GSM verwaltet werden erfüllen Sie zwei wesentliche Funktionen:",
            )}
          </div>
          <ol className="ml-6 mt-3 list-decimal [&>li]:mt-2">
            <li>
              {t(
                "Kontaktarten werden mit den Kontaktrollen (Stakeholder) aus MS Dynamics verknüpft. Das ermöglicht bei Änderungen in MS Dynamics die Zuordnung anzupassen.",
              )}
            </li>
            <li>
              {t(
                "Kontaktarten werden mit den benötigten Kontakten in den verschiedenen Dienstleistungen verknüpft. Das ermöglicht eine automatische Zuordnung von Kontakten an den benötigten Stellen in Vorgängen zu einer Dienstleistungen.",
              )}
            </li>
          </ol>
          <div className="mt-3 font-bold">
            {t(
              "Solange Kontakte in MS Dynamics verwaltet werden macht es Sinn, die Kontaktarten an den Rollen dort zu orientieren.",
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <ContactKindList />
      </CardContent>
      <CardFooter className="justify-end">
        <NewContactKindDialog />
      </CardFooter>
    </Card>
  );
}
