import { Position } from "@/services/backend/rlt/treatments/position";
import { useEffect, useState } from "react";
import {
  UpdateCycleDiscountRequest,
  useRltTreatmentUpdateCycleDiscountMutation,
} from "@/services/backend/rlt/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";
import { FloatNumberInput } from "@/routes/gesec/processes/_components/ui/number-input";

export function CycleDiscountCell({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const { canExecute } = useGuard("RLT.Treatment.UpdateCycleDiscountRequest");

  if (!canExecute) {
    return <DiscountValue discount={position.cycleDiscount} />;
  }

  return <DiscountInput treatmentId={treatmentId} position={position} />;
}

function DiscountValue({ discount }: { discount: number }) {
  return (
    <div className="flex h-6 items-center justify-end whitespace-nowrap">
      {discount} %
    </div>
  );
}

function DiscountInput({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [request, setRequest] = useState<UpdateCycleDiscountRequest>({
    id: treatmentId,
    positionId: position.id,
    discount: position.cycleDiscount,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentUpdateCycleDiscountMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  useEffect(() => {
    setRequest((prev) => ({ ...prev, discount: position.cycleDiscount }));
  }, [position.cycleDiscount]);

  return (
    <div className="flex h-6 items-center justify-end whitespace-nowrap">
      <span className="mr-2">%</span>
      <FloatNumberInput
        value={request.discount}
        onChange={(discount) => setRequest({ ...request, discount })}
      />
    </div>
  );
}
