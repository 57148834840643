import { Position } from "@/services/backend/vbs/treatments/position";
import { fPrice } from "./utils";

export function ContractPriceCell({ position }: { position: Position }) {
  return (
    <div className="flex h-6 items-center justify-end whitespace-nowrap font-bold">
      {fPrice(position.contractPrice)} €
    </div>
  );
}
