import { ChangeEvent, useState } from "react";
import { v4 } from "uuid";
import t from "@/lang/lang";
import {
  Branch,
  ListBranchRequest,
  useDeleteBranchMutation,
  useListBranchQuery,
  usePutBranchMutation,
} from "@/services/backend/branch/branch";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Button } from "@/shared/components/ui/button";
import { H3 } from "@/shared/components/ui/typography";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Check, Edit, RefreshCw, Trash2, X } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { CompanySelect } from "@/shared/components/domain/company/company-select";

export function BranchesRoute() {
  return (
    <BaseDataPage>
      <BaseDataPageHeader>
        <BaseDataPageTitle>{t("Niederlassungen")}</BaseDataPageTitle>
      </BaseDataPageHeader>
      <BaseDataPageContent>
        <BranchRegistration />
        <BranchList />
      </BaseDataPageContent>
    </BaseDataPage>
  );
}

function BranchRegistration() {
  const [name, setName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [register, { isLoading, error }] = usePutBranchMutation();

  const canRegister = !isLoading;

  const doRegister = () => {
    if (!canRegister) {
      return;
    }
    const id = v4();
    register({ id, name, companyId });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Niederlassung registrieren")}</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div className="grid gap-1.5">
          <Label>{t("Name der Niederlassung")}</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("Name der Niederlassung")}
          />
          <InputValidationErrors field="name" error={error} />
        </div>
        <div className="grid gap-1.5">
          <Label>{t("Unternehmen")}</Label>
          <CompanySelect
            companyId={companyId}
            onCompanyIdChange={setCompanyId}
          />
          <InputValidationErrors field="companyId" error={error} />
        </div>
      </CardContent>
      <CardFooter>
        <Button
          onClick={doRegister}
          disabled={!canRegister}
          className="ml-auto"
        >
          {t("Erstellen")}
        </Button>
      </CardFooter>
    </Card>
  );
}

function BranchList() {
  const { data, isLoading, error } = useListBranchQuery(
    {} as ListBranchRequest,
  );
  const branches = [...(data?.branches || [])]?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  if (isLoading) {
    return (
      <div className="space-y-2">
        <H3>{t("Niederlassungen")}</H3>
        <Table className="animate-pulse">
          <TableHeader>
            <TableRow>
              <TableHead>{t("Niederlassung")}</TableHead>
              <TableHead>{t("Unternehmen")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </TableHeader>
        </Table>
      </div>
    );
  }

  if (error) {
    return (
      <div className="space-y-2">
        <H3>{t("Niederlassungen")}</H3>
        <RTKQueryErrorAlert error={error} />
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <H3>{t("Niederlassungen")}</H3>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Niederlassung")}</TableHead>
            <TableHead>{t("Unternehmen")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {branches?.map((branch) => (
            <BranchTableRow branch={branch} key={branch.id} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function BranchTableRow({ branch }: { branch: Branch }) {
  const [edit, setEdit] = useState(false);

  if (edit) {
    return <TableRowEdit branch={branch} cancel={() => setEdit(false)} />;
  }

  return (
    <TableRow>
      <TableCell>{branch.name}</TableCell>
      <TableCell>
        <CompanyName companyId={branch.companyId} short />
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={() => setEdit(true)}
          size="sm"
          className="mr-2"
          variant="outline"
        >
          <Edit className="h-4 w-4" />
        </Button>
        <DeleteBranchDialog branch={branch} />
      </TableCell>
    </TableRow>
  );
}

function TableRowEdit({
  branch,
  cancel,
}: {
  branch: Branch;
  cancel: () => void;
}) {
  const [name, setName] = useState(branch.name);

  const [update, { isLoading, error, isSuccess, reset }] =
    usePutBranchMutation();

  const canUpdate = !isLoading && name.length > 3;

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    reset();
    setName(e.target.value);
  };

  const doUpdate = () => {
    if (!canUpdate) {
      return;
    }
    update({
      id: branch.id,
      name,
      companyId: branch.companyId,
    });
  };

  if (isSuccess) {
    cancel();
    reset();
  }

  return (
    <TableRow>
      <TableCell>
        <Input type="text" onChange={updateName} value={name} />
        <InputValidationErrors field="name" error={error} />
      </TableCell>
      <TableCell>
        <CompanyName companyId={branch.companyId} short />
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={doUpdate}
          size="sm"
          className="mr-2"
          disabled={!canUpdate}
        >
          <Check className="h-4 w-4" />
        </Button>
        <Button onClick={cancel} size="sm" variant="destructive">
          <X className="h-4 w-4" />
        </Button>
      </TableCell>
    </TableRow>
  );
}

function DeleteBranchDialog({ branch }: { branch: Branch }) {
  const [deleteBranch, { isLoading, error }] = useDeleteBranchMutation();

  const doDelete = () => {
    if (isLoading) {
      return;
    }

    deleteBranch({
      id: branch.id,
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t(`Niederlassung ${branch.name} wirklich löschen?`)}
          </DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" disabled={isLoading} onClick={doDelete}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4" />}
            <span>{t("Löschen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
