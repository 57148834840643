import { backendApi } from "@/services/backend/api";
import { LastMinuteRiskAnalysis } from "@/services/backend/rlt/work-orders/last-minute-risk-analysis";
import { Filter } from "@/shared/lib/filter/filter";

const lmraApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltLMRACreate: build.mutation<LastMinuteRiskAnalysis, CreateLMRARequest>({
      query: (request) => ({
        url: `/rlt/lmra`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["RltLMRA"],
    }),
    rltLMRAShow: build.query<LastMinuteRiskAnalysis, ShowLMRARequest>({
      query: (request) => ({
        url: `/rlt/lmra`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltLMRA", id: result.id }] : [],
    }),
    rltLMRAList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/rlt/lmra/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "RltLMRA", id: "LIST" }] : [],
    }),
    rltLMRASetCheckResult: build.mutation<
      LastMinuteRiskAnalysis,
      SetCheckResult
    >({
      query: (request) => ({
        url: `/rlt/lmra/check/result`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltLMRA", id: request.id },
        "RltLMRA",
      ],
    }),
    rltLMRACommentCheck: build.mutation<
      LastMinuteRiskAnalysis,
      CommentCheckRequest
    >({
      query: (request) => ({
        url: `/rlt/lmra/check/comment`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltLMRA", id: request.id },
        "RltLMRA",
      ],
    }),
    rltLMRAConfirm: build.mutation<LastMinuteRiskAnalysis, ConfirmLMRARequest>({
      query: (request) => ({
        url: `/rlt/lmra/confirm`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltLMRA", id: request.id },
        "RltLMRA",
      ],
    }),
    rltLMRAUnconfirm: build.mutation<
      LastMinuteRiskAnalysis,
      UnconfirmLMRARequest
    >({
      query: (request) => ({
        url: `/rlt/lmra/unconfirm`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltLMRA", id: request.id },
        "RltLMRA",
      ],
    }),
    rltLMRADelete: build.mutation<LastMinuteRiskAnalysis, DeleteLMRARequest>({
      query: (request) => ({
        url: `/rlt/lmra`,
        method: "DELETE",
        params: request,
      }),
      invalidatesTags: ["RltLMRA"],
    }),
  }),
});

export const {
  useRltLMRACreateMutation,
  useRltLMRAShowQuery,
  useRltLMRAListQuery,
  useRltLMRASetCheckResultMutation,
  useRltLMRACommentCheckMutation,
  useRltLMRAConfirmMutation,
  useRltLMRAUnconfirmMutation,
  useRltLMRADeleteMutation,
} = lmraApi;

export interface CreateLMRARequest {
  id: string;
  processId: string;
}

export interface ShowLMRARequest {
  id: string;
}

export interface List {
  lmras: LastMinuteRiskAnalysis[];
  count: number;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface SetCheckResult {
  id: string;
  checkId: string;
  result: null | number;
}

export interface CommentCheckRequest {
  id: string;
  checkId: string;
  comment: string;
}

export interface ConfirmLMRARequest {
  id: string;
  conductedBy: string;
  confirmedBy: string;
}

export interface UnconfirmLMRARequest {
  id: string;
}

export interface DeleteLMRARequest {
  id: string;
}
