import {
  updateDate,
  updateSpecialAgreements,
} from "@/services/backend/htz/offers/offer";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { DatePicker } from "@/shared/components/ui/date-picker";
import { useOfferContext } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  hasFieldError,
  resetField,
} from "@/shared/app-lib/errors/validation-error";

export function DataCard() {
  const { offer, onOfferChange } = useOfferContext();

  const onDateChange = (date: string | null) => {
    onOfferChange(updateDate(offer, date));
  };

  const onAgreementsChange = (text: string) => {
    onOfferChange(updateSpecialAgreements(offer, text));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Angebot")}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <DateInput
          date={offer.date}
          onDateChange={onDateChange}
          disabled={offer.immutable}
        />
        <SpecialAgreementsInput
          agreements={offer.specialAgreements}
          onAgreementChange={onAgreementsChange}
          disabled={offer.immutable}
        />
      </CardContent>
    </Card>
  );
}

interface DateInputProps {
  date: string | null;
  onDateChange: (date: string | null) => void;
  disabled: boolean;
}

function DateInput({ date, onDateChange, disabled }: DateInputProps) {
  const { validationError, setValidationError } = useOfferContext();

  const formattedDate = date ? new Date(date) : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
    setValidationError(resetField(validationError, "date"));
  };

  const markError = hasFieldError(validationError, "date");

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Angebotsdatum")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={disabled}
      />
    </div>
  );
}

interface SpecialAgreementsInputProps {
  agreements: string;
  onAgreementChange: (agreements: string) => void;
  disabled: boolean;
}

function SpecialAgreementsInput({
  agreements,
  onAgreementChange,
  disabled,
}: SpecialAgreementsInputProps) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Besondere Vereinbarungen")}</Label>
      <Textarea
        value={agreements}
        onChange={(e) => onAgreementChange(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
}
