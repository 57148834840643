import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { NavLink, useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { ArrowLeft, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { useHtzAhuComponentCatalogShowQuery } from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import { AhuComponentCatalogItemTable } from "@/routes/base-data/processes/htz/ahu-component-catalogs/[ahuComponentCatalogId]/_components/ahu-component-catalog-item-table";
import { PutAhuComponentCatalogItemDialog } from "@/routes/base-data/processes/htz/ahu-component-catalogs/[ahuComponentCatalogId]/_components/put-ahu-component-catalog-item-dialog";
import { Button } from "@/shared/components/ui/button";

export function AhuComponentCatalogRoute() {
  const { ahuComponentCatalogId } = useParams();

  const {
    data: catalog,
    isLoading,
    error,
  } = useHtzAhuComponentCatalogShowQuery({
    id: ahuComponentCatalogId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex space-x-4">
            <RefreshCw className="animate-spin" />
            <span>{t("Lade Katalog")}</span>
          </CardTitle>
        </CardHeader>
      </Card>
    );
  }

  return (
    <div>
      <Card>
        <CardHeader className="relative">
          <CardTitle>
            <NavLink to="./..">
              <Button variant="outline" size="sm" className="m-0 mb-4">
                <ArrowLeft className="mr-2 h-4 w-4" />
                <span>{t("Übersicht")}</span>
              </Button>
            </NavLink>
            <div>{catalog!.name}</div>
          </CardTitle>
          <CardDescription>
            {t("Vorlagen für Komponenten einer RLT-Anlage.")}
          </CardDescription>
          <div className="absolute right-4 top-4">
            <PutAhuComponentCatalogItemDialog catalogId={catalog!.id} />
          </div>
        </CardHeader>
        <AhuComponentCatalogItemTable catalog={catalog!} />
      </Card>
    </div>
  );
}
