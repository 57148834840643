import { backendApi } from "@/services/backend/api";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";

const positionCatalogApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzPositionCatalog", "HtzActivePositionCatalog"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzPositionCatalogPut: build.mutation<PositionCatalog, PutRequest>({
        query: (request) => ({
          url: `/htz/position-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzPositionCatalog", id: request.id },
          "HtzPositionCatalog",
        ],
      }),
      htzPositionCatalogShow: build.query<PositionCatalog, ShowRequest>({
        query: (request) => ({
          url: `/htz/position-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzPositionCatalog"),
      }),
      htzPositionCatalogList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/position-catalog/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.catalogs, "HtzPositionCatalog"),
      }),
      htzPositionCatalogDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/position-catalog`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzPositionCatalog"],
      }),
      htzPositionCatalogPutActiveCatalog: build.mutation<
        ActiveCatalog,
        ActiveCatalog
      >({
        query: (request) => ({
          url: `htz/active-position-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["HtzActivePositionCatalog"],
      }),
      htzPositionCatalogShowActiveCatalog: build.query<
        ActiveCatalog,
        ShowActiveCatalogRequest
      >({
        query: (request) => ({
          url: `htz/active-position-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["HtzActivePositionCatalog"],
      }),
    }),
  });

export const {
  useHtzPositionCatalogPutMutation,
  useHtzPositionCatalogShowQuery,
  useHtzPositionCatalogListQuery,
  useHtzPositionCatalogDeleteMutation,
  useHtzPositionCatalogPutActiveCatalogMutation,
  useHtzPositionCatalogShowActiveCatalogQuery,
} = positionCatalogApi;

export interface PutRequest extends PositionCatalog {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  company?: Filter<string>;
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  catalogs: PositionCatalog[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface ActiveCatalog {
  companyId: string;
  catalogId: string | null;
}

export interface ShowActiveCatalogRequest {
  companyId: string;
}
