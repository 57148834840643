import { H4 } from "@/shared/components/ui/typography";
import {
  PropertyType,
  PropertyUnit,
  ServicePropertyUtil,
} from "@/services/backend/qpm/complementary-services/types";
import { PropertyFields } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/property-fields";
import { useState } from "react";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { Button } from "@/shared/components/ui/button";
import {
  QPMRequirementComplementaryServiceProperty,
  RequirementCycleType,
} from "@/services/backend/qpm/requirements/types";

export type CycleType = {
  id: string;
  isSelected: boolean;
  cycleType: RequirementCycleType;
  name: string;
};

export type CycleTypeList = CycleType[];

export type CycleComponentProps = {
  cycleType: RequirementCycleType;
  qpmCycleAmountPerYear: number;
  onChange: (
    cycleType: RequirementCycleType,
    qpmCycleAmountPerYear: number,
  ) => void;
};

export function CycleComponent({
  cycleType,
  qpmCycleAmountPerYear,
  onChange,
}: CycleComponentProps) {
  const [property, setProperty] =
    useState<QPMRequirementComplementaryServiceProperty>({
      value: qpmCycleAmountPerYear,
      property: {
        id: "0",
        serviceId: "",
        name: "QPM",
        unit: PropertyUnit.TimesPerYear,
        position: 0,
        type: PropertyType.TimePeriod,
      },
    });

  const initialCycleTypes: CycleTypeList = [
    {
      id: "1",
      isSelected: cycleType === RequirementCycleType.InitialControl,
      cycleType: RequirementCycleType.InitialControl,
      name: "EB",
    },
    {
      id: "2",
      isSelected:
        cycleType === RequirementCycleType.InitialControlEradicationMonitoring,
      cycleType: RequirementCycleType.InitialControlEradicationMonitoring,
      name: "EB mit TK",
    },
  ];

  const [selectedCycleTypes, setSelectedCycleTypes] =
    useState<CycleTypeList>(initialCycleTypes);

  const handleClick = (type: RequirementCycleType) => {
    const newCycleTypes: CycleTypeList = selectedCycleTypes.map((ct) =>
      ct.cycleType === type
        ? { ...ct, isSelected: !ct.isSelected }
        : { ...ct, isSelected: false },
    );
    setSelectedCycleTypes(newCycleTypes);

    onChange(type, property.value);
  };

  const callback = (value: number) => {
    setProperty({ ...property, value });
    onChange(
      selectedCycleTypes.find((t) => t.isSelected)?.cycleType ||
        RequirementCycleType.None,
      value,
    );
  };

  return (
    <div className="flex w-full flex-col gap-2 border-b py-2">
      <H4>Turnus</H4>
      <div className="flex w-full items-center gap-4">
        {selectedCycleTypes.map((cycle) => (
          <div key={cycle.id} className="flex items-center gap-1 p-1">
            <Checkbox
              checked={cycle.isSelected}
              onClick={() => handleClick(cycle.cycleType)}
            />
            <Button
              className="bg-transparent p-1 text-base text-black  hover:bg-transparent hover:text-primary"
              onClick={() => handleClick(cycle.cycleType)}
            >
              {cycle.name}
            </Button>
          </div>
        ))}
        <div className="flex w-full gap-2">
          <div className="flex items-center gap-2">
            <Checkbox
              checked={property.value > 0}
              onClick={() => (property.value > 0 ? callback(0) : callback(1))}
              className=""
            />
            <div className="flex-nowrap font-semibold">QPM</div>
          </div>
          <PropertyFields
            value={property.value}
            displayUnit={ServicePropertyUtil.toString(property.property.unit)}
            step={ServicePropertyUtil.inputStep(property.property.unit)}
            onValueChange={callback}
          />
        </div>
      </div>
    </div>
  );
}
