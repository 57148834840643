import { Filter } from "@/shared/lib/filter/filter";
import { backendApi } from "../../api";
import { Calculation, Parameters } from "./calculation";
import { Selection } from "./expenses";

const vbsCalculationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsCalculationCreate: build.mutation<Calculation, CreateRequest>({
      query: (request) => ({
        url: `/vbs/calculation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["VbsCalculation", "VbsCalculationCurrent"],
    }),
    vbsCalculationCreateFromOld: build.mutation<
      Calculation,
      CreateFromOldRequest
    >({
      query: (request) => ({
        url: `/vbs/calculation/create-from-old`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["VbsCalculation", "VbsCalculationCurrent"],
    }),
    vbsCalculationShow: build.query<Calculation, ShowRequest>({
      query: (request) => ({
        url: `/vbs/calculation`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsCalculation", id: result.id }] : [],
    }),
    vbsCalculationList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/vbs/calculation/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsCalculation", id: "LIST" }] : [],
    }),
    updateCalculationEmployees: build.mutation<
      Calculation,
      UpdateEmployeesRequest
    >({
      query: (request) => ({
        url: `/vbs/calculation/employees`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _error, request) => [
        { type: "VbsCalculation", id: request.id },
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
        { type: "VbsOfferCurrent", id: result?.processId ?? "LIST" },
      ],
    }),
    updateCalculationParameters: build.mutation<
      Calculation,
      UpdateParametersRequest
    >({
      query: (request) => ({
        url: `/vbs/calculation/parameters`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _error, request) => [
        { type: "VbsCalculation", id: request.id },
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
        { type: "VbsOfferCurrent", id: result?.processId ?? "LIST" },
      ],
    }),
    updateCalculationTravelTime: build.mutation<
      Calculation,
      UpdateTravelTimeRequest
    >({
      query: (request) => ({
        url: `/vbs/calculation/travel-time`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _error, request) => [
        { type: "VbsCalculation", id: request.id },
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
        { type: "VbsOfferCurrent", id: result?.processId ?? "LIST" },
      ],
    }),
    updateCalculationExpenses: build.mutation<
      Calculation,
      UpdateExpensesRequest
    >({
      query: (request) => ({
        url: `/vbs/calculation/expenses`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result, _error, request) => [
        { type: "VbsCalculation", id: request.id },
        "VbsOffer",
        "VbsConfirmation",
        "VbsContract",
        { type: "VbsOfferCurrent", id: result?.processId ?? "LIST" },
      ],
    }),
    deleteCalculation: build.mutation<undefined, DeleteRequest>({
      query: (request) => ({
        url: `/vbs/calculation`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "VbsCalculation", id: request.id },
        { type: "VbsCalculation", id: "LIST" },
        "VbsCalculationCurrent",
      ],
    }),
    vbsCalculationShowCurrent: build.query<Current, ShowCurrentRequest>({
      query: (request) => ({
        url: `/vbs/calculation/current`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsCalculationCurrent", id: result.processId }] : [],
    }),
    vbsCalculationSetCurrent: build.mutation<Current, Current>({
      query: (request) => ({
        url: `/vbs/calculation/current`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["VbsCalculationCurrent"],
    }),
  }),
});

export const {
  useVbsCalculationCreateMutation,
  useVbsCalculationCreateFromOldMutation,
  useVbsCalculationShowQuery,
  useVbsCalculationListQuery,
  useUpdateCalculationEmployeesMutation,
  useUpdateCalculationParametersMutation,
  useUpdateCalculationTravelTimeMutation,
  useUpdateCalculationExpensesMutation,
  useDeleteCalculationMutation,
  useVbsCalculationShowCurrentQuery,
  useVbsCalculationSetCurrentMutation,
} = vbsCalculationApi;

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface CreateFromOldRequest {
  oldCalculationId: string;
  newCalculationId: string;
  newProcessId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  createdAt?: Filter<string>;
  owner?: Filter<string>;
  ownerType?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface UpdateEmployeesRequest {
  id: string;
  count: number;
}

export interface UpdateParametersRequest {
  id: string;
  parameters: Parameters;
}

export interface UpdateTravelTimeRequest {
  id: string;
  drives: number;
  hoursPerDrive: number;
}

export interface UpdateExpensesRequest {
  id: string;
  selection: Selection;
  perDiemDays: number;
  overnightNights: number;
}

export interface DeleteRequest {
  id: string;
}

export interface List {
  calculations: Calculation[];
  count: number;
}

export interface Current {
  processId: string;
  calculationId: string;
}

export interface ShowCurrentRequest {
  processId: string;
}
