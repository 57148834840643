export interface Filter<Value> {
  active: boolean;
  mode?: FilterMode;
  values?: Value[];
}

export enum FilterMode {
  // Or is the default mode
  Or = "or",
  Not = "not",
  IsNull = "isNull",
  // NotNull ignores values in the values array
  NotNull = "notNull",
  // Between expects exactly two values in the values array
  Between = "between",
  // Like evaluates to find any match in the values array
  Like = "like",
}
