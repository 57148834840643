import { Filter } from "@/shared/lib/filter/filter";
import {
  Monitor,
  MonitorList,
  MonitorRecord,
} from "@/services/backend/qpm/monitors/types";
import {
  ComplementaryServiceProperty,
  QpmComplementaryService,
} from "@/services/backend/qpm/complementary-services/types";
import { AreaList } from "@/services/backend/qpm/buildings/types";

export type Requirement = {
  id: string;
  processId: string;
  artefactMetaId: string;
  customerId: string;
  createdAt: string;
  identifier: string;
  name: string;
  description: string;
  areaList: AreaList;
  monitor: MonitorList;
  selectedAreas: RequirementAreaList;
  selectedMonitors: SelectedAreaMonitors;
};

export interface RequirementList {
  requirements: Requirement[];
  count: number;
}

export type SelectedMonitor = {
  requirementId: string;
  areaId: string;
  monitorId: string;
  amount: number;
  existingStockAmount?: number;
  comment: string;
  position: string;
};

export type SelectedAreaMonitors = {
  monitors: { [areaId: string]: AreaMonitor[] };
};

export type SelectedMonitorsList = {
  monitors: SelectedMonitor[];
};

export type MonitorSummary = {
  monitor: Monitor;
  totalAmount: number;
  totalInstallationTimeSeconds: number;
  totalServiceTimeSeconds: number;
};

export type SelectedMonitorSummary = {
  requirementId: string;
  monitors: MonitorSummary[];
  typeSummary: { [key: string]: number };
  totalInstallationTimeSeconds: number;
  totalServiceTimeSeconds: number;
};

export type AreaMonitor = MonitorRecord & {
  amount: number;
  existingStockAmount?: number;
  comment: string;
  position: string;
};

export type QpmRequirementComplementaryService = {
  requirementId: string;
  complementaryService: QpmComplementaryService;
  properties: QPMRequirementComplementaryServiceProperty[];
};

export type QPMRequirementComplementaryServiceProperty = {
  value: number;
  property: ComplementaryServiceProperty;
};

export type RequirementComplementaryServiceList = {
  services: QpmRequirementComplementaryService[];
};

export type QpmRequirementServiceProperties = {
  requirementId: string;
  properties: [
    {
      property: { id: string };
      value: number;
    },
  ];
};

export type CreateRequirementRequest = {
  id: string;
  processId: string;
  artefactMetaId: string;
  customerId: string;
  identifier: string;
  name?: string;
  description?: string;
};

export type UpdateRequirementRequest = {
  id: string;
  processId: string;
  artefactMetaId: string;
  customerId: string;
  identifier: string;
  name?: string;
  description?: string;
};

export type GetRequirementRequest = {
  id: string;
};

export type ListRequirementRequest = {
  process: Filter<string>;
  customer: Filter<string>;
  immutable: Filter<boolean>;
  owner: Filter<string>;
  ownerType: Filter<string>;
};

export type SelectMonitorRequest = {
  monitorId: string;
  requirementId: string;
  areaId: string;
  amount: number;
  comment: string;
  position: string;
};

export type GetSelectedMonitorSummaryRequest = {
  requirementId: Filter<string>;
  buildingId: Filter<string>;
  levelId: Filter<string>;
};

export type UpdateRequirementMonitorRequest = {
  monitorId: string;
  requirementId: string;
  areaId: string;
  amount: number;
  existingStockAmount?: number;
  comment: string;
};

export type DeleteRequirementMonitorRequest = {
  monitorId: string;
  requirementId: string;
  areaId: string;
};
export type ListSelectedMonitorsRequest = {
  requirementId: string;
};

export type RequirementArea = {
  requirementId: string;
  areaId: string;
};

export type AddRequirementAreaRequest = {
  requirementId: string;
  areaId: string;
};

export type RequirementAreaList = {
  requirementAreas: RequirementArea[];
};

export type DeleteRequirementAreaRequest = {
  requirementId: string;
  areaId: string;
};

export type ListRequirementAreasRequest = {
  requirementId: string;
};

export type ListByRequirementIdRequest = {
  requirementId: string;
};

export type RequirementPest = {
  requirementId: string;
  pestId: string;
};

export type RequirementPestList = RequirementPest[];

export type RequirementPestCategory = {
  requirementId: string;
  pestCategoryId: string;
  immutable: boolean;
};

export type RequirementPestCategoryList = {
  pestCategories: RequirementPestCategory[];
};

export type AddRequirementPestRequest = {
  requirementId: string;
  pestId: string;
};

export type DeleteRequirementPestRequest = {
  requirementId: string;
  pestId: string;
};

export type GetRequirementPestListRequest = {
  requirementId: string;
};

export type AddRequirementPestCategoryRequest = {
  requirementId: string;
  pestCategoryId: string;
};

export type GetRequirementPestCategoryListRequest = {
  requirementId: string;
};

export type DeleteRequirementPestCategoryRequest = {
  requirementId: string;
  pestCategoryId: string;
};

export enum RequirementCycleType {
  None = "",
  InitialControl = "initial-control",
  InitialControlEradicationMonitoring = "initial-control-eradication-monitoring",
}

export type RequirementAdditionalInformation = {
  requirementId: string;
  certificateNotes: string;
  additionalNotes: string;
  travelTimeSeconds: number;
  travelCompany: string;
  cycleType: RequirementCycleType;
  qpmCycleAmountPerYear: number;
};

export type AddRequirementAdditionalInformationRequest = {
  requirementId: string;
  certificateNotes: string;
  additionalNotes: string;
  travelTimeSeconds: number;
  travelCompany: string;
  cycleType: RequirementCycleType;
  qpmCycleAmountPerYear: number;
};

export type GetRequirementAdditionalInformationRequest = {
  requirementId: string;
};

export type DeleteRequirementAdditionalInformationRequest = {
  requirementId: string;
};

export type RequirementAreaRisk = {
  requirementId: string;
  areaId: string;
  riskLevelId: string;
  riskTypeId: string;
  detection: string;
  recommendation: string;
  measure: string;
};

export type AddRequirementAreaRiskRequest = {
  requirementId: string;
  areaId: string;
  riskLevelId: string;
  riskTypeId: string;
  detection: string;
  recommendation: string;
  measure: string;
};

export type ListRequirementAreaRisks = {
  risks: RequirementAreaRisk[];
};

export type ListRequirementAreaRisksRequest = {
  requirementId: string;
  areaId: string;
};

export type DeleteRequirementAreaRisksRequest = {
  requirementId: string;
  areaId: string;
  riskTypeId: string;
};
