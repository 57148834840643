import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Plus, RefreshCw } from "lucide-react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  PutRequest,
  useHtzPositionCatalogPutMutation,
} from "@/services/backend/htz/position-catalog/service";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { useState } from "react";
import { v4 } from "uuid";
import { CompanySelect } from "@/shared/components/domain/company/company-select";

export function NewPositionCatalogDialog() {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<PutRequest>({
    id: "",
    name: "",
    companyId: "",
    items: [],
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useHtzPositionCatalogPutMutation();

  if (isSuccess) {
    setOpen(false);
    setRequest({
      id: "",
      name: "",
      companyId: "",
      items: [],
    });
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    const id = v4();
    put({
      ...request,
      id,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-5 w-5" />
          <span>{t("Katalog")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Katalog erstellen")}</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid content-start gap-1.5">
            <Label>{t("Katalogname")}</Label>
            <Input
              value={request?.name}
              onChange={(e) => setRequest({ ...request, name: e.target.value })}
            />
            <InputValidationErrors error={error} field="name" />
          </div>
          <div className="grid content-start gap-1.5">
            <Label>{t("Unternehmen")}</Label>
            <CompanySelect
              companyId={request.companyId}
              onCompanyIdChange={(companyId) =>
                setRequest({ ...request, companyId })
              }
            />
            <InputValidationErrors error={error} field="name" />
          </div>
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick}>
            {isLoading && <RefreshCw className="mr-2 h-5 w-5 animate-spin" />}
            <span>{t("Erstellen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
