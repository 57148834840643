export interface Expenses {
  selection: Selection;
  overnight: Overnight;
  perDiem: PerDiem;
  total: number;
}

export enum Selection {
  None = "None",
  Overnight = "Overnight",
  PerDiem = "PerDiem",
}

export interface ExpensesParameters {
  overnightAllowance: number;
  overnightHotelRate: number;
  perDiemAllowance: number;
}

export interface Overnight {
  employees: number;
  nights: number;
  allowance: number;
  hotelRate: number;
}

export interface PerDiem {
  employees: number;
  days: number;
  allowance: number;
}
