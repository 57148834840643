export interface AhuComponentCatalog {
  id: string;
  name: string;
  items: Item[];
}

export function addItem(
  catalog: AhuComponentCatalog,
  template: Item,
): AhuComponentCatalog {
  return {
    ...catalog,
    items: [...catalog.items, template],
  };
}

export function updateItem(
  catalog: AhuComponentCatalog,
  template: Item,
): AhuComponentCatalog {
  return {
    ...catalog,
    items: catalog.items.map((templ) => {
      if (templ.id === template.id) {
        return template;
      }
      return templ;
    }),
  };
}

export function deleteItem(
  catalog: AhuComponentCatalog,
  templateId: string,
): AhuComponentCatalog {
  return {
    ...catalog,
    items: catalog.items.filter((templ) => templ.id !== templateId),
  };
}

export interface Item {
  id: string;
  catalogId: string;
  componentKindId: string;
  name: string;
  details: string;
  group: string;
  type: string;
  img: string;
  sizeFactor: SizeFactor;
}

export type SizeFactor = 1 | 2 | 4;
