import {
  useHtzPositionCatalogShowActiveCatalogQuery,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import { useHtzAirHandlingUnitListQuery } from "@/services/backend/htz/ahu/service";

export function usePositionsTableDependencies(
  companyId?: string,
  customerId?: string,
) {
  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzPositionCatalogShowActiveCatalogQuery(
    {
      companyId: companyId ?? "",
    },
    {
      skip: companyId === undefined,
    },
  );

  const {
    data: positionCatalog,
    isLoading: catalogIsLoading,
    error: catalogError,
  } = useHtzPositionCatalogShowQuery(
    {
      id: activeCatalog?.catalogId ?? "",
    },
    {
      skip: activeCatalog === undefined || activeCatalog?.catalogId === null,
    },
  );

  const {
    data: ahuList,
    isLoading: ahuIsLoading,
    error: ahuError,
  } = useHtzAirHandlingUnitListQuery(
    {
      customer: {
        active: customerId !== undefined,
        values: [customerId ?? ""],
      },
    },
    {
      skip: customerId === undefined,
    },
  );
  const airHandlingUnits = ahuList?.airHandlingUnits ?? [];

  const isLoading = activeCatalogIsLoading || catalogIsLoading || ahuIsLoading;

  const error = (() => {
    switch (true) {
      case activeCatalogError !== undefined:
        return activeCatalogError;
      case catalogError !== undefined:
        return catalogError;
      case ahuError !== undefined:
        return ahuError;
      case activeCatalog?.catalogId === null:
        // fake 404 error
        return {
          status: 404,
        };
      default:
        return undefined;
    }
  })();

  return {
    catalog: positionCatalog,
    airHandlingUnits,
    isLoading,
    error,
  };
}
