import { Kind } from "@/services/backend/vbs/treatments/component";
import {
  ExhaustHoodSystem as ExhaustHoodSystemFormula,
  VentilatedCeilingSystem as VentilatedCeilingSystemFormula,
} from "@/services/backend/vbs/treatments/formula";
import { Position } from "@/services/backend/vbs/treatments/position";
import { fPrice } from "./utils";

export function BasePriceCell({ position }: { position: Position }) {
  switch (position.formula.componentKind) {
    case Kind.ExhaustHoodSystem:
      return <ExhaustHoodSystem position={position} />;
    case Kind.VentilatedCeilingSystem:
      return <VentilatedCeilingSystem position={position} />;
    default:
      return (
        <div className="flex h-6 items-center justify-end whitespace-nowrap font-bold">
          {fPrice(position.basePrice)} €
        </div>
      );
  }
}

function ExhaustHoodSystem({ position }: { position: Position }) {
  const formula = position.formula.parameters as ExhaustHoodSystemFormula;
  return (
    <div className="whitespace-nowrap">
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.exhaustHood.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.aerosolSeparator.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.aluminiumMeshGreaseFilter.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.lighting.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.dummyPlate.basePrice)} €
      </div>
      <div className="mt-2 flex h-6 items-center justify-end font-bold">
        {fPrice(position.basePrice)} €
      </div>
    </div>
  );
}

function VentilatedCeilingSystem({ position }: { position: Position }) {
  const formula = position.formula.parameters as VentilatedCeilingSystemFormula;
  return (
    <div className="whitespace-nowrap">
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.ventilatedCeiling.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.aerosolSeparator.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.lighting.basePrice)} €
      </div>
      <div className="flex h-6 items-center justify-end">
        {fPrice(formula.dummyPlate.basePrice)} €
      </div>
      <div className="mt-2 flex h-6 items-center justify-end font-bold">
        {fPrice(position.basePrice)} €
      </div>
    </div>
  );
}
