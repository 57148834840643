import { HTMLAttributes, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { AlertCircle, CircleCheck, RefreshCw } from "lucide-react";
import t from "../../../lang/lang";

export interface PersistenceStateInfoProps
  extends HTMLAttributes<HTMLDivElement> {
  state: PersistenceState;
}

export function PersistenceStateInfo(props: PersistenceStateInfoProps) {
  const { state, className } = props;
  switch (state) {
    case PersistenceState.SAVED:
      return <Saved className={className} />;
    case PersistenceState.SAVING:
      return <Saving className={className} />;
    case PersistenceState.ERROR:
      return <Error className={className} />;
    default:
      return null;
  }
}

export function PersistenceStateUsingContext({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  const { persistenceState } = usePersistenceContext();
  return (
    <PersistenceStateInfo state={persistenceState} className={className} />
  );
}

// TODO error message like this is fine, user should habe the option to click on it and see the error
function Error(props: HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "flex items-center space-x-2 rounded bg-red-500 px-3 py-1 text-sm font-bold text-gray-100",
        className,
      )}
    >
      <AlertCircle className="h-4 w-4" />
      <span className="whitespace-nowrap">{t("Fehler beim Speichern")}</span>
    </div>
  );
}

function Saved(props: HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "flex items-center space-x-2 text-sm font-bold text-gray-500",
        className,
      )}
    >
      <CircleCheck className="h-4 w-4" />
      <span>{t("Gespeichert")}</span>
    </div>
  );
}

function Saving(props: HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "flex animate-pulse items-center space-x-2 text-sm font-bold text-gray-500",
        className,
      )}
    >
      <RefreshCw className="h-4 w-4" />
      <span>{t("Speichere")}</span>
    </div>
  );
}

export function usePersistenceStatusUpdate(
  isLoading: boolean,
  error: never,
  state: PersistenceState,
  setPersistenceStatus: (state: PersistenceState) => void,
) {
  return useEffect(() => {
    if (isLoading && state !== PersistenceState.SAVING) {
      setPersistenceStatus(PersistenceState.SAVING);
      return;
    }
    if (error && state !== PersistenceState.ERROR) {
      setPersistenceStatus(PersistenceState.ERROR);
    }
  }, [isLoading, error, state, setPersistenceStatus]);
}
