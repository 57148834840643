import { Button } from "@/shared/components/ui/button";
import { Copy } from "lucide-react";
import { v4 } from "uuid";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import t from "@/lang/lang";
import { useRltCalculationCreateFromOldMutation } from "@/services/backend/rlt/calculations/service";

interface Props {
  calculationId: string;
  processId: string;
  onCopy: (calculationId: string) => void;
}

export function CopyCalculationButton({
  calculationId,
  processId,
  onCopy,
}: Props) {
  const [copy, { isLoading, error, reset }] =
    useRltCalculationCreateFromOldMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (isLoading) {
      return;
    }

    const id = v4();
    copy({
      oldCalculationId: calculationId,
      newCalculationId: id,
      newProcessId: processId,
    })
      .unwrap()
      .then(() => onCopy(id));
  };

  return (
    <Button onClick={onClick} size="sm">
      <Copy className="mr-2 h-4 w-4" />
      <span>{t("Daten übernehmen")}</span>
    </Button>
  );
}
