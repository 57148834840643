/**
 * Interface for the state of the treatment component.
 * @property availableColumns The columns that are available to be displayed.
 * @property columns The columns that are currently displayed.
 */
export interface TreatmentComponentState {
  availableColumns: Column[];
  columns: Column[];
}

/**
 * Column defines the available columns for the treatment component.
 * The order is intentional and represents the default order of the columns.
 */
export enum Column {
  Position = "position",
  Completed = "completed",
  AssignedWorkOrder = "assignedWorkOrder",
  ComponentKind = "componentKind",
  ComponentData = "componentData",
  Cycle = "cycle",
  Formula = "formula",
  CleanTime = "cleanTime",
  BasePrice = "basePrice",
  TipCommission = "tipCommission",
  OfferPrice = "offerPrice",
  CycleDiscount = "cycleDiscount",
  ContractPrice = "contractPrice",
  Comment = "comment",
  Actions = "actions",
}

export const ALL_COLUMNS: Column[] = Object.values(Column);
