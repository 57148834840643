import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import t from "@/lang/lang";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, PanelsTopLeft } from "lucide-react";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { useVbsCalculationListQuery } from "@/services/backend/vbs/calculations/service";
import { Calculation } from "@/services/backend/vbs/calculations/calculation";
import { FilterMode } from "@/shared/lib/filter/filter";
import { CreateCalculationDialog } from "@/routes/gesec/processes/[processId]/vbs/calculations/_components/create-calculation-dialog";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function CalculationsRoute() {
  const { processId } = useParams();
  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Kalkulationen")}</MilestonePageTitle>
        <MilestonePageActions>
          <SubNavigation />
          <CreateCalculationDialog processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  );
}

function SubNavigation() {
  const { processId, calculationId } = useParams();

  const { data: list } = useVbsCalculationListQuery({
    process: {
      active: true,
      values: [processId!],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  const currentPageLabel = () => {
    if (calculationId) {
      return <ArtefactNumberById artefactId={calculationId} />;
    }

    return t("Übersicht");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel()}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <NavLink to="">
            <DropdownMenuItem>
              <PanelsTopLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Kalkulationen")}</DropdownMenuLabel>
        {[...(list?.calculations ?? [])]
          .sort(byCreatedAt)
          .map((calculation) => (
            <NavLink to={calculation.id} key={calculation.id}>
              <DropdownMenuItem>
                <ArtefactNumberById artefactId={calculation.id} />
              </DropdownMenuItem>
            </NavLink>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byCreatedAt(a: Calculation, b: Calculation) {
  return a.createdAt.localeCompare(b.createdAt);
}
