import { Position } from "@/services/backend/vbs/treatments/position";
import { fPrice } from "./utils";

export function OfferPriceCell({ position }: { position: Position }) {
  return (
    <div className="flex h-6 items-center justify-end whitespace-nowrap font-bold">
      {fPrice(position.offerPrice)} €
    </div>
  );
}
