import { useParams } from "react-router-dom";
import {
  useHtzOrderPutMutation,
  useHtzOrderShowQuery,
} from "@/services/backend/htz/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";
import { useMemo, useState } from "react";
import { Order } from "@/services/backend/htz/orders/order";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  PositionsTable,
  PositionsTableSkeleton,
} from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table";
import {
  PositionsTableContext,
  PositionsTableContextInterface,
} from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table-context";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { usePositionsTableDependencies } from "@/routes/gesec/processes/[processId]/htz/_shared/positions/use-positions-table-dependencies";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function PositionsRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading: orderIsLoading,
    error: orderError,
  } = useHtzOrderShowQuery({
    id: orderId!,
  });

  const {
    catalog,
    airHandlingUnits,
    isLoading: depsIsLoading,
    error: depsError,
  } = usePositionsTableDependencies(order?.companyId, order?.customerId);

  if (orderError || depsError) {
    return <RTKQueryErrorAlert error={orderError || depsError} />;
  }

  if (orderIsLoading || depsIsLoading) {
    return <PositionsTableSkeleton />;
  }

  return (
    <OrderPositionsUpdater
      order={order!}
      positionCatalog={catalog!}
      airHandlingUnits={airHandlingUnits!}
    />
  );
}

interface OrderPositionsUpdaterProps {
  order: Order;
  positionCatalog: PositionCatalog;
  airHandlingUnits: AirHandlingUnit[];
}

function OrderPositionsUpdater({
  order,
  positionCatalog,
  airHandlingUnits,
}: OrderPositionsUpdaterProps) {
  const { notReadyError, setNotReadyError } = useErrArtefactNotReady();

  const [request, setRequest] = useState({ ...order });
  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzOrderPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const validationError =
    notReadyError ?? (rtkErrIsValidationError(error) ? error.data : null);

  const value: PositionsTableContextInterface = useMemo(
    () => ({
      positions: request.positions,
      onPositionsChange: (positions) =>
        setRequest((r) => ({ ...r, positions })),
      immutable: false,
      validationError,
      setValidationError: setNotReadyError,
      positionCatalog,
      airHandlingUnits,
    }),
    [
      request.positions,
      validationError,
      positionCatalog,
      airHandlingUnits,
      setNotReadyError,
    ],
  );

  return (
    <PositionsTableContext.Provider value={value}>
      <PositionsTable />
    </PositionsTableContext.Provider>
  );
}
