// Enums

// TODO
// eslint-disable-next-line max-classes-per-file
export enum Status {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export enum MonitorTypeKey {
  CheckPoint = "PP",
  FlyExterminator = "FIV",
  ALoRa = "ALoRa",
}

export enum RiskTypeKey {
  UNSPECIFIED = "",
  Rodent = "rodent",
  CrawlingInsect = "crawling-insect",
  FlyingInsect = "flying-insect",
  Bird = "bird",
}

export enum RiskLevelKey {
  None = "none",
  Low = "low",
  Medium = "medium",
  High = "high",
}

export enum QPMSearchParams {
  CollapsibleAreasOpen = "open",
}

export enum ArtefactDocType {
  Requirement = "BA",
  Calculation = "K",
  Offer = "AN",
}
