import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandGroup,
  CommandItem,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import t from "@/lang/lang";
import { Filter } from "@/shared/lib/filter/filter";
import { Label } from "@/services/backend/processes/state/state";

export interface StateFilterProps {
  filter: Filter<Label>;
  onChange: (filter: Filter<Label>) => void;
}

export function StateFilter({ filter, onChange }: StateFilterProps) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
        >
          <span>{t("Zustand")}</span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandGroup>
            {Object.values(Label)
              .filter((label) => label !== Label.Unknown)
              .map((label) => (
                <CommandItem
                  key={label}
                  value={label}
                  onSelect={(value) => {
                    const current = value as Label;
                    const values = filter.values!.includes(current)
                      ? filter.values!.filter((v) => v !== current)
                      : [...filter.values!, current];
                    if (values.length === 0) {
                      onChange({ active: false, values: [] });
                    } else {
                      onChange({ active: true, values });
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      filter.values!.includes(label)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  {t(label)}
                </CommandItem>
              ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
