import { Label } from "@/shared/components/ui/label";
import t from "@/lang/lang";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { cn } from "@/shared/lib/utils";
import { useMemo } from "react";

export enum Soiling {
  None = "none",
  Light = "light",
  Moderate = "moderate",
  Heavy = "heavy",
}

export function SoilingSelect({
  soiling,
  onSoilingChange,
  errors,
}: {
  soiling: Soiling;
  onSoilingChange: (soiling: Soiling) => void;
  errors: string[];
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Verschmutzungsgrad")}</Label>
      <Select value={soiling.toString()} onValueChange={onSoilingChange}>
        <SelectTrigger>
          <SelectValue placeholder={t("Verschmutzungsgrad")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="none">
              <Dot soiling={Soiling.None} />
            </SelectItem>
            <SelectItem value="light">
              <Dot soiling={Soiling.Light} />
            </SelectItem>
            <SelectItem value="moderate">
              <Dot soiling={Soiling.Moderate} />
            </SelectItem>
            <SelectItem value="heavy">
              <Dot soiling={Soiling.Heavy} />
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

export function Dot({ soiling }: { soiling: Soiling }) {
  const { className, label } = useMemo(() => {
    switch (soiling) {
      case Soiling.None:
        return {
          className: "",
          label: t("keine"),
        };
      case Soiling.Light:
        return {
          className: "",
          label: t("gering"),
        };
      case Soiling.Moderate:
        return {
          className: "bg-yellow-300 border-yellow-300",
          label: t("mittel"),
        };
      case Soiling.Heavy:
        return {
          className: "bg-red-500 border-red-500",
          label: t("stark"),
        };
      default:
        return {
          className: "",
          label: t("keine"),
        };
    }
  }, [soiling]);

  return (
    <span className="flex items-center whitespace-nowrap">
      <span
        className={cn(
          "mr-2 inline-block h-4 w-4 rounded-full border bg-background",
          className,
        )}
      />
      {label}
    </span>
  );
}
