/**
 * byDate is a compareFn to compare two objects with a "date" field,
 * such that it can be used to sort an array.
 *
 * byDate is a bit odd since it demands the specific interface,
 * but it kept turning up at multiple places, and it seemed odd
 * to keep copying it.
 *
 * @param a
 * @param b
 */
export function byDate(a: { date: string | null }, b: { date: string | null }) {
  switch (true) {
    case a.date === null && b.date === null:
      return 0;
    case a.date !== null && b.date === null:
      return 1;
    case a.date === null && b.date !== null:
      return -1;
    default:
      return a.date!.localeCompare(b.date!);
  }
}
