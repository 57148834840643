import { useParams } from "react-router-dom";
import { Card } from "@/shared/components/ui/card";
import { ConfirmationTable } from "@/routes/gesec/processes/[processId]/htz/confirmations/_components/confirmation-table";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { CreateConfirmationButton } from "@/routes/gesec/processes/[processId]/htz/confirmations/_components/create-confirmation-button";

export function ConfirmationIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePageContent>
      <MilestonePageContentMenu>
        <CreateConfirmationButton processId={processId!} />
      </MilestonePageContentMenu>
      <Card>
        <ConfirmationTable processId={processId!} />
      </Card>
    </MilestonePageContent>
  );
}
