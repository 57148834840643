import { Content } from "@/routes/_components/main-nav";
import GesecRouter from "@/routes/gesec/router";

export default function GesecRoot() {
  const router = GesecRouter();

  return (
    <div>
      {router}
      <Content />
    </div>
  );
}
