import t from "@/lang/lang";
import { Outlet } from "react-router-dom";
import { H2 } from "@/shared/components/ui/typography";

export function ContactsRoute() {
  return (
    <div>
      <H2>{t("Kontakte")}</H2>
      <div className="pt-4 text-muted-foreground">
        {t(
          'Kontakte werden nicht direkt im GSM verwaltet. Die Daten werden aus MS Dynamics übernommen und kopiert wo nötig. Zur Konfiguration wie dies geschieht stehen "Kontaktarten" zur Verfügung. Zusätzlich muss für die einzelnen Dienstleistungen konfiguriert werden, welche Kontaktarten verwendet werden sollen.',
        )}
      </div>
      <div className="pt-6">
        <Outlet />
      </div>
    </div>
  );
}
