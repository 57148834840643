import React, { useState } from "react";
import {
  PropertyUnit,
  ServicePropertyUtil,
} from "@/services/backend/qpm/complementary-services/types";
import { H4 } from "@/shared/components/ui/typography";
import { PropertyTimeField } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/property-fields";
import { Input } from "@/shared/components/ui/input";

interface TravelInfoComponentProps {
  travelData: { travelTimeSeconds: number; travelCompany: string };
  onChange: (travelTime: number, travelCompany: string) => void;
}

export function TravelInfoComponent({
  travelData,
  onChange,
}: TravelInfoComponentProps) {
  const [inConjunctionText, setInConjunctionText] = useState<string>(
    travelData.travelCompany,
  );

  const onChangeInConjunctionText = (value: string) => {
    setInConjunctionText(value);
    onChange(travelData.travelTimeSeconds, value);
  };

  const onChangeTravelTime = (value: number) => {
    onChange(value, travelData.travelCompany);
  };

  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>Fahrtzeit einfach</H4>
      <div className="gap flex flex-col gap-2 md:flex-row md:items-center">
        <PropertyTimeField
          onValueChange={onChangeTravelTime}
          displayValue={travelData.travelTimeSeconds}
          displayUnit={ServicePropertyUtil.toString(PropertyUnit.Hours)}
          immutable={false}
        />
        <div className="flex items-center gap-1">
          <span className="w-full">in Verbindung mit</span>
          <Input
            type="text"
            className="rounded-sm border p-1"
            placeholder="Verbindung..."
            value={inConjunctionText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeInConjunctionText(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
