import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";

export interface Order extends Artefact, Immutability {
  processId: string;
  customerId: string;
  companyId: string;
  offerId: string;
  treatmentId: string;
  calculationId: string;
  date: string;
  channel: string;
  customerReference: string;
  comment: string;
  files: OrderFile[];
  serviceAddressId: string;
  customerAddressId: string;
  representativeId: string;
}

export interface OrderFile {
  id: string;
  name: string;
}

export enum Channel {
  EMAIL = "email",
  PHONE = "phone",
  POST = "post",
  FAX = "fax",
}
