import { backendApi } from "@/services/backend/api";
import { Offer } from "@/services/backend/htz/offers/offer";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import {
  downloadResponseHandler,
  previewResponseHandler,
} from "@/services/backend/pdf-helpers";

const offerApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzOffer"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzOfferPut: build.mutation<Offer, PutRequest>({
        query: (request) => ({
          url: `/htz/offer`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzOffer", id: request.id },
          "HtzOffer",
        ],
      }),
      htzOfferShow: build.query<Offer, ShowRequest>({
        query: (request) => ({
          url: `/htz/offer`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzOffer"),
      }),
      htzOfferList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/offer/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) => providesList(result?.offers, "HtzOffer"),
      }),
      htzOfferDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/offer`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzOffer"],
      }),
      htzOfferPdfPreview: build.query<{ objectURL: string }, PreviewPDFRequest>(
        {
          query: (request) => ({
            url: `/htz/offer/pdf`,
            method: "PATCH",
            body: request,
            // headers: {
            //   Accept: "application/pdf",
            // },
            responseHandler: previewResponseHandler,
            // IMPORTANT. Since this query will download the PDF under no circumstances
            // should the results be cached. This could end up using the users memory.
            cache: "no-cache",
          }),
          providesTags: (_r, _e, request) => [
            { type: "HtzOffer", id: request.offerId },
          ],
        },
      ),
      htzOfferPdfDownload: build.mutation<
        { filename: string; objectURL: string },
        PDFRequest
      >({
        query: (request) => ({
          url: `/htz/offer/pdf`,
          method: "PATCH",
          body: {
            ...request,
            download: true,
          },
          responseHandler: downloadResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        invalidatesTags: (_r, _e, request) => [
          { type: "HtzOffer", id: request.offerId },
        ],
      }),
    }),
  });

export const {
  useHtzOfferPutMutation,
  useHtzOfferShowQuery,
  useHtzOfferListQuery,
  useHtzOfferDeleteMutation,
  useHtzOfferPdfPreviewQuery,
  useHtzOfferPdfDownloadMutation,
} = offerApi;

export interface PutRequest extends Offer {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  date?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  offers: Offer[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export enum OfferDocument {
  ALL = "all",
  OFFER = "offer",
  ORDER = "order",
}

export interface PreviewPDFRequest {
  offerId: string;
  document: OfferDocument;
}

export interface PDFRequest {
  offerId: string;
  document: OfferDocument;
  download: boolean;
  downloadBy: string;
}
