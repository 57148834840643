import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  useWorkOrderContext,
  WorkOrderContextProvider,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import {
  TabsNav,
  TabsNavContent,
  TabsNavLink,
  TabsNavList,
} from "@/shared/components/layout/tabs-nav";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteWorkOrderDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/delete-work-order-dialog";
import { WorkOrderPdf } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-pdf";
import { AirMicrobialSamplesContextProvider } from "@/shared/components/domain/samples/air-microbial-samples-context";
import { SurfaceMicrobialSamplesContextProvider } from "@/shared/components/domain/samples/surface-microbial-samples-context";
import { useWorkOrderContextProviderDependencies } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/use-work-order-context-provider-dependencies";
import { useSamplesAirMicrobialSampleSetListQuery } from "@/services/backend/samples/sample/air-microbial-service";
import { FilterMode } from "@/shared/lib/filter/filter";
import { useSamplesSurfaceMicrobialSampleSetListQuery } from "@/services/backend/samples/sample/surface-microbial-service";
import { DustDensitySamplesContextProvider } from "@/shared/components/domain/samples/dust-density-samples-context";
import { WaterSamplesContextProvider } from "@/shared/components/domain/samples/water-samples-context";
import { useSamplesWaterSampleListQuery } from "@/services/backend/samples/sample/water-sample-service";
import { useSamplesDustDensitySampleListQuery } from "@/services/backend/samples/sample/dust-density-sample-service";

export function WorkOrderRoute() {
  const { workOrderId } = useParams();

  const {
    workOrder,
    positionCatalog,
    ahuComponentCatalog,
    structuralAssessmentCatalog,
    visualAssessmentCatalog,
    customerAhus,
    processOffers,
    processOrders,
    laboratories,
    devices,
    isLoading,
    error,
  } = useWorkOrderContextProviderDependencies(workOrderId!);

  const {
    data: airMicrobialList,
    isLoading: airMicrobialListLoading,
    error: airMicrobialListError,
  } = useSamplesAirMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrder?.id ?? ""],
    },
  });
  const {
    data: surfaceMicrobialList,
    isLoading: surfaceMicrobialListLoading,
    error: surfaceMicrobialListError,
  } = useSamplesSurfaceMicrobialSampleSetListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrder?.id ?? ""],
    },
  });

  const {
    data: dustDensityList,
    isLoading: dustDensityListLoading,
    error: dustDensityListError,
  } = useSamplesDustDensitySampleListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrder?.id ?? ""],
    },
  });

  const {
    data: waterList,
    isLoading: waterListLoading,
    error: waterListError,
  } = useSamplesWaterSampleListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrder?.id ?? ""],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (
    airMicrobialListError ||
    surfaceMicrobialListError ||
    dustDensityListError ||
    waterListError
  ) {
    const err =
      airMicrobialListError ||
      surfaceMicrobialListError ||
      dustDensityListError ||
      waterListError;
    return <RTKQueryErrorAlert error={err} />;
  }

  if (
    isLoading ||
    airMicrobialListLoading ||
    surfaceMicrobialListLoading ||
    dustDensityListLoading ||
    waterListLoading
  ) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  // TODO catch if one of the deps is undefined

  return (
    <PersistenceContextProvider>
      <WorkOrderContextProvider
        workOrder={workOrder!}
        positionCatalog={positionCatalog!}
        ahuComponentCatalog={ahuComponentCatalog!}
        structuralAssessmentCatalog={structuralAssessmentCatalog!}
        visualAssessmentCatalog={visualAssessmentCatalog!}
        airHandlingUnits={customerAhus!}
        offers={processOffers!}
        orders={processOrders!}
        laboratories={laboratories!}
        devices={devices!}
      >
        <AirMicrobialSamplesContextProvider
          initialSets={airMicrobialList!.sampleSets}
        >
          <SurfaceMicrobialSamplesContextProvider
            initialSets={surfaceMicrobialList!.sampleSets}
          >
            <DustDensitySamplesContextProvider
              initialSamples={dustDensityList!.samples}
            >
              <WaterSamplesContextProvider initialSamples={waterList!.samples}>
                <WorkOrderPage />
              </WaterSamplesContextProvider>
            </DustDensitySamplesContextProvider>
          </SurfaceMicrobialSamplesContextProvider>
        </AirMicrobialSamplesContextProvider>
      </WorkOrderContextProvider>
    </PersistenceContextProvider>
  );
}

function WorkOrderPage() {
  const { workOrder, validationError } = useWorkOrderContext();

  const hasDataError = hasFieldError(validationError, "date");
  const hasPosError = hasFieldError(validationError, "positions");

  return (
    <MilestonePageContent>
      <TabsNav>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabsNavList>
              <TabsNavLink to="data" hasValidationError={hasDataError}>
                {t("Arbeitsschein")}
              </TabsNavLink>
              <TabsNavLink to="positions" hasValidationError={hasPosError}>
                {t("Leistungen")}
              </TabsNavLink>
              <TabsNavLink to="laboratory" hasValidationError={hasPosError}>
                {t("Labor")}
              </TabsNavLink>
            </TabsNavList>
            <span className="font-mono text-lg text-gray-900">
              <ArtefactNumberById artefactId={workOrder.id} />
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            <DeleteWorkOrderDialog workOrderId={workOrder.id} />
            <WorkOrderPdf workOrder={workOrder} />
          </div>
        </MilestonePageContentMenu>
        <TabsNavContent />
      </TabsNav>
    </MilestonePageContent>
  );
}
