import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import t from "@/lang/lang";
import { NavLink, Outlet, useParams } from "react-router-dom";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, PanelsTopLeft } from "lucide-react";
import { useHtzWorkOrderListQuery } from "@/services/backend/htz/work-order/service";

export function WorkOrdersRoute() {
  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Arbeitsscheine")}</MilestonePageTitle>
        <MilestonePageActions>
          <Subnavigation />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  );
}

function Subnavigation() {
  const { processId, workOrderId } = useParams();

  const { data: list } = useHtzWorkOrderListQuery({
    process: {
      active: true,
      values: [processId!],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  const currentPageLabel = () => {
    if (workOrderId) {
      return <ArtefactNumberById artefactId={workOrderId} />;
    }

    return t("Übersicht");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel()}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <NavLink to="">
            <DropdownMenuItem>
              <PanelsTopLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Auftragsbestätigungen")}</DropdownMenuLabel>
        {[...(list?.workOrders ?? [])]
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .map((workOrder) => (
            <NavLink to={workOrder.id} key={workOrder.id}>
              <DropdownMenuItem>
                <ArtefactNumberById artefactId={workOrder.id} />
              </DropdownMenuItem>
            </NavLink>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
