import { RouteObject } from "react-router-dom";
import NumbersArtefactBaseDataRoute from "@/routes/base-data/numbers/artefact/numbers-artefact-base-data-route";
import { NumbersProcessBaseDataRoute } from "@/routes/base-data/numbers/process/numbers-process-base-data-route";

export const numbersBaseDataRoutes: RouteObject[] = [
  {
    path: "artefact",
    element: <NumbersArtefactBaseDataRoute />,
  },
  {
    path: "process",
    element: <NumbersProcessBaseDataRoute />,
  },
];
