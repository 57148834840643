import { deleteItem } from "@/services/backend/htz/position-catalog/position-catalog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import t from "@/lang/lang";
import {
  useHtzPositionCatalogPutMutation,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useState } from "react";

export interface DeletePositionCatalogItemDialogProps {
  catalogId: string;
  positionId: string;
}

export function DeletePositionCatalogItemDialog({
  catalogId,
  positionId,
}: DeletePositionCatalogItemDialogProps) {
  const [open, setOpen] = useState(false);
  const { data: catalog } = useHtzPositionCatalogShowQuery({ id: catalogId });
  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzPositionCatalogPutMutation();

  if (isSuccess) {
    reset();
    setOpen(false);
  }

  if (!catalog) {
    return (
      <Button size="sm" variant="destructive" disabled>
        <Trash2 className="h-4 w-4" />
      </Button>
    );
  }

  const onClick = () => {
    if (isLoading || !catalog) {
      return;
    }

    put(deleteItem(catalog, positionId));
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(`Leistung löschen?`)}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
