import { Label } from "@/services/backend/processes/state/state";
import t from "@/lang/lang";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { HTMLAttributes, ReactNode } from "react";
import { Archive, CheckCircle, HelpCircle, RefreshCw } from "lucide-react";
import { cn } from "@/shared/lib/utils";

interface StateIconProps extends HTMLAttributes<HTMLDivElement> {
  label?: Label;
}

export function StateIcon({ label = undefined, className }: StateIconProps) {
  if (label === undefined) {
    return null;
  }

  const icons: { [key: string]: ReactNode } = {
    [Label.Unknown]: <HelpCircle className="h-4 w-4" />,
    [Label.Running]: <RefreshCw className="h-4 w-4" />,
    [Label.Archived]: <Archive className="h-4 w-4" />,
    [Label.Done]: <CheckCircle className="h-4 w-4" />,
  };

  return (
    <div className={cn("flex", className)}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>{icons[label]}</TooltipTrigger>
          <TooltipContent>{t(label)}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
