import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { acquireBackendAccessToken } from "@/shared/lib/authorization/msal-access-token";

/**
 * reAuthOn401Wrapper is a wrapper around fetchBaseQuery that will automatically
 * re-authenticate the user if a 401 response is received.
 * Be-aware that this is an edge case, since msal will automatically handle
 * the token refresh in most cases before it is sent to the backend.
 *
 * reAuthOn401Wrapper considers only msal, since dev token
 * auth should actually fail should there be a 401 (wrong configuration).
 */
export function reAuthOn401Wrapper(
  baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {
  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      // get a new token, will be available in the next baseQuery call
      await acquireBackendAccessToken();
      // retry the request
      result = await baseQuery(args, api, extraOptions);
    }
    return result;
  };
}
