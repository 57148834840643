import {
  Context,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SurfaceMicrobialSampleSet } from "@/services/backend/samples/sample/surface-microbial-sample";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import {
  useSamplesSurfaceMicrobialSampleSetDeleteMutation,
  useSamplesSurfaceMicrobialSampleSetPutMutation,
} from "@/services/backend/samples/sample/surface-microbial-service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";

interface SurfaceMicrobialSampleSetContextInterface {
  // what the context was initialized with
  initialSets: SurfaceMicrobialSampleSet[];
  // what was added from within the context
  newSets: SurfaceMicrobialSampleSet[];
  addSet: (set: SurfaceMicrobialSampleSet) => unknown;
  removeNewSet: (setId: EntityId) => unknown;
  // what was deleted from within the context
  deletedSets: EntityId[];
  deleteSet: (setId: EntityId) => unknown;
  removeDeletedSet: (setId: EntityId) => unknown;
  // what was updated from within the context
  updatedSets: SurfaceMicrobialSampleSet[];
  updateSet: (set: SurfaceMicrobialSampleSet) => unknown;
  removeUpdatedSet: (setId: EntityId) => unknown;
  // the current state of the context
  sets: SurfaceMicrobialSampleSet[];
  // setSets: Dispatch<SetStateAction<SurfaceMicrobialSampleSet[]>>;
  showError: (setId: string) => unknown;
}

const SurfaceMicrobialSamplesContext: Context<SurfaceMicrobialSampleSetContextInterface> =
  createContext<SurfaceMicrobialSampleSetContextInterface>({
    initialSets: [],
    newSets: [],
    addSet: () => null,
    removeNewSet: () => null,
    deletedSets: [],
    deleteSet: () => null,
    removeDeletedSet: () => null,
    updatedSets: [],
    updateSet: () => null,
    removeUpdatedSet: () => null,
    sets: [],
    showError: () => null,
  });

export function useSurfaceMicrobialSamplesContext() {
  return useContext(SurfaceMicrobialSamplesContext);
}

export function SurfaceMicrobialSamplesContextProvider({
  initialSets,
  children,
}: {
  initialSets: SurfaceMicrobialSampleSet[];
  children: ReactNode;
}) {
  const [initialSetsState, setInitialSetsState] =
    useState<SurfaceMicrobialSampleSet[]>(initialSets);
  const [newSets, setNewSets] = useState<SurfaceMicrobialSampleSet[]>([]);
  const [deletedSets, setDeletedSets] = useState<EntityId[]>([]);
  const [updatedSets, setUpdatedSets] = useState<SurfaceMicrobialSampleSet[]>(
    [],
  );
  // const [sets, setSets] = useState<SurfaceMicrobialSampleSet[]>(initialSets);

  // update runs after successful sync with the backend
  useEffect(() => {
    setInitialSetsState(initialSets);
  }, [initialSets]);

  // The current state of sets is computed.
  const sets = useMemo(() => {
    const allSets = [...initialSetsState, ...newSets];
    const updated = allSets.map((set) => {
      const updatedSet = updatedSets.find((u) => u.id === set.id);
      return updatedSet ?? set;
    });
    return updated.filter((set) => !deletedSets.includes(set.id));
  }, [initialSetsState, newSets, deletedSets, updatedSets]);

  const value = useMemo(
    () => ({
      initialSets: initialSetsState,
      newSets,
      addSet: (set: SurfaceMicrobialSampleSet) =>
        setNewSets((prev) => putSet(prev, set)),
      removeNewSet: (setId: EntityId) =>
        setNewSets((prev) => prev.filter((s) => s.id !== setId)),
      deletedSets,
      deleteSet: (setId: EntityId) =>
        setDeletedSets((prev) => [...prev, setId]),
      removeDeletedSet: (setId: EntityId) =>
        setDeletedSets((prev) => prev.filter((id) => id !== setId)),
      updatedSets,
      updateSet: (set: SurfaceMicrobialSampleSet) =>
        setUpdatedSets((prev) => putSet(prev, set)),
      removeUpdatedSet: (setId: EntityId) =>
        setUpdatedSets((prev) => prev.filter((s) => s.id !== setId)),
      sets,
      showError: () => null, // TODO, implement after backend sync
    }),
    [initialSetsState, newSets, deletedSets, updatedSets, sets],
  );

  return (
    <SurfaceMicrobialSamplesContext.Provider value={value}>
      <Updater>
        <Deleter>{children}</Deleter>
      </Updater>
    </SurfaceMicrobialSamplesContext.Provider>
  );
}

function Updater({ children }: PropsWithChildren) {
  const { newSets, updatedSets, deletedSets, removeUpdatedSet, removeNewSet } =
    useSurfaceMicrobialSamplesContext();

  // "update" is the set to be updated next.
  // It will be picked up by the debounced mutation below.
  const [nextUpdate, setNextUpdate] = useState<SurfaceMicrobialSampleSet>(
    {} as SurfaceMicrobialSampleSet, // initial set will be ignored by the mutation
  );

  // schedule the next update
  useEffect(() => {
    if (newSets.length > 0) {
      const candidate = newSets[0];
      if (!deletedSets.includes(candidate.id)) {
        setNextUpdate(newSets[0]);
      }
    }

    if (updatedSets.length > 0) {
      const candidate = updatedSets[0];
      if (!deletedSets.includes(candidate.id)) {
        setNextUpdate(updatedSets[0]);
      }
    }
  }, [deletedSets, newSets, removeUpdatedSet, updatedSets]);

  const [put, { isLoading, error, isSuccess, reset }] =
    useSamplesSurfaceMicrobialSampleSetPutMutation();

  const decoratedReset = () => {
    reset();
    if (nextUpdate.id) {
      removeNewSet(nextUpdate.id);
      removeUpdatedSet(nextUpdate.id);
    }
  };

  useDebouncedMutationWithPersistenceStateContextUpdate(
    nextUpdate,
    put,
    isLoading,
    error,
    isSuccess,
    decoratedReset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}

function Deleter({ children }: PropsWithChildren) {
  const { deletedSets, removeDeletedSet } = useSurfaceMicrobialSamplesContext();

  const [nextDelete, setNextDelete] = useState<EntityId>("");

  // schedule the next delete
  useEffect(() => {
    if (deletedSets.length > 0) {
      setNextDelete(deletedSets[0]);
    }
  }, [deletedSets]);

  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useSamplesSurfaceMicrobialSampleSetDeleteMutation();

  const request = useMemo(() => ({ id: String(nextDelete) }), [nextDelete]);

  const decoratedReset = () => {
    reset();
    if (nextDelete) {
      removeDeletedSet(nextDelete);
    }
  };

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    doDelete,
    isLoading,
    error,
    isSuccess,
    decoratedReset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}

export function getSurfaceMicrobialSet(
  sets: SurfaceMicrobialSampleSet[],
  setId: EntityId,
): SurfaceMicrobialSampleSet | null {
  return sets.find((s) => s.id === setId) ?? null;
}

function putSet(
  sets: SurfaceMicrobialSampleSet[],
  set: SurfaceMicrobialSampleSet,
) {
  const idx = sets.findIndex((s) => s.id === set.id);
  if (idx === -1) {
    return [...sets, set];
  }
  return sets.map((s, i) => (i === idx ? set : s));
}
