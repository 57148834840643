import { Outlet, useParams } from "react-router-dom";
import { useHtzOrderShowQuery } from "@/services/backend/htz/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Order } from "@/services/backend/htz/orders/order";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteOrderDialog } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/_components/delete-order-dialog";
import { OrderCardSkeleton } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/data/_components/order-card";
import {
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";

export function OrderRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading,
    error,
  } = useHtzOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <PageSkeleton />;
  }

  return <Page order={order!} />;
}

export function Page({ order }: { order: Order }) {
  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <MilestonePageContent>
          <MilestonePageContentMenu>
            <div className="flex items-center space-x-4">
              <TabNavigation />
            </div>
            <div className="flex items-center space-x-2">
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteOrderDialog orderId={order.id} />
            </div>
          </MilestonePageContentMenu>
          <Outlet />
        </MilestonePageContent>
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

export function PageSkeleton() {
  return (
    <div className="animate-pulse">
      <MilestonePageContent>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabNavigation />
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            <Button variant="outline" disabled>
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </MilestonePageContentMenu>
        <OrderCardSkeleton />
      </MilestonePageContent>
    </div>
  );
}

function TabNavigation() {
  const { notReadyError } = useErrArtefactNotReady();

  const hasDataError = hasFieldError(notReadyError, "date");
  const hasPosError = hasFieldError(notReadyError, "positions");
  const hasAddrError = hasFieldError(
    notReadyError,
    "postalAddress",
    "serviceAddress",
  );
  const hasRepError = hasFieldError(notReadyError, "representative");

  return (
    <TabsNavList>
      <TabsNavLink to="data" hasValidationError={hasDataError}>
        {t("Auftragseingang")}
      </TabsNavLink>
      <TabsNavLink to="positions" hasValidationError={hasPosError}>
        {t("Leistungen")}
      </TabsNavLink>
      <TabsNavLink to="addresses" hasValidationError={hasAddrError}>
        {t("Adressen")}
      </TabsNavLink>
      <TabsNavLink to="representative" hasValidationError={hasRepError}>
        {t("Betreuer")}
      </TabsNavLink>
    </TabsNavList>
  );
}
