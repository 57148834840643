export interface User {
  id: string;
  fullname: string;
  initials: string;
  roles: string[];
  groups: string[];
}

export function hasRole(user: User, role: string): boolean {
  return user.roles.includes(role);
}
