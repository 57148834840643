import { H2, HeadingProps } from "@/shared/components/ui/typography";
import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";

interface BaseDataPageProps extends HTMLAttributes<HTMLDivElement> {}

export function BaseDataPage({ children, ...props }: BaseDataPageProps) {
  return <div {...props}>{children}</div>;
}

export function BaseDataPageHeader({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("space-y-2", className)} {...props}>
      {children}
    </div>
  );
}

interface BaseDataPageTitleProps extends HeadingProps {}

export function BaseDataPageTitle({
  children,
  className,
  ...props
}: BaseDataPageTitleProps) {
  return (
    <H2 className={cn("flex w-full justify-between", className)} {...props}>
      {children}
    </H2>
  );
}

export function BaseDataPageDescription({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("text-sm text-muted-foreground")} {...props}>
      {children}
    </div>
  );
}

export function BaseDataPageContent({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("space-y-4 py-4", props.className)}>{children}</div>
  );
}
