import {
  addItem,
  Item,
  newCatalogItem,
  updateItem,
} from "@/services/backend/htz/position-catalog/position-catalog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Edit, Plus } from "lucide-react";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import {
  useHtzPositionCatalogPutMutation,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import { Textarea } from "@/shared/components/ui/textarea";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  fieldErrors,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { v4 } from "uuid";
import { Checkbox } from "@/shared/components/ui/checkbox";

export function PutPositionCatalogItemDialog({
  catalogId,
  position = undefined,
}: {
  catalogId: string;
  position?: Item;
}) {
  const { data: catalog } = useHtzPositionCatalogShowQuery({ id: catalogId });
  const [open, setOpen] = useState(false);
  const [updatedPosition, setUpdatedPosition] = useState<Item>(
    position ?? newCatalogItem(v4(), catalogId),
  );

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzPositionCatalogPutMutation();

  const validationError = rtkErrIsValidationError(error) ? error.data : null;
  const errorsFor = (field: string): string[] => {
    const prefixedField = `positions.${updatedPosition.id}.${field}`;
    const errors = fieldErrors(validationError, prefixedField);
    return errors ?? [];
  };

  if (isSuccess) {
    setOpen(false);
    if (!position) {
      setUpdatedPosition(newCatalogItem(v4(), catalogId));
    }
    reset();
  }

  if (!catalog) {
    return position ? (
      <Button variant="outline" size="sm" disabled>
        <Edit className="h-5 w-5" />
      </Button>
    ) : (
      <Button size="sm" disabled>
        <Plus className="mr-2 h-5 w-5" />
        <span>{t("Leistung")}</span>
      </Button>
    );
  }

  const onClick = () => {
    if (isLoading || !catalog) {
      return;
    }

    const updatedCatalog = position
      ? updateItem(catalog, updatedPosition)
      : addItem(catalog, updatedPosition);

    put(updatedCatalog);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {position ? (
          <Button variant="outline" size="sm">
            <Edit className="h-5 w-5" />
          </Button>
        ) : (
          <Button size="sm">
            <Plus className="mr-2 h-5 w-5" />
            <span>{t("Leistung")}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {position ? t("Leistung bearbeiten") : t("Leistung hinzufügen")}
          </DialogTitle>
        </DialogHeader>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Titel")}</Label>
          <Input
            type="text"
            placeholder={t("Titel")}
            value={updatedPosition.title}
            onChange={(e) =>
              setUpdatedPosition({ ...updatedPosition, title: e.target.value })
            }
          />
          <InputErrorMessages errors={errorsFor("title")} />
        </div>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Kategorie")}</Label>
          <Input
            type="text"
            placeholder={t("Kategorie")}
            value={updatedPosition.category}
            onChange={(e) =>
              setUpdatedPosition({
                ...updatedPosition,
                category: e.target.value,
              })
            }
          />
          <InputErrorMessages errors={errorsFor("category")} />
        </div>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Beschreibung")}</Label>
          <Textarea
            placeholder={t("Beschreibung")}
            value={updatedPosition.description}
            onChange={(e) =>
              setUpdatedPosition({
                ...updatedPosition,
                description: e.target.value,
              })
            }
          />
          <InputErrorMessages errors={errorsFor("description")} />
        </div>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Preis")}</Label>
          <Input
            type="number"
            placeholder={t("Preis")}
            value={updatedPosition.unitPrice}
            onChange={(e) =>
              setUpdatedPosition({
                ...updatedPosition,
                unitPrice: Number(e.target.value),
              })
            }
          />
          <InputErrorMessages errors={errorsFor("unitPrice")} />
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={updatedPosition.structuralInspectionRequired}
            onCheckedChange={(checked) => {
              if (checked === "indeterminate") {
                return;
              }
              setUpdatedPosition({
                ...updatedPosition,
                structuralInspectionRequired: checked,
              });
            }}
          />
          <Label>{t("Benötigt Konstruktive Beurteilung")}</Label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={updatedPosition.visualInspectionRequired}
            onCheckedChange={(checked) => {
              if (checked === "indeterminate") {
                return;
              }
              setUpdatedPosition({
                ...updatedPosition,
                visualInspectionRequired: checked,
              });
            }}
          />
          <Label>{t("Benötigt Visuelle Beurteilung")}</Label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={updatedPosition.microbiologicalInspectionRequired}
            onCheckedChange={(checked) => {
              if (checked === "indeterminate") {
                return;
              }
              setUpdatedPosition({
                ...updatedPosition,
                microbiologicalInspectionRequired: checked,
              });
            }}
          />
          <Label>{t("Benötigt Mikrobiologische Beurteilung")}</Label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            checked={updatedPosition.photoDocumentationRequired}
            onCheckedChange={(checked) => {
              if (checked === "indeterminate") {
                return;
              }
              setUpdatedPosition({
                ...updatedPosition,
                photoDocumentationRequired: checked,
              });
            }}
          />
          <Label>{t("Benötigt Fotodokumentation")}</Label>
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Speichern")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
