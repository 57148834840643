import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useRltOfferShowQuery } from "@/services/backend/rlt/offers/service";
import { Outlet, useParams } from "react-router-dom";
import { Offer } from "@/services/backend/rlt/offers/offer";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { Tab } from "@headlessui/react";
import t from "@/lang/lang";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { DeleteOfferDialog } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/_components/delete-offer-dialog";
import { Card } from "@/shared/components/ui/card";
import { OfferImmutablePage } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/_components/offer-immutable-page";
import { OfferPdf } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/_components/offer-pdf";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function OfferRoute() {
  const { offerId } = useParams();

  const {
    data: offer,
    isLoading,
    error,
  } = useRltOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (offer!.immutable) {
    return <OfferImmutablePage offer={offer!} />;
  }

  return <OfferPage offer={offer!} />;
}

function OfferPage(props: { offer: Offer }) {
  const { offer } = props;

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <Page offer={offer} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

function Page({ offer }: { offer: Offer }) {
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <MilestonePageContent>
      <Tab.Group>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabsNavList>
              <TabsNavLink
                to="data"
                hasValidationError={hasOfferError(notReadyError)}
              >
                {t("Angebot")}
              </TabsNavLink>
              <TabsNavLink to="treatment">{t("Leistungen")}</TabsNavLink>
              <TabsNavLink to="calculation">{t("Kalkulation")}</TabsNavLink>
              <TabsNavLink
                to="customer-address"
                hasValidationError={hasAddressError(notReadyError)}
              >
                {t("Anschrift")}
              </TabsNavLink>
              <TabsNavLink
                to="representative"
                hasValidationError={hasRepresentativeError(notReadyError)}
              >
                {t("Betreuer")}
              </TabsNavLink>
              <TabsNavLink to="contract">{t("Vertrag")}</TabsNavLink>
            </TabsNavList>
            <span className="font-mono text-lg text-gray-900">
              <ArtefactNumberById artefactId={offer.id} />
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            <DeleteOfferDialog offerId={offer.id} />
            <OfferPdf offer={offer} />
          </div>
        </MilestonePageContentMenu>
        <Outlet />
      </Tab.Group>
    </MilestonePageContent>
  );
}

function hasOfferError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "date",
    "treatment.offerPrice",
    "pricesForExtras.ratePerAccessPanel",
    "pricesForExtras.hourlyRate",
  ];

  return fields.some((field) => field in error.errors);
}

function hasAddressError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "customerAddress.city",
  ];

  return fields.some((field) => field in error.errors);
}

function hasRepresentativeError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}
