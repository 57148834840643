import { backendApi } from "@/services/backend/api";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { SurfaceMicrobialSampleSet } from "@/services/backend/samples/sample/surface-microbial-sample";
import { Filter } from "@/shared/lib/filter/filter";
import { EntityId } from "@/shared/nidavellir/types/entity-id";

const labApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesSurfaceMicrobialSampleSet"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesSurfaceMicrobialSampleSetPut: build.mutation<
        SurfaceMicrobialSampleSet,
        PutRequest
      >({
        query: (request) => ({
          url: `/samples/surface-microbial`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesSurfaceMicrobialSampleSet", id: request.id },
          "SamplesSurfaceMicrobialSampleSet",
        ],
      }),
      samplesSurfaceMicrobialSampleSetUpdateLab: build.mutation<
        SurfaceMicrobialSampleSet,
        UpdateLabRequest
      >({
        query: (request) => ({
          url: `/samples/surface-microbial/lab`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesSurfaceMicrobialSampleSet", id: request.id },
          "SamplesSurfaceMicrobialSampleSet",
        ],
      }),
      samplesSurfaceMicrobialSampleSetShow: build.query<
        SurfaceMicrobialSampleSet,
        ShowRequest
      >({
        query: (request) => ({
          url: `/samples/surface-microbial`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("SamplesSurfaceMicrobialSampleSet"),
      }),
      samplesSurfaceMicrobialSampleSetList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/surface-microbial/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.sampleSets, "SamplesSurfaceMicrobialSampleSet"),
      }),
      samplesSurfaceMicrobialSampleSetDelete: build.mutation<
        null,
        DeleteRequest
      >({
        query: (request) => ({
          url: `/samples/surface-microbial`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["SamplesSurfaceMicrobialSampleSet"],
      }),
    }),
  });

export const {
  useSamplesSurfaceMicrobialSampleSetPutMutation,
  useSamplesSurfaceMicrobialSampleSetUpdateLabMutation,
  useSamplesSurfaceMicrobialSampleSetShowQuery,
  useSamplesSurfaceMicrobialSampleSetListQuery,
  useSamplesSurfaceMicrobialSampleSetDeleteMutation,
} = labApi;

export interface PutRequest extends SurfaceMicrobialSampleSet {}

export interface UpdateLabRequest {
  id: string;
  labId: EntityId;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  lab?: Filter<string>;
  artefact?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  sampleSets: SurfaceMicrobialSampleSet[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
