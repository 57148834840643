import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import t from "@/lang/lang";
import { Outlet, useParams } from "react-router-dom";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { Loader } from "lucide-react";

export type RequirementContextType = {
  customerId: string;
  addressId: string;
};

export default function RequirementsRoute() {
  const { processId } = useParams();
  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (processDataIsLoading) {
    return <Loader />;
  }

  if (processDataError || !process) {
    return <div>Fehler beim Laden der Vorgangsdaten</div>;
  }
  return (
    <MilestonePage>
      <MilestonePageHeader className="flex flex-col items-start">
        <MilestonePageTitle>{t("Bestandsaufnahme")}</MilestonePageTitle>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet
          context={{
            customerId: process!.customerId,
          }}
        />
      </MilestonePageContent>
    </MilestonePage>
  );
}
