import { useState } from "react";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Check,
  checkRequiresChildren,
  LastMinuteRiskAnalysis,
} from "@/services/backend/rlt/work-orders/last-minute-risk-analysis";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  ConfirmLMRARequest,
  SetCheckResult,
  useRltLMRAConfirmMutation,
  useRltLMRASetCheckResultMutation,
} from "@/services/backend/rlt/work-orders/lmra-service";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { AlertCircle } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Card, CardContent, CardFooter } from "@/shared/components/ui/card";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Button } from "@/shared/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/shared/components/ui/radio-group";

export function UnconfirmedLMRAComponent(props: {
  lmra: LastMinuteRiskAnalysis;
}) {
  const { lmra } = props;

  return (
    <div className="space-y-4">
      <StartBeforeWorkAlert />
      <ConfirmationComponent lmra={lmra} />
      <CheckListComponent lmraId={lmra.id} checks={lmra.checks} />
    </div>
  );
}

function StartBeforeWorkAlert() {
  return (
    <Alert>
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{t("Vor Beginn durchführen!")}</AlertTitle>
      <AlertDescription>
        {t(
          "Die Gefährdungsbeurteilung / LMRA muss direkt vor Beginn der Arbeiten am Arbeitsplatz durchgeführt werden.",
        )}
      </AlertDescription>
    </Alert>
  );
}

function ConfirmationComponent(props: { lmra: LastMinuteRiskAnalysis }) {
  const { lmra } = props;
  const { user } = useAuth();
  const [request, setRequest] = useState<ConfirmLMRARequest>({
    id: lmra.id,
    confirmedBy: user?.id ?? "",
    conductedBy: "",
  });
  const [confirm, { isLoading, error }] = useRltLMRAConfirmMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    confirm(request);
  };

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="grid items-center gap-1.5">
          <Label>{t("Durchgeführt von")}</Label>
          <Input
            value={request.conductedBy}
            onChange={(e) =>
              setRequest({ ...request, conductedBy: e.target.value })
            }
            placeholder={t("Mitarbeiter")}
          />
          <InputValidationErrors error={error} field="conductedBy" />
        </div>
        <RTKQueryErrorAlert error={error} className="mt-4" />
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button
          variant="outline"
          className="bg-white"
          onClick={onClick}
          disabled={isLoading}
        >
          {t("Durchführung bestätigen")}
        </Button>
      </CardFooter>
    </Card>
  );
}

function CheckListComponent(props: { lmraId: string; checks: Check[] }) {
  const { lmraId, checks } = props;

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nr")}</TableHead>
            <TableHead className="w-full">{t("Fragestellung")}</TableHead>
            <TableHead>{t("Prüfung")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...checks].sort(byNumber).map((check) => (
            <CheckComponent key={check.id} lmraId={lmraId} check={check} />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function byNumber(a: Check, b: Check) {
  return a.number - b.number;
}

function ChildCheckListComponent(props: { lmraId: string; checks: Check[] }) {
  const { lmraId, checks } = props;

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full table-auto border-collapse text-left text-sm text-gray-500">
        <TableBody>
          {[...checks].sort(byNumber).map((check) => (
            <CheckComponent key={check.id} lmraId={lmraId} check={check} />
          ))}
        </TableBody>
      </table>
    </div>
  );
}

function CheckComponent(props: { lmraId: string; check: Check }) {
  const { lmraId, check } = props;
  const [request, setRequest] = useState<SetCheckResult>({
    id: lmraId,
    checkId: check.id,
    result: check.result,
  });
  const [setResult, { isLoading, error, isSuccess, reset }] =
    useRltLMRASetCheckResultMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setResult,
    isLoading,
    error,
    isSuccess,
    reset,
    1,
  );

  return (
    <TableRow>
      <TableCell className="align-top">{check.number}</TableCell>
      <TableCell className="align-top">
        <div>{check.description}</div>
        {checkRequiresChildren(check) && (
          <div className="mt-6">
            <ChildCheckListComponent lmraId={lmraId} checks={check.children} />
          </div>
        )}
      </TableCell>
      <TableCell className="align-top">
        <RadioGroup
          value={check.checked ? check.options[check.result] : ""}
          onValueChange={(value) => {
            if (value === "") {
              setRequest((prev) => ({ ...prev, result: null }));
            }
            setRequest((prev) => ({
              ...prev,
              result: check.options.indexOf(value),
            }));
          }}
          className={
            check.options.length <= 2 ? "flex justify-center space-x-4" : ""
          }
        >
          {check.options.map((option) => (
            <Label
              key={option}
              htmlFor={check.id + option}
              className="flex cursor-pointer items-center space-x-2 rounded-lg border px-2 py-3"
            >
              <RadioGroupItem value={option} id={check.id + option} />
              <span>{option}</span>
            </Label>
          ))}
        </RadioGroup>
      </TableCell>
    </TableRow>
  );
}
