import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import t from "@/lang/lang";
import { useListCompanyQuery } from "@/services/backend/company/company";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RefreshCw } from "lucide-react";
import { SequencesTable } from "@/routes/base-data/numbers/artefact/_components/sequences-table";

export default function NumbersArtefactBaseDataRoute() {
  return (
    <PersistenceContextProvider>
      <BaseDataPage>
        <BaseDataPageHeader>
          <BaseDataPageTitle>{t("Dokumenten Nummern")}</BaseDataPageTitle>
          <BaseDataPageDescription>
            {t(
              "Hier können die Nummernsequenzen für unterschiedliche Dokumentenarten für die Unternehmen konfiguriert werden.",
            )}
          </BaseDataPageDescription>
        </BaseDataPageHeader>
        <BaseDataPageContent>
          <SequencesTables />
        </BaseDataPageContent>
      </BaseDataPage>
    </PersistenceContextProvider>
  );
}

function SequencesTables() {
  const { data: list, isLoading, error } = useListCompanyQuery({});

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t("Lade Sequenzen ...")}</CardTitle>
        </CardHeader>
        <CardContent className="flex justify-center">
          <RefreshCw className="animate-spin" />
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      {list!.companies.map((company) => (
        <SequencesTable company={company} key={company.id} />
      ))}
    </div>
  );
}
