import { Card } from "@/shared/components/ui/card";
import { OrderTable } from "@/routes/gesec/processes/[processId]/htz/orders/_components/order-table";
import { useParams } from "react-router-dom";

export function OrdersIndexRoute() {
  const { processId } = useParams();

  return (
    <Card>
      <OrderTable processId={processId!} />
    </Card>
  );
}
