export enum ArtefactKind {
  Calculation = "calculation",
  Certificate = "certificate",
  Confirmation = "confirmation",
  Contract = "contract",
  Documentation = "documentation",
  LastMinuteRiskAnalysis = "lastminuteriskanalysis",
  Offer = "offer",
  Order = "order",
  Requirement = "requirement",
  Report = "report",
  Treatment = "treatment",
  WorkOrder = "workorder",
}

export const ArtefactKindList: ArtefactKind[] = Object.values(ArtefactKind);
