import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { NewPositionCatalogDialog } from "@/routes/base-data/processes/htz/position-catalogs/_components/new-position-catalog-dialog";
import { ColumnDef } from "@tanstack/react-table";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { DeletePositionCatalogDialog } from "@/routes/base-data/processes/htz/position-catalogs/_components/delete-position-catalog-dialog";
import { ActivePositionCatalogCheckbox } from "@/routes/base-data/processes/htz/position-catalogs/_components/active-position-catalog-checkbox";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useHtzPositionCatalogListQuery } from "@/services/backend/htz/position-catalog/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function PositionCatalogTable() {
  const { data: list, error, isLoading } = useHtzPositionCatalogListQuery({});

  const catalogs = list?.catalogs || [];

  const table = useDataTableController(tableColumns, catalogs);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Card>
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Leistungskataloge")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können Sie Kataloge mit Vorlagen zur Definition von Positionen von HTZ Dienstleistungen verwalten.",
            )}
          </CardDescription>
        </div>
        <NewPositionCatalogDialog />
      </CardHeader>
      <DataTableContainer>
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </DataTableContainer>
    </Card>
  );
}

const tableColumns: ColumnDef<PositionCatalog>[] = [
  {
    header: t("Aktiv"),
    id: "activeCatalog",
    cell: ({ row }) => (
      <div className="ml-4">
        <ActivePositionCatalogCheckbox
          companyId={row.original.companyId}
          catalogId={row.original.id}
        />
      </div>
    ),
  },
  {
    header: t("Katalogname"),
    accessorKey: "name",
    cell: ({ row }) => {
      const path = `/base-data/processes/htz/position-catalogs/${row.original.id}`;
      return (
        <NavLink to={path}>
          <Button variant="link">{row.original.name}</Button>
        </NavLink>
      );
    },
  },
  {
    header: t("Unternehmen"),
    accessorKey: "companyId",
    cell: ({ row }) => <CompanyName companyId={row.original.companyId} />,
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => (
      <div>
        <DeletePositionCatalogDialog catalog={row.original} />
      </div>
    ),
  },
];

function Skeleton() {
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Leistungskataloge")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können Sie Kataloge mit Vorlagen zur Definition von Positionen von HTZ Dienstleistungen verwalten.",
            )}
          </CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}
