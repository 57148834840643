export interface Component {
  kind: Kind;
  data?: unknown;
  formula?: unknown;
  canHaveChildren: boolean;
  permittedChildrenKind: Kind[];
  cleanTime: number;
  basePrice: number;
}

export enum Kind {
  AccessPanel = "AccessPanel",
  AirHandlingUnit = "AirHandlingUnit",
  AirHandlingUnitSchwerpunkt = "AirHandlingUnitSchwerpunkt",
  AirHandlingUnitSystem = "AirHandlingUnitSystem",
  AwayAirDuctAngular = "AwayAirDuctAngular",
  AwayAirDuctRound = "AwayAirDuctRound",
  CrossFlowHeatExchanger = "CrossFlowHeatExchanger",
  ExhaustDuctAngular = "ExhaustDuctAngular",
  ExhaustDuctRound = "ExhaustDuctRound",
  ExtraHours = "ExtraHours",
  FreeText = "FreeText",
  OutdoorAirDuctAngular = "OutdoorAirDuctAngular",
  OutdoorAirDuctRound = "OutdoorAirDuctRound",
  RecirculatingSprayHumidifier = "RecirculatingSprayHumidifier",
  RotaryHeatExchanger = "RotaryHeatExchanger",
  SplitUnit = "SplitUnit",
  SupplyDuctAngular = "SupplyDuctAngular",
  SupplyDuctRound = "SupplyDuctRound",
}

export interface Common<Data, Formula> {
  kind: Kind;
  data: Data;
  formula: Formula;
  canHaveChildren: boolean;
  permittedChildrenKind: Kind[];
  cleanTime: number;
  basePrice: number;
}

export interface AccessPanel
  extends Common<AccessPanelData, AccessPanelFormula> {}

export interface AccessPanelData {
  count: number;
}

export interface AccessPanelFormula {
  pricePerPanel: number;
}

export enum CleaningKind {
  Service = "Service",
  Sanierung = "Sanierung",
  Schwerpunkt = "Schwerpunkt",
}

export interface AirHandlingUnit
  extends Common<AirHandlingUnitData, AirHandlingUnitFormula> {}

export interface AirHandlingUnitData {
  cleaningKind: CleaningKind;
  length: number;
  width: number;
  height: number;
}

export interface AirHandlingUnitFormula {
  sanierungFactor: number;
  minutesPerCubicMeter: number;
  hourlyRate: number;
}

export interface AirHandlingUnitSchwerpunkt
  extends Common<
    AirHandlingUnitSchwerpunktData,
    AirHandlingUnitSchwerpunktFormula
  > {}

export interface AirHandlingUnitSchwerpunktData {
  cleanTime: number;
}

export interface AirHandlingUnitSchwerpunktFormula {
  hourlyRate: number;
}

export interface AirHandlingUnitSystem extends Component {
  designation: string;
  cleaningKind: CleaningKind;
}

export interface AwayAirDuctAngular
  extends Common<AwayAirDuctAngularData, AwayAirDuctAngularFormula> {}

export interface AwayAirDuctAngularData {
  cleaningKind: CleaningKind;
  length: number;
  width: number;
  height: number;
}

export interface AwayAirDuctAngularFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface AwayAirDuctRound
  extends Common<AwayAirDuctRoundData, AwayAirDuctRoundFormula> {}

export interface AwayAirDuctRoundData {
  cleaningKind: CleaningKind;
  length: number;
  diameter: number;
}

export interface AwayAirDuctRoundFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface CrossFlowHeatExchanger
  extends Common<CrossFlowHeatExchangerData, CrossFlowHeatExchangerFormula> {}

export interface CrossFlowHeatExchangerData {
  cleaningKind: CleaningKind;
  length: number;
  width: number;
}

export interface CrossFlowHeatExchangerFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface ExhaustDuctAngular
  extends Common<ExhaustDuctAngularData, ExhaustDuctAngularFormula> {}

export interface ExhaustDuctAngularData {
  cleaningKind: CleaningKind;
  length: number;
  width: number;
  height: number;
}

export interface ExhaustDuctAngularFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface ExhaustDuctRound
  extends Common<ExhaustDuctRoundData, ExhaustDuctRoundFormula> {}

export interface ExhaustDuctRoundData {
  cleaningKind: CleaningKind;
  length: number;
  diameter: number;
}

export interface ExhaustDuctRoundFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface ExtraHours extends Common<ExtraHoursData, ExtraHoursFormula> {}

export interface ExtraHoursData {
  hours: number;
}

export interface ExtraHoursFormula {
  hourlyRate: number;
}

export interface FreeText extends Common<FreeTextData, FreeTextFormula> {}

export interface FreeTextData {
  text: string;
  price: number;
}

export interface FreeTextFormula {}

export interface OutdoorAirDuctAngular
  extends Common<OutdoorAirDuctAngularData, AwayAirDuctAngularFormula> {}

export interface OutdoorAirDuctAngularData {
  cleaningKind: CleaningKind;
  length: number;
  width: number;
  height: number;
}

export interface OutdoorAirDuctAngularFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface OutdoorAirDuctRound
  extends Common<OutdoorAirDuctRoundData, AwayAirDuctRoundFormula> {}

export interface OutdoorAirDuctRoundData {
  cleaningKind: CleaningKind;
  length: number;
  diameter: number;
}

export interface OutdoorAirDuctRoundFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface RecirculatingSprayHumidifier
  extends Common<
    RecirculatingSprayHumidifierData,
    RecirculatingSprayHumidifierFormula
  > {}

export interface RecirculatingSprayHumidifierData {
  cleaningKind: CleaningKind;
  floorLength: number;
  floorWidth: number;
  nozzleCount: number;
}

export interface RecirculatingSprayHumidifierFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  minutesPerNozzle: number;
  hourlyRate: number;
}

export interface RotaryHeatExchanger
  extends Common<RotaryHeatExchangerData, RotaryHeatExchangerFormula> {}

export interface RotaryHeatExchangerData {
  cleaningKind: CleaningKind;
  diameter: number;
}

export interface RotaryHeatExchangerFormula {
  sanierungFactor: number;
  minutesPerDiameter: number;
  hourlyRate: number;
}

export interface SplitUnit extends Common<SplitUnitData, SplitUnitFormula> {}

export interface SplitUnitData {
  count: number;
}

export interface SplitUnitFormula {
  hoursPerUnit: number;
  hourlyRate: number;
}

export interface SupplyDuctAngular
  extends Common<SupplyDuctAngularData, AwayAirDuctAngularFormula> {}

export interface SupplyDuctAngularData {
  cleaningKind: CleaningKind;
  length: number;
  width: number;
  height: number;
}

export interface SupplyDuctAngularFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}

export interface SupplyDuctRound
  extends Common<SupplyDuctRoundData, AwayAirDuctRoundFormula> {}

export interface SupplyDuctRoundData {
  cleaningKind: CleaningKind;
  length: number;
  diameter: number;
}

export interface SupplyDuctRoundFormula {
  sanierungFactor: number;
  minutesPerSquareMeter: number;
  hourlyRate: number;
}
