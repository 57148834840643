import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { useContactsContactKindListQuery } from "@/services/backend/contacts/contact-kind/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { EditContactKindDialog } from "./edit-contact-kind-dialog";
import { DeleteContactKindDialog } from "./delete-contact-kind-dialog";

export function ContactKindList() {
  const { data: list, isLoading, error } = useContactsContactKindListQuery({});

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-full">{t("Art")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {list?.items.map((kind) => (
          <TableRow key={kind.id}>
            <TableCell>{kind.name}</TableCell>
            <TableCell className="flex space-x-2">
              <EditContactKindDialog kind={kind} />
              <DeleteContactKindDialog kind={kind} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function Skeleton() {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Art")}</TableHead>
        </TableRow>
      </TableHeader>
    </Table>
  );
}
