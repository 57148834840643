import { useAuth } from "@/shared/lib/authorization/auth-context";
import { useAuthEntraGroupShowCompaniesQuery } from "@/services/backend/entra/entragroup/service";
import { useListCompanyQuery } from "@/services/backend/company/company";

export function useUserCompanies() {
  const { user } = useAuth();

  // ENTRA
  const {
    data: list,
    isLoading,
    error,
  } = useAuthEntraGroupShowCompaniesQuery({
    groupIds: user?.groups ?? [],
  });

  // DEV TOKEN
  const { data: listDev } = useListCompanyQuery({});

  const companyIds = (() => {
    const envCompany = import.meta.env.VITE_DEV_COMPANY;

    switch (import.meta.env.VITE_AUTH) {
      case "dev":
        return listDev?.companies
          .filter((company) => {
            if (!envCompany) return true;
            return company.portalRoute === envCompany;
          })
          .map((company) => company.id);
      default:
        return list?.companyIds;
    }
  })();

  return { companyIds, isLoading, error };
}
