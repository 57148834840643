import { ComplementaryServiceComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/complementary-service-component";
import { TravelInfoComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/travel-info-component";
import { PestsComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/pests-component";
import { CycleComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/cycle-component";
import { CertificatesComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/certificates-component";
import { AdditionalCommentsComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/additional-comments-component";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  useQpmRequirementAdditionalInformationAddMutation,
  useQpmRequirementAdditionalInformationGetQuery,
} from "@/services/backend/qpm/requirements/service";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import { useOutletContext } from "react-router-dom";
import { CreateRequirementContext } from "@/routes/gemex/processes/qpm/requirements/requirement/create/create-requirement";
import t from "@/lang/lang";

export function AdditionalInfoRoute() {
  const requirementContext = useOutletContext<CreateRequirementContext>();
  return (
    <AdditionalInformationComponent
      requirement={requirementContext.requirement}
      customerId={requirementContext.customerId}
    />
  );
}

export type AdditionalInformationProps = {
  requirement: Requirement;
  customerId: string;
};

export function AdditionalInformationComponent({
  requirement,
  customerId,
}: AdditionalInformationProps) {
  const { toast } = useToast();

  const { data: dataAdditionalInfo, error: errorAdditionalInformation } =
    useQpmRequirementAdditionalInformationGetQuery({
      requirementId: requirement.id,
    });

  const [
    updateAdditionalInfo,
    { error: errorSetAdditionalInfo, reset: resetAdditionalInfo },
  ] = useQpmRequirementAdditionalInformationAddMutation();

  useEffect(() => {
    if (errorSetAdditionalInfo) {
      toast({ ...parseRTKQueryError(errorSetAdditionalInfo) });
      resetAdditionalInfo();
    }
  }, [errorSetAdditionalInfo, resetAdditionalInfo, toast]);

  if (errorAdditionalInformation) {
    return <RTKQueryErrorAlert error={errorAdditionalInformation} />;
  }

  if (!dataAdditionalInfo || !requirement) {
    return <div>Fehler beim Laden der zusätlichen Informationen.</div>;
  }

  return (
    <Card className="">
      <CardHeader>
        <CardTitle className="text-xl">
          {t("Zusätzliche Informationen")}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col space-y-2">
        <ComplementaryServiceComponent requirementId={requirement.id} />
        <TravelInfoComponent
          travelData={{
            travelTimeSeconds: dataAdditionalInfo.travelTimeSeconds,
            travelCompany: dataAdditionalInfo.travelCompany,
          }}
          onChange={(travelTimeSeconds, travelCompany) =>
            updateAdditionalInfo({
              ...dataAdditionalInfo,
              travelTimeSeconds,
              travelCompany,
            })
          }
        />
        <PestsComponent requirement={requirement} />
        <CycleComponent
          cycleType={dataAdditionalInfo.cycleType}
          qpmCycleAmountPerYear={dataAdditionalInfo.qpmCycleAmountPerYear}
          onChange={(cycleType, qpmCycleAmountPerYear) =>
            updateAdditionalInfo({
              ...dataAdditionalInfo,
              cycleType,
              qpmCycleAmountPerYear,
            })
          }
        />
        <CertificatesComponent
          customerId={customerId}
          comments={dataAdditionalInfo.certificateNotes}
          onChange={(comment) =>
            updateAdditionalInfo({
              ...dataAdditionalInfo,
              certificateNotes: comment,
            })
          }
        />
        <AdditionalCommentsComponent
          comments={dataAdditionalInfo.additionalNotes}
          onChange={(comment) =>
            updateAdditionalInfo({
              ...dataAdditionalInfo,
              additionalNotes: comment,
            })
          }
        />
      </CardContent>
    </Card>
  );
}
