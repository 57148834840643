import {
  generateSampleNumber,
  newSample,
  Sample,
} from "@/services/backend/samples/sample/sample";
import { Kind } from "@/services/backend/samples/sample/kind";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { ArtefactNamespace } from "@/shared/service-manager/artefact/artefact-namespace";

export interface WaterSample extends Sample {
  temperature: number;
}

export function newWaterSample(
  id: string,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
): WaterSample {
  return {
    ...newSample(
      id,
      generateSampleNumber(),
      Kind.Water,
      artefactId,
      artefactNamespace,
    ),
    temperature: 0,
  };
}

export function updateTemperature(
  sample: WaterSample,
  temperature: number,
): WaterSample {
  return {
    ...sample,
    temperature,
  };
}
