import { useParams } from "react-router-dom";
import { useRltOrderShowQuery } from "@/services/backend/rlt/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  OrderCard,
  OrderCardSkeleton,
} from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/data/_components/order-card";

export function OrderDataRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading,
    error,
  } = useRltOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <OrderCardSkeleton />;
  }

  return <OrderCard order={order!} />;
}
