import { Navigate, RouteObject } from "react-router-dom";
import { OrdersIndexRoute } from "@/routes/gesec/processes/[processId]/rlt/orders/orders-index";
import { OrderRoute } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/order-route";
import { OrderDataRoute } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/data/order-data-route";
import { OrderTreatmentRoute } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/treatment/order-treatment-route";
import { OrderCustomerAddressRoute } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/customer-address/order-customer-address-route";
import { OrderRepresentativeRoute } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/representative/offer-representative-route";

export const rltOrdersRoutes: RouteObject[] = [
  {
    index: true,
    element: <OrdersIndexRoute />,
  },
  {
    path: ":orderId",
    element: <OrderRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <OrderDataRoute />,
      },
      {
        path: "treatment",
        element: <OrderTreatmentRoute />,
      },
      {
        path: "customer-address",
        element: <OrderCustomerAddressRoute />,
      },
      {
        path: "representative",
        element: <OrderRepresentativeRoute />,
      },
    ],
  },
];
