import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import t from "@/lang/lang";
import {
  Company,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { AlertTriangle, RefreshCw } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { is404 } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  CreateProcessNumberSequenceRequest,
  useNumbersProcessNumberCreateSequenceMutation,
  useNumbersProcessNumberShowSequenceQuery,
} from "@/services/backend/numbers/process-number/service";
import { useState } from "react";
import { Input } from "@/shared/components/ui/input";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";

export function NumbersProcessBaseDataRoute() {
  return (
    <PersistenceContextProvider>
      <BaseDataPage>
        <BaseDataPageHeader>
          <BaseDataPageTitle>{t("Vorgang Nummern")}</BaseDataPageTitle>
          <BaseDataPageDescription>
            {t(
              "Hier können die Nummernsequenzen für die Vorgänge der Unternehmen angelegt werden. Eine Vorgangsummer ist dienstleistungsübergreifend eindeutig pro Unternehmen.",
            )}
          </BaseDataPageDescription>
        </BaseDataPageHeader>
        <BaseDataPageContent>
          <SequencesTable />
        </BaseDataPageContent>
      </BaseDataPage>
    </PersistenceContextProvider>
  );
}

function SequencesTable() {
  const { data: list, isLoading, error } = useListCompanyQuery({});

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t("Lade Sequenzen ...")}</CardTitle>
        </CardHeader>
        <CardContent className="flex justify-center">
          <RefreshCw className="animate-spin" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Sequenzen")}</CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>{t("Unternehmen")}</TableCell>
            <TableCell>{t("Start")}</TableCell>
            <TableCell>{t("Letzte vergebene Nummer")}</TableCell>
            <TableCell>{t("Aktionen")}</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {list?.companies.map((company) => (
            <CompanySequenceTable company={company} />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function CompanySequenceTable({ company }: { company: Company }) {
  const {
    data: sequence,
    isLoading,
    error,
  } = useNumbersProcessNumberShowSequenceQuery({ companyId: company.id });

  if (error) {
    if (is404(error)) {
      return <CreateSequenceTableRow company={company} />;
    }

    return (
      <TableRow>
        <TableCell>{company.name}</TableCell>
        <TableCell colSpan={3}>{t("Fehler")}</TableCell>
      </TableRow>
    );
  }

  if (isLoading) {
    return (
      <TableRow>
        <TableCell>{company.name}</TableCell>
        <TableCell colSpan={3} className="animate-pulse">
          {t("Lade Sequenz ...")}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{company.name}</TableCell>
      <TableCell>{sequence!.start.toLocaleString()}</TableCell>
      <TableCell>{sequence!.lastValue.toLocaleString()}</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
}

function CreateSequenceTableRow({ company }: { company: Company }) {
  const [request, setRequest] = useState<CreateProcessNumberSequenceRequest>({
    companyId: company.id,
    start: 0,
  });

  return (
    <TableRow>
      <TableCell>{!company.name}</TableCell>
      <TableCell>
        <Input
          placeholder={t("Startnummer")}
          className="h-9"
          value={request.start}
          onChange={(e) =>
            setRequest({ ...request, start: Number(e.target.value) })
          }
        />
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell>
        <ConfirmCreationDialog request={request} />
      </TableCell>
    </TableRow>
  );
}

function ConfirmCreationDialog({
  request,
}: {
  request: CreateProcessNumberSequenceRequest;
}) {
  const [open, setOpen] = useState(false);
  const [create, { isLoading, error, isSuccess, reset }] =
    useNumbersProcessNumberCreateSequenceMutation();

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    create(request);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button size="sm">{t("Erstellen")}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center space-x-2">
            <AlertTriangle />
            <span>{t("Sequenz erstellen")}</span>
          </DialogTitle>
          <DialogDescription>
            {t(
              "Der Klick auf Bestätigen wird die Sequenz erstellen. Diese Aktion kann nicht rückgängig gemacht werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button disabled={isLoading} onClick={doCreate}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
            <span>{t("Erstellen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
