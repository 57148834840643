import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useOutletContext } from "react-router-dom";
import { CreateRequirementContext } from "@/routes/gemex/processes/qpm/requirements/requirement/create/create-requirement";
import t from "@/lang/lang";

export function AddressInfoComponent() {
  const requirementContext = useOutletContext<CreateRequirementContext>();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-md">{t("Serviceanschrift")}</CardTitle>
      </CardHeader>
      <CardContent>
        <span>{requirementContext.currentAddress.address.street}</span>
        <div className="flex items-center gap-2">
          <span>{requirementContext.currentAddress.address.postalCode}</span>
          <span>{requirementContext.currentAddress.address.city}</span>
        </div>
      </CardContent>
    </Card>
  );
}
