import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { useState } from "react";
import {
  PutRequest,
  useHtzAirHandlingUnitPutMutation,
} from "@/services/backend/htz/ahu/service";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Button } from "@/shared/components/ui/button";
import { RefreshCw } from "lucide-react";

export function AhuDataCard({ ahu }: { ahu: AirHandlingUnit }) {
  const [request, setRequest] = useState<PutRequest>({ ...ahu });
  const [put, { isLoading, error }] = useHtzAirHandlingUnitPutMutation();

  const doUpdate = () => {
    if (isLoading) {
      return;
    }

    put(request);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{ahu.name}</CardTitle>
        <CardDescription>{t("Basisdaten der RLT-Anlage")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid content-start gap-1.5">
          <Label>{t("Name")}</Label>
          <Input
            value={request?.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
          <InputValidationErrors error={error} field="name" />
        </div>
        <RTKQueryErrorAlert error={error} />
      </CardContent>
      <CardFooter className="justify-end">
        <Button onClick={doUpdate} disabled={isLoading}>
          {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
          {t("Aktualisieren")}
        </Button>
      </CardFooter>
    </Card>
  );
}
