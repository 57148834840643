import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";
import { addPosition } from "@/services/backend/htz/offers/offer";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Item } from "@/services/backend/htz/position-catalog/position-catalog";
import { newFromCatalog } from "@/services/backend/htz/position/position";
import { useOfferContext } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";

export function AddPositionButton() {
  const { positionCatalog, offer, onOfferChange } = useOfferContext();
  const { items } = positionCatalog;
  const categories = uniqueCategories(items);

  const onAdd = (catPos: Item) => {
    const newPosition = newFromCatalog(catPos);
    onOfferChange(addPosition(offer, newPosition));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={offer.immutable}>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Position")}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {categories.map((category) => (
          <DropdownMenuGroup key={category}>
            <DropdownMenuLabel>{category}</DropdownMenuLabel>
            {items.filter(catFltr(category)).map((pos) => (
              <DropdownMenuItem key={pos.id} onClick={() => onAdd(pos)}>
                {pos.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function uniqueCategories(positions: Item[]): string[] {
  return positions.reduce((acc, cur) => {
    if (acc.indexOf(cur.category) !== -1) {
      return acc;
    }
    return [...acc, cur.category];
  }, [] as string[]);
}

function catFltr(cat: string) {
  return (pos: Item) => pos.category === cat;
}
