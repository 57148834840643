import { isFetchBaseQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

/**
 * ValidationError is the validation error as
 * returned by the backend.
 */
export interface ValidationError {
  msg: string;
  errors: FieldErrors;
}

export function fieldErrors(error: unknown, field: string): string[] | null {
  if (!error) {
    return null;
  }

  if (!isValidationError(error)) {
    return null;
  }

  return error.errors[field] ?? null;
}

/**
 * fieldErrorsForPrefix extracts the field errors for with the given prefix.
 * @param error
 * @param prefix
 */
export function fieldErrorsForPrefix(
  error: ValidationError | null,
  prefix: string,
): FieldErrors | undefined {
  if (!error) {
    return undefined;
  }

  if (prefix === "") {
    return error.errors;
  }

  return Object.fromEntries(
    Object.entries(error.errors)
      .filter(([key]) => key.startsWith(prefix))
      .map(([key, value]) => [key.slice(prefix.length), value]),
  );
}

export function resetField(
  error: unknown,
  field: string,
): ValidationError | null {
  if (!error) {
    return null;
  }

  if (!isValidationError(error)) {
    return null;
  }

  const entries = Object.entries(error.errors).filter(([key]) => key !== field);

  // if there are no errors left, delete whole error
  if (entries.length === 0) {
    return null;
  }

  const errors = Object.fromEntries(entries);

  return {
    ...error,
    errors,
  };
}

export function hasFieldError(
  error: ValidationError | null,
  ...fields: string[]
): boolean {
  if (!error) {
    return false;
  }

  // starts with is used since merged validation errors may contain
  // keys where the field name is only a prefix and sub-fields are
  // separated by a dot or similar.
  return Object.keys(error.errors).some((key) =>
    fields.some((field) => key.startsWith(field)),
  );
}

export function isValidationError(error: unknown): error is ValidationError {
  // test if error is an object
  if (!error || typeof error !== "object") {
    return false;
  }

  // test if error has the required fields
  if (!("msg" in error) || !("errors" in error)) {
    return false;
  }

  // test if the fields are of the correct type
  if (typeof error.msg !== "string") {
    return false;
  }

  return isFieldErrors(error.errors);
}

export function rtkErrIsValidationError(
  error: unknown,
): error is { data: ValidationError } {
  if (!error) {
    return false;
  }

  if (!isFetchBaseQueryError(error)) {
    return false;
  }

  if (error.status !== 422) {
    return false;
  }

  return isValidationError(error.data);
}

export interface FieldErrors {
  [field: string]: string[];
}

function isFieldErrors(errors: unknown): errors is FieldErrors {
  if (typeof errors !== "object" || errors === null) {
    return false;
  }

  return Object.entries(errors).every(
    ([, v]) => Array.isArray(v) && v.every((e) => typeof e === "string"),
  );
}
