import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Plus, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { useEffect, useState } from "react";
import {
  PutRequest,
  useHtzAirHandlingUnitPutMutation,
} from "@/services/backend/htz/ahu/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

export function NewAhuDialog({ customerId }: { customerId: string }) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<PutRequest>({
    id: "",
    name: "",
    customerId,
    components: [],
  });
  const [put, { isLoading, error, data, reset }] =
    useHtzAirHandlingUnitPutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(`${data.id}`);
      setOpen(false);
      setRequest({
        id: "",
        name: "",
        customerId,
        components: [],
      });
      reset();
    }
  }, [data, setOpen, setRequest, reset, customerId, navigate]);

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    put({
      ...request,
      id: v4(),
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Neue RLT-Anlage")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("RLT-Anlage erstellen")}</DialogTitle>
          <DialogDescription>
            {t("Erstellt eine neue RLT-Anlage für den Kunden.")}
          </DialogDescription>
        </DialogHeader>
        <div className="grid content-start gap-1.5">
          <Label>{t("Name")}</Label>
          <Input
            value={request?.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
          <InputValidationErrors error={error} field="name" />
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doCreate} disabled={isLoading}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
            {t("Erstellen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
