import { Label } from "@/shared/components/ui/label";
import t from "@/lang/lang";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { cn } from "@/shared/lib/utils";
import { useMemo } from "react";

export enum Severity {
  None = "none",
  Minor = "minor",
  Major = "major",
}

export function SeveritySelect({
  severity,
  onSeverityChange,
  errors,
}: {
  severity: Severity;
  onSeverityChange: (severity: Severity) => void;
  errors: string[];
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Mangelbeseitigung")}</Label>
      <Select value={severity.toString()} onValueChange={onSeverityChange}>
        <SelectTrigger>
          <SelectValue placeholder={t("Mangelbeseitigung")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="none">
              <Dot severity={Severity.None} />
            </SelectItem>
            <SelectItem value="minor">
              <Dot severity={Severity.Minor} />
            </SelectItem>
            <SelectItem value="major">
              <Dot severity={Severity.Major} />
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

export function Dot({ severity }: { severity: Severity }) {
  const { className, label } = useMemo(() => {
    switch (severity) {
      case Severity.None:
        return {
          className: "",
          label: t("keine"),
        };
      case Severity.Minor:
        return {
          className: "bg-yellow-300 border-yellow-300",
          label: t("nächste Wartung"),
        };
      case Severity.Major:
        return {
          className: "bg-red-500 border-red-500",
          label: t("umgehend"),
        };
      default:
        return {
          className: "",
          label: t("keine"),
        };
    }
  }, [severity]);

  return (
    <span className="flex items-center whitespace-nowrap">
      <span
        className={cn(
          "mr-2 inline-block h-4 w-4 rounded-full border bg-background",
          className,
        )}
      />
      {label}
    </span>
  );
}
