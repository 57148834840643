import { AhuComponentCatalogTable } from "@/routes/base-data/processes/htz/ahu-component-catalogs/_components/ahu-component-catalog-table";
import { ComponentKindsTable } from "@/routes/base-data/processes/htz/ahu-component-catalogs/_components/component-kinds-table";

export function AhuComponentCatalogsRoute() {
  return (
    <div className="space-y-2">
      <ComponentKindsTable />
      <AhuComponentCatalogTable />
    </div>
  );
}
