import { useHtzOrderShowQuery } from "@/services/backend/htz/orders/service";
import { isZeroUUID } from "@/shared/lib/utilities/uuid";
import t from "@/lang/lang";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { formatDate } from "@/shared/lib/utilities/date";

export function OrderIdentifier({ orderId }: { orderId: string }) {
  const { data: order } = useHtzOrderShowQuery({ id: orderId });

  if (!order) {
    return null;
  }

  return (
    <span>
      <span className="mr-1">
        Vom {order.date ? formatDate(order.date) : t("- nicht gesetzt -")}
      </span>
      {!order.offerId || isZeroUUID(order.offerId) ? (
        t("- ohne Angebot -")
      ) : (
        <span>
          <span>{t("zu Angebot ")}</span>
          <ArtefactNumberById artefactId={order.offerId} />
        </span>
      )}
      {order.customerReference && (
        <span>
          {t("; Kundenreferenz: ")}
          {order.customerReference}
        </span>
      )}
    </span>
  );
}
