import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { AddItemButton } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/documentation/[documentationId]/_components/add-item-button";
import { Outlet, useParams } from "react-router-dom";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { DeleteItemButton } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/documentation/[documentationId]/_components/delete-item-button";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import { useVbsDocumentationShowQuery } from "@/services/backend/vbs/work-orders/documentation-service";
import { EditItemButton } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/documentation/[documentationId]/_components/edit-item-button";

export function WorkOrderDocumentationRoute() {
  return <Outlet />;
}

export function WorkOrderDocumentationIndexRoute() {
  const { workOrderId, documentationId } = useParams();
  return (
    <DocumentationCard
      documentationId={documentationId!}
      workOrderId={workOrderId!}
    />
  );
}

export function DocumentationCard({
  documentationId,
  workOrderId,
}: {
  documentationId: string;
  workOrderId: string;
}) {
  return (
    <Card>
      <CardHeader className="pb-2">
        <div className="flex justify-between">
          <div>
            <CardTitle>{t("Dokumentation")}</CardTitle>
            <CardDescription className="max-w-4xl">
              {t(
                "Hier besteht die Möglichkeit die Arbeiten und die Arbeitsbedingungen zu dokumentieren.",
              )}
            </CardDescription>
          </div>
          <AddItemButton
            documentationId={documentationId!}
            workOrderId={workOrderId}
          />
        </div>
      </CardHeader>
      <DocumentationTable
        documentationId={documentationId!}
        workOrderId={workOrderId}
      />
    </Card>
  );
}

function DocumentationTable({
  documentationId,
  workOrderId,
}: {
  documentationId: string;
  workOrderId: string;
}) {
  const {
    data: documentation,
    isLoading,
    error,
  } = useVbsDocumentationShowQuery({
    id: documentationId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <TableSkeleton />;
  }

  const documentationItems = documentation!.items;

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nr.")}</TableHead>
          <TableHead className="w-full">{t("Titel")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("Intern")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {documentationItems.map((item) => (
          <TableRow key={item.id}>
            <TableCell className="text-center">{item.number}</TableCell>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.isInternal ? t("Ja") : t("Nein")}</TableCell>
            <TableCell className="flex items-center space-x-2">
              <EditItemButton
                documentationId={documentationId}
                itemId={item.id}
                workOrderId={workOrderId}
              />
              <DeleteItemButton
                documentationId={documentationId}
                itemId={item.id}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function TableSkeleton() {
  return (
    <Table className="animate-pulse">
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nr.")}</TableHead>
          <TableHead>{t("Position")}</TableHead>
          <TableHead className="w-full">{t("Titel")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("Intern")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
    </Table>
  );
}
