import {
  useVbsWorkOrderPreviewPDFQuery,
  useVbsWorkOrderShowQuery,
  WorkOrderDocument,
} from "@/services/backend/vbs/work-orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetContextProvider,
  ArtefactSheetHeader,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
  useArtefactSheetContext,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { WorkOrderData } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/data/work-order-data-route";
import { Button } from "@/shared/components/ui/button";
import { WorkOrder } from "@/services/backend/vbs/work-orders/work-order";
import {
  ArtefactActionsDownload,
  ArtefactActionsHeader,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import { DownloadButton } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/_components/work-order-immutable-page";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavigateToArtefactButton } from "@/shared/components/domain/artefact/preview/_components/navigate-to-artefact-button";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { RefreshCw } from "lucide-react";
import { LMRAComponent } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/last-minute-risk-analysis/[lmraId]/work-order-risk-analysis-route";
import { useVbsLMRAShowQuery } from "@/services/backend/vbs/work-orders/lmra-service";
import { Card } from "@/shared/components/ui/card";
import { PositionList } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/positions/work-order-positions-route";
import { DocumentationCard } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/documentation/[documentationId]/work-order-documentation-route";

export function VbsWorkOrderPreview({ workOrderId }: { workOrderId: string }) {
  const {
    data: workOrder,
    isLoading,
    error,
  } = useVbsWorkOrderShowQuery({ id: workOrderId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RefreshCw className="h-4 w-4" />;
  }

  return (
    <ArtefactSheetContextProvider>
      <WorkOrderArtefactSheet workOrder={workOrder!} />
    </ArtefactSheetContextProvider>
  );
}

function WorkOrderArtefactSheet({ workOrder }: { workOrder: WorkOrder }) {
  const { open, setOpen } = useArtefactSheetContext();

  return (
    <ArtefactSheet open={open} onOpenChange={setOpen}>
      <ArtefactSheetTrigger asChild>
        <Button variant="ghost" size="sm">
          {t("Vorschau")}
        </Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>
            {t(workOrder!.artefactKind)} {t("Vorschau")}
          </ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          {workOrder!.immutable ? (
            <ImmutableWorkOrderPreview workOrder={workOrder!} />
          ) : (
            <MutableWorkOrderPreview workOrder={workOrder!} />
          )}
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function ImmutableWorkOrderPreview({ workOrder }: { workOrder: WorkOrder }) {
  const { open } = useArtefactSheetContext();

  const { data, isLoading, error } = useVbsWorkOrderPreviewPDFQuery(
    {
      id: workOrder.id,
      document: WorkOrderDocument.All,
    },
    {
      // This prevents request to the backend if the artefact sheet has
      // not been opened. Otherwise, whenever the preview component
      // is used, the PDF would be preloaded (and often unnecessarily so).
      skip: !open,
    },
  );

  return (
    <>
      <ArtefactSheetPreview
        isLoading={isLoading}
        objectURL={data?.objectURL}
        error={error}
      />
      <ImmutableWorkOrderActions workOrder={workOrder} />
    </>
  );
}

function ImmutableWorkOrderActions({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <ArtefactSheetActions>
      <ArtefactActionsHeader
        title={t("Angebot")}
        artefactNumber={<ArtefactNumberById artefactId={workOrder.id} />}
      />
      <ArtefactSheetActionsContent>
        <ArtefactActionsDownload>
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.All}
            text={t("Alle Dokumente")}
          />
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.WorkOrder}
            text={t("Arbeitsschein")}
          />
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.Documentation}
            text={t("Dokumentation")}
          />
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.LastMinuteRiskAnalysis}
            text={t("Gefährdungsbeurteilung")}
          />
        </ArtefactActionsDownload>
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}

function MutableWorkOrderPreview({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <>
      <MutableWorkOrderView workOrder={workOrder} />
      <MutableWorkOrderActions workOrder={workOrder} />
    </>
  );
}

function MutableWorkOrderView({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <div className="md:col-span-4">
      <PersistenceContextProvider>
        <MilestonePageContent>
          <Tabs defaultValue="data">
            <MilestonePageContentMenu>
              <TabsList>
                <TabsTrigger value="data">{t("Arbeitsschein")}</TabsTrigger>
                <TabsTrigger value="lmra">{t("LMRA")}</TabsTrigger>
                <TabsTrigger value="positions">{t("Arbeiten")}</TabsTrigger>
                <TabsTrigger value="documentation">
                  {t("Dokumentation")}
                </TabsTrigger>
              </TabsList>
              <span className="ml-4 font-mono text-lg text-gray-900">
                <ArtefactNumberById artefactId={workOrder.id} />
              </span>
              <PersistenceStateUsingContext className="ml-auto mr-2" />
            </MilestonePageContentMenu>
            <div className="max-h-[75vh] overflow-auto">
              <TabsContent value="data">
                <WorkOrderData workOrder={workOrder} />
              </TabsContent>
              <TabsContent value="lmra">
                <LMRALoader lmraId={workOrder.lastMinuteRiskAnalysisId} />
              </TabsContent>
              <TabsContent value="positions">
                <PositionList workOrder={workOrder} />
              </TabsContent>
              <TabsContent value="documentation">
                <DocumentationCard
                  documentationId={workOrder.documentationId}
                  workOrderId={workOrder.id}
                />
              </TabsContent>
            </div>
          </Tabs>
        </MilestonePageContent>
      </PersistenceContextProvider>
    </div>
  );
}

function LMRALoader({ lmraId }: { lmraId: string }) {
  const {
    data: lmra,
    isLoading,
    error,
  } = useVbsLMRAShowQuery({
    id: lmraId!,
  });

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <LMRAComponent lmra={lmra!} />;
}

function MutableWorkOrderActions({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <ArtefactSheetActions className="md:col-span-1">
      <ArtefactSheetActionsHeader
        title={t(workOrder.artefactKind)}
        artefactNumber={<ArtefactNumberById artefactId={workOrder.id} />}
      />
      <ArtefactSheetActionsContent>
        <NavigateToArtefactButton
          artefactId={workOrder.id}
          artefactKind={workOrder.artefactKind}
          processId={workOrder.processId}
          processKind={ProcessKind.VBS}
          companyId={workOrder.companyId}
        />
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}
