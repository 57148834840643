import { useEffect, useState } from "react";
import { SegmentKind } from "@/services/backend/vbs/treatments/segmentKind";
import { Position } from "@/services/backend/vbs/treatments/position";
import { Segment } from "@/services/backend/vbs/treatments/segment";
import t from "@/lang/lang";
import { useVbsTreatmentAddAreaMutation } from "@/services/backend/vbs/treatments/service";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { SelectColumnsDropdown } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/select-columns-dropdown";
import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state-context";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, Plus } from "lucide-react";
import { Input } from "@/shared/components/ui/input";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { SegmentAreaComponent } from "./segment-area-component";
import { fCleanTime } from "./utils";

export function SegmentComponent({
  treatmentId,
  processId,
  segment,
  positions,
}: {
  treatmentId: string;
  processId: string;
  segment: Segment;
  positions: Position[];
}) {
  const { segmentKind, areas, cleanTime } = segment;
  const { state } = useTreatmentComponentState();
  const { columns } = state;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-end space-x-2 px-4 pt-2">
        <AddAreaDropdown treatmentId={treatmentId} segmentKind={segmentKind} />
        <SelectColumnsDropdown />
      </div>
      <div>
        {areas.map((segmentArea) => (
          <SegmentAreaComponent
            treatmentId={treatmentId}
            processId={processId}
            segmentKind={segmentKind}
            segmentArea={segmentArea}
            positions={positions}
            key={segmentArea.area}
          />
        ))}
        {columns.includes(Column.CleanTime) && (
          <div className="flex items-center justify-end space-x-4 border-t bg-muted px-6 py-3 font-bold uppercase">
            <span>{t("Gesamt")}</span>
            <span>{fCleanTime(cleanTime)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

function AddAreaDropdown({
  treatmentId,
  segmentKind,
}: {
  treatmentId: string;
  segmentKind: SegmentKind;
}) {
  const [open, setOpen] = useState(false);
  const [individualName, setIndividualName] = useState("");
  const [add, { isLoading, error }] = useVbsTreatmentAddAreaMutation();
  const { toast } = useToast();

  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    if (isLoading) {
      setPersistenceState(PersistenceState.SAVING);
    } else if (error) {
      setPersistenceState(PersistenceState.ERROR);
    } else {
      setPersistenceState(PersistenceState.SAVED);
    }
  }, [isLoading, error, setPersistenceState]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [error, toast]);

  const onAdd = (name: string) => {
    if (isLoading) {
      return;
    }
    add({
      id: treatmentId,
      area: name,
      segment: segmentKind,
    });
    setOpen(false);
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{t("Bereich hinzufügen")}</span>
          <ChevronDown className="ml-2 h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuGroup className="space-y-2 px-2 py-2">
          <Input
            value={individualName}
            onChange={(e) => setIndividualName(e.target.value)}
            placeholder={t("Name eingeben")}
          />
          <Button
            variant="secondary"
            className="w-full py-2"
            onClick={() => onAdd(individualName)}
          >
            {t("Erstellen")}
          </Button>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Vorschläge")}</DropdownMenuLabel>
        <DropdownMenuGroup>
          {["Hauptküche", "Spülküche", "Speiseausgabe"].map((name) => (
            <DropdownMenuItem
              key={name}
              onClick={() => onAdd(t(name))}
              className="cursor-pointer"
            >
              <Plus className="mr-2 h-4 w-4" />
              <span>{t(name)}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
