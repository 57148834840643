import { NavLink, useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { rfc3339toDate } from "@/shared/lib/utilities/date";
import { useRltWorkOrderListQuery } from "@/services/backend/rlt/work-orders/service";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { Card } from "@/shared/components/ui/card";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function WorkOrderListRoute() {
  const { processId } = useParams();
  const {
    data: list,
    isLoading,
    error,
  } = useRltWorkOrderListQuery({
    process: { active: true, values: [processId!] },
    customer: { active: false, values: [] },
    immutable: { active: false, values: [] },
  });

  if (isLoading) {
    return (
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Nummer")}</TableHead>
              <TableHead>{t("Teamleiter")}</TableHead>
              <TableHead>{t("Beginn")}</TableHead>
              <TableHead>{t("Ende")}</TableHead>
              <TableHead className="whitespace-nowrap">
                {t("% erledigt")}
              </TableHead>
              <TableHead className="w-full">{t("Kommentar")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={7}
                className="animate-pulse text-muted-foreground"
              >
                {t("Lade Arbeitsscheine...")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Teamleiter")}</TableHead>
            <TableHead>{t("Beginn")}</TableHead>
            <TableHead>{t("Ende")}</TableHead>
            <TableHead className="whitespace-nowrap">
              {t("% erledigt")}
            </TableHead>
            <TableHead className="w-full">{t("Kommentar")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {list?.workOrders.map((workOrder) => (
            <TableRow key={workOrder.id}>
              <TableCell>
                <NavLink to={`../../${workOrder.id}`}>
                  <ArtefactNumberById artefactId={workOrder.id} />
                </NavLink>
              </TableCell>
              <TableCell className="whitespace-nowrap">
                {workOrder.teamLeaderId}
              </TableCell>
              <TableCell align="right">
                {rfc3339toDate(workOrder.beginDate).toLocaleDateString()}
              </TableCell>
              <TableCell align="right">
                {rfc3339toDate(workOrder.endDate).toLocaleDateString()}
              </TableCell>
              <TableCell align="right">
                {workOrder.percentageCompleted.toFixed(2)} %
              </TableCell>
              <TableCell>{workOrder.comment}</TableCell>
              <TableCell>
                <NavLink to={`../../${workOrder.id}`}>
                  <Button size="sm" variant="outline">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}
