import { useRltCertificateListQuery } from "@/services/backend/rlt/certificates/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { rfc3339toDateString } from "@/shared/lib/utilities/date";
import { NavLink } from "react-router-dom";
import { Badge } from "@/shared/components/ui/badge";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Card } from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";
import { Edit } from "lucide-react";

export function CertificateListTable(props: { processId: string }) {
  const { processId } = props;
  const {
    data: list,
    isLoading,
    error,
  } = useRltCertificateListQuery({
    process: {
      active: true,
      values: [processId],
    },
  });

  if (isLoading) {
    return (
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Status")}</TableHead>
              <TableHead>{t("Arbeitsbeginn")}</TableHead>
              <TableHead>{t("Arbeitsende")}</TableHead>
              <TableHead>{t("Ausstellung")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell
                className="animate-pulse text-center text-muted-foreground"
                colSpan={5}
              >
                {t("Lade Zertifikate...")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Status")}</TableHead>
            <TableHead>{t("Arbeitsbeginn")}</TableHead>
            <TableHead>{t("Arbeitsende")}</TableHead>
            <TableHead>{t("Ausstellung")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {list!.certificates!.map((certificate) => (
            <TableRow key={certificate.id}>
              <TableCell>
                <CertificateStatusBadge issued={certificate.immutable} />
              </TableCell>
              <TableCell>
                {rfc3339toDateString(certificate.workStartedAt)}
              </TableCell>
              <TableCell>
                {rfc3339toDateString(certificate.workEndedAt)}
              </TableCell>
              <TableCell>{issueDate(certificate.issueDate)}</TableCell>
              <TableCell>
                <NavLink to={certificate.id}>
                  <Button variant="outline" size="sm" className="-my-2">
                    <Edit className="h-4 w-4" />
                  </Button>
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function CertificateStatusBadge(props: { issued: boolean }) {
  const { issued } = props;
  if (issued) {
    return <Badge className="bg-green-600">{t("Ausgestellt")}</Badge>;
  }
  return (
    <Badge className="bg-yellow-400 text-foreground">
      {t("In Bearbeitung")}
    </Badge>
  );
}

function issueDate(date: string): string {
  const dateString = rfc3339toDateString(date);
  if (dateString === "") {
    return "Nicht ausgestellt";
  }
  return dateString;
}
