import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import { useShowCompanyQuery } from "@/services/backend/company/company";
import {
  Content,
  Logo,
  MainNav,
  NavLinkElement,
  NotificationsMenu,
  UserMenu,
} from "@/routes/_components/main-nav";
import { Toaster } from "@/shared/components/ui/toaster";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Search } from "@/routes/_components/search/search";

export default function Root() {
  const { companyId } = useActiveCompany();
  const {
    data: company,
    isLoading,
    error,
  } = useShowCompanyQuery({ id: companyId });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const navList: NavLinkElement[] = [
    {
      to: `/tenant/${company?.portalRoute}/processes`,
      label: "Vorgänge",
    },
    { to: "/customers", label: "Kunden" },
    { to: "/base-data", label: "Stammdaten" },
  ];

  return (
    <div>
      <div className="fixed z-50 w-full border-b bg-background">
        <div className="mx-4 flex h-16 items-center">
          <Logo />
          <MainNav className="mx-6" navList={navList} />
          <div className="ml-auto flex items-center space-x-4">
            <Search />
            <NotificationsMenu />
            <UserMenu />
          </div>
        </div>
      </div>
      <div className="pt-16">
        <Content />
      </div>
      <Toaster />
    </div>
  );
}
