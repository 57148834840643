import { useRltOrderShowQuery } from "@/services/backend/rlt/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetContextProvider,
  ArtefactSheetHeader,
  ArtefactSheetMain,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
  useArtefactSheetContext,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component";
import { Column } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";
import { Button } from "@/shared/components/ui/button";
import { Order } from "@/services/backend/rlt/orders/order";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavigateToArtefactButton } from "@/shared/components/domain/artefact/preview/_components/navigate-to-artefact-button";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { RefreshCw } from "lucide-react";
import { ProcessCustomerAddressCardById } from "@/shared/components/domain/addresses/customer-address-card";
import { ProcessServiceAddressCardById } from "@/shared/components/domain/addresses/service-address-card";
import { ProcessRepresentativeCardById } from "@/shared/components/domain/representatives/representative-card";
import { OrderCard } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/data/_components/order-card";

export function RltOrderPreview({ orderId }: { orderId: string }) {
  const {
    data: order,
    isLoading,
    error,
  } = useRltOrderShowQuery({ id: orderId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RefreshCw className="h-4 w-4" />;
  }

  return (
    <ArtefactSheetContextProvider>
      <OrderArtefactSheet order={order!} />
    </ArtefactSheetContextProvider>
  );
}

function OrderArtefactSheet({ order }: { order: Order }) {
  const { open, setOpen } = useArtefactSheetContext();

  return (
    <ArtefactSheet open={open} onOpenChange={setOpen}>
      <ArtefactSheetTrigger asChild>
        <Button variant="ghost" size="sm">
          {t("Vorschau")}
        </Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>
            {t(order!.artefactKind)} {t("Vorschau")}
          </ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <OrderPreview order={order!} />
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function OrderPreview({ order }: { order: Order }) {
  return (
    <>
      <OrderView order={order} />
      <OrderActions order={order} />
    </>
  );
}

function OrderView({ order }: { order: Order }) {
  return (
    <div className="md:col-span-4">
      <PersistenceContextProvider>
        <MilestonePageContent>
          <Tabs defaultValue="order">
            <MilestonePageContentMenu>
              <TabsList>
                <TabsTrigger value="order">{t(order.artefactKind)}</TabsTrigger>
                <TabsTrigger value="treatment">{t("Leistungen")}</TabsTrigger>
                <TabsTrigger value="customer-address">
                  {t("Anschrift")}
                </TabsTrigger>
                <TabsTrigger value="representative">
                  {t("Betreuer")}
                </TabsTrigger>
              </TabsList>
              <span className="ml-4 font-mono text-lg text-gray-900">
                <ArtefactNumberById artefactId={order.id} />
              </span>
              <PersistenceStateUsingContext className="ml-auto mr-2" />
            </MilestonePageContentMenu>
            <div className="max-h-[75vh] overflow-auto">
              <TabsContent value="order">
                <OrderCard order={order} />
              </TabsContent>
              <TabsContent value="treatment">
                <TreatmentComponent
                  treatmentId={order.treatmentId}
                  availableColumns={[
                    Column.Position,
                    Column.ComponentKind,
                    Column.ComponentData,
                    Column.Cycle,
                    Column.Formula,
                    Column.CleanTime,
                    Column.BasePrice,
                    Column.TipCommission,
                    Column.OfferPrice,
                    Column.Comment,
                    Column.Actions,
                  ]}
                  defaultColumns={[
                    Column.Position,
                    Column.ComponentKind,
                    Column.ComponentData,
                    Column.Cycle,
                    Column.TipCommission,
                    Column.OfferPrice,
                    Column.Comment,
                    Column.Actions,
                  ]}
                />
              </TabsContent>
              <TabsContent value="customer-address">
                <div className="space-y-4">
                  <ProcessCustomerAddressCardById
                    id={order.customerAddressId}
                  />
                  <ProcessServiceAddressCardById id={order.serviceAddressId} />
                </div>
              </TabsContent>
              <TabsContent value="representative">
                <ProcessRepresentativeCardById id={order.representativeId} />
              </TabsContent>
            </div>
          </Tabs>
        </MilestonePageContent>
      </PersistenceContextProvider>
    </div>
  );
}

function OrderActions({ order }: { order: Order }) {
  return (
    <ArtefactSheetActions className="md:col-span-1">
      <ArtefactSheetActionsHeader
        title={t(order.artefactKind)}
        artefactNumber={<ArtefactNumberById artefactId={order.id} />}
      />
      <ArtefactSheetActionsContent>
        <NavigateToArtefactButton
          artefactId={order.id}
          artefactKind={order.artefactKind}
          processId={order.processId}
          processKind={ProcessKind.RLT}
          companyId={order.companyId}
        />
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}
