import { applyNodeChanges, NodeChange, XYPosition } from "@xyflow/react";
import { v4 } from "uuid";
import {
  Item,
  SizeFactor,
} from "@/services/backend/htz/ahu/ahu-component-catalog";

export interface AirHandlingUnit {
  id: string;
  name: string;
  customerId: string;
  components: Component[];
}

/**
 * addComponent also allows the AirHandlingUnit to be used with react flow,
 * since a Component is a valid Node in react flow.
 * @param ahu
 * @param node
 */
export function addComponent(
  ahu: AirHandlingUnit,
  node: Component,
): AirHandlingUnit {
  const newComponent = {
    ...node,
    id: v4(),
    order: ahu.components.length,
  };

  return {
    ...ahu,
    components: [...ahu.components, newComponent],
  };
}

export function removeComponent(
  ahu: AirHandlingUnit,
  id: string,
): AirHandlingUnit {
  return {
    ...ahu,
    components: ahu.components.filter((component) => component.id !== id),
  };
}

/**
 * changeNodes enables integrating an AirHandlingUnit with
 * React Flow. It can be used in event handlers passed to
 * a ReactFlow component.
 * @param ahu
 * @param changes
 */
export function changeNodes(
  ahu: AirHandlingUnit,
  changes: NodeChange<Component>[],
): AirHandlingUnit {
  return {
    ...ahu,
    components: applyNodeChanges(changes, ahu.components),
  };
}

export function moveUp(
  ahu: AirHandlingUnit,
  component: Component,
): AirHandlingUnit {
  if (component.order === 0) {
    return ahu;
  }

  const components = ahu.components.map((c, order) => ({ ...c, order }));

  const beforeAfter = components.reduce(
    (prev, cur) => {
      if (cur.id === component.id) {
        return prev;
      }
      if (cur.order < component.order - 1) {
        return {
          ...prev,
          before: [...prev.before, cur],
        };
      }
      return {
        ...prev,
        after: [...prev.after, cur],
      };
    },
    { before: [] as Component[], after: [] as Component[] },
  );

  const { before, after } = beforeAfter;

  return {
    ...ahu,
    components: [...before, component, ...after].map((c, order) => ({
      ...c,
      order,
    })),
  };
}

export function moveDown(
  ahu: AirHandlingUnit,
  component: Component,
): AirHandlingUnit {
  const components = ahu.components.map((c, order) => ({ ...c, order }));

  const beforeAfter = components.reduce(
    (prev, cur) => {
      if (cur.id === component.id) {
        return prev;
      }
      if (cur.order <= component.order + 1) {
        return {
          ...prev,
          before: [...prev.before, cur],
        };
      }
      return {
        ...prev,
        after: [...prev.after, cur],
      };
    },
    { before: [] as Component[], after: [] as Component[] },
  );

  const { before, after } = beforeAfter;

  return {
    ...ahu,
    components: [...before, component, ...after].map((c, order) => ({
      ...c,
      order,
    })),
  };
}

/**
 * A Component represents a building block of an air handling unit.
 * This could be filter chambers, cross flow heat exchangers or similar.
 *
 * Component is designed such that it can be passed as a Node into
 * a ReactFlow diagram. While this design strongly couples the
 * implementation of a component to ReactFlow and vice versa, it was
 * the simplest way to integrate both at the time. The coupling
 * in trade-off to more complex designs was willingly accepted.
 */
export interface Component {
  // The component id is also used as a ReactFlow id.
  id: string;
  componentKindId: string;
  name: string;
  // Order allows to order the components in the table view.
  // This is helpful, since there actually is a partial order
  // among the components defined by the direction the air is
  // flowing through them.
  order: number;
  // ReactFlow specific fields to make Component satisfy the NodeBase constraint.
  type: string;
  position: XYPosition;
  // This data is passed into ReactFlow components by ReactFlow.
  data: {
    name: string;
    img: string;
    sizeFactor: SizeFactor;
    details: string;
  };
}

export function newFromTemplate(template: Item): Component {
  const id = v4();
  return {
    id,
    componentKindId: template.componentKindId,
    name: template.name,
    order: 0,
    type: template.type,
    position: { x: 0, y: 0 },
    data: {
      name: template.name,
      img: template.img,
      sizeFactor: template.sizeFactor,
      details: template.details,
    },
  };
}
