import { createContext, useContext } from "react";
import { TreatmentComponentState } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";

export const useTreatmentComponentState = () =>
  useContext(TreatmentComponentStateContext);

interface TreatmentComponentStateContextInterface {
  state: TreatmentComponentState;
  setState: (state: TreatmentComponentState) => void;
}

export const TreatmentComponentStateContext =
  createContext<TreatmentComponentStateContextInterface>({
    state: {
      columns: [],
      availableColumns: [],
    },
    setState: () => null,
  });
