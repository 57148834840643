import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {}

export function H1(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h1
      {...props}
      className={cn(
        "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
        className,
      )}
    >
      {children}
    </h1>
  );
}

export function H2(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h2
      {...props}
      className={cn(
        "scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0",
        className,
      )}
    >
      {children}
    </h2>
  );
}

export function H3(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h3
      {...props}
      className={cn(
        "scroll-m-20 text-2xl font-semibold tracking-tight",
        className,
      )}
    >
      {children}
    </h3>
  );
}

export function H4(props: HeadingProps) {
  const { children, className } = props;

  return (
    <h4
      {...props}
      className={cn(
        "scroll-m-20 text-xl font-semibold tracking-tight",
        className,
      )}
    >
      {children}
    </h4>
  );
}

export function P(props: HTMLAttributes<HTMLParagraphElement>) {
  const { children, className } = props;

  return (
    <p
      {...props}
      className={cn("leading-7 [&:not(:first-child)]:mt-6", className)}
    >
      {children}
    </p>
  );
}

export function Blockquote(props: HTMLAttributes<HTMLQuoteElement>) {
  const { children, className } = props;

  return (
    <blockquote
      {...props}
      className={cn("mt-6 border-l-2 pl-6 italic", className)}
    >
      {children}
    </blockquote>
  );
}

export function Large(props: HTMLAttributes<HTMLDivElement>) {
  const { children, className } = props;

  return (
    <div {...props} className={cn("text-lg font-semibold", className)}>
      {children}
    </div>
  );
}

export function Small(props: HTMLAttributes<HTMLDivElement>) {
  const { children, className } = props;

  return (
    <div
      {...props}
      className={cn("text-sm font-medium leading-none", className)}
    >
      {children}
    </div>
  );
}

export function Muted(props: HTMLAttributes<HTMLParagraphElement>) {
  const { children, className } = props;

  return (
    <p {...props} className={cn("text-sm text-muted-foreground", className)}>
      {children}
    </p>
  );
}
