import { Content } from "@/routes/_components/main-nav";
import GemexRouter from "@/routes/gemex/router";

export default function GemexRoot() {
  const router = GemexRouter();
  return (
    <div>
      {router}
      <Content />
    </div>
  );
}
