import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useRepresentativesProcessRepresentativePutMutation,
  useRepresentativesProcessRepresentativeShowQuery,
} from "@/services/backend/representatives/processrepresentative/service";
import { ProcessRepresentative } from "@/services/backend/representatives/processrepresentative/process-representative";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";

interface RepresentativeCardProps {
  representative: Representative;
  onRepresentativeChange: (representative: Representative) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}

export function RepresentativeCard({
  representative,
  onRepresentativeChange,
  title = t("Betreuer"),
  description = t("Kontakt für Rückfragen des Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: RepresentativeCardProps) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? fieldErrors[field] ?? [] : [];
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">{t("Name")}</Label>
          <Input
            type="text"
            id="name"
            className={
              errorMessages("name").length > 0 ? "border-red-500 shadow-md" : ""
            }
            placeholder={t("Name")}
            value={representative.name}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("name");
              }
              onRepresentativeChange({
                ...representative,
                name: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("name")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="mobilephone">{t("Mobil")}</Label>
          <Input
            type="text"
            id="mobilephone"
            className={
              errorMessages("mobilephone").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Mobil")}
            value={representative.mobilephone}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("name");
              }
              onRepresentativeChange({
                ...representative,
                mobilephone: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("mobilephone")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="email">{t("E-Mail")}</Label>
          <Input
            type="text"
            id="E-Mail"
            className={
              errorMessages("email").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("E-Mail")}
            value={representative.email}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("name");
              }
              onRepresentativeChange({
                ...representative,
                email: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("email")} />
        </div>
      </CardContent>
    </Card>
  );
}

export function ProcessRepresentativeCard({
  representative,
  title = t("Betreuer"),
  description = t("Kontakt für Rückfragen des Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  representative: ProcessRepresentative;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const [request, setRequest] = useState<ProcessRepresentative>({
    ...representative,
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    useRepresentativesProcessRepresentativePutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <RepresentativeCard
      representative={request}
      onRepresentativeChange={(rep) => setRequest({ ...request, ...rep })}
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      disabled={representative.immutable}
    />
  );
}

export function ProcessRepresentativeCardById({
  id,
  title = t("Betreuer"),
  description = t("Kontakt für Rückfragen des Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  id: string;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const {
    data: representative,
    isLoading,
    error,
  } = useRepresentativesProcessRepresentativeShowQuery({
    id,
  });

  if (isLoading) {
    return (
      <RepresentativeCardSkeleton title={title!} description={description!} />
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ProcessRepresentativeCard
      representative={representative!}
      title={title}
      description={description}
      fieldErrors={fieldErrors}
      resetFieldError={resetFieldError}
    />
  );
}

export function RepresentativeCardSkeleton({
  title = t("Betreuer"),
  description = "Kontakt für Rückfragen des Kunden.",
}: {
  title?: string;
  description?: string;
}) {
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}
