import { Calculation } from "@/services/backend/vbs/calculations/calculation";
import { ReactNode, useState } from "react";
import { Selection } from "@/services/backend/vbs/calculations/expenses";
import {
  fHours,
  FloatNumberInput,
  fMoney,
  IntNumberInput,
  Value,
} from "@/routes/gesec/processes/_components/ui/number-input";
import {
  UpdateEmployeesRequest,
  UpdateExpensesRequest,
  UpdateParametersRequest,
  UpdateTravelTimeRequest,
  useUpdateCalculationEmployeesMutation,
  useUpdateCalculationExpensesMutation,
  useUpdateCalculationParametersMutation,
  useUpdateCalculationTravelTimeMutation,
  useVbsCalculationShowQuery,
} from "@/services/backend/vbs/calculations/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";
import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { ChevronDown, ChevronRight } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "@/shared/components/ui/radio-group";
import { Label } from "@/shared/components/ui/label";
import { Card } from "@/shared/components/ui/card";

export function CalculationCalculationRoute() {
  const { calculationId } = useParams();

  return <CalculationComponent calculationId={calculationId!} />;
}

export function CalculationComponent(props: { calculationId: string }) {
  const { calculationId } = props;
  const {
    data: calculation,
    isLoading,
    error,
  } = useVbsCalculationShowQuery({ id: calculationId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <Card>
      <EmployeeCount
        calculationId={calculation!.id}
        count={calculation!.employees}
      />
      <TreatmentPrices calculation={calculation!} />
      <MaterialCosts calculation={calculation!} />
      <TravelTimeCosts calculation={calculation!} />
      <ExpensesCosts calculation={calculation!} />
      <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
        <span className="font-bold uppercase">{t("Gesamt")}</span>
        <span className="font-bold">{fMoney(calculation!.totalCost)}</span>
      </div>
    </Card>
  );
}

function EmployeeCount(props: { calculationId: string; count: number }) {
  const { calculationId, count } = props;
  const [request, setRequest] = useState<UpdateEmployeesRequest>({
    id: calculationId,
    count,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useUpdateCalculationEmployeesMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div className="flex justify-between px-6 py-3">
      <span>{t("Anzahl Mitarbeiter")}</span>
      <IntNumberInput
        value={request.count}
        onChange={(newCount) => setRequest({ ...request, count: newCount })}
      />
    </div>
  );
}

function TreatmentPrices(props: { calculation: Calculation }) {
  const { calculation } = props;
  const [open, setOpen] = useState(true);
  const { treatmentPrice } = calculation;

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="border-t bg-gray-50 px-6 py-3">
        <div className="flex justify-between font-bold">
          <CollapsibleTrigger>
            <div className="flex items-center space-x-2">
              {open ? (
                <ChevronDown className="h-5 w-5" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
              <span>{t("Grundbehandlungskosten")}</span>
            </div>
          </CollapsibleTrigger>
          <span className="">{fMoney(treatmentPrice)}</span>
        </div>
      </div>
      <CollapsibleContent>
        <div className="border-t py-2">
          <TreatmentCostForWorkHours calculation={calculation} />
          <TreatmentCostExtras calculation={calculation} />
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function TreatmentCostForWorkHours({
  calculation,
}: {
  calculation: Calculation;
}) {
  const { totalCleanTime, treatmentPriceHours, parameters } = calculation;
  const [request, setRequest] = useState<UpdateParametersRequest>({
    id: calculation.id,
    parameters: calculation.parameters,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useUpdateCalculationParametersMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { canExecute } = useGuard("VBS.Calculation.UpdateParametersRequest");

  return (
    <div>
      <div className="flex justify-between px-6">
        <span>Arbeitszeit aus {t("Aufnahme")}</span>
        <span>{fHours(totalCleanTime)}</span>
      </div>
      <div className="flex justify-between px-6">
        <span>Manntage</span>
        <span>{fHours(totalCleanTime / parameters.hoursInManDay)}</span>
      </div>
      <div className="flex justify-between px-6">
        <span>Tagessatz</span>
        <div>
          {canExecute ? (
            <FloatNumberInput
              value={request.parameters.manDayRate}
              onChange={(manDayRate) =>
                setRequest({
                  ...request,
                  parameters: {
                    ...request.parameters,
                    manDayRate,
                  },
                })
              }
              width={24}
            />
          ) : (
            <Value value={parameters.manDayRate} fractions={2} />
          )}
        </div>
      </div>
      <div>
        <div className="px-6">
          <div className="flex w-full justify-between border-t font-bold">
            <span>{t("Grundbehandlungskosten Arbeitszeit")}</span>
            <span>{fMoney(treatmentPriceHours)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function TreatmentCostExtras({ calculation }: { calculation: Calculation }) {
  const { treatmentPriceExtras } = calculation;

  return (
    <div className="px-6">
      <div className="flex w-full justify-between font-bold">
        <span>{t("Grundbehandlungskosten Extras")}</span>
        <span>{fMoney(treatmentPriceExtras)}</span>
      </div>
    </div>
  );
}

function MaterialCosts(props: { calculation: Calculation }) {
  const { calculation } = props;
  const [open, setOpen] = useState(true);
  const [request, setRequest] = useState<UpdateParametersRequest>({
    id: calculation.id,
    parameters: calculation.parameters,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useUpdateCalculationParametersMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { canExecute } = useGuard("VBS.Calculation.UpdateParametersRequest");

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="border-t bg-gray-50 px-6 py-3">
        <div className="flex justify-between font-bold">
          <CollapsibleTrigger>
            <div className="flex items-center space-x-2">
              {open ? (
                <ChevronDown className="h-5 w-5" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
              <span>{t("Materialkosten")}</span>
            </div>
          </CollapsibleTrigger>
          <span className="">{fMoney(calculation.materialCost)}</span>
        </div>
      </div>
      <CollapsibleContent>
        <div className="border-t py-2">
          <div className="flex justify-between px-6">
            <span>{t("Pauschale auf Grundbehandlung")}</span>
            <span>
              {canExecute ? (
                <FloatNumberInput
                  value={request.parameters.materialFlatRate}
                  onChange={(materialFlatRate) =>
                    setRequest({
                      ...request,
                      parameters: {
                        ...request.parameters,
                        materialFlatRate,
                      },
                    })
                  }
                />
              ) : (
                <Value value={calculation.parameters.materialFlatRate} />
              )}
              <span> %</span>
            </span>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function TravelTimeCosts(props: { calculation: Calculation }) {
  const { calculation } = props;
  const [open, setOpen] = useState(true);
  const [request, setRequest] = useState<UpdateTravelTimeRequest>({
    id: calculation.id,
    drives: calculation.travelTime.drives,
    hoursPerDrive: calculation.travelTime.hoursPerDrive,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useUpdateCalculationTravelTimeMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="border-t bg-gray-50 px-6 py-3">
        <div className="flex justify-between font-bold">
          <CollapsibleTrigger>
            <div className="flex items-center space-x-2">
              {open ? (
                <ChevronDown className="h-5 w-5" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
              <span>{t("Fahrtkosten")}</span>
            </div>
          </CollapsibleTrigger>
          <span className="">{fMoney(calculation.travelTimeCost)}</span>
        </div>
      </div>
      <CollapsibleContent>
        <div className="border-t py-2">
          <div className="flex justify-between px-6">
            <span>{t("Anzahl Fahrten")}</span>
            <IntNumberInput
              value={request.drives}
              onChange={(drives) => {
                setRequest({ ...request, drives });
              }}
            />
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Dauer pro Fahrt (Stunden)")}</span>
            <FloatNumberInput
              value={request.hoursPerDrive}
              onChange={(hoursPerDrive) =>
                setRequest({ ...request, hoursPerDrive })
              }
            />
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Anzahl Mitarbeiter")}</span>
            <span>{calculation.travelTime.employees}</span>
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Fahrzeit Gesamt")}</span>
            <span>{fHours(calculation.travelTime.totalTravelTime)}</span>
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Manntage")}</span>
            <span>
              {fHours(
                calculation.travelTime.totalTravelTime /
                  calculation.parameters.hoursInManDay,
              )}
            </span>
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Manntagessatz")}</span>
            <span>{fMoney(calculation.parameters.manDayRate)}</span>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}

function ExpensesCosts(props: { calculation: Calculation }) {
  const { calculation } = props;
  const { expenses } = calculation;
  const [open, setOpen] = useState(true);

  const [request, setRequest] = useState<UpdateExpensesRequest>({
    id: calculation.id,
    selection: expenses.selection,
    perDiemDays: expenses.perDiem.days,
    overnightNights: expenses.overnight.nights,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useUpdateCalculationExpensesMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  let selectionView: ReactNode;
  switch (expenses.selection) {
    case Selection.None:
      selectionView = <div />;
      break;
    case Selection.PerDiem:
      selectionView = (
        <div className="mt-2">
          <div className="flex justify-between px-6">
            <span>{t("Anzahl Tage")}</span>
            <IntNumberInput
              value={request.perDiemDays}
              onChange={(perDiemDays) =>
                setRequest({ ...request, perDiemDays })
              }
            />
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Anzahl Mitarbeiter")}</span>
            {/* The count of employees can only be updated for the total calculation in this view. */}
            <span>{fMoney(calculation.expenses.perDiem.employees)}</span>
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Verpflegungspauschale")}</span>
            <Value value={calculation.expenses.perDiem.allowance} />
          </div>
        </div>
      );
      break;
    case Selection.Overnight:
      selectionView = (
        <div className="mt-2">
          <div className="flex justify-between px-6">
            <span>{t("Anzahl Nächte")}</span>
            <IntNumberInput
              value={request.overnightNights}
              onChange={(overnightNights) =>
                setRequest({ ...request, overnightNights })
              }
            />
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Anzahl Mitarbeiter")}</span>
            {/* The count of employees can only be updated for the total calculation in this view. */}
            <span>{fMoney(calculation.expenses.overnight.employees)}</span>
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Verpflegungspauschale")}</span>
            <Value value={calculation.expenses.overnight.allowance} />
          </div>
          <div className="flex justify-between px-6">
            <span>{t("Übernachtungskosten")}</span>
            <Value value={calculation.expenses.overnight.hotelRate} />
          </div>
        </div>
      );
      break;
    default:
      selectionView = <div />;
  }

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="border-t bg-gray-50 px-6 py-3">
        <div className="flex justify-between font-bold">
          <CollapsibleTrigger>
            <div className="flex items-center space-x-2">
              {open ? (
                <ChevronDown className="h-5 w-5" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
              <span>{t("Spesen")}</span>
            </div>
          </CollapsibleTrigger>
          <span className="">{fMoney(calculation.expenses.total)}</span>
        </div>
      </div>
      <CollapsibleContent>
        <div className="border-t py-2">
          <RadioGroup
            className="flex justify-center space-x-2 px-6"
            value={request.selection}
            onValueChange={(selection) =>
              setRequest({ ...request, selection: selection as Selection })
            }
          >
            {[Selection.None, Selection.PerDiem, Selection.Overnight].map(
              (kind) => (
                <Label
                  key={kind}
                  htmlFor={kind}
                  className="flex w-full cursor-pointer items-center justify-center space-x-2 rounded border px-3 py-3"
                >
                  <RadioGroupItem value={kind} id={kind} />
                  <span>{t(kind)}</span>
                </Label>
              ),
            )}
          </RadioGroup>
          {selectionView}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}
