import { useParams } from "react-router-dom";
import { useRltConfirmationShowQuery } from "@/services/backend/rlt/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { CalculationComponent } from "@/routes/gesec/processes/[processId]/rlt/calculations/[calculationId]/calculation/calculation-route";

export function ConfirmationCalculationRoute() {
  const { confirmationId } = useParams();
  const {
    data: confirmation,
    isLoading,
    error,
  } = useRltConfirmationShowQuery({ id: confirmationId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return null;
  }

  return <CalculationComponent calculationId={confirmation!.calculationId} />;
}
