import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import { WorkOrder } from "@/services/backend/vbs/work-orders/work-order";
import { Outlet, useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useVbsWorkOrderShowQuery } from "@/services/backend/vbs/work-orders/service";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import t from "@/lang/lang";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { DeleteWorkOrderDialog } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/_components/delete-work-order-dialog";
import { SignatureButton } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/_components/signature-button";
import { Card } from "@/shared/components/ui/card";
import { WorkOrderImmutablePage } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/_components/work-order-immutable-page";
import { WorkOrderPdf } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/_components/work-order-pdf";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function WorkOrderRoute() {
  const { workOrderId } = useParams();
  const {
    data: workOrder,
    isLoading,
    error,
  } = useVbsWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (workOrder!.immutable) {
    return <WorkOrderImmutablePage workOrder={workOrder!} />;
  }

  return <WorkSlipPage workOrder={workOrder!} />;
}

function WorkSlipPage(props: { workOrder: WorkOrder }) {
  const { workOrder } = props;

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <Page workOrder={workOrder} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

function Page({ workOrder }: { workOrder: WorkOrder }) {
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <MilestonePageContent>
      <MilestonePageContentMenu>
        <div className="flex items-center space-x-4">
          <TabsNavList>
            <TabsNavLink
              to="data"
              hasValidationError={hasDataError(notReadyError)}
            >
              {t("Daten")}
            </TabsNavLink>
            <TabsNavLink
              to={`last-minute-risk-analysis/${workOrder.lastMinuteRiskAnalysisId}`}
              hasValidationError={hasLMRAError(notReadyError)}
            >
              {t("LMRA")}
            </TabsNavLink>
            <TabsNavLink to="positions">{t("Arbeiten")}</TabsNavLink>
            <TabsNavLink
              to={`documentation/${workOrder.documentationId}`}
              hasValidationError={hasDocumentationError(notReadyError)}
            >
              {t("Dokumentation")}
            </TabsNavLink>
          </TabsNavList>
          <ArtefactNumberById artefactId={workOrder.id} />
        </div>
        <div className="flex items-center space-x-2">
          <PersistenceStateUsingContext className="mr-2" />
          <DeleteWorkOrderDialog workOrderId={workOrder.id} />
          <SignatureButton workSlipID={workOrder.id} />
          <WorkOrderPdf workOrderId={workOrder.id} />
        </div>
      </MilestonePageContentMenu>
      <Outlet />
    </MilestonePageContent>
  );
}

function hasDataError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "beginDate",
    "endDate",
    "teamLeaderId",
    "customerAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
    "contact.name",
    "contact.mobilephone",
    "contact.email",
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}

function hasLMRAError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = ["lastMinuteRiskAnalysis"];

  return fields.some((field) => field in error.errors);
}

function hasDocumentationError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = ["documentation"];

  return fields.some((field) => field in error.errors);
}
