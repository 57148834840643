import {
  Building,
  CreateBuildingRequest,
} from "@/services/backend/qpm/buildings/types";
import { useEffect, useState } from "react";
import { useQpmBuildingCreateMutation } from "@/services/backend/qpm/buildings/service";
import { v4 } from "uuid";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useToast } from "@/shared/hooks/use-toast";

export function AddBuildingComponent(props: {
  customerId: string;
  addressId: string;
  onBuildingCreate: (building: Building) => void;
}) {
  const { customerId, addressId, onBuildingCreate } = props;
  const { toast } = useToast();
  const [newBuildingName, setNewBuildingName] = useState<string>("");
  const [createNewBuilding, { error, isSuccess, data, reset }] =
    useQpmBuildingCreateMutation();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error) });
      reset();
    }
  }, [error, reset, toast]);

  useEffect(() => {
    if (isSuccess) {
      setNewBuildingName("");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data && onBuildingCreate) {
      onBuildingCreate(data);
      setNewBuildingName("");
      reset();
    }
  }, [data, onBuildingCreate, reset]);

  const handleInputChange = (event: { target: { value: string } }) => {
    const name = event.target.value;
    setNewBuildingName(name);
  };

  const handleSubmit = () => {
    if (!newBuildingName) {
      return;
    }
    const newBuildingRequest: CreateBuildingRequest = {
      id: v4(),
      name: newBuildingName.trim(),
      customerId,
      addressId,
    };
    createNewBuilding(newBuildingRequest);
  };

  return (
    <div className="flex items-center gap-2">
      <Input
        className="h-8 px-2 py-1"
        placeholder="Gebäude hinzufügen..."
        value={newBuildingName}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <Button className="h-8 w-8 p-1" onClick={handleSubmit}>
        <Plus />
      </Button>
    </div>
  );
}
