import { H4 } from "@/shared/components/ui/typography";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useEffect, useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { PestCategoryRecord } from "@/services/backend/qpm/pests/types";
import { useQpmPestCategoryRecordListQuery } from "@/services/backend/qpm/pests/service";
import {
  useQpmRequirementPestCategoryAddMutation,
  useQpmRequirementPestCategoryDeleteMutation,
  useQpmRequirementPestCategoryListQuery,
} from "@/services/backend/qpm/requirements/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Requirement } from "@/services/backend/qpm/requirements/types";

type PestComponentProps = {
  requirement: Requirement;
};

export function PestsComponent({ requirement }: PestComponentProps) {
  const { toast } = useToast();
  const {
    data: initialPestCategories,
    error: errorPestCategories,
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isSuccess: isSuccessPestCategories,
  } = useQpmPestCategoryRecordListQuery({
    artefactMetaId: requirement.artefactMetaId,
  });

  const { data: selectedPestCategories, error: errorSelectedPestCategory } =
    useQpmRequirementPestCategoryListQuery({ requirementId: requirement.id });
  const [selectedPests, setSelectedPests] = useState<PestCategoryRecord[]>([]);

  const [
    addRequirementPestCategory,
    {
      error: errorAddRequirementPestCategory,
      reset: resetAddRequirementPestCategory,
    },
  ] = useQpmRequirementPestCategoryAddMutation();

  const [
    deleteRequirementPestCategory,
    { error: errorDeleteRPC, reset: resetDeleteRPC },
  ] = useQpmRequirementPestCategoryDeleteMutation();

  useEffect(() => {
    if (errorAddRequirementPestCategory) {
      toast({ ...parseRTKQueryError(errorAddRequirementPestCategory) });
      resetAddRequirementPestCategory();
    }
  }, [errorAddRequirementPestCategory, resetAddRequirementPestCategory, toast]);

  useEffect(() => {
    if (errorDeleteRPC) {
      toast({ ...parseRTKQueryError(errorDeleteRPC) });
      resetDeleteRPC();
    }
  }, [errorDeleteRPC, resetDeleteRPC, toast]);

  useEffect(() => {
    if (initialPestCategories) {
      let pestCategories = initialPestCategories.categories;
      if (selectedPestCategories) {
        pestCategories = pestCategories.map((pestCategory) => ({
          ...pestCategory,
          infestationPresent: selectedPestCategories.pestCategories.some(
            (p) => p.pestCategoryId === pestCategory.id,
          ),
          immutable:
            selectedPestCategories.pestCategories.find(
              (pc) => pc.pestCategoryId === pestCategory.id,
            )?.immutable || false,
        }));
      }
      setSelectedPests(pestCategories);
    }
  }, [initialPestCategories, selectedPestCategories]);

  const handleClick = (id: string, active: boolean) => {
    if (active) {
      deleteRequirementPestCategory({
        requirementId: requirement.id,
        pestCategoryId: id,
      });
    } else {
      addRequirementPestCategory({
        requirementId: requirement.id,
        pestCategoryId: id,
      });
    }
  };

  if (errorPestCategories) {
    return <RTKQueryErrorAlert error={errorPestCategories} />;
  }

  if (errorSelectedPestCategory) {
    return <RTKQueryErrorAlert error={errorSelectedPestCategory} />;
  }

  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>Schädlinge</H4>
      <div className="flex flex-col md:grid md:grid-cols-3 xl:grid-cols-5">
        {selectedPests.map((pest) => (
          <div key={pest.id} className="flex items-center gap-1 p-1">
            <Checkbox
              checked={pest.infestationPresent}
              onClick={() => handleClick(pest.id, pest.infestationPresent)}
              disabled={pest.immutable}
            />
            <Button
              className="bg-transparent p-1 text-base text-black hover:bg-transparent hover:text-primary"
              onClick={() => handleClick(pest.id, pest.infestationPresent)}
              disabled={pest.immutable}
            >
              {pest.name}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
