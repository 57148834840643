export enum PropertyUnit {
  None = "none",
  MonthlyHours = "hours/month",
  Weeks = "weeks",
  Hours = "hours",
  TimesPerYear = "times/year",
}

export enum PropertyType {
  None = "none",
  Time = "time",
  TimeFraction = "time-fraction",
  TimeAfter = "time-after",
  TimePeriod = "time-period",
}

export class ServicePropertyUtil {
  private static descriptions: { [key in PropertyUnit]: string } = {
    [PropertyUnit.None]: "",
    [PropertyUnit.Hours]: "hh:mm:ss",
    [PropertyUnit.Weeks]: "Wochen",
    [PropertyUnit.MonthlyHours]: "Std./Monat",
    [PropertyUnit.TimesPerYear]: "x jährlich",
  };

  static toString(propertyUnit: PropertyUnit) {
    return this.descriptions[propertyUnit];
  }

  static inputStep(unit: PropertyUnit): string {
    switch (unit) {
      case PropertyUnit.Weeks:
      case PropertyUnit.TimesPerYear:
        return "1";
      default:
        return "0.5";
    }
  }
}

export enum ComplementaryServiceType {
  None = "none",
  BasicRefurbishment = "basic-refurbishment",
  RedemptionControl = "redemption-control",
  InstallationQPM = "installation-qpm",
  ServiceQPM = "service-qpm",
  ServiceRFID = "service-rfid",
  HygieneAudit = "hygiene-audit",
}

export type QpmComplementaryService = {
  id: string;
  key: ComplementaryServiceType;
  nextId: string;
  name: string;
  description: string;
  position: number;
};

export type ComplementaryServiceProperty = {
  id: string;
  serviceId: string;
  name: string;
  unit: PropertyUnit;
  type: PropertyType;
  position: number;
};

export type QpmComplementaryServiceList = {
  services: QpmComplementaryService[];
};

export type ShowComplementaryServiceRequest = {
  id: string;
};

export type CreateComplementaryServiceRequest = {
  id: string;
  key: string;
  name: string;
  description: string;
  properties: ComplementaryServiceProperty[];
};

export type ListComplementaryServiceRequest = {
  // Add any filtering properties
};

export type DeleteComplementaryServiceRequest = {
  id: string;
};
