import t from "@/lang/lang";
import { Outlet } from "react-router-dom";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";

export function VbsBaseDataRoute() {
  return (
    <PersistenceContextProvider>
      <BaseDataPage>
        <BaseDataPageHeader>
          <BaseDataPageTitle>{t("VBS")}</BaseDataPageTitle>
          <BaseDataPageDescription>
            {t(
              "Hier können die Basisdaten für Vorgänge von VBS Dienstleistungen konfiguriert werden. Änderungen wirken sich nur auf neue Vorgänge aus.",
            )}
          </BaseDataPageDescription>
        </BaseDataPageHeader>
        <BaseDataPageContent>
          <div className="flex justify-between">
            <TabsNavList>
              <TabsNavLink to="calculation">{t("Kalkulation")}</TabsNavLink>
              <TabsNavLink to="offer">{t("Angebot")}</TabsNavLink>
              <TabsNavLink to="contract">{t("Vertrag")}</TabsNavLink>
            </TabsNavList>
            <PersistenceStateUsingContext />
          </div>
          <div>
            <Outlet />
          </div>
        </BaseDataPageContent>
      </BaseDataPage>
    </PersistenceContextProvider>
  );
}
