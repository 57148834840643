import { backendApi } from "@/services/backend/api";
import {
  AddRequirementAdditionalInformationRequest,
  AddRequirementAreaRequest,
  AddRequirementAreaRiskRequest,
  AddRequirementPestCategoryRequest,
  AddRequirementPestRequest,
  CreateRequirementRequest,
  DeleteRequirementAdditionalInformationRequest,
  DeleteRequirementAreaRequest,
  DeleteRequirementAreaRisksRequest,
  DeleteRequirementMonitorRequest,
  DeleteRequirementPestCategoryRequest,
  DeleteRequirementPestRequest,
  GetRequirementAdditionalInformationRequest,
  GetRequirementPestCategoryListRequest,
  GetRequirementPestListRequest,
  GetRequirementRequest,
  GetSelectedMonitorSummaryRequest,
  ListByRequirementIdRequest,
  ListRequirementAreaRisks,
  ListRequirementAreaRisksRequest,
  ListRequirementAreasRequest,
  ListRequirementRequest,
  ListSelectedMonitorsRequest,
  QpmRequirementServiceProperties,
  Requirement,
  RequirementAdditionalInformation,
  RequirementArea,
  RequirementAreaList,
  RequirementAreaRisk,
  RequirementComplementaryServiceList,
  RequirementList,
  RequirementPest,
  RequirementPestCategory,
  RequirementPestCategoryList,
  RequirementPestList,
  SelectedMonitor,
  SelectedMonitorsList,
  SelectedMonitorSummary,
  SelectMonitorRequest,
  UpdateRequirementMonitorRequest,
  UpdateRequirementRequest,
} from "@/services/backend/qpm/requirements/types";

const qpmRequirementServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "QpmSelectedMonitor",
      "QpmSelectedMonitorSummary",
      "QpmRequirement",
      "QpmRequirementArea",
      "QpmRequirementAdditionalInformation",
      "QpmRequirementServiceProperties",
      "QpmRequirementComplementaryService",
      "QpmRequirementPest",
      "QpmRequirementPestCategory",
      "QpmRequirementAreaRisk",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmRequirementCreate: build.mutation<
        Requirement,
        CreateRequirementRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRequirement"],
      }),
      qpmRequirementUpdate: build.mutation<
        Requirement,
        UpdateRequirementRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: ["QpmRequirement"],
      }),
      qpmRequirementGet: build.query<Requirement, GetRequirementRequest>({
        query: (request) => ({
          url: `/qpm/requirement`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRequirement", id: result.id }] : [],
      }),
      qpmRequirementList: build.query<
        RequirementList,
        Partial<ListRequirementRequest>
      >({
        query: (request) => ({
          url: `/qpm/requirement/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRequirement", id: "LIST" }] : [],
      }),
      qpmRequirementAreaAdd: build.mutation<
        RequirementArea,
        AddRequirementAreaRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/area`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementArea"],
      }),
      qpmRequirementAreaList: build.query<
        RequirementAreaList,
        ListRequirementAreasRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/area/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRequirementArea", id: "LIST" }] : [],
      }),
      qpmRequirementAreaDelete: build.mutation<
        RequirementArea,
        DeleteRequirementAreaRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/area`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementArea"],
      }),
      qpmRequirementAdditionalInformationAdd: build.mutation<
        RequirementAdditionalInformation,
        AddRequirementAdditionalInformationRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/additional-information`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementAdditionalInformation"],
      }),
      qpmRequirementAdditionalInformationGet: build.query<
        RequirementAdditionalInformation,
        GetRequirementAdditionalInformationRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/additional-information`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [
                {
                  type: "QpmRequirementAdditionalInformation",
                  id: result.requirementId,
                },
              ]
            : [],
      }),
      qpmRequirementAdditionalInformationDelete: build.mutation<
        RequirementAdditionalInformation,
        DeleteRequirementAdditionalInformationRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/additional-information`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementAdditionalInformation"],
      }),
      qpmRequirementSelectedMonitorAdd: build.mutation<
        SelectedMonitor,
        SelectMonitorRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/selected-monitor`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          { type: "QpmSelectedMonitor", id: "LIST" },
          "QpmRequirementAdditionalInformation",
          "QpmSelectedMonitorSummary",
          "QpmRequirementPestCategory",
        ],
      }),
      qpmRequirementSelectedMonitorList: build.query<
        SelectedMonitorsList,
        ListSelectedMonitorsRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/selected-monitor/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmSelectedMonitor", id: "LIST" }] : [],
      }),
      qpmRequirementSelectedMonitorUpdate: build.mutation<
        SelectedMonitor,
        UpdateRequirementMonitorRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/selected-monitor`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: [
          { type: "QpmSelectedMonitor", id: "LIST" },
          "QpmRequirementAdditionalInformation",
          "QpmSelectedMonitorSummary",
          "QpmRequirementPestCategory",
        ],
      }),
      qpmRequirementSelectedMonitorSummary: build.query<
        SelectedMonitorSummary,
        Partial<GetSelectedMonitorSummaryRequest>
      >({
        query: (request) => ({
          url: `/qpm/requirement/selected-monitor-summary`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmSelectedMonitorSummary", id: "LIST" }] : [],
      }),
      qpmRequirementSelectedMonitorDelete: build.mutation<
        { message: string },
        DeleteRequirementMonitorRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/selected-monitor`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: [
          { type: "QpmSelectedMonitor", id: "LIST" },
          "QpmRequirementAdditionalInformation",
          "QpmSelectedMonitorSummary",
          "QpmRequirementPestCategory",
        ],
      }),
      qpmRequirementComplementaryServiceAdd: build.mutation<
        QpmRequirementServiceProperties,
        QpmRequirementServiceProperties
      >({
        query: (request) => ({
          url: `/qpm/requirement/complementary-service`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: [
          { type: "QpmRequirementServiceProperties", id: "LIST" },
        ],
      }),
      qpmRequirementComplementaryServiceList: build.query<
        RequirementComplementaryServiceList,
        ListByRequirementIdRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/complementary-service/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [{ type: "QpmRequirementComplementaryService", id: "LIST" }]
            : [],
      }),
      qpmRequirementPestAdd: build.mutation<
        RequirementPest,
        AddRequirementPestRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/pest`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementPest"],
      }),
      qpmRequirementPestList: build.query<
        RequirementPestList,
        GetRequirementPestListRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/pest/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRequirementPest", id: "LIST" }] : [],
      }),
      qpmRequirementPestDelete: build.mutation<
        RequirementPest,
        DeleteRequirementPestRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/pest`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementPest"],
      }),
      qpmRequirementPestCategoryAdd: build.mutation<
        RequirementPestCategory,
        AddRequirementPestCategoryRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/pest-category`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementPestCategory"],
      }),
      qpmRequirementPestCategoryList: build.query<
        RequirementPestCategoryList,
        GetRequirementPestCategoryListRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/pest-category/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRequirementPestCategory", id: "LIST" }] : [],
      }),
      qpmRequirementPestCategoryDelete: build.mutation<
        RequirementPestCategory,
        DeleteRequirementPestCategoryRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/pest-category`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementPestCategory"],
      }),
      qpmRequirementAreaRiskAdd: build.mutation<
        RequirementAreaRisk,
        AddRequirementAreaRiskRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/risk`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementAreaRisk"],
      }),
      qpmRequirementAreaRiskList: build.query<
        ListRequirementAreaRisks,
        ListRequirementAreaRisksRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/risk/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRequirementAreaRisk", id: "LIST" }] : [],
      }),
      qpmRequirementAreaRiskDelete: build.mutation<
        RequirementAreaRisk,
        DeleteRequirementAreaRisksRequest
      >({
        query: (request) => ({
          url: `/qpm/requirement/risk`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRequirementAreaRisk"],
      }),
    }),
  });

export const {
  useQpmRequirementCreateMutation,
  useQpmRequirementUpdateMutation,
  useQpmRequirementGetQuery,
  useQpmRequirementListQuery,
  useQpmRequirementAreaAddMutation,
  useQpmRequirementAreaListQuery,
  useQpmRequirementAreaDeleteMutation,
  useQpmRequirementAdditionalInformationAddMutation,
  useQpmRequirementAdditionalInformationGetQuery,
  useQpmRequirementAdditionalInformationDeleteMutation,
  useQpmRequirementSelectedMonitorAddMutation,
  useQpmRequirementSelectedMonitorUpdateMutation,
  useQpmRequirementSelectedMonitorListQuery,
  useQpmRequirementSelectedMonitorDeleteMutation,
  useQpmRequirementSelectedMonitorSummaryQuery,
  useQpmRequirementComplementaryServiceAddMutation,
  useQpmRequirementComplementaryServiceListQuery,
  useQpmRequirementPestAddMutation,
  useQpmRequirementPestListQuery,
  useQpmRequirementPestCategoryAddMutation,
  useQpmRequirementPestDeleteMutation,
  useQpmRequirementPestCategoryListQuery,
  useQpmRequirementPestCategoryDeleteMutation,
  useQpmRequirementAreaRiskAddMutation,
  useQpmRequirementAreaRiskListQuery,
  useQpmRequirementAreaRiskDeleteMutation,
} = qpmRequirementServiceApi;
