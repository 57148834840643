import { H1 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import { CustomersTable } from "@/routes/customers/_components/customers-table";
import { Outlet } from "react-router-dom";

export function CustomersRoute() {
  return <Outlet />;
}

export function CustomersIndexRoute() {
  return (
    <div className="mx-2 my-4 space-y-4">
      <H1 className="mb-4">{t("Kunden")}</H1>
      <CustomersTable />
    </div>
  );
}
