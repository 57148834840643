export interface Address {
  company: string;
  department: string;
  contact: string;
  street: string;
  postbox: string;
  postalCode: string;
  city: string;
  country: string;
  comment: string;
}

export function newAddress(): Address {
  return {
    company: "",
    department: "",
    contact: "",
    street: "",
    postbox: "",
    postalCode: "",
    city: "",
    country: "",
    comment: "",
  };
}

export function oneLine(address: Address): string {
  return `${address.street}, ${address.postalCode} ${address.city}`;
}
