import { SegmentKind } from "@/services/backend/rlt/treatments/segmentKind";
import { Filter } from "@/shared/lib/filter/filter";
import { backendApi } from "../../api";
import { Certificate } from "./certificate";
import t from "../../../../lang/lang";

const rltCertificatesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltCertificateShow: build.query<Certificate, ShowRequest>({
      query: (request) => ({
        url: "/rlt/certificate",
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
      ],
    }),
    rltCertificateList: build.query<List, ListRequest>({
      query: (request) => ({
        url: "/rlt/certificate/list",
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.certificates.map((res) => ({
                type: "RltCertificate" as const,
                id: res.id,
              })),
              { type: "RltCertificate", id: "LIST" },
            ]
          : [{ type: "RltCertificate", id: "LIST" }],
    }),
    rltCertificateCreate: build.mutation<Certificate, CreateRequest>({
      query: (request) => ({
        url: "/rlt/certificate",
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "RltCertificate", id: "LIST" }],
    }),
    rltCertificateUpdateIssueDate: build.mutation<
      Certificate,
      UpdateIssueDateRequest
    >({
      query: (request) => ({
        url: `/rlt/certificate/issue-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
        { type: "RltCertificate", id: "LIST" },
      ],
    }),
    rltCertificateUpdateWorkStartedAt: build.mutation<
      Certificate,
      UpdateWorkStartedAtRequest
    >({
      query: (request) => ({
        url: "/rlt/certificate/work-started-at",
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
        { type: "RltCertificate", id: "LIST" },
      ],
    }),
    rltCertificateUpdateWorkEndedAt: build.mutation<
      Certificate,
      UpdateWorkEndedAtRequest
    >({
      query: (request) => ({
        url: "/rlt/certificate/work-ended-at",
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
        { type: "RltCertificate", id: "LIST" },
      ],
    }),
    rltCertificateUpdateSegments: build.mutation<
      Certificate,
      UpdateSegmentsRequest
    >({
      query: (request) => ({
        url: "/rlt/certificate/segments",
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
        { type: "RltCertificate", id: "LIST" },
      ],
    }),
    rltCertificatePreviewPDF: build.query<
      { objectURL: string },
      PreviewPDFRequest
    >({
      query: (request) => ({
        url: "/rlt/certificate/preview-pdf",
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
      ],
    }),
    rltCertificateDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: "/rlt/certificate/download-pdf",
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          let filename = t("Zertifikat.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCertificate", id: request.id },
        { type: "RltCertificate", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useRltCertificateShowQuery,
  useRltCertificateListQuery,
  useRltCertificateCreateMutation,
  useRltCertificateUpdateIssueDateMutation,
  useRltCertificateUpdateWorkStartedAtMutation,
  useRltCertificateUpdateWorkEndedAtMutation,
  useRltCertificateUpdateSegmentsMutation,
  useRltCertificatePreviewPDFQuery,
  useRltCertificateDownloadPDFMutation,
} = rltCertificatesApi;

export interface ShowRequest {
  id: string;
}

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface UpdateIssueDateRequest {
  id: string;
  issueDate: string;
}

export interface UpdateWorkStartedAtRequest {
  id: string;
  workStartedAt: string;
}

export interface UpdateWorkEndedAtRequest {
  id: string;
  workEndedAt: string;
}

export interface UpdateSegmentsRequest {
  id: string;
  segments: SegmentKind[];
}

export interface PreviewPDFRequest {
  id: string;
}

export interface DownloadPDFRequest {
  id: string;
  downloadBy: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  issueDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  certificates: Certificate[];
  count: number;
}
