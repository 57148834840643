import { PositionsTable } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/positions-table";
import { PositionsContextProvider } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/positions-context";
import { useParams } from "react-router-dom";

export function PositionsRoute() {
  const { workOrderId } = useParams();

  return (
    <PositionsContextProvider workOrderId={workOrderId!}>
      <PositionsTable />
    </PositionsContextProvider>
  );
}
