import { useParams } from "react-router-dom";
import { Card } from "@/shared/components/ui/card";
import { CalculationListTable } from "@/routes/gesec/processes/[processId]/vbs/calculations/_components/calculation-list-table";

export function CalculationIndexRoute() {
  const { processId } = useParams();

  return (
    <Card>
      <CalculationListTable processId={processId!} />
    </Card>
  );
}
