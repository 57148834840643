import { acquireBackendAccessToken } from "@/shared/lib/authorization/msal-access-token";

/**
 * provideBackendToken will provide a token for the backend depending on the
 * current env.
 */
export async function provideBackendToken(): Promise<string> {
  switch (import.meta.env.VITE_AUTH) {
    case "dev":
      return import.meta.env.VITE_BACKEND_DEV_TOKEN;
    default:
      return acquireBackendAccessToken();
  }
}
