import { Contract as ContractDefaults } from "@/services/backend/rlt/defaults/contract";
import { Calculation as CalculationDefaults } from "./calculations";
import { Offer as OfferDefaults } from "./offer";

export interface Defaults {
  key: Key;
  data: CalculationDefaults | OfferDefaults | ContractDefaults;
}

export enum Key {
  Offer = "Offer",
  Calculation = "Calculation",
  Contract = "Contract",
}
