import { Group } from "@/services/backend/entra/entragroup/group";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import t from "@/lang/lang";
import { useAuthEntraGroupDeleteMutation } from "@/services/backend/entra/entragroup/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useState } from "react";

export function DeleteEntraGroupDialog({ group }: { group: Group }) {
  const [open, setOpen] = useState(false);
  const [deleteGroup, { isLoading, error, isSuccess, reset }] =
    useAuthEntraGroupDeleteMutation();

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteGroup({ id: group.id });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Entra Gruppe entfernen")}</DialogTitle>
        </DialogHeader>
        <div>
          {t("Soll Gruppe ") + group.name + t(" wirklich entfernt werden?")}
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick} disabled={isLoading}>
            {t("Entfernen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
