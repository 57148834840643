import { backendApi } from "@/services/backend/api";
import { ProcessContact } from "@/services/backend/contacts/processcontact/process-contact";

const processContactApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    contactsProcessContactPut: build.mutation<ProcessContact, ProcessContact>({
      query: (request) => ({
        url: "/contacts/process-contact",
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessContact", id: request.id },
        { type: "ProcessContact", id: "LIST" },
      ],
    }),
    contactsProcessContactShow: build.query<ProcessContact, ShowRequest>({
      query: (request) => ({
        url: "/contacts/process-contact",
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _error, request) => [
        { type: "ProcessContact", id: request.id },
      ],
    }),
    contactsProcessContactDelete: build.mutation<ProcessContact, DeleteRequest>(
      {
        query: (request) => ({
          url: "/contacts/process-contact",
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "ProcessContact", id: request.id },
          { type: "ProcessContact", id: "LIST" },
        ],
      },
    ),
  }),
});

export const {
  useContactsProcessContactPutMutation,
  useContactsProcessContactShowQuery,
  useContactsProcessContactDeleteMutation,
} = processContactApi;

export interface ShowRequest {
  id: string;
}

export interface DeleteRequest {
  id: string;
}
