import * as React from "react";
import { cn } from "@/shared/lib/utils";

/**
 * ScrollBodyTable in combination with ScrollBodyTableHeader
 * enables to define a shadcn table, in which the body can be
 * scrolled and the header is sticky. The two components should
 * be used as a replacement for their respective counterparts.
 * The table should then be wrapped in a ScrollArea component.
 */
const ScrollBodyTable = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <table
    ref={ref}
    className={cn("w-full caption-bottom text-sm", className)}
    {...props}
  />
));
ScrollBodyTable.displayName = "ScrollBodyTable";

const ScrollBodyTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn("sticky top-0 bg-background [&_tr]:border-b", className)}
    {...props}
  />
));
ScrollBodyTableHeader.displayName = "TableHeader";

export { ScrollBodyTable, ScrollBodyTableHeader };
