import { ArtefactDocType } from "@/services/backend/qpm/shared/enums";

function cleanName(customerName: string): string {
  const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ";
  return customerName
    .toUpperCase()
    .split("")
    .filter((char) => allowedChars.includes(char))
    .join("");
}

function shortenName(customerName: string): string {
  const allowedChars = "BCDFGHJKLMNPQRSTVWXYZ";
  return customerName
    .toUpperCase()
    .split("")
    .filter((char) => allowedChars.includes(char))
    .join("");
}

export function createDocumentIdentifier(
  customerName: string,
  docType: ArtefactDocType,
  counter: number,
): string {
  let cleanedName = cleanName(customerName);

  if (cleanedName.length > 5) {
    cleanedName = shortenName(customerName);
  }

  return `${cleanedName.toUpperCase()}/${docType}-${counter}`;
}
