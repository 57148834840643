import t from "@/lang/lang";
import { ProcessListTable } from "@/routes/gesec/processes/_components/process-list-table";
import { H1 } from "@/shared/components/ui/typography";

export default function ProcessesRoute() {
  return (
    <div className="mx-2 my-4 space-y-2">
      <H1 className="mb-4">{t("Vorgänge")}</H1>
      <ProcessListTable />
    </div>
  );
}
