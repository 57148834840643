import { backendApi } from "@/services/backend/api";
import { StructuralAssessmentCatalog } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";

const structuralAssessmentCatalogApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "HtzStructuralAssessmentCatalog",
      "HtzActiveStructuralAssessmentCatalog",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzStructuralAssessmentCatalogPut: build.mutation<
        StructuralAssessmentCatalog,
        PutRequest
      >({
        query: (request) => ({
          url: `/htz/inspection/structural-assessment-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzStructuralAssessmentCatalog", id: request.id },
          "HtzStructuralAssessmentCatalog",
        ],
      }),
      htzStructuralAssessmentCatalogShow: build.query<
        StructuralAssessmentCatalog,
        ShowRequest
      >({
        query: (request) => ({
          url: `/htz/inspection/structural-assessment-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzStructuralAssessmentCatalog"),
      }),
      htzStructuralAssessmentCatalogList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/inspection/structural-assessment-catalog/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.catalogs, "HtzStructuralAssessmentCatalog"),
      }),
      htzStructuralAssessmentCatalogDelete: build.mutation<null, DeleteRequest>(
        {
          query: (request) => ({
            url: `/htz/inspection/structural-assessment-catalog`,
            method: "DELETE",
            body: request,
          }),
          invalidatesTags: ["HtzStructuralAssessmentCatalog"],
        },
      ),
      htzStructuralAssessmentCatalogPutActiveCatalog: build.mutation<
        ActiveCatalog,
        ActiveCatalog
      >({
        query: (request) => ({
          url: `htz/inspection/structural-assessment-catalog/active-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["HtzActiveStructuralAssessmentCatalog"],
      }),
      htzStructuralAssessmentCatalogShowActiveCatalog: build.query<
        ActiveCatalog,
        ShowActiveCatalogRequest
      >({
        query: (request) => ({
          url: `htz/inspection/structural-assessment-catalog/active-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["HtzActiveStructuralAssessmentCatalog"],
      }),
    }),
  });

export const {
  useHtzStructuralAssessmentCatalogPutMutation,
  useHtzStructuralAssessmentCatalogShowQuery,
  useHtzStructuralAssessmentCatalogListQuery,
  useHtzStructuralAssessmentCatalogDeleteMutation,
  useHtzStructuralAssessmentCatalogPutActiveCatalogMutation,
  useHtzStructuralAssessmentCatalogShowActiveCatalogQuery,
} = structuralAssessmentCatalogApi;

export interface PutRequest extends StructuralAssessmentCatalog {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  catalogs: StructuralAssessmentCatalog[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface ActiveCatalog {
  catalogId: string | null;
}

export interface ShowActiveCatalogRequest {}
