import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { cn } from "@/shared/lib/utils";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "@/shared/components/ui/calendar";

interface Props {
  date: Date | undefined;
  onDateChange: (date: Date | undefined) => void;
  disabled?: boolean;
  markError?: boolean;
}

export function DatePicker({ date, onDateChange, disabled, markError }: Props) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
            markError && "border-red-500 shadow-md",
          )}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={onDateChange}
          initialFocus
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  );
}

interface DateStringPickerProps {
  date: string | null;
  onDateChange: (date: string | null) => void;
  disabled?: boolean;
  markError?: boolean;
}

export function DateStringPicker({
  date,
  onDateChange,
  disabled,
  markError,
}: DateStringPickerProps) {
  return (
    <DatePicker
      date={date ? new Date(date) : undefined}
      onDateChange={(date) =>
        onDateChange(date ? setCurrentTime(date).toISOString() : null)
      }
      disabled={disabled}
      markError={markError}
    />
  );
}

function setCurrentTime(date: Date): Date {
  const now = new Date();
  date.setHours(now.getHours());
  date.setMinutes(now.getMinutes());
  return date;
}
