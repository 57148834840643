import { RepresentativeCard } from "@/shared/components/domain/representatives/representative-card";
import { Representative } from "@/services/backend/representatives/representative/representative";
import {
  fieldErrorsForPrefix,
  resetField,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { useParams } from "react-router-dom";
import {
  useHtzOrderPutMutation,
  useHtzOrderShowQuery,
} from "@/services/backend/htz/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import {
  Order,
  updateRepresentative,
} from "@/services/backend/htz/orders/order";
import t from "@/lang/lang";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";

export function RepresentativeRoute() {
  const { orderId } = useParams();
  const {
    data: order,
    isLoading,
    error,
  } = useHtzOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return <RepresentativePage order={order!} />;
}

function RepresentativePage({ order }: { order: Order }) {
  const { notReadyError, setNotReadyError } = useErrArtefactNotReady();

  const [request, setRequest] = useState({ ...order });
  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzOrderPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const validationError =
    notReadyError ?? (rtkErrIsValidationError(error) ? error.data : null);

  const onRepresentativeChange = (representative: Representative) => {
    setRequest(updateRepresentative(request, representative));
  };

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setNotReadyError(resetField(notReadyError, prefixedField));
  };

  return (
    <div className="space-x-4">
      <RepresentativeCard
        representative={request.representative}
        onRepresentativeChange={onRepresentativeChange}
        fieldErrors={fieldErrorsForPrefix(validationError, "representative.")}
        resetFieldError={resetValidationErrorField("representative.")}
      />
    </div>
  );
}
