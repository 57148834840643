import { backendApi } from "../../api";
import { Defaults, Key } from "./defaults";

const defaultsService = backendApi.injectEndpoints({
  endpoints: (build) => ({
    showVBSDefaults: build.query<Defaults, ShowRequest>({
      query: (request) => ({
        url: `/vbs/defaults`,
        method: "GET",
        params: request,
      }),
      providesTags: (_r, _e, request) => [
        { type: "VbsDefaults", id: request.key },
      ],
    }),
    putVBSDefaults: build.mutation<Defaults, PutRequest>({
      query: (request) => ({
        url: `/vbs/defaults`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsDefaults", id: request.key },
      ],
    }),
  }),
});

export const {
  useShowVBSDefaultsQuery: useVbsDefaultsShowQuery,
  usePutVBSDefaultsMutation: useVbsDefaultsPutMutation,
} = defaultsService;

export interface ShowRequest {
  key: Key;
}

export interface PutRequest extends Defaults {}
