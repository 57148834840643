import t from "@/lang/lang";
import { Outlet } from "react-router-dom";
import { PutCompanyDialog } from "@/routes/base-data/companies/list/components/put-company-dialog";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";

export function CompaniesRoute() {
  return (
    <BaseDataPage>
      <BaseDataPageHeader>
        <BaseDataPageTitle>
          <span>{t("Unternehmen")}</span>
          <PutCompanyDialog />
        </BaseDataPageTitle>
        <BaseDataPageDescription>
          {t("Ein Unternehmen ist ein Unternehmen der GESA Gruppe.")}
        </BaseDataPageDescription>
      </BaseDataPageHeader>
      <BaseDataPageContent>
        <Outlet />
      </BaseDataPageContent>
    </BaseDataPage>
  );
}
