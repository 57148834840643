import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Plus, RefreshCw } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { useEffect } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useHtzOrderPutMutation } from "@/services/backend/htz/orders/service";
import { newOrder } from "@/services/backend/htz/orders/order";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

interface Props {
  processId: string;
}

export function CreateOrderButton({ processId }: Props) {
  const navigate = useNavigate();
  const { data: proc } = useProcessesProcessShowQuery({ id: processId });

  const [create, { error, isLoading }] = useHtzOrderPutMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
    }
  }, [error, toast]);

  const doCreate = () => {
    if (isLoading || !proc) {
      return;
    }

    const id = v4();

    create(newOrder(id, proc))
      .unwrap()
      .then(() => {
        navigate(`${id}`);
      });
  };

  const disabled = isLoading || !proc;

  return (
    <Button onClick={doCreate} disabled={disabled}>
      {isLoading ? (
        <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        <Plus className="mr-2 h-5 w-5" />
      )}
      <span>{t("Auftragseingang")}</span>
    </Button>
  );
}
