import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";

export function LinkedAhuDataCard({ ahu }: { ahu: AirHandlingUnit }) {
  // Angebote
  // Arbeitsscheine
  // ...

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Verbundene Daten")}</CardTitle>
        <CardDescription>
          {t("Angebote, Arbeitsscheine für Anlage: ")}
          {ahu.name}
        </CardDescription>
      </CardHeader>
    </Card>
  );
}
