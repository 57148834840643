import { Position } from "@/services/backend/htz/work-order/work-order";
import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useHtzWorkOrderDeletePositionMutation,
  useHtzWorkOrderListPositionQuery,
} from "@/services/backend/htz/work-order/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card, CardHeader, CardTitle } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";

interface PositionsContextInterface {
  positions: Position[];
  addPosition: (position: Position) => void;
  deletePosition: (position: Position) => void;
  deletedPositions: Position[];
  removeDeletedPosition: (position: Position) => void;
}

export const PositionsContext: Context<PositionsContextInterface> =
  createContext<PositionsContextInterface>({
    positions: [],
    addPosition: () => null,
    deletePosition: () => null,
    deletedPositions: [],
    removeDeletedPosition: () => null,
  });

export function usePositionsContext() {
  return useContext(PositionsContext);
}

export function PositionsContextProvider({
  workOrderId,
  children,
}: {
  workOrderId: string;
  children: ReactNode;
}) {
  const {
    data: serverPositions,
    isLoading,
    error,
  } = useHtzWorkOrderListPositionQuery({
    workOrder: {
      active: true,
      values: [workOrderId],
    },
  });

  // newPositions is used to keep track of positions that are added locally.
  // Once the server response contains the new position, it is removed from
  // newPositions.
  // newPositions are not actively synced with the server. The assumption is
  // that after a position is added, the user will immediately edit it,
  // and after that the PositionContext for this position will PUT to the
  // server.
  const [newPositions, setNewPositions] = useState<Position[]>([]);
  useEffect(() => {
    if (serverPositions) {
      setNewPositions((prev) =>
        prev.filter((pos) =>
          serverPositions.positions.some((sp) => sp.id === pos.id),
        ),
      );
    }
  }, [serverPositions]);

  // deletedPositions is used to keep track of positions that are deleted locally.
  // deletedPositions, are synced with the server using the Deleter component.
  const [deletedPositions, setDeletedPositions] = useState<Position[]>([]);

  const addPosition = useCallback((position: Position) => {
    setNewPositions((prev) => {
      if (prev.some((pos) => pos.id === position.id)) {
        return prev;
      }
      return [...prev, position];
    });
  }, []);

  const deletePosition = useCallback((position: Position) => {
    setDeletedPositions((prev) => {
      if (prev.some((pos) => pos.id === position.id)) {
        return prev;
      }
      return [...prev, position];
    });
  }, []);

  const removeDeletedPosition = useCallback((position: Position) => {
    setDeletedPositions((prev) => prev.filter((pos) => pos.id !== position.id));
  }, []);

  const positions = useMemo(() => {
    if (!serverPositions) {
      return [];
    }
    return (
      serverPositions.positions
        .filter((pos) => !deletedPositions.some((dp) => dp.id === pos.id))
        .concat(
          newPositions.filter(
            (nPos) =>
              !serverPositions.positions.some((sp) => sp.id === nPos.id),
          ),
        ) ?? []
    );
  }, [deletedPositions, newPositions, serverPositions]);

  const value = useMemo(
    () => ({
      positions,
      addPosition,
      deletePosition,
      deletedPositions,
      removeDeletedPosition,
    }),
    [
      positions,
      addPosition,
      deletePosition,
      deletedPositions,
      removeDeletedPosition,
    ],
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <PositionsTableSkeleton />;
  }

  return (
    <PositionsContext.Provider value={value}>
      <Deleter>{children}</Deleter>
    </PositionsContext.Provider>
  );
}

function Deleter({ children }: { children: ReactNode }) {
  const { deletedPositions, removeDeletedPosition } = usePositionsContext();

  const [nextDelete, setNextDelete] = useState<Position>({} as Position);

  // schedule the next delete
  useEffect(() => {
    if (deletedPositions.length > 0) {
      setNextDelete(deletedPositions[0]);
    }
  }, [deletedPositions]);

  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderDeletePositionMutation();

  const request = useMemo(() => ({ id: String(nextDelete.id) }), [nextDelete]);

  // This will clear the deleted position from the list of deleted positions
  // after the delete operation is successful.
  const decoratedReset = () => {
    reset();
    if (nextDelete) {
      removeDeletedPosition(nextDelete);
    }
  };

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    doDelete,
    isLoading,
    error,
    isSuccess,
    decoratedReset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}

export function PositionsTableSkeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Leistungen")}</span>
        </CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Pos")}</TableHead>
            <TableHead>{t("Erledigt")}</TableHead>
            <TableHead>{t("RLT-Anlage")}</TableHead>
            <TableHead>{t("Titel")}</TableHead>
            <TableHead>{t("Optional?")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell colSpan={9} className="animate-pulse text-center">
              {t("Lade Daten")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}
