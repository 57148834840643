import {
  TreatmentComponentStateContext,
  useTreatmentComponentState,
} from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state-context";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  Column,
  TreatmentComponentState,
} from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { useFilterAvailableColumnsForRole } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/use-filter-available-columns-for-role";

interface Props extends PropsWithChildren {
  availableColumns: Column[];
  defaultColumns: Column[];
}

export function TreatmentComponentStateContextProvider({
  children,
  availableColumns,
  defaultColumns,
}: Props) {
  const [state, setState] = useState<TreatmentComponentState>({
    availableColumns,
    columns: defaultColumns.filter((column) =>
      availableColumns.includes(column),
    ),
  });
  const value = useMemo(() => ({ state, setState }), [state, setState]);

  return (
    <TreatmentComponentStateContext.Provider value={value}>
      <TreatmentComponentColumnFilter
        availableColumns={availableColumns}
        defaultColumns={defaultColumns}
      >
        {children}
      </TreatmentComponentColumnFilter>
    </TreatmentComponentStateContext.Provider>
  );
}

function TreatmentComponentColumnFilter({
  children,
  availableColumns,
  defaultColumns,
}: Props) {
  const roleFilteredAvailableColumns =
    useFilterAvailableColumnsForRole(availableColumns);
  const { state, setState } = useTreatmentComponentState();

  useEffect(() => {
    if (
      state.availableColumns.every(
        (col, index) => roleFilteredAvailableColumns[index] === col,
      )
    ) {
      return;
    }
    setState({
      ...state,
      availableColumns: roleFilteredAvailableColumns,
      columns: defaultColumns.filter((column) =>
        roleFilteredAvailableColumns.includes(column),
      ),
    });
  }, [defaultColumns, roleFilteredAvailableColumns, setState, state]);

  return children;
}
