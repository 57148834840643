import { useState } from "react";
import {
  AccessPanel,
  AirHandlingUnit,
  AirHandlingUnitFormula,
  AirHandlingUnitSchwerpunkt,
  AirHandlingUnitSchwerpunktFormula,
  AwayAirDuctAngular,
  AwayAirDuctAngularFormula,
  AwayAirDuctRound,
  Component,
  CrossFlowHeatExchanger,
  CrossFlowHeatExchangerFormula,
  ExhaustDuctAngular,
  ExhaustDuctAngularFormula,
  ExhaustDuctRound,
  ExtraHours,
  ExtraHoursFormula,
  Kind,
  OutdoorAirDuctAngular,
  OutdoorAirDuctAngularFormula,
  OutdoorAirDuctRound,
  OutdoorAirDuctRoundFormula,
  RecirculatingSprayHumidifier,
  RecirculatingSprayHumidifierFormula,
  RotaryHeatExchanger,
  RotaryHeatExchangerFormula,
  SplitUnit,
  SplitUnitFormula,
  SupplyDuctAngular,
  SupplyDuctAngularFormula,
  SupplyDuctRound,
  SupplyDuctRoundFormula,
} from "@/services/backend/rlt/treatments/component";
import {
  UpdateFormulaRequest,
  useRltTreatmentUpdateFormulaMutation,
} from "@/services/backend/rlt/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import t from "@/lang/lang";
import { FloatNumberInput } from "@/routes/gesec/processes/_components/ui/number-input";

export function FormulaCell(props: {
  treatmentId: string;
  positionId: string;
  component: Component;
}) {
  const { treatmentId, positionId, component: comp } = props;

  const [request, setRequest] = useState<UpdateFormulaRequest>({
    id: treatmentId,
    positionId,
    component: comp,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentUpdateFormulaMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  switch (comp.kind) {
    case Kind.AccessPanel:
      return (
        <FormulaAccessPanel
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnit:
      return (
        <FormulaAirHandlingUnit
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnitSchwerpunkt:
      return (
        <FormulaAirHandlingUnitSchwerpunkt
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnitSystem:
      return null;
    case Kind.AwayAirDuctAngular:
      return (
        <FormulaAwayAirDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AwayAirDuctRound:
      return (
        <FormulaAwayAirDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.CrossFlowHeatExchanger:
      return (
        <FormulaCrossFlowHeatExchanger
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustDuctAngular:
      return (
        <FormulaExhaustDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustDuctRound:
      return (
        <FormulaExhaustDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExtraHours:
      return (
        <FormulaExtraHours
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.FreeText:
      return null;
    case Kind.OutdoorAirDuctAngular:
      return (
        <FormulaOutdoorAirDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.OutdoorAirDuctRound:
      return (
        <FormulaOutdoorAirDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.RecirculatingSprayHumidifier:
      return (
        <FormulaRecirculatingSprayHumidifier
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.RotaryHeatExchanger:
      return (
        <FormulaRotaryHeatExchanger
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SplitUnit:
      return (
        <FormulaSplitUnit
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SupplyDuctAngular:
      return (
        <FormulaSupplyDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SupplyDuctRound:
      return (
        <FormulaSupplyDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    default:
      return null;
  }
}

function FormulaAccessPanel({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as AccessPanel;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("€ / Stk")}</span>
      </div>
      <FloatNumberInput
        value={formula.pricePerPanel}
        onChange={(pricePerPanel) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              pricePerPanel,
            },
          })
        }
      />
    </div>
  );
}

function FormulaAirHandlingUnit({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as AirHandlingUnit;

  return (
    <div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Faktor:")}</span>
        </div>
        <FloatNumberInput
          value={formula.sanierungFactor}
          onChange={(sanierungFactor) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                sanierungFactor,
              } as AirHandlingUnitFormula,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Min. / m³")}</span>
        </div>
        <FloatNumberInput
          value={formula.minutesPerCubicMeter}
          onChange={(minutesPerCubicMeter) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                minutesPerCubicMeter,
              } as AirHandlingUnitFormula,
            })
          }
        />
      </div>
    </div>
  );
}

function FormulaAirHandlingUnitSchwerpunkt({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as AirHandlingUnitSchwerpunkt;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("€ / Std.")}</span>
      </div>
      <FloatNumberInput
        value={formula.hourlyRate}
        onChange={(hourlyRate) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              hourlyRate,
            } as AirHandlingUnitSchwerpunktFormula,
          })
        }
      />
    </div>
  );
}

function FormulaAwayAirDuctAngular({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as AwayAirDuctAngular;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as AwayAirDuctAngularFormula,
          })
        }
      />
    </div>
  );
}

function FormulaAwayAirDuctRound({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as AwayAirDuctRound;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as AwayAirDuctAngularFormula,
          })
        }
      />
    </div>
  );
}

function FormulaCrossFlowHeatExchanger({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as CrossFlowHeatExchanger;

  return (
    <div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Faktor:")}</span>
        </div>
        <FloatNumberInput
          value={formula.sanierungFactor}
          onChange={(sanierungFactor) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                sanierungFactor,
              } as CrossFlowHeatExchangerFormula,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Min. / m²")}</span>
        </div>
        <FloatNumberInput
          value={formula.minutesPerSquareMeter}
          onChange={(minutesPerSquareMeter) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                minutesPerSquareMeter,
              } as CrossFlowHeatExchangerFormula,
            })
          }
        />
      </div>
    </div>
  );
}

function FormulaExhaustDuctAngular({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as ExhaustDuctAngular;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as ExhaustDuctAngularFormula,
          })
        }
      />
    </div>
  );
}

function FormulaExhaustDuctRound({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as ExhaustDuctRound;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as ExhaustDuctAngularFormula,
          })
        }
      />
    </div>
  );
}

function FormulaExtraHours({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as ExtraHours;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("€ / Std")}</span>
      </div>
      <FloatNumberInput
        value={formula.hourlyRate}
        onChange={(hourlyRate) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              hourlyRate,
            } as ExtraHoursFormula,
          })
        }
      />
    </div>
  );
}

function FormulaOutdoorAirDuctAngular({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as OutdoorAirDuctAngular;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as OutdoorAirDuctAngularFormula,
          })
        }
      />
    </div>
  );
}

function FormulaOutdoorAirDuctRound({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as OutdoorAirDuctRound;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as OutdoorAirDuctRoundFormula,
          })
        }
      />
    </div>
  );
}

function FormulaRecirculatingSprayHumidifier({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as RecirculatingSprayHumidifier;

  return (
    <div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Faktor:")}</span>
        </div>
        <FloatNumberInput
          value={formula.sanierungFactor}
          onChange={(sanierungFactor) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                sanierungFactor,
              } as RecirculatingSprayHumidifierFormula,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Min. / m²")}</span>
        </div>
        <FloatNumberInput
          value={formula.minutesPerSquareMeter}
          onChange={(minutesPerSquareMeter) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                minutesPerSquareMeter,
              } as RecirculatingSprayHumidifierFormula,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span className="whitespace-nowrap">{t("Min. / Stk.")}</span>
        </div>
        <FloatNumberInput
          value={formula.minutesPerNozzle}
          onChange={(minutesPerNozzle) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                minutesPerNozzle,
              } as RecirculatingSprayHumidifierFormula,
            })
          }
        />
      </div>
    </div>
  );
}

function FormulaRotaryHeatExchanger({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as RotaryHeatExchanger;

  return (
    <div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Faktor:")}</span>
        </div>
        <FloatNumberInput
          value={formula.sanierungFactor}
          onChange={(sanierungFactor) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                sanierungFactor,
              } as RotaryHeatExchangerFormula,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between space-x-4">
        <div className="flex h-6 items-center">
          <span>{t("Min. / m ø")}</span>
        </div>
        <FloatNumberInput
          value={formula.minutesPerDiameter}
          onChange={(minutesPerDiameter) =>
            onChange({
              ...component,
              formula: {
                ...formula,
                minutesPerDiameter,
              } as RotaryHeatExchangerFormula,
            })
          }
        />
      </div>
    </div>
  );
}

function FormulaSplitUnit({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as SplitUnit;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Std. / Stk.")}</span>
      </div>
      <FloatNumberInput
        value={formula.hoursPerUnit}
        onChange={(hoursPerUnit) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              hoursPerUnit,
            } as SplitUnitFormula,
          })
        }
      />
    </div>
  );
}

function FormulaSupplyDuctAngular({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as SupplyDuctAngular;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as SupplyDuctAngularFormula,
          })
        }
      />
    </div>
  );
}

function FormulaSupplyDuctRound({
  component,
  onChange,
}: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { formula } = component as SupplyDuctRound;

  return (
    <div className="flex w-32 justify-between space-x-4">
      <div className="flex h-6 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={formula.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...component,
            formula: {
              ...formula,
              minutesPerSquareMeter,
            } as SupplyDuctRoundFormula,
          })
        }
      />
    </div>
  );
}
