export interface Component {
  kind: Kind;
  data: unknown;
}

export enum Kind {
  AccessPanel = "AccessPanel",
  AerosolSeparator = "AerosolSeparator",
  AirHandlingUnit = "AirHandlingUnit",
  AluminiumMeshGreaseFilter = "AluminiumMeshGreaseFilter",
  Anemostat = "Anemostat",
  DummyPlate = "DummyPlate",
  ExhaustDuctAngular = "ExhaustDuctAngular",
  ExhaustDuctRound = "ExhaustDuctRound",
  ExhaustHood = "ExhaustHood",
  ExhaustHoodSystem = "ExhaustHoodSystem",
  ExtraHours = "ExtraHours",
  FreeText = "FreeText",
  Lighting = "Lighting",
  PassiveArea = "PassiveArea",
  SupplyDuctAngular = "SupplyDuctAngular",
  SupplyDuctRound = "SupplyDuctRound",
  VentilatedCeiling = "VentilatedCeiling",
  VentilatedCeilingSystem = "VentilatedCeilingSystem",
}

export interface AccessPanel {
  count: number;
}

export interface AerosolSeparator {
  count: number;
}

export interface AirHandlingUnit {
  cleanTime: number;
}

export interface AluminiumMeshGreaseFilter {
  count: number;
}

export interface Anemostat {
  count: number;
}

export interface DummyPlate {
  count: number;
}

export interface ExhaustDuctAngular {
  length: number;
  height: number;
  width: number;
  buildUp: BuildUp;
}

export enum BuildUp {
  Resinous = "Resinous",
  Fatty = "Fatty",
  Oily = "Oily",
}

export interface ExhaustDuctRound {
  length: number;
  diameter: number;
  buildUp: BuildUp;
}

export interface ExhaustHood {
  length: number;
  width: number;
}

export interface ExhaustHoodSystem {
  exhaustHood: Component;
  aerosolSeparator: Component;
  aluminiumMeshGreaseFilter: Component;
  lighting: Component;
  dummyPlate: Component;
}

export interface ExtraHours {
  hours: number;
}

export interface FreeText {
  text: string;
  price: number;
}

export interface Lighting {
  count: number;
}

export interface PassiveArea {
  length: number;
  width: number;
}

export interface SupplyDuctAngular {
  length: number;
  height: number;
  width: number;
}

export interface SupplyDuctRound {
  length: number;
  diameter: number;
}

export interface VentilatedCeiling {
  length: number;
  width: number;
  construction: Construction;
}

export enum Construction {
  Open = "Open",
  Closed = "Closed",
  Heydal = "Heydal",
}

export interface VentilatedCeilingSystem {
  ventilatedCeiling: Component;
  aerosolSeparator: Component;
  lighting: Component;
  dummyPlate: Component;
}
