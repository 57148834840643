import { backendApi } from "@/services/backend/api";

import {
  Documentation,
  Item,
} from "@/services/backend/vbs/work-orders/documentation";
import { Kind } from "@/services/backend/vbs/treatments/component";
import { Filter } from "@/shared/lib/filter/filter";

const documentationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsDocumentationCreate: build.mutation<Documentation, CreateRequest>({
      query: (request) => ({
        url: `/vbs/documentation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "VbsDocumentation", id: "LIST" }],
    }),
    vbsDocumentationShow: build.query<Documentation, ShowRequest>({
      query: (request) => ({
        url: `/vbs/documentation`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsDocumentation", id: result.id }] : [],
    }),
    vbsDocumentationList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/vbs/documentation/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsDocumentation", id: "LIST" }] : [],
    }),
    vbsDocumentationAddItem: build.mutation<Documentation, AddItemRequest>({
      query: (request) => ({
        url: `/vbs/documentation/item`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsDocumentation", id: request.id },
      ],
    }),
    vbsDocumentUpdateItem: build.mutation<Documentation, UpdateItemRequest>({
      query: (request) => ({
        url: `/vbs/documentation/item`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsDocumentation", id: request.id },
      ],
    }),
    vbsDocumentationDeleteItem: build.mutation<null, DeleteItemRequest>({
      query: (request) => ({
        url: `/vbs/documentation/item`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsDocumentation", id: request.id },
      ],
    }),
    vbsDocumentationUploadImage: build.mutation<Item, UploadImageRequest>({
      query: (request) => {
        const body = new FormData();
        body.append("id", request.id);
        body.append("itemId", request.itemId);
        body.append("beforeOrAfter", request.beforeOrAfter);
        body.append("imageId", request.imageId);
        body.append("image", request.image, request.image.name);
        return {
          url: `/vbs/documentation/item/image`,
          method: "POST",
          headers: {
            //  this is needed, otherwise a 415 error is returned
            Accept: "application/json",
          },
          body,
          formData: true,
        };
      },
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsDocumentation", id: request.itemId },
      ],
    }),
    vbsDocumentationUploadImageForPosition: build.mutation<
      Item,
      UploadImageForPositionRequest
    >({
      query: (request) => {
        const body = new FormData();
        body.append("id", request.id);
        body.append("positionId", request.positionId);
        body.append("positionNumber", request.positionNumber);
        body.append("componentKind", request.componentKind);
        body.append("beforeOrAfter", request.beforeOrAfter);
        body.append("imageId", request.imageId);
        body.append("image", request.image, request.image.name);
        return {
          url: `/vbs/documentation/item/image-for-position`,
          method: "POST",
          headers: {
            //  this is needed, otherwise a 415 error is returned
            Accept: "application/json",
          },
          body,
          formData: true,
        };
      },
      invalidatesTags: (result) => [
        { type: "VbsDocumentation", id: result?.id },
      ],
    }),
    vbsDocumentationShowImage: build.query<
      { objectURL: string },
      ShowImageRequest
    >({
      query: (request) => ({
        url: `/vbs/documentation/item/image`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the Image under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
    }),
    vbsDocumentationDeleteImage: build.mutation<Item, DeleteImageRequest>({
      query: (request) => ({
        url: `/vbs/documentation/item/image`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsDocumentation", id: request.id },
      ],
    }),
    vbsDocumentationDelete: build.mutation<null, DeleteRequest>({
      query: (request) => ({
        url: `/vbs/documentation`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsDocumentation", id: request.id },
        { type: "VbsDocumentation", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useVbsDocumentationCreateMutation,
  useVbsDocumentationShowQuery,
  useVbsDocumentationListQuery,
  useVbsDocumentationAddItemMutation,
  useVbsDocumentUpdateItemMutation,
  useVbsDocumentationDeleteItemMutation,
  useVbsDocumentationUploadImageMutation,
  useVbsDocumentationUploadImageForPositionMutation,
  useVbsDocumentationShowImageQuery,
  useVbsDocumentationDeleteImageMutation,
  useVbsDocumentationDeleteMutation,
} = documentationApi;

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface ShowRequest {
  id: string;
}

export interface List {
  documentations: Documentation[];
  count: number;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface AddItemRequest {
  id: string;
  itemId: string;
}

export interface UpdateItemRequest {
  id: string;
  itemId: string;
  positionId: string;
  title: string;
  description: string;
  isInternal: boolean;
}

export interface DeleteItemRequest {
  id: string;
  itemId: string;
}

export interface UploadImageRequest {
  id: string;
  itemId: string;
  beforeOrAfter: "before" | "after";
  imageId: string;
  image: File;
}

export interface UploadImageForPositionRequest {
  id: string;
  positionId: string;
  positionNumber: string;
  componentKind: Kind;
  beforeOrAfter: "before" | "after";
  imageId: string;
  image: File;
}

export interface ShowImageRequest {
  id: string;
  itemId: string;
  imageId: string;
}

export interface DeleteImageRequest {
  id: string;
  itemId: string;
  imageId: string;
}

export interface DeleteRequest {
  id: string;
}
