export function fCleanTime(cleanTime: number): string {
  let result = cleanTime;
  if (Number.isNaN(result)) {
    result = 0;
  }
  return result.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function fPrice(price: number): string {
  let result = price;
  if (Number.isNaN(result)) {
    result = 0;
  }
  return result.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
