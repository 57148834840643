import { backendApi } from "@/services/backend/api";
import { Result } from "@/services/backend/search/search";

const searchApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    search: build.query<Result, SearchQuery>({
      query: (query) => ({
        url: "search",
        params: query,
        cache: "no-cache",
      }),
    }),
  }),
});

export const { useSearchQuery } = searchApi;

interface SearchQuery {
  query: string;
}
