import {
  Area,
  AreaList,
  CreateAreaRequest,
} from "@/services/backend/qpm/buildings/types";
import { useEffect, useState } from "react";
import { useQpmAreaAddMutation } from "@/services/backend/qpm/buildings/service";
import { v4 } from "uuid";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { ChevronsUpDown, Plus } from "lucide-react";
import t from "@/lang/lang";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/shared/components/ui/command";

export function AreaAddComponent({
  levelId,
  onAreaCreate,
}: {
  levelId: string;
  onAreaCreate: (area: Area) => void;
}) {
  const [newArea, setNewArea] = useState<string>("");
  const [createNewArea, { error, isLoading }] = useQpmAreaAddMutation();

  const handleInputChange = (event: { target: { value: string } }) => {
    const name = event.target.value;
    setNewArea(name);
  };

  const handleSubmit = () => {
    if (!newArea) {
      return;
    }

    const newAreaRequest: CreateAreaRequest = {
      id: v4(),
      name: newArea,
      levelId,
    };
    createNewArea(newAreaRequest)
      .unwrap()
      .then((response) => {
        onAreaCreate(response);
        setNewArea("");
      });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>An error occurred while adding the new area!</div>;
  }

  return (
    <div className="flex gap-2">
      <Input
        type="text"
        placeholder="Bereich hinzufügen..."
        value={newArea}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <Button onClick={handleSubmit}>
        <Plus />
      </Button>
    </div>
  );
}

export type AreaSelectorComboBoxProps = {
  areas?: AreaList;
  onAreaSelect: (area: Area) => void;
  levelId?: string;
  onAreaCreate: (area: Area) => void;
};

export function AreaSelectorComboBox({
  areas,
  onAreaSelect,
  levelId,
  onAreaCreate,
}: AreaSelectorComboBoxProps) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const [newAreaName, setNewAreaName] = useState<string>("");
  const [createNewArea, { error: errorCreateArea, reset, data }] =
    useQpmAreaAddMutation();

  useEffect(() => {
    if (errorCreateArea) {
      toast({ ...parseRTKQueryError(errorCreateArea) });
      reset();
    }
  }, [errorCreateArea, reset, toast]);

  useEffect(() => {
    if (data && onAreaCreate) {
      setNewAreaName("");
      setOpen(false);
      onAreaCreate(data);
      reset();
    }
  }, [data, onAreaCreate, reset]);

  const handleInputChange = (value: string) => {
    setNewAreaName(value);
  };

  const handleSubmit = () => {
    if (!newAreaName || !levelId) {
      return;
    }

    const newAreaRequest: CreateAreaRequest = {
      id: v4(),
      name: newAreaName,
      levelId,
    };
    createNewArea(newAreaRequest);
  };

  const handleSelect = (area: Area) => {
    onAreaSelect(area);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={!levelId || areas === undefined}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[250px] justify-between"
        >
          {t("Bereich wählen oder anlegen")}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0" side="bottom" align="start">
        <Command>
          <CommandInput
            onValueChange={handleInputChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
            placeholder={t("Suchen oder Anlegen...")}
          />
          <CommandList>
            <CommandEmpty>
              <div className="flex flex-col gap-3 px-6 text-sm">
                <span>{t("Bereich nicht vorhanden.")}</span>
                <Button onClick={handleSubmit} variant="default">
                  {t("Hinzufügen")}
                </Button>
              </div>
            </CommandEmpty>
            <CommandGroup heading={t("Existierende Bereiche")}>
              {areas &&
                areas.map((area) => (
                  <CommandItem
                    onSelect={() => handleSelect(area)}
                    key={area.base.id}
                  >
                    {area.base.name}
                  </CommandItem>
                ))}
            </CommandGroup>
            <CommandSeparator />
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
