import { v4 } from "uuid";
import t from "@/lang/lang";
import { useVbsCertificateCreateMutation } from "@/services/backend/vbs/certificates/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useEffect } from "react";
import { Button } from "@/shared/components/ui/button";
import { Plus, RefreshCw } from "lucide-react";

export function NewCertificateButton(props: { processId: string }) {
  const { processId } = props;
  const [createCertificate, { isLoading, error, reset }] =
    useVbsCertificateCreateMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (isLoading) {
      return;
    }

    createCertificate({
      id: v4(),
      processId,
    });
  };

  return (
    <Button onClick={onClick} className="space-x-2" disabled={isLoading}>
      {isLoading ? (
        <RefreshCw className="h-5 w-5 animate-spin" />
      ) : (
        <Plus className="h-5 w-5" />
      )}
      <span>{t("Neues Zertifikat")}</span>
    </Button>
  );
}
