import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import t from "@/lang/lang";
import {
  AhuComponentCatalog,
  Item,
} from "@/services/backend/htz/ahu/ahu-component-catalog";
import { DeleteAhuComponentCatalogItemDialog } from "@/routes/base-data/processes/htz/ahu-component-catalogs/[ahuComponentCatalogId]/_components/delete-ahu-component-catalog-item-dialog";
import { PutAhuComponentCatalogItemDialog } from "@/routes/base-data/processes/htz/ahu-component-catalogs/[ahuComponentCatalogId]/_components/put-ahu-component-catalog-item-dialog";

export function AhuComponentCatalogItemTable({
  catalog,
}: {
  catalog: AhuComponentCatalog;
}) {
  const table = useDataTableController(tableColumns, catalog.items);

  return (
    <DataTableContainer>
      <div className="border-b">
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </div>
      <div className="px-4 pb-2">
        <DataTableBrowserPagination table={table} />
      </div>
    </DataTableContainer>
  );
}

const tableColumns: ColumnDef<Item>[] = [
  {
    header: t("Name"),
    accessorKey: "name",
    cell: ({ row }) => <span className="">{row.original.name}</span>,
  },
  {
    header: t("Details"),
    accessorKey: "details",
  },
  {
    header: t("Gruppe"),
    accessorKey: "group",
  },
  {
    header: t("Piktogramm"),
    id: "img",
    cell: ({ row }) => (
      <img
        src={`data:image/svg+xml;base64,${row.original.img}`}
        alt={t("Piktogramm")}
        className="h-8"
      />
    ),
  },
  {
    header: t("Größenfaktor"),
    accessorKey: "sizeFactor",
    cell: ({ row }) => (
      <div className="text-right">{row.original.sizeFactor}</div>
    ),
  },
  {
    header: t("Aktionen"),
    id: "actions",
    cell: ({ row }) => (
      <div className="flex items-center space-x-2" key={row.original.id}>
        <PutAhuComponentCatalogItemDialog
          catalogId={row.original.catalogId}
          item={row.original}
        />
        <DeleteAhuComponentCatalogItemDialog
          catalogId={row.original.catalogId}
          positionId={row.original.id}
        />
      </div>
    ),
  },
];
