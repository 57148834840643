import { WorkOrder } from "@/services/backend/htz/work-order/work-order";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import { StructuralAssessmentCatalog } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { VisualAssessmentCatalog } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { Offer } from "@/services/backend/htz/offers/offer";
import { Order } from "@/services/backend/htz/orders/order";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";
import { useHtzWorkOrderShowQuery } from "@/services/backend/htz/work-order/service";
import {
  useHtzPositionCatalogShowActiveCatalogQuery,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import {
  useHtzAhuComponentCatalogShowActiveCatalogQuery,
  useHtzAhuComponentCatalogShowQuery,
} from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import {
  useHtzStructuralAssessmentCatalogShowActiveCatalogQuery,
  useHtzStructuralAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog-service";
import {
  useHtzVisualAssessmentCatalogShowActiveCatalogQuery,
  useHtzVisualAssessmentCatalogShowQuery,
} from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog-service";
import { useHtzAirHandlingUnitListQuery } from "@/services/backend/htz/ahu/service";
import { useHtzOrderListQuery } from "@/services/backend/htz/orders/service";
import { useHtzOfferListQuery } from "@/services/backend/htz/offers/service";
import { Laboratory } from "@/services/backend/samples/lab/laboratory";
import { Device } from "@/services/backend/samples/device/device";
import { useSamplesLaboratoryListQuery } from "@/services/backend/samples/lab/service";
import { useSamplesDeviceListQuery } from "@/services/backend/samples/device/service";

export interface WorkOrderContextProviderDependencies {
  isLoading: boolean;
  error: unknown;
  workOrder: WorkOrder | undefined;
  positionCatalog: PositionCatalog | undefined;
  ahuComponentCatalog: AhuComponentCatalog | undefined;
  structuralAssessmentCatalog: StructuralAssessmentCatalog | undefined;
  visualAssessmentCatalog: VisualAssessmentCatalog | undefined;
  customerAhus: AirHandlingUnit[] | undefined;
  processOffers: Offer[] | undefined;
  processOrders: Order[] | undefined;
  laboratories: Laboratory[] | undefined;
  devices: Device[] | undefined;
}

export function useWorkOrderContextProviderDependencies(
  workOrderId: string,
): WorkOrderContextProviderDependencies {
  const {
    data: workOrder,
    isLoading: workOrderIsLoading,
    error: workOrderError,
  } = useHtzWorkOrderShowQuery({ id: workOrderId });

  const {
    data: activePositionCatalog,
    isLoading: activePositionCatalogIsLoading,
    error: activePositionCatalogError,
  } = useHtzPositionCatalogShowActiveCatalogQuery(
    {
      companyId: workOrder?.companyId ?? "",
    },
    {
      skip: workOrder === undefined,
    },
  );

  const {
    data: positionCatalog,
    isLoading: positionCatalogIsLoading,
    error: positionCatalogError,
  } = useHtzPositionCatalogShowQuery(
    {
      id: activePositionCatalog?.catalogId ?? "",
    },
    {
      skip:
        activePositionCatalog === undefined ||
        activePositionCatalog?.catalogId === null,
    },
  );

  const {
    data: activeAhuComponentCatalog,
    isLoading: activeAhuComponentCatalogIsLoading,
    error: activeAhuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowActiveCatalogQuery({});

  const {
    data: ahuComponentCatalog,
    isLoading: ahuComponentCatalogIsLoading,
    error: ahuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowQuery(
    {
      id: activeAhuComponentCatalog?.catalogId ?? "",
    },
    {
      skip:
        activeAhuComponentCatalog === undefined ||
        activeAhuComponentCatalog?.catalogId === null,
    },
  );

  const {
    data: activeSACatalog,
    isLoading: activeSACatalogIsLoading,
    error: activeSACatalogError,
  } = useHtzStructuralAssessmentCatalogShowActiveCatalogQuery({});

  const {
    data: structuralAssessmentCatalog,
    isLoading: structuralAssessmentCatalogIsLoading,
    error: structuralAssessmentCatalogError,
  } = useHtzStructuralAssessmentCatalogShowQuery(
    {
      id: activeSACatalog?.catalogId ?? "",
    },
    {
      skip:
        activeSACatalog === undefined || activeSACatalog?.catalogId === null,
    },
  );

  const {
    data: activeVACatalog,
    isLoading: activeVACatalogIsLoading,
    error: activeVACatalogError,
  } = useHtzVisualAssessmentCatalogShowActiveCatalogQuery({});

  const {
    data: visualAssessmentCatalog,
    isLoading: visualAssessmentCatalogIsLoading,
    error: visualAssessmentCatalogError,
  } = useHtzVisualAssessmentCatalogShowQuery(
    {
      id: activeVACatalog?.catalogId ?? "",
    },
    {
      skip:
        activeVACatalog === undefined || activeVACatalog?.catalogId === null,
    },
  );

  const {
    data: ahuList,
    isLoading: ahuIsLoading,
    error: ahuError,
  } = useHtzAirHandlingUnitListQuery(
    {
      customer: {
        active: workOrder !== undefined,
        values: [workOrder?.customerId ?? ""],
      },
    },
    {
      skip: workOrder === undefined,
    },
  );
  const customerAhus = ahuList?.airHandlingUnits ?? [];

  const {
    data: orderList,
    isLoading: orderIsLoading,
    error: orderError,
  } = useHtzOrderListQuery(
    {
      process: {
        active: true,
        values: [workOrder?.processId ?? ""],
      },
      immutable: {
        active: false,
        values: [true],
      },
    },
    {
      skip: workOrder === undefined,
    },
  );
  const processOrders = orderList?.orders ?? [];

  const {
    data: offerList,
    isLoading: offerIsLoading,
    error: offerError,
  } = useHtzOfferListQuery(
    {
      process: {
        active: true,
        values: [workOrder?.processId ?? ""],
      },
      immutable: {
        active: true,
        values: [true],
      },
    },
    {
      skip: workOrder === undefined,
    },
  );
  const processOffers = offerList?.offers ?? [];

  const {
    data: laboratoryList,
    isLoading: laboratoryIsLoading,
    error: laboratoryError,
  } = useSamplesLaboratoryListQuery({});
  const laboratories = laboratoryList?.labs ?? [];

  const {
    data: deviceList,
    isLoading: deviceIsLoading,
    error: deviceError,
  } = useSamplesDeviceListQuery({});
  const devices = deviceList?.devices ?? [];

  const isLoading =
    workOrderIsLoading ||
    activePositionCatalogIsLoading ||
    positionCatalogIsLoading ||
    activeAhuComponentCatalogIsLoading ||
    ahuComponentCatalogIsLoading ||
    activeSACatalogIsLoading ||
    structuralAssessmentCatalogIsLoading ||
    activeVACatalogIsLoading ||
    visualAssessmentCatalogIsLoading ||
    ahuIsLoading ||
    orderIsLoading ||
    offerIsLoading ||
    laboratoryIsLoading ||
    deviceIsLoading;

  const error = (() => {
    switch (true) {
      case workOrderError !== undefined:
        return workOrderError;
      case activePositionCatalogError !== undefined:
        return activePositionCatalogError;
      case positionCatalogError !== undefined:
        return positionCatalogError;
      case activeAhuComponentCatalogError !== undefined:
        return activeAhuComponentCatalogError;
      case ahuComponentCatalogError !== undefined:
        return ahuComponentCatalogError;
      case activeSACatalogError !== undefined:
        return activeSACatalogError;
      case structuralAssessmentCatalogError !== undefined:
        return structuralAssessmentCatalogError;
      case activeVACatalogError !== undefined:
        return activeVACatalogError;
      case visualAssessmentCatalogError !== undefined:
        return visualAssessmentCatalogError;
      case ahuError !== undefined:
        return ahuError;
      case orderError !== undefined:
        return orderError;
      case offerError !== undefined:
        return offerError;
      case laboratoryError !== undefined:
        return laboratoryError;
      case deviceError !== undefined:
        return deviceError;
      case activePositionCatalog?.catalogId === null:
        // fake 404 error
        return {
          status: 404,
        };
      default:
        return undefined;
    }
  })();

  return {
    workOrder,
    positionCatalog,
    ahuComponentCatalog,
    structuralAssessmentCatalog,
    visualAssessmentCatalog,
    customerAhus,
    processOffers,
    processOrders,
    laboratories,
    devices,
    isLoading,
    error,
  };
}
