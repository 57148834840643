import {
  ALL_COLUMNS,
  Column,
} from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { Role } from "@/shared/lib/authorization/roles";

export function useFilterAvailableColumnsForRole(columns: Column[]): Column[] {
  const { user } = useAuth();

  if (!user) {
    return [];
  }

  const roles = user.roles ?? [];

  const columnsForRoles = roles
    .map((role) => roleColumnsMap[role as Role])
    .reduce((acc, val) => {
      val?.forEach((column) => {
        if (!acc.includes(column)) {
          acc.push(column);
        }
      });
      return acc;
    }, []);

  return columns.filter((column) => columnsForRoles.includes(column));
}

export const roleColumnsMap: Record<Role, Column[]> = {
  Admin: ALL_COLUMNS,
  Director: ALL_COLUMNS,
  BranchManager: [
    Column.Position,
    Column.Completed,
    Column.AssignedWorkOrder,
    Column.ComponentKind,
    Column.ComponentData,
    Column.Cycle,
    Column.CleanTime,
    Column.BasePrice,
    Column.TipCommission,
    Column.OfferPrice,
    Column.CycleDiscount,
    Column.ContractPrice,
    Column.Comment,
    Column.Actions,
  ],
};
