import { LevelList } from "@/services/backend/qpm/buildings/types";
import { LevelSegmentCollapsible } from "@/routes/gemex/processes/qpm/building/_components/level-segment-collapsible";
import { AddLevelComponent } from "@/routes/gemex/processes/qpm/shared/components/add-level-component";
import t from "@/lang/lang";

export type BuildingLevelsComponentProps = {
  buildingId: string;
  levelList: LevelList;
};

export function BuildingLevelsComponent({
  buildingId,
  levelList,
}: BuildingLevelsComponentProps) {
  return (
    <>
      {levelList &&
        levelList.map((level) => (
          <LevelSegmentCollapsible
            level={level}
            id={level.base.id}
            key={level.base.id}
            buildingId={buildingId}
          />
        ))}
      <div className="grid gap-4 p-4">
        {levelList && levelList.length <= 0 && (
          <p>{t("Es sind noch keine Ebenen vorhanden.")}</p>
        )}
        <AddLevelComponent buildingId={buildingId} />
      </div>
    </>
  );
}
