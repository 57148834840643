import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import { Card, CardHeader, CardTitle } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  Position,
  updateDescription,
  updateMicrobialInspectionRequired,
  updatePhotoDocumentationRequired,
  updateStructuralInspectionRequired,
  updateVisualInspectionRequired,
} from "@/services/backend/htz/work-order/work-order";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { AhuSelect } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/ahu-select";
import { AddPositionButton } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/add-position-button";
import { Fragment, useMemo, useState } from "react";
import { DeletePositionDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/delete-position-dialog";
import { Textarea } from "@/shared/components/ui/textarea";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, ChevronRight } from "lucide-react";
import { InspectionSheet } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-sheet";
import {
  PositionContextProvider,
  usePositionContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/position-context";
import { usePositionsContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/positions-context";

export function PositionsTable() {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Leistungen")}</span>
          <AddPositionButton />
        </CardTitle>
      </CardHeader>
      <Table>
        <PositionTableHeader />
        <PositionsTableBody />
      </Table>
    </Card>
  );
}

function PositionTableHeader() {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>{t("Pos")}</TableHead>
        <TableHead>{t("Erledigt")}</TableHead>
        <TableHead>{t("RLT-Anlage")}</TableHead>
        <TableHead>{t("Titel")}</TableHead>
        <TableHead>{t("Optional?")}</TableHead>
        <TableHead>{t("Aktionen")}</TableHead>
      </TableRow>
    </TableHeader>
  );
}

function PositionsTableBody() {
  const { airHandlingUnits } = useWorkOrderContext();
  const { positions } = usePositionsContext();

  const ahus = useMemo(
    () =>
      positions.reduce((prev, curr) => {
        if (
          !curr.airHandlingUnitId ||
          prev.some((p) => p === curr.airHandlingUnitId)
        ) {
          return prev;
        }

        return [...prev, curr.airHandlingUnitId];
      }, [] as string[]),
    [positions],
  );

  const positionsWithoutAhu = positions.filter((pos) => !pos.airHandlingUnitId);

  return (
    <TableBody>
      {ahus.map((ahuId) => (
        <Fragment key={ahuId}>
          <TableRow>
            <TableCell colSpan={6} className="h-8 bg-muted py-0 font-medium">
              {airHandlingUnits.filter((ahu) => ahu.id === ahuId)[0]?.name}
            </TableCell>
          </TableRow>
          {positions.filter(byAhuId(ahuId)).map((position) => (
            <PositionTableRowProvider key={position.id} position={position} />
          ))}
        </Fragment>
      ))}
      {positionsWithoutAhu.length > 0 && (
        <TableRow>
          <TableCell colSpan={6} className="h-8 bg-muted py-0 font-medium">
            {t("Ohne RLT-Anlage")}
          </TableCell>
        </TableRow>
      )}
      {positionsWithoutAhu.map((position) => (
        <PositionTableRowProvider key={position.id} position={position} />
      ))}
      {positions.length === 0 && (
        <TableRow>
          <TableCell colSpan={6} className="text-center">
            {t("Keine Positionen")}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

function byAhuId(ahuId: string) {
  return (a: Position) => a.airHandlingUnitId === ahuId;
}

function PositionTableRowProvider({ position }: { position: Position }) {
  // TODO, continue here,
  //  what happens when the ahu changes?
  //  -> this would have to be reflected in the positions of the work order context
  //     otherwise, the sorting in the table does not work.

  return (
    <PositionContextProvider position={position}>
      <PositionTableRow />
    </PositionContextProvider>
  );
}

function PositionTableRow() {
  const { position, onPositionChange } = usePositionContext();

  return (
    <PositionContextProvider position={position}>
      <TableRow className="border-b-0">
        <TableCell className="py-2">{position.number}</TableCell>
        <TableCell className="py-2">
          <Checkbox />
        </TableCell>
        <TableCell className="py-2">
          <AhuSelect position={position} onPositionChange={onPositionChange} />
        </TableCell>
        <TableCell className="py-2">{position.title}</TableCell>
        <TableCell className="py-2">{position.optionalOffer}</TableCell>
        <TableCell className="py-0">
          <div className="flex gap-2">
            <InspectionSheet position={position} />
            <DeletePositionDialog position={position} />
          </div>
        </TableCell>
      </TableRow>
      <PositionDetailsRow />
    </PositionContextProvider>
  );
}

function PositionDetailsRow() {
  const [open, setOpen] = useState(false);
  const { position, onPositionChange } = usePositionContext();
  const { workOrder } = useWorkOrderContext();

  const disabled = workOrder.immutable;

  return (
    <>
      <PositionDetailsRowToggle open={open} toggle={() => setOpen(!open)} />
      {open && (
        <>
          <TableRow className="border-b-0">
            <TableCell className="bg-background py-2"> </TableCell>
            <TableCell className="bg-background py-2" colSpan={8}>
              <Textarea
                value={position.description}
                onChange={(e) =>
                  onPositionChange(updateDescription(position, e.target.value))
                }
                disabled={disabled}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="bg-background py-2"> </TableCell>
            <TableCell className="bg-background pt-2" colSpan={8}>
              <div className="grid grid-cols-2 gap-2">
                <InspectionRequiredCheckbox
                  label={t("Konstruktive Beurteilung nötig")}
                  checked={position.structuralInspectionRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updateStructuralInspectionRequired(position, value),
                    )
                  }
                  disabled={workOrder.immutable}
                />
                <InspectionRequiredCheckbox
                  label={t("Visuelle Beurteilung nötig")}
                  checked={position.visualInspectionRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updateVisualInspectionRequired(position, value),
                    )
                  }
                  disabled={workOrder.immutable}
                />
                <InspectionRequiredCheckbox
                  label={t("Mikrobiologische Inspektion nötig")}
                  checked={position.microbialInspectionRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updateMicrobialInspectionRequired(position, value),
                    )
                  }
                  disabled={workOrder.immutable}
                />
                <InspectionRequiredCheckbox
                  label={t("Fotodokumentation nötig")}
                  checked={position.photoDocumentationRequired}
                  onCheckedChange={(value) =>
                    onPositionChange(
                      updatePhotoDocumentationRequired(position, value),
                    )
                  }
                  disabled={workOrder.immutable}
                />
              </div>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

function PositionDetailsRowToggle({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}) {
  return (
    <TableRow className={open ? "border-b-0" : ""}>
      <TableCell className="h-6 bg-background py-0 pb-1"> </TableCell>
      <TableCell colSpan={8} className="h-6 bg-background py-0 pb-1">
        <Button className="h-6 px-1.5" variant="ghost" onClick={toggle}>
          {open ? (
            <ChevronDown className="mr-2 h-4 w-4" />
          ) : (
            <ChevronRight className="mr-2 h-4 w-4" />
          )}
          <span>{t("Details")}</span>
        </Button>
      </TableCell>
    </TableRow>
  );
}

interface InspectionRequiredCheckboxProps {
  label: string;
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  disabled?: boolean;
}

export function InspectionRequiredCheckbox({
  label,
  checked,
  onCheckedChange,
  disabled,
}: InspectionRequiredCheckboxProps) {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={label}
        checked={checked}
        onCheckedChange={(state) => {
          switch (state) {
            case true:
            case false:
              onCheckedChange(state);
              break;
            default:
          }
        }}
        disabled={disabled}
      />
      <label
        htmlFor={label}
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
  );
}
