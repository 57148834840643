import {
  CreateLevelRequest,
  Level,
} from "@/services/backend/qpm/buildings/types";
import { useEffect, useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { ChevronsUpDown } from "lucide-react";
import t from "@/lang/lang";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/shared/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { useToast } from "@/shared/hooks/use-toast";
import { useQpmLevelAddMutation } from "@/services/backend/qpm/buildings/service";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";
import { Label } from "@/shared/components/ui/label";

type LevelSelectorProps = {
  levels?: Level[];
  selectedLevel: Level | undefined;
  handleLevelSelect: (value: string) => void;
  buildingId?: string;
  onLevelCreatedCallback?: (level: Level) => void;
};

export function LevelSelector({
  levels = [],
  selectedLevel,
  handleLevelSelect,
  buildingId = "",
  onLevelCreatedCallback = undefined,
}: LevelSelectorProps) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const [newLevelName, setNewLevelName] = useState<string>("");
  const [createNewLevel, { error: createLevelError, reset, data }] =
    useQpmLevelAddMutation();

  useEffect(() => {
    if (createLevelError) {
      toast({ ...parseRTKQueryError(createLevelError) });
      reset();
    }
  }, [createLevelError, reset, toast]);

  useEffect(() => {
    if (data && onLevelCreatedCallback) {
      setNewLevelName("");
      setOpen(false);
      onLevelCreatedCallback(data);
      reset();
    }
  }, [data, onLevelCreatedCallback, reset]);

  const handleInputChange = (value: string) => {
    setNewLevelName(value);
  };

  const handleSubmit = () => {
    if (!newLevelName || !buildingId) {
      return;
    }
    const newLevelRequest: CreateLevelRequest = {
      id: v4(),
      name: newLevelName.trim(),
      buildingId,
    };
    createNewLevel(newLevelRequest);
  };

  const handleSelect = (levelId: string) => {
    handleLevelSelect(levelId);
    setOpen(false);
  };

  return (
    <div className="grid items-start gap-1.5">
      <Label html-for="level-selctor">{t("Ebene")}</Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="level-selctor"
            disabled={!buildingId || levels === undefined}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[250px] justify-between"
          >
            {selectedLevel?.base.name || t("Ebene auswählen...")}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-0" side="bottom" align="start">
          <Command>
            <CommandInput
              onValueChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
              placeholder={t("Neue Ebene anlegen...")}
            />
            <CommandList>
              <CommandEmpty>
                <div className="flex flex-col gap-3 px-6 text-sm">
                  <span>{t("Ebene nicht vorhanden.")}</span>
                  <Button onClick={handleSubmit} variant="default">
                    {t("Hinzufügen")}
                  </Button>
                </div>
              </CommandEmpty>
              <CommandGroup heading={t("Existierende Ebenen")}>
                {levels &&
                  levels.map((level) => (
                    <CommandItem
                      onSelect={() => handleSelect(level.base.id)}
                      key={level.base.id}
                    >
                      {level.base.name}
                    </CommandItem>
                  ))}
              </CommandGroup>
              <CommandSeparator />
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
