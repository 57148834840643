import { PropsWithChildren } from "react";
import { DevAuthContextProvider } from "./dev-context-provider";
import { MsalAuthContextProvider } from "./msal-context-provider";

/**
 * AuthProvider should be used at the top-level of the application.
 * Everything that requires some sort of authentication protection
 * and wants to use the useAuth hook with the predefined functionality
 * must be a child.
 *
 * @param props
 * @constructor
 */
export function AuthProvider(props: PropsWithChildren) {
  const { children } = props;

  switch (import.meta.env.VITE_AUTH) {
    case "dev":
      return <DevAuthContextProvider>{children}</DevAuthContextProvider>;
    default:
      return <MsalAuthContextProvider>{children}</MsalAuthContextProvider>;
  }
}
