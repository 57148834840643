import {
  sortCompanies,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export interface CompanySelectProps {
  companyId: string;
  onCompanyIdChange: (companyId: string) => void;
}

export function CompanySelect({
  companyId,
  onCompanyIdChange,
}: CompanySelectProps) {
  const { data, isLoading, error } = useListCompanyQuery({});
  const companies = sortCompanies(data?.companies ?? [], "name");

  if (isLoading) {
    return (
      <Select disabled>
        <SelectTrigger className="animate-pulse">
          <SelectValue placeholder={t("Unternehmen auswählen")} />
        </SelectTrigger>
      </Select>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Select value={companyId} onValueChange={onCompanyIdChange}>
      <SelectTrigger>
        <SelectValue placeholder={t("Unternehmen auswählen")} />
      </SelectTrigger>
      <SelectContent>
        {companies.map((company) => (
          <SelectItem value={company.id} key={company.id}>
            {company.shortName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
