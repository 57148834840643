import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { ProcessNumber } from "@/shared/components/domain/numbers/process-number";
import { CustomerName } from "@/shared/components/domain/customer/customer-name";
import { ProcessTopic } from "@/shared/components/domain/process-topic/process-topic";

export function ProcessInformation({ processId }: { processId: string }) {
  const {
    data: process,
    isLoading,
    error,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton className="h-6 w-1/2" />;
  }

  return (
    <span className="flex h-6 space-x-2 font-bold">
      <ProcessNumber processId={process?.id ?? ""} />
      <span>-</span>
      <CustomerName customerId={process?.customerId ?? ""} />
      <span>-</span>
      <ProcessTopic processId={process?.id ?? ""} />
    </span>
  );
}
