import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import t from "../../../../lang/lang";
import { BranchMatching } from "./branch-matches";
import { ProcessKindMatching } from "./process-kind-matches";
import { CompanyMatching } from "./company-matches";

export default function MatchesRoute() {
  return (
    <BaseDataPage>
      <BaseDataPageHeader>
        <BaseDataPageTitle>{t("Verknüpfungen")}</BaseDataPageTitle>
        <BaseDataPageDescription>
          {t(`
            Hier können die notwendigen Verknüpfungen zwischen Entitäten des GSM und MS Dynamics vorgenommen werden.
            Im generellen gilt, dass ohne eine Verknüpfung keine entsprechend Daten zwischen den Systemen ausgetauscht 
            werden können.
            `)}
        </BaseDataPageDescription>
      </BaseDataPageHeader>
      <BaseDataPageContent className="space-y-8">
        <CompanyMatching />
        <BranchMatching />
        <ProcessKindMatching />
      </BaseDataPageContent>
    </BaseDataPage>
  );
}
