import React, { useEffect, useState } from "react";
import { Input } from "@/shared/components/ui/input";

export function PropertyFields(props: {
  value: number;
  displayUnit: string;
  step: string;
  onValueChange: (value: number) => void;
}) {
  const { value, displayUnit, step, onValueChange } = props;
  const [selectedValue, setSelectedValue] = useState<number>(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(Number(e.target.value));
    onValueChange(Number(e.target.value));
  };

  return (
    <div className="flex items-center gap-2 p-1">
      <input
        type="number"
        step={step}
        min="0"
        className="w-12 rounded-sm border p-1 text-end"
        value={selectedValue || 0}
        onChange={handleChange}
      />
      <span>{displayUnit}</span>
    </div>
  );
}

type TimePropertyFieldProps = {
  displayValue: number | undefined;
  displayUnit: string;
  onValueChange: (value: number) => void;
  immutable: boolean;
};

// Utility function to convert seconds to hh:mm:ss
const formatTime = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

// Utility function to parse hh:mm:ss to seconds
const parseTime = (timeString: string) => {
  const [hrs, mins, secs] = timeString.split(":").map(Number);

  return (hrs || 0) * 3600 + (mins || 0) * 60 + (secs || 0);
};

export function PropertyTimeField({
  displayValue,
  displayUnit,
  onValueChange,
  immutable = false,
}: TimePropertyFieldProps) {
  const [formattedValue, setFormattedValue] = useState(
    formatTime(displayValue || 0),
  );

  useEffect(() => {
    setFormattedValue(formatTime(displayValue || 0));
  }, [displayValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timeInSeconds = parseTime(e.target.value);
    onValueChange(timeInSeconds);
    setFormattedValue(e.target.value);
  };

  return (
    <div className="flex items-center gap-2 p-1">
      <Input
        type="time"
        min={0}
        step={1}
        className="rounded-sm border px-1 disabled:bg-muted disabled:opacity-100"
        value={formattedValue}
        onChange={handleChange}
        disabled={immutable}
      />
      <span>{displayUnit}</span>
    </div>
  );
}
