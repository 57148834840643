import { Severity } from "@/services/backend/htz/inspection/severity";

export interface StructuralAssessmentCatalog {
  id: string;
  name: string;
  items: Item[];
}

export function addItem(
  catalog: StructuralAssessmentCatalog,
  item: Item,
): StructuralAssessmentCatalog {
  return {
    ...catalog,
    items: [...catalog.items, item],
  };
}

export function updateItem(
  catalog: StructuralAssessmentCatalog,
  item: Item,
): StructuralAssessmentCatalog {
  return {
    ...catalog,
    items: catalog.items.map((i) => {
      if (i.id === item.id) {
        return item;
      }
      return i;
    }),
  };
}

export function deleteItem(
  catalog: StructuralAssessmentCatalog,
  itemId: string,
): StructuralAssessmentCatalog {
  return {
    ...catalog,
    items: catalog.items.filter((item) => item.id !== itemId),
  };
}

export function itemsForComponentKind(
  catalog: StructuralAssessmentCatalog,
  componentKindId: string,
): Item[] {
  return [
    ...catalog.items.filter((item) =>
      item.componentKinds.some((ck) => ck === componentKindId),
    ),
  ];
}

export interface Item {
  id: string;
  catalogId: string;
  componentKinds: string[];
  element: string;
  shorthand: string;
  severity: Severity;
  requirement: string;
  deficiency: string;
  recommendation: string;
}
