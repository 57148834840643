import { backendApi } from "@/services/backend/api";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";

const offerApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzAirHandlingUnit"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzAirHandlingUnitPut: build.mutation<AirHandlingUnit, PutRequest>({
        query: (request) => ({
          url: `/htz/air-handling-unit`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzAirHandlingUnit", id: request.id },
          "HtzAirHandlingUnit",
        ],
      }),
      htzAirHandlingUnitShow: build.query<AirHandlingUnit, ShowRequest>({
        query: (request) => ({
          url: `/htz/air-handling-unit`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzAirHandlingUnit"),
      }),
      htzAirHandlingUnitList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/air-handling-unit/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.airHandlingUnits, "HtzAirHandlingUnit"),
      }),
      htzAirHandlingUnitDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/air-handling-unit`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzAirHandlingUnit"],
      }),
    }),
  });

export const {
  useHtzAirHandlingUnitPutMutation,
  useHtzAirHandlingUnitShowQuery,
  useHtzAirHandlingUnitListQuery,
  useHtzAirHandlingUnitDeleteMutation,
} = offerApi;

export interface PutRequest extends AirHandlingUnit {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  airHandlingUnits: AirHandlingUnit[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
