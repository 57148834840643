import { Position } from "@/services/backend/rlt/treatments/position";
import { H4 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import {
  AccessPanel,
  AirHandlingUnit,
  AirHandlingUnitSchwerpunkt,
  AirHandlingUnitSystem,
  AwayAirDuctAngular,
  AwayAirDuctRound,
  CleaningKind,
  CrossFlowHeatExchanger,
  ExhaustDuctAngular,
  ExhaustDuctRound,
  ExtraHours,
  FreeText,
  Kind,
  OutdoorAirDuctAngular,
  OutdoorAirDuctRound,
  RecirculatingSprayHumidifier,
  RotaryHeatExchanger,
  SplitUnit,
  SupplyDuctAngular,
  SupplyDuctRound,
} from "@/services/backend/rlt/treatments/component";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  UpdateActuallyTreatedRequest,
  useRltTreatmentUpdateActuallyTreatedMutation,
} from "@/services/backend/rlt/treatments/service";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function ComponentData({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  return (
    <div>
      <div>
        <H4>{t("Basisdaten")}</H4>
        <div className="mb-2 text-sm text-muted-foreground">
          {t(
            "Wenn notwendig können hier Abweichungen von von den beauftragten Arbeiten erfasst werden.",
          )}
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="h-10">{t("Komponente")}</TableHead>
              <TableHead className="h-10">{t("Laut Aufnahme")}</TableHead>
              <TableHead className="h-10">
                {t("Tatsächlich erledigt")}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Data treatmentId={treatmentId} position={position} />
            {[...(position.children ?? [])]
              .sort(byKindTranslation)
              .map((child) => (
                <Data
                  key={child.id}
                  treatmentId={treatmentId}
                  position={child}
                />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

function byKindTranslation(a: Position, b: Position) {
  const aKind = t(a.component.kind);
  const bKind = t(b.component.kind);
  return aKind.localeCompare(bKind);
}

function Data({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [request, setRequest] = useState<UpdateActuallyTreatedRequest>({
    id: treatmentId,
    positionId: position.id,
    component: position.actuallyTreated,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentUpdateActuallyTreatedMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  switch (position.component.kind) {
    case Kind.AccessPanel:
      return (
        <AccessPanelRow
          component={position.component as AccessPanel}
          actual={request.component as AccessPanel}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.AirHandlingUnit:
      return (
        <AirHandlingUnitRow
          component={position.component as AirHandlingUnit}
          actual={request.component as AirHandlingUnit}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.AirHandlingUnitSchwerpunkt:
      return (
        <AirHandlingUnitSchwerpunktRow
          component={position.component as AirHandlingUnitSchwerpunkt}
          actual={request.component as AirHandlingUnitSchwerpunkt}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.AirHandlingUnitSystem:
      return (
        <AirHandlingUnitSystemRow
          component={position.component as AirHandlingUnitSystem}
          actual={request.component as AirHandlingUnitSystem}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.AwayAirDuctAngular:
      return (
        <AwayAirDuctAngularRow
          component={position.component as AwayAirDuctAngular}
          actual={request.component as AwayAirDuctAngular}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.AwayAirDuctRound:
      return (
        <AwayAirDuctRoundRow
          component={position.component as AwayAirDuctRound}
          actual={request.component as AwayAirDuctRound}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.CrossFlowHeatExchanger:
      return (
        <CrossFlowHeatExchangerRow
          component={position.component as CrossFlowHeatExchanger}
          actual={request.component as CrossFlowHeatExchanger}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.ExhaustDuctAngular:
      return (
        <ExhaustDuctAngularRow
          component={position.component as ExhaustDuctAngular}
          actual={request.component as ExhaustDuctAngular}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.ExhaustDuctRound:
      return (
        <ExhaustDuctRoundRow
          component={position.component as ExhaustDuctRound}
          actual={request.component as ExhaustDuctRound}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.ExtraHours:
      return (
        <ExtaHoursRow
          component={position.component as ExtraHours}
          actual={request.component as ExtraHours}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.FreeText:
      return (
        <FreeTextRow
          component={position.component as FreeText}
          actual={request.component as FreeText}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.OutdoorAirDuctAngular:
      return (
        <OutdoorAirDuctAngularRow
          component={position.component as OutdoorAirDuctAngular}
          actual={request.component as OutdoorAirDuctAngular}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.OutdoorAirDuctRound:
      return (
        <OutdoorAirDuctRoundRow
          component={position.component as OutdoorAirDuctRound}
          actual={request.component as OutdoorAirDuctRound}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.RecirculatingSprayHumidifier:
      return (
        <RecirculatingSprayHumidifierRow
          component={position.component as RecirculatingSprayHumidifier}
          actual={request.component as RecirculatingSprayHumidifier}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.RotaryHeatExchanger:
      return (
        <RotaryHeatExchangerRow
          component={position.component as RotaryHeatExchanger}
          actual={request.component as RotaryHeatExchanger}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.SplitUnit:
      return (
        <SplitUnitRow
          component={position.component as SplitUnit}
          actual={request.component as SplitUnit}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.SupplyDuctAngular:
      return (
        <SupplyDuctAngularRow
          component={position.component as SupplyDuctAngular}
          actual={request.component as SupplyDuctAngular}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    case Kind.SupplyDuctRound:
      return (
        <SupplyDuctRoundRow
          component={position.component as SupplyDuctRound}
          actual={request.component as SupplyDuctRound}
          onActualChange={(component) => {
            setRequest((prev) => ({
              ...prev,
              component,
            }));
          }}
        />
      );
    default:
      return <div />;
  }
}

function AccessPanelRow({
  component,
  actual,
  onActualChange,
}: {
  component: AccessPanel;
  actual: AccessPanel;
  onActualChange: (component: AccessPanel) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AccessPanel)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={component.data.count}
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.data.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...component.data,
                  count: Number(e.target.value) || 0,
                },
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AirHandlingUnitRow({
  component,
  actual,
  onActualChange,
}: {
  component: AirHandlingUnit;
  actual: AirHandlingUnit;
  onActualChange: (component: AirHandlingUnit) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AirHandlingUnit)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={component.data.length}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.width}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.height}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={actual.data.length}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  length: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.width}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  width: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.height}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  height: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AirHandlingUnitSchwerpunktRow({
  component,
  actual,
  onActualChange,
}: {
  component: AirHandlingUnitSchwerpunkt;
  actual: AirHandlingUnitSchwerpunkt;
  onActualChange: (component: AirHandlingUnitSchwerpunkt) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">
        {t(Kind.AirHandlingUnitSchwerpunkt)}
      </TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={component.data.cleanTime}
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={actual.data.cleanTime}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  cleanTime: Number(e.target.value) || 0,
                },
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AirHandlingUnitSystemRow({
  component,
  actual,
  onActualChange,
}: {
  component: AirHandlingUnitSystem;
  actual: AirHandlingUnitSystem;
  onActualChange: (component: AirHandlingUnitSystem) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-2 align-top">
        {t(Kind.AirHandlingUnitSystem)}
      </TableCell>
      <TableCell className="py-1">
        <div className="grid items-center gap-1.5">
          <Input
            value={component.designation}
            className="h-8"
            type="text"
            placeholder={t("Anlagenbezeichnung")}
            disabled
          />
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Reinigung:")}</Label>
            <Input value={component.cleaningKind} className="h-8" disabled />
          </div>
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid items-center gap-1.5">
          <Input
            value={actual.designation}
            onChange={(e) =>
              onActualChange({
                ...actual,
                designation: e.target.value,
              })
            }
            className="h-8"
            type="text"
            placeholder={t("Anlagenbezeichnung")}
          />
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Reinigung:")}</Label>
            <CleaningKindSelect
              value={actual.cleaningKind}
              onChange={(cleaningKind) =>
                onActualChange({
                  ...actual,
                  cleaningKind,
                })
              }
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function CleaningKindSelect({
  value,
  onChange,
}: {
  value: CleaningKind;
  onChange: (e: CleaningKind) => void;
}) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="h-8">
        <SelectValue>{t(value)}</SelectValue>
      </SelectTrigger>
      <SelectContent align="end">
        <SelectGroup>
          <SelectLabel>{t("Reinigung")}</SelectLabel>
          {[CleaningKind.Service, CleaningKind.Sanierung].map((buildUp) => (
            <SelectItem value={buildUp} key={buildUp}>
              {t(buildUp)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function AwayAirDuctAngularRow({
  component,
  actual,
  onActualChange,
}: {
  component: AwayAirDuctAngular;
  actual: AwayAirDuctAngular;
  onActualChange: (component: AwayAirDuctAngular) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AwayAirDuctAngular)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={component.data.length}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.width}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.height}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={actual.data.length}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  length: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.width}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  width: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.height}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  height: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AwayAirDuctRoundRow({
  component,
  actual,
  onActualChange,
}: {
  component: AwayAirDuctRound;
  actual: AwayAirDuctRound;
  onActualChange: (component: AwayAirDuctRound) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AwayAirDuctRound)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={component.data.length}
              className="h-8"
              type="number"
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={component.data.diameter}
              className="h-8"
              type="number"
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.data.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    length: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={actual.data.diameter}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    diameter: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function CrossFlowHeatExchangerRow({
  component,
  actual,
  onActualChange,
}: {
  component: CrossFlowHeatExchanger;
  actual: CrossFlowHeatExchanger;
  onActualChange: (component: CrossFlowHeatExchanger) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.CrossFlowHeatExchanger)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-3 items-center gap-1.5">
          <Label>{t("LxB (m):")}</Label>
          <Input
            value={component.data.length}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.width}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-3 items-center gap-1.5">
          <Label>{t("LxB (m):")}</Label>
          <Input
            value={actual.data.length}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  length: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.width}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  width: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function ExhaustDuctAngularRow({
  component,
  actual,
  onActualChange,
}: {
  component: ExhaustDuctAngular;
  actual: ExhaustDuctAngular;
  onActualChange: (component: ExhaustDuctAngular) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExhaustDuctAngular)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={component.data.length}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.width}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.height}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={actual.data.length}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  length: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.width}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  width: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.height}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  height: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function ExhaustDuctRoundRow({
  component,
  actual,
  onActualChange,
}: {
  component: ExhaustDuctRound;
  actual: ExhaustDuctRound;
  onActualChange: (component: ExhaustDuctRound) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExhaustDuctRound)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={component.data.length}
              className="h-8"
              type="number"
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={component.data.diameter}
              className="h-8"
              type="number"
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.data.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    length: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={actual.data.diameter}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    diameter: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function ExtaHoursRow({
  component,
  actual,
  onActualChange,
}: {
  component: ExtraHours;
  actual: ExtraHours;
  onActualChange: (component: ExtraHours) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExtraHours)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={component.data.hours}
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={actual.data.hours}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  hours: Number(e.target.value) || 0,
                },
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function FreeTextRow({
  component,
  actual,
  onActualChange,
}: {
  component: FreeText;
  actual: FreeText;
  onActualChange: (component: FreeText) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-2 align-top">{t(Kind.FreeText)}</TableCell>
      <TableCell className="py-1">
        <div className="grid items-center gap-1.5">
          <Input
            value={component.data.text}
            className="h-8"
            type="text"
            placeholder={t("Beschreibung")}
            disabled
          />
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Euro:")}</Label>
            <Input
              value={component.data.price}
              className="h-8"
              type="number"
              placeholder={t("Euro")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid items-center gap-1.5">
          <Input
            value={actual.data.text}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  text: e.target.value,
                },
              })
            }
            className="h-8"
            type="text"
            placeholder={t("Beschreibung")}
          />
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Euro:")}</Label>
            <Input
              value={actual.data.price}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    price: Number(e.target.value) || 0,
                  },
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Euro")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function OutdoorAirDuctAngularRow({
  component,
  actual,
  onActualChange,
}: {
  component: OutdoorAirDuctAngular;
  actual: OutdoorAirDuctAngular;
  onActualChange: (component: OutdoorAirDuctAngular) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.OutdoorAirDuctAngular)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={component.data.length}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.width}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.height}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={actual.data.length}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  length: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.width}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  width: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.height}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  height: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function OutdoorAirDuctRoundRow({
  component,
  actual,
  onActualChange,
}: {
  component: OutdoorAirDuctRound;
  actual: OutdoorAirDuctRound;
  onActualChange: (component: OutdoorAirDuctRound) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.OutdoorAirDuctRound)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={component.data.length}
              className="h-8"
              type="number"
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={component.data.diameter}
              className="h-8"
              type="number"
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.data.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    length: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={actual.data.diameter}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    diameter: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function RecirculatingSprayHumidifierRow({
  component,
  actual,
  onActualChange,
}: {
  component: RecirculatingSprayHumidifier;
  actual: RecirculatingSprayHumidifier;
  onActualChange: (component: RecirculatingSprayHumidifier) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-2 align-top">
        {t(Kind.RecirculatingSprayHumidifier)}
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-3 items-center gap-1.5">
          <Label>{t("LxB (m):")}</Label>
          <Input
            value={component.data.floorLength}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.floorWidth}
            className="h-8"
            type="number"
            disabled
          />
          <Label>{t("Düsen:")}</Label>
          <Input
            value={component.data.nozzleCount}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-3 items-center gap-1.5">
          <Label>{t("LxB (m):")}</Label>
          <Input
            value={actual.data.floorLength}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  floorLength: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.floorWidth}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  floorWidth: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Label>{t("Düsen:")}</Label>
          <Input
            value={actual.data.nozzleCount}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  nozzleCount: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function RotaryHeatExchangerRow({
  component,
  actual,
  onActualChange,
}: {
  component: RotaryHeatExchanger;
  actual: RotaryHeatExchanger;
  onActualChange: (component: RotaryHeatExchanger) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.RotaryHeatExchanger)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("ø (m):")}</Label>
          <Input
            value={component.data.diameter}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("ø (m):")}</Label>
          <Input
            value={actual.data.diameter}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  diameter: Number(e.target.value) || 0,
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function SplitUnitRow({
  component,
  actual,
  onActualChange,
}: {
  component: SplitUnit;
  actual: SplitUnit;
  onActualChange: (component: SplitUnit) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.SplitUnit)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={component.data.count}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.data.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  count: Number(e.target.value) || 0,
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function SupplyDuctAngularRow({
  component,
  actual,
  onActualChange,
}: {
  component: SupplyDuctAngular;
  actual: SupplyDuctAngular;
  onActualChange: (component: SupplyDuctAngular) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.SupplyDuctAngular)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={component.data.length}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.width}
            className="h-8"
            type="number"
            disabled
          />
          <Input
            value={component.data.height}
            className="h-8"
            type="number"
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-4 items-center gap-1.5">
          <Label>{t("LxBxH (m):")}</Label>
          <Input
            value={actual.data.length}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  length: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.width}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  width: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
          <Input
            value={actual.data.height}
            onChange={(e) =>
              onActualChange({
                ...actual,
                data: {
                  ...actual.data,
                  height: Number(e.target.value),
                },
              })
            }
            className="h-8"
            type="number"
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function SupplyDuctRoundRow({
  component,
  actual,
  onActualChange,
}: {
  component: SupplyDuctRound;
  actual: SupplyDuctRound;
  onActualChange: (component: SupplyDuctRound) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.SupplyDuctRound)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={component.data.length}
              className="h-8"
              type="number"
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={component.data.diameter}
              className="h-8"
              type="number"
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.data.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    length: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("ø (m):")}</Label>
            <Input
              value={actual.data.diameter}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  data: {
                    ...actual.data,
                    diameter: Number(e.target.value),
                  },
                })
              }
              className="h-8"
              type="number"
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
