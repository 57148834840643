import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useHtzConfirmationListQuery } from "@/services/backend/htz/confirmations/service";
import { Confirmation } from "@/services/backend/htz/confirmations/confirmation";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";

export function ConfirmationTable(props: { processId: string }) {
  const { processId } = props;

  const {
    data: list,
    isLoading,
    error,
  } = useHtzConfirmationListQuery({
    process: {
      active: true,
      values: [processId],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-24">{t("Aktuelles")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-full">{t("Heruntergeladen")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Erstellt am")}</TableHead>
          <TableHead>{t("Auftragsbestätugungsdatum")}</TableHead>
          <TableHead>{t("Heruntergeladen")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.confirmations ?? [])]
          .sort(byConfirmationDate)
          .map((confirmation) => (
            <TableRow key={confirmation.id}>
              <TableCell>
                <NavLink to={`${confirmation.id}`}>
                  <ArtefactNumberById artefactId={confirmation.id} />
                </NavLink>
              </TableCell>
              <TableCell>
                {formatDate(confirmation.createdAt)},{" "}
                {formatTime(confirmation.createdAt)}
              </TableCell>
              <TableCell>
                {confirmation.date && formatDate(confirmation.date)}
              </TableCell>
              <TableCell>{confirmation.immutable ? "ja" : "nein"}</TableCell>
              <TableCell>
                <div className="flex space-x-2">
                  <NavLink to={`${confirmation.id}`}>
                    <Button variant="ghost">
                      <Search className="h-4 w-4" />
                    </Button>
                  </NavLink>
                </div>
              </TableCell>
            </TableRow>
          ))}
        {list?.confirmations?.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Auftragsbestätugunge gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function byConfirmationDate(a: Confirmation, b: Confirmation) {
  switch (true) {
    case a === null && b === null:
      return 0;
    case a === null && b !== null:
      return 1;
    case a !== null && b === null:
      return -1;
    default:
      return a.date!.localeCompare(b.date!);
  }
}
