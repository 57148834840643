import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import { NavLink, Outlet, useParams } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, PanelsTopLeft } from "lucide-react";
import { CreateConfirmationButton } from "@/routes/gesec/processes/[processId]/rlt/confirmations/_components/create-confirmation-dialog";
import { useRltConfirmationListQuery } from "@/services/backend/rlt/confirmations/service";
import { Confirmation } from "@/services/backend/rlt/confirmations/confirmation";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export default function ConfirmationsRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Auftragsbestätigungen")}</MilestonePageTitle>
        <MilestonePageActions>
          <Subnavigation />
          <CreateConfirmationButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  );
}

function Subnavigation() {
  const { processId, confirmationId } = useParams();

  const { data: list } = useRltConfirmationListQuery({
    process: {
      active: true,
      values: [processId!],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  const currentPageLabel = () => {
    if (confirmationId) {
      return <ArtefactNumberById artefactId={confirmationId} />;
    }

    return t("Übersicht");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel()}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <NavLink to="">
            <DropdownMenuItem>
              <PanelsTopLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Auftragsbestätigungen")}</DropdownMenuLabel>
        {[...(list?.confirmations ?? [])].sort(byDate).map((confirmation) => (
          <NavLink to={confirmation.id} key={confirmation.id}>
            <DropdownMenuItem>
              <ArtefactNumberById artefactId={confirmation.id} />
            </DropdownMenuItem>
          </NavLink>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byDate(a: Confirmation, b: Confirmation) {
  return a.confirmationDate.localeCompare(b.confirmationDate);
}
