import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component";
import { useRltConfirmationShowQuery } from "@/services/backend/rlt/confirmations/service";
import { Column } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";

export function ConfirmationTreatmentRoute() {
  const { confirmationId } = useParams();
  const {
    data: confirmation,
    isLoading,
    error,
  } = useRltConfirmationShowQuery({ id: confirmationId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <TreatmentComponent
      treatmentId={confirmation!.treatmentId}
      availableColumns={[
        Column.Position,
        Column.ComponentKind,
        Column.ComponentData,
        Column.Cycle,
        Column.Formula,
        Column.CleanTime,
        Column.BasePrice,
        Column.TipCommission,
        Column.OfferPrice,
        Column.Comment,
        Column.Actions,
      ]}
      defaultColumns={[
        Column.Position,
        Column.ComponentKind,
        Column.BasePrice,
        Column.TipCommission,
        Column.OfferPrice,
        Column.Comment,
        Column.Actions,
      ]}
    />
  );
}
