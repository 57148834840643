import { backendApi } from "../../api";
import { Defaults, Key } from "./defaults";

const defaultsService = backendApi.injectEndpoints({
  endpoints: (build) => ({
    showRLTDefaults: build.query<Defaults, ShowRequest>({
      query: (request) => ({
        url: `/rlt/defaults`,
        method: "GET",
        params: request,
      }),
      providesTags: (_r, _e, request) => [
        { type: "RltDefaults", id: request.key },
      ],
    }),
    putRLTDefaults: build.mutation<Defaults, PutRequest>({
      query: (request) => ({
        url: `/rlt/defaults`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltDefaults", id: request.key },
      ],
    }),
  }),
});

export const {
  useShowRLTDefaultsQuery: useRltDefaultsShowQuery,
  usePutRLTDefaultsMutation: useRltDefaultsPutMutation,
} = defaultsService;

export interface ShowRequest {
  key: Key;
}

export interface PutRequest extends Defaults {}
