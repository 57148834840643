import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useContactsProcessContactPutMutation,
  useContactsProcessContactShowQuery,
} from "@/services/backend/contacts/processcontact/service";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { ProcessContact } from "@/services/backend/contacts/processcontact/process-contact";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";

export function ContactCard({
  contact,
  onContactChange,
  title = "Kontakt",
  description = "Kontakt beim Kunden.",
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  contact: Contact;
  onContactChange: (contact: Contact) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? fieldErrors[field] ?? [] : [];
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <div className="flex items-start space-x-4">
          <a href={`mailto:${contact.email}`} rel="noreferrer">
            <Button variant="outline">{t("E-Mail")}</Button>
          </a>
          <a href={`mailto:${contact.mobilephone}`} rel="noreferrer">
            <Button variant="outline">{t("Anrufen")}</Button>
          </a>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">{t("Name")}</Label>
          <Input
            type="text"
            id="name"
            placeholder={t("Name")}
            value={contact.name}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("name");
              }
              onContactChange({ ...contact, name: e.target.value });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("name")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="mobilephone">{t("Mobil")}</Label>
          <Input
            type="text"
            id="mobilephone"
            placeholder={t("Mobil")}
            value={contact.mobilephone}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("mobilephone");
              }
              onContactChange({ ...contact, mobilephone: e.target.value });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("mobilephone")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="email">{t("E-Mail")}</Label>
          <Input
            type="text"
            id="E-Mail"
            placeholder={t("E-Mail")}
            value={contact.email}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("email");
              }
              onContactChange({ ...contact, email: e.target.value });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("email")} />
        </div>
      </CardContent>
    </Card>
  );
}

export function ServiceContactCardById({
  id,
  title = "Service Kontakt",
  description = "Kontakt des Ansprechpartners für den Service.",
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  id: string;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const {
    data: serviceContact,
    isLoading,
    error,
  } = useContactsProcessContactShowQuery({
    id,
  });

  if (isLoading) {
    return <Skeleton title={title!} description={description!} />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ServiceContactCard
      contact={serviceContact!}
      title={title}
      description={description}
      fieldErrors={fieldErrors}
      resetFieldError={resetFieldError}
    />
  );
}

function ServiceContactCard({
  contact,
  title = "Service Kontakt",
  description = "Kontakt des Ansprechpartners für den Service.",
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  contact: ProcessContact;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const [request, setRequest] = useState<ProcessContact>(contact);
  const [update, { isLoading, error, isSuccess, reset }] =
    useContactsProcessContactPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <ContactCard
      contact={contact}
      onContactChange={(c) => setRequest({ ...request, ...c })}
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
    />
  );
}

function Skeleton(props: { title: string; description: string }) {
  const { title, description } = props;
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}
