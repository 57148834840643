import { useParams } from "react-router-dom";
import { Card } from "@/shared/components/ui/card";
import { OfferTable } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-table";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { CreateOfferDialog } from "@/routes/gesec/processes/[processId]/htz/offers/_components/create-offer-dialog";

export function OfferIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePageContent>
      <MilestonePageContentMenu>
        <CreateOfferDialog processId={processId!} />
      </MilestonePageContentMenu>
      <Card>
        <OfferTable processId={processId!} />
      </Card>
    </MilestonePageContent>
  );
}
