import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import t from "@/lang/lang";
import {
  Item,
  VisualAssessmentCatalog,
} from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { DeleteVisualAssessmentCatalogItemDialog } from "@/routes/base-data/processes/htz/visual-assessment-catalogs/[visualAssessmentCatalogId]/_components/delete-visual-assessment-catalog-item-dialog";
import { PutVisualAssessmentCatalogItemDialog } from "@/routes/base-data/processes/htz/visual-assessment-catalogs/[visualAssessmentCatalogId]/_components/put-visual-assessment-catalog-item-dialog";
import { ComponentKindName } from "@/shared/components/domain/htz/ahu/component-kind-name";

export function VisualAssessmentCatalogItemsTable({
  catalog,
}: {
  catalog: VisualAssessmentCatalog;
}) {
  const table = useDataTableController(tableColumns, catalog.items);

  return (
    <DataTableContainer>
      <div className="border-b">
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </div>
      <div className="px-4 pb-2">
        <DataTableBrowserPagination table={table} />
      </div>
    </DataTableContainer>
  );
}

const tableColumns: ColumnDef<Item>[] = [
  {
    header: t("Kürzel"),
    accessorKey: "shorthand",
  },
  {
    header: t("Relevant für Arten von Komponenten"),
    cell: ({ row }) => (
      <div>
        {row.original.componentKinds.map((kindId, index) => (
          <span key={kindId}>
            <ComponentKindName id={kindId} />
            {index < row.original.componentKinds.length - 1 && ", "}
          </span>
        ))}
      </div>
    ),
  },
  {
    header: t("Element"),
    accessorKey: "element",
  },
  {
    header: t("Aktionen"),
    id: "actions",
    cell: ({ row }) => (
      <div
        className="flex items-center justify-end space-x-2"
        key={row.original.id}
      >
        <PutVisualAssessmentCatalogItemDialog
          catalogId={row.original.catalogId}
          item={row.original}
        />
        <DeleteVisualAssessmentCatalogItemDialog
          catalogId={row.original.catalogId}
          itemId={row.original.id}
        />
      </div>
    ),
  },
];
