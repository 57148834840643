import { Artefact } from "@/shared/service-manager/artefact/artefact";
import { Process } from "@/services/backend/processes/process/process";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  newPositionFrom,
  Position,
} from "@/services/backend/htz/position/position";
import { v4 } from "uuid";
import {
  Address,
  newAddress,
} from "@/services/backend/addresses/address/address";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { Offer } from "@/services/backend/htz/offers/offer";

export interface Order extends Artefact {
  processId: string;
  customerId: string;
  companyId: string;
  offerId: string | null;
  date: string | null;
  positions: Position[];
  channel: Channel | null;
  customerReference: string;
  comment: string;
  files: OrderFile[];
  postalAddress: Address;
  serviceAddress: Address;
  representative: Representative;
  createdAt: string;
  updatedAt: string;
}

export interface OrderFile {
  id: string;
  name: string;
}

export enum Channel {
  EMAIL = "email",
  PHONE = "phone",
  POST = "post",
  FAX = "fax",
}

export function newOrder(id: string, process: Process): Order {
  return {
    id,
    artefactKind: ArtefactKind.Order,
    processId: process.id,
    customerId: process.customerId,
    companyId: process.companyId,
    date: new Date().toISOString(),
    offerId: null,
    positions: [],
    channel: null,
    customerReference: "",
    comment: "",
    files: [],
    postalAddress: newAddress(),
    serviceAddress: newAddress(),
    representative: {
      name: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Representative,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function newOrderFrom(
  order: Order,
  processId: string,
  companyId: string,
  customerId: string,
): Order {
  return {
    id: v4(),
    artefactKind: ArtefactKind.Order,
    processId,
    companyId,
    customerId,
    date: new Date().toISOString(),
    offerId: order.offerId,
    positions: order.positions.map((pos) => newPositionFrom(pos)),
    channel: order.channel,
    customerReference: order.customerReference,
    comment: order.comment,
    files: [],
    postalAddress: order.postalAddress,
    serviceAddress: order.serviceAddress,
    representative: order.representative,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function updateDate(order: Order, date: string | null): Order {
  return {
    ...order,
    date,
  };
}

export function updateOffer(order: Order, offer: Offer | null): Order {
  if (offer === null) {
    return {
      ...order,
      offerId: null,
    };
  }

  return {
    ...order,
    offerId: offer.id,
    positions: offer.positions.map((pos) => newPositionFrom(pos)),
    postalAddress: offer.postalAddress,
    serviceAddress: offer.serviceAddress,
    representative: offer.representative,
  };
}

export function updateCustomerReference(
  order: Order,
  customerReference: string,
): Order {
  return {
    ...order,
    customerReference,
  };
}

export function updateComment(order: Order, comment: string): Order {
  return {
    ...order,
    comment,
  };
}

export function addPosition(order: Order, position: Position): Order {
  if (order.positions.some((pos) => pos.id === position.id)) {
    return order;
  }

  return {
    ...order,
    positions: [
      ...order.positions,
      {
        ...position,
        number: (order.positions.length + 1).toString(),
      },
    ],
  };
}

export function updatePosition(order: Order, position: Position): Order {
  return {
    ...order,
    positions: order.positions.map((pos) => {
      if (pos.id === position.id) {
        return position;
      }
      return pos;
    }),
  };
}

export function deletePosition(order: Order, positionId: string): Order {
  return {
    ...order,
    positions: order.positions
      .filter((pos) => pos.id !== positionId)
      .map((pos, idx) => ({
        ...pos,
        number: (idx + 1).toString(),
      })),
  };
}

export function updatePostalAddress(order: Order, address: Address): Order {
  return {
    ...order,
    postalAddress: address,
  };
}

export function updateServiceAddress(order: Order, address: Address): Order {
  return {
    ...order,
    serviceAddress: address,
  };
}

export function updateRepresentative(
  order: Order,
  representative: Representative,
): Order {
  return {
    ...order,
    representative,
  };
}
