import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Company } from "@/services/backend/company/company";
import { Card, CardHeader, CardTitle } from "@/shared/components/ui/card";
import {
  ArtefactKind,
  ArtefactKindList,
} from "@/services/backend/artefacts/kind";
import {
  CreateSequenceRequest,
  useNumbersArtefactNumberCreateSequenceMutation,
  useNumbersArtefactNumberShowSequenceQuery,
} from "@/services/backend/numbers/artefact-number/service";
import { is404 } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { AlertTriangle, RefreshCw } from "lucide-react";
import { useState } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export interface SequencesTableProps {
  company: Company;
}

export function SequencesTable({ company }: SequencesTableProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{company.name}</CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>{t("Dokument")}</TableCell>
            <TableCell>{t("Start")}</TableCell>
            <TableCell>{t("Letzte vergebene Nummer")}</TableCell>
            <TableCell>{t("Aktionen")}</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {ArtefactKindList.map((artefactKind) => (
            <SequenceTableRow
              key={artefactKind}
              artefactKind={artefactKind}
              companyId={company.id}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function SequenceTableRow({
  artefactKind,
  companyId,
}: {
  artefactKind: ArtefactKind;
  companyId: string;
}) {
  const {
    data: sequence,
    isLoading,
    error,
  } = useNumbersArtefactNumberShowSequenceQuery({ artefactKind, companyId });

  if (error) {
    if (is404(error)) {
      return (
        <CreateSequenceTableRow
          artefactKind={artefactKind}
          companyId={companyId}
        />
      );
    }
    return (
      <TableRow>
        <TableCell>{t(artefactKind)}</TableCell>
        <TableCell colSpan={3}>{t("Fehler")}</TableCell>
      </TableRow>
    );
  }

  if (isLoading) {
    return (
      <TableRow>
        <TableCell>{t(artefactKind)}</TableCell>
        <TableCell colSpan={3} className="animate-pulse">
          {t("Lade Sequenz ...")}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell>{t(artefactKind)}</TableCell>
      <TableCell>{sequence!.start.toLocaleString()}</TableCell>
      <TableCell>{sequence!.lastValue.toLocaleString()}</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
}

function CreateSequenceTableRow({
  artefactKind,
  companyId,
}: {
  artefactKind: ArtefactKind;
  companyId: string;
}) {
  const [request, setRequest] = useState<CreateSequenceRequest>({
    companyId,
    artefactKind,
    start: 0,
  });

  return (
    <TableRow>
      <TableCell>{t(artefactKind)}</TableCell>
      <TableCell>
        <Input
          placeholder={t("Startnummer")}
          className="h-9"
          value={request.start}
          onChange={(e) =>
            setRequest({ ...request, start: Number(e.target.value) })
          }
        />
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell>
        <ConfirmCreationDialog request={request} />
      </TableCell>
    </TableRow>
  );
}

function ConfirmCreationDialog({
  request,
}: {
  request: CreateSequenceRequest;
}) {
  const [open, setOpen] = useState(false);
  const [create, { isLoading, error, isSuccess, reset }] =
    useNumbersArtefactNumberCreateSequenceMutation();

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    create(request);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button size="sm">{t("Erstellen")}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex items-center space-x-2">
            <AlertTriangle />
            <span>{t("Sequenz erstellen")}</span>
          </DialogTitle>
          <DialogDescription>
            {t(
              "Der Klick auf Bestätigen wird die Sequenz erstellen. Diese Aktion kann nicht rückgängig gemacht werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button disabled={isLoading} onClick={doCreate}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
            <span>{t("Erstellen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
