import { RouteObject } from "react-router-dom";
import { CustomerRoute } from "@/routes/customers/[customerId]/customer-route";
import { CustomersIndexRoute } from "@/routes/customers/customers-route";
import { customerRoutes } from "@/routes/customers/[customerId]/customer-routes";

export const customersRoutes: RouteObject[] = [
  {
    index: true,
    element: <CustomersIndexRoute />,
  },
  {
    path: ":customerId",
    element: <CustomerRoute />,
    children: customerRoutes,
  },
];
