import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { AlertCircle } from "lucide-react";
import t from "@/lang/lang";
import { Username } from "@/shared/components/domain/username/username";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

export interface Artefact {
  id: string;
  artefactKind: ArtefactKind;
}

export interface Immutability {
  immutable: boolean;
  immutabilityReason: string;
  immutabilitySetBy: string;
  immutabilitySetAt: string | null;
}

export interface Ownership {
  ownerId: string;
  ownerType: string;
}

export function ArtefactImmutableAlert(props: {
  artefact: Artefact & Immutability;
}) {
  const { artefact } = props;
  if (!artefact.immutable) {
    return null;
  }

  const artefactKind = t(artefact.artefactKind);
  const setAt = artefact.immutabilitySetAt
    ? new Date(artefact.immutabilitySetAt).toLocaleString()
    : "";
  const setBy = artefact.immutabilitySetBy;
  const reason = t(artefact.immutabilityReason);

  return (
    <Alert>
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{artefactKind} festgeschrieben</AlertTitle>
      <AlertDescription>
        <span>
          {artefactKind} wurde am {setAt} von{" "}
        </span>
        <Username userId={setBy} />
        <span> festgeschrieben. Grund: {reason}</span>
      </AlertDescription>
    </Alert>
  );
}
