import { useBranchesBranchShowQuery } from "@/services/backend/branch/branch";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function BranchName(props: { branchId: string }) {
  const { branchId } = props;

  const {
    data: branch,
    isLoading,
    isError,
  } = useBranchesBranchShowQuery({ id: branchId });

  if (isLoading) {
    return <Skeleton className="h-2 w-52" />;
  }

  if (isError) {
    return <span>Fehler beim Laden der Niederlassung</span>;
  }

  return <span>{branch?.name}</span>;
}
