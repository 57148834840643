import { useRltConfirmationListQuery } from "@/services/backend/rlt/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";

import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function ConfirmationListTable(props: { processId: string }) {
  const { processId } = props;
  const {
    data: list,
    isLoading,
    error,
  } = useRltConfirmationListQuery({
    process: {
      active: true,
      values: [processId],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Heruntergeladen")}</TableHead>
          <TableHead className="w-full">{t("Bestätigt Angebot")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {list?.confirmations.map((confirmation) => (
          <TableRow key={confirmation.id}>
            <TableCell>
              <NavLink to={`${confirmation.id}`}>
                <ArtefactNumberById artefactId={confirmation.id} />
              </NavLink>
            </TableCell>
            <TableCell>
              {new Date(confirmation.confirmationDate).toLocaleDateString()}
            </TableCell>
            <TableCell>
              {confirmation.immutable ? t("Ja") : t("Nein")}
            </TableCell>
            <TableCell>
              <ArtefactNumberById artefactId={confirmation.confirmedOfferId} />
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`${confirmation.id}`}>
                  <Button variant="ghost">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {list?.confirmations.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Auftragsbestätigungen gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function Skeleton() {
  return (
    <Table className="animate-pulse">
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Heruntergeladen")}</TableHead>
          <TableHead>{t("Bestätigt Angebot")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
    </Table>
  );
}
