import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { CheckCircle, Copy, Plus, Search } from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import {
  useVbsCalculationListQuery,
  useVbsCalculationShowCurrentQuery,
} from "@/services/backend/vbs/calculations/service";
import { NavLink, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { ProcessTopic } from "@/shared/components/domain/process-topic/process-topic";
import { useEffect, useState } from "react";
import {
  useVbsOfferCreateFromCalculationMutation,
  useVbsOfferCreateMutation,
  useVbsOfferListQuery,
} from "@/services/backend/vbs/offers/service";
import { Calculation } from "@/services/backend/vbs/calculations/calculation";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { CopyOfferButton } from "@/routes/gesec/processes/[processId]/vbs/offers/_components/copy-offer-button";
import { FilterMode } from "@/shared/lib/filter/filter";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

interface Props {
  processId: string;
}

export function CreateOfferDialog({ processId }: Props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const closeDialogAndNavigate = (calculationId: string) => {
    setOpen(false);
    navigate(calculationId);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-5 w-5" />
          <span>{t("Angebot")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] min-w-[80vw] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>{t("Neues Angebot erstellen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Angebote können entweder komplett neu, auf Basis einer Kalkulation oder auf Basis eines alten Angebots erstellt werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="manual">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="manual">{t("Neu Anlegen")}</TabsTrigger>
            <TabsTrigger value="from-calculation">
              {t("Kalkulationen")}
            </TabsTrigger>
            <TabsTrigger value="from-old">{t("Alte Angebote")}</TabsTrigger>
          </TabsList>
          <TabsContent value="manual">
            <CreateManually
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
          <TabsContent value="from-calculation">
            <CreateFromCalculation
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
          <TabsContent value="from-old">
            <CreateFromOld
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

function CreateManually({
  processId,
  onCreated,
}: {
  processId: string;
  onCreated: (calculationId: string) => void;
}) {
  const [create, { isLoading, error }] = useVbsOfferCreateMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    const id = v4();
    create({ id, processId })
      .unwrap()
      .then(() => {
        onCreated(id);
      });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Neu Anlegen")}</CardTitle>
        <CardDescription>
          {t(
            "Erstellt eine Angebot mit den Basisdaten des Prozesses und des Kunden.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RTKQueryErrorAlert error={error} />
      </CardContent>
      <CardFooter className="justify-end">
        <Button onClick={onClick} disabled={isLoading}>
          {t("Erstellen")}
        </Button>
      </CardFooter>
    </Card>
  );
}

function CreateFromCalculation({
  processId,
  onCreated,
}: {
  processId: string;
  onCreated: (calculationId: string) => void;
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Auf Basis alter Kalkulation")}</CardTitle>
        <CardDescription>
          {t(
            "Kopiert die Daten der alten Kalkulation. Es werden nur Kalkulationen von abgeschlossenen Vorgängen angezeigt.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <CalculationsTable processId={processId} onCreated={onCreated} />
      </CardContent>
    </Card>
  );
}

function CalculationsTable({
  processId,
  onCreated,
}: {
  processId: string;
  onCreated: (calculationId: string) => void;
}) {
  const {
    data: list,
    isLoading,
    error,
  } = useVbsCalculationListQuery({
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-24">{t("Aktuelle")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-56">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-24">{t("Aktuelle")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Erstellt am")}</TableHead>
          <TableHead className="w-56">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.calculations ?? [])]
          .sort(byCreatedAt)
          .map((calculation) => (
            <TableRow key={calculation.id}>
              <TableCell className="pl-8">
                <CurrentCalculationMarker
                  calculationId={calculation.id}
                  processId={processId}
                />
              </TableCell>
              <TableCell>
                <ArtefactNumberById artefactId={calculation.id} />
              </TableCell>
              <TableCell>
                {new Date(calculation.createdAt).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <div className="flex space-x-2">
                  <NavLink to={`../calculations/${calculation.id}`}>
                    <Button variant="outline" size="sm">
                      <Search className="h-4 w-4" />
                    </Button>
                  </NavLink>
                  <CreateOfferFromCalculationButton
                    calculationId={calculation.id}
                    processId={processId}
                    onCreated={onCreated}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

function byCreatedAt(a: Calculation, b: Calculation) {
  return a.createdAt.localeCompare(b.createdAt);
}

function CurrentCalculationMarker({
  calculationId,
  processId,
}: {
  calculationId: string;
  processId: string;
}) {
  const { data: current } = useVbsCalculationShowCurrentQuery({
    processId,
  });

  if (current?.calculationId === calculationId) {
    return <CheckCircle className="h-5 w-5 text-green-700" color="green" />;
  }

  return <span />;
}

function CreateOfferFromCalculationButton(props: {
  calculationId: string;
  processId: string;
  onCreated: (offerId: string) => void;
}) {
  const { calculationId, processId, onCreated } = props;
  const [create, { isLoading, error, reset }] =
    useVbsOfferCreateFromCalculationMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (isLoading) {
      return;
    }
    const id = v4();
    create({
      id,
      calculationId,
      processId,
    })
      .unwrap()
      .then(() => onCreated(id));
  };

  return (
    <Button onClick={onClick} disabled={isLoading} size="sm">
      <Copy className="mr-3 h-4 w-4" />
      <span>{t("Daten Übernehmen")}</span>
    </Button>
  );
}

function CreateFromOld(props: {
  processId: string;
  onCreated: (offerId: string) => void;
}) {
  const { processId, onCreated } = props;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Auf Basis eines alten Angebots")}</CardTitle>
        <CardDescription>
          {t(
            "Kopiert die Daten des alten Angebots. Es werden nur Angebote von abgeschlossenen Vorgängen angezeigt.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <OldOfferTable processId={processId} onCreated={onCreated} />
      </CardContent>
    </Card>
  );
}

function OldOfferTable({
  processId,
  onCreated,
}: {
  processId: string;
  onCreated: (offerId: string) => void;
}) {
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  const {
    data: list,
    isLoading,
    error,
  } = useVbsOfferListQuery({
    customer: {
      active: true,
      values: [process?.customerId ?? ""],
    },
    immutable: {
      active: true,
      values: [true],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Datum")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {list?.offers?.map((offer) => (
          <TableRow key={offer.id}>
            <TableCell>
              {new Date(offer.offerDate).toLocaleDateString()}
            </TableCell>
            <TableCell>
              <ArtefactNumberById artefactId={offer.id} />
            </TableCell>
            <TableCell>
              <ProcessTopic processId={processId} />
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavigateToOffer
                  currentProcessId={processId}
                  offerProcessId={offer.processId}
                  offerId={offer.id}
                />
                <CopyOfferButton
                  offerId={offer.id}
                  processId={processId}
                  onCopy={onCreated}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function NavigateToOffer(props: {
  currentProcessId: string;
  offerProcessId: string;
  offerId: string;
}) {
  const { currentProcessId, offerProcessId, offerId } = props;

  // If the calculation is part of the same process,
  // simply navigate to the calculation
  if (currentProcessId === offerProcessId) {
    return (
      <NavLink to={offerId}>
        <Button variant="outline" size="sm">
          <Search className="h-4 w-4" />
        </Button>
      </NavLink>
    );
  }

  // If the calculation is part of a different process,
  // show a warning and then navigate to the calculation
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <Search className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Achtung")}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {t(
            "Dieses Angebot ist Teil eines anderen Vorgangs. Sie müssen nachdem Sie das Angbot angesehen haben zum aktuellen Vorgang zurückkehren.",
          )}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <NavLink to={`/processes/vbs/${offerProcessId}/offers/${offerId}`}>
            <Button>{t("Vorgang wechseln")}</Button>
          </NavLink>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
