import { useState } from "react";
import { Certificate } from "@/services/backend/vbs/certificates/certificate";
import {
  UpdateIssueDateRequest,
  UpdateSegmentsRequest,
  UpdateWorkEndedAtRequest,
  UpdateWorkStartedAtRequest,
  useVbsCertificateShowQuery,
  useVbsCertificateUpdateIssueDateMutation,
  useVbsCertificateUpdateSegmentsMutation,
  useVbsCertificateUpdateWorkEndedAtMutation,
  useVbsCertificateUpdateWorkStartedAtMutation,
} from "@/services/backend/vbs/certificates/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import t from "@/lang/lang";
import {
  dateStringToRFC3339,
  rfc3339toDateString,
} from "@/shared/lib/utilities/date";
import {
  SegmentKind,
  SegmentKindList,
} from "@/services/backend/vbs/treatments/segmentKind";
import { ProcessCustomerAddressCardById } from "@/shared/components/domain/addresses/customer-address-card";
import { ProcessServiceAddressCardById } from "@/shared/components/domain/addresses/service-address-card";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { Switch } from "@/shared/components/ui/switch";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import {
  errorsFor,
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { fieldErrors } from "@/shared/app-lib/errors/validation-error";

export function CertificateComponent(props: { certificateId: string }) {
  const { certificateId } = props;
  const { data, isLoading, error } = useVbsCertificateShowQuery({
    id: certificateId,
  });
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  if (isLoading) {
    return <CertificateSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="space-y-4">
      <CertificateEditForm certificate={data!} />
      <ProcessCustomerAddressCardById
        id={data!.customerAddressId}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "customerAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`customerAddress.${field}`)
        }
      />
      <ProcessServiceAddressCardById
        id={data!.serviceAddressId}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}

function CertificateSkeleton() {
  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>{t("Zertifikat Daten")}</CardTitle>
          <CardDescription>{t("Basisdaten des Zertifikats")}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
          <Skeleton className="h-6 w-full" />
        </CardContent>
      </Card>
    </div>
  );
}

function CertificateEditForm(props: { certificate: Certificate }) {
  const { certificate } = props;
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Zertifikat Daten")}</CardTitle>
        <CardDescription>{t("Basisdaten des Zertifikats")}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <IssueDateInput
          certificateId={certificate.id}
          issueDate={certificate.issueDate}
        />
        <WorkStartDateInput
          certificateId={certificate.id}
          workStartedAt={certificate.workStartedAt}
        />
        <WorkEndDateInput
          certificateId={certificate.id}
          workEndedAt={certificate.workEndedAt}
        />
        <SegmentSelectionComponent certificate={certificate} />
      </CardContent>
    </Card>
  );
}

function IssueDateInput(props: { certificateId: string; issueDate: string }) {
  const { certificateId, issueDate } = props;
  const [request, setRequest] = useState<UpdateIssueDateRequest>({
    id: certificateId,
    issueDate,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsCertificateUpdateIssueDateMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { notReadyError } = useErrArtefactNotReady();

  const errorMessages: string[] = [
    ...(errorsFor(notReadyError, "issueDate") ?? []),
    ...(fieldErrors(error, "issueDate") ?? []),
  ];

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="issueDate">{t("Datum der Ausstellung")}</Label>
      <Input
        type="date"
        id="issueDate"
        value={rfc3339toDateString(request.issueDate)}
        onChange={(e) =>
          setRequest({
            ...request,
            issueDate: dateStringToRFC3339(e.target.value),
          })
        }
      />
      <InputErrorMessages errors={errorMessages} />
    </div>
  );
}

function WorkStartDateInput(props: {
  certificateId: string;
  workStartedAt: string;
}) {
  const { certificateId, workStartedAt } = props;
  const [request, setRequest] = useState<UpdateWorkStartedAtRequest>({
    id: certificateId,
    workStartedAt,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsCertificateUpdateWorkStartedAtMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError } = useErrArtefactNotReady();

  const errorMessages: string[] = [
    ...(errorsFor(notReadyError, "workStartedAt") ?? []),
    ...(fieldErrors(error, "workStartedAt") ?? []),
  ];

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="workStartedAt">{t("Beginn der Arbeiten")}</Label>
      <Input
        type="date"
        id="workStartedAt"
        value={rfc3339toDateString(request.workStartedAt)}
        onChange={(e) =>
          setRequest({
            ...request,
            workStartedAt: dateStringToRFC3339(e.target.value),
          })
        }
      />
      <InputErrorMessages errors={errorMessages} />
    </div>
  );
}

function WorkEndDateInput(props: {
  certificateId: string;
  workEndedAt: string;
}) {
  const { certificateId, workEndedAt } = props;
  const [request, setRequest] = useState<UpdateWorkEndedAtRequest>({
    id: certificateId,
    workEndedAt,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsCertificateUpdateWorkEndedAtMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError } = useErrArtefactNotReady();

  const errorMessages: string[] = [
    ...(errorsFor(notReadyError, "workEndedAt") ?? []),
    ...(fieldErrors(error, "workEndedAt") ?? []),
  ];

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="endDate">{t("Ende der Arbeiten")}</Label>
      <Input
        type="date"
        id="endDate"
        value={rfc3339toDateString(request.workEndedAt)}
        onChange={(e) =>
          setRequest({
            ...request,
            workEndedAt: dateStringToRFC3339(e.target.value),
          })
        }
      />
      <InputErrorMessages errors={errorMessages} />
    </div>
  );
}

function SegmentSelectionComponent(props: { certificate: Certificate }) {
  const { certificate } = props;
  const [request, setRequest] = useState<UpdateSegmentsRequest>({
    id: certificate.id,
    segments: certificate.segments,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsCertificateUpdateSegmentsMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError } = useErrArtefactNotReady();

  const segmentSelected = (segmentKind: SegmentKind): boolean =>
    request.segments.includes(segmentKind);

  const updateSelection = (
    segmentKind: SegmentKind,
    selection: boolean,
  ): void => {
    if (selection) {
      if (request.segments.includes(segmentKind)) {
        return;
      }
      setRequest({
        id: request.id,
        segments: [...request.segments, segmentKind],
      });
    } else {
      setRequest({
        id: request.id,
        segments: request.segments.filter((segment) => segment !== segmentKind),
      });
    }
  };

  const errorMessages: string[] = [
    ...(errorsFor(notReadyError, "segments") ?? []),
    ...(fieldErrors(error, "segments") ?? []),
  ];

  return (
    <div>
      <Label>{t("Behandelte Bauteilgruppen")}</Label>
      <div className="space-y-2">
        {SegmentKindList.map((segmentKind) => (
          <div className="flex items-center space-x-2" key={segmentKind}>
            <Switch
              checked={segmentSelected(segmentKind)}
              onCheckedChange={(newValue) =>
                updateSelection(segmentKind, newValue)
              }
            >
              <span className="sr-only">Enable notifications</span>
            </Switch>
            <Label>{t(segmentKind)}</Label>
          </div>
        ))}
      </div>
      <InputErrorMessages errors={errorMessages} />
    </div>
  );
}
