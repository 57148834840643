import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import {
  Item,
  PositionCatalog,
} from "@/services/backend/htz/position-catalog/position-catalog";
import t from "@/lang/lang";
import { PutPositionCatalogItemDialog } from "@/routes/base-data/processes/htz/position-catalogs/[positionCatalogId]/_components/put-position-catalog-item-dialog";
import { DeletePositionCatalogItemDialog } from "@/routes/base-data/processes/htz/position-catalogs/[positionCatalogId]/_components/delete-position-catalog-item-dialog";

export function PositionCatalogItemTable({
  catalog,
}: {
  catalog: PositionCatalog;
}) {
  const table = useDataTableController(tableColumns, catalog.items);

  return (
    <DataTableContainer>
      <div className="border-b">
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </div>
      <div className="px-4 pb-2">
        <DataTableBrowserPagination table={table} />
      </div>
    </DataTableContainer>
  );
}

const tableColumns: ColumnDef<Item>[] = [
  {
    header: t("Leistung"),
    accessorKey: "title",
    cell: ({ row }) => <span className="">{row.original.title}</span>,
  },
  {
    header: t("Kategorie"),
    accessorKey: "category",
  },
  {
    header: t("Beschreibung"),
    accessorKey: "description",
    cell: ({ row }) => {
      if (row.original.description.length > 200) {
        return `${row.original.description.slice(0, 200)} ...`;
      }
      return row.original.description;
    },
  },
  {
    header: t("Preis"),
    accessorKey: "unitPrice",
    cell: ({ row }) => (
      <div className="text-right">
        {row.original.unitPrice.toLocaleString("de", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
      </div>
    ),
  },
  {
    header: t("Aktionen"),
    id: "actions",
    cell: ({ row }) => (
      <div className="flex items-center space-x-2" key={row.original.id}>
        <PutPositionCatalogItemDialog
          catalogId={row.original.catalogId}
          position={row.original}
        />
        <DeletePositionCatalogItemDialog
          catalogId={row.original.catalogId}
          positionId={row.original.id}
        />
      </div>
    ),
  },
];
