import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import {
  ListBranchRequest,
  useListBranchQuery,
} from "@/services/backend/branch/branch";
import t from "@/lang/lang";
import { Filter } from "@/shared/lib/filter/filter";

export interface BranchFilterProps {
  filter: Filter<string>;
  onChange: (filter: Filter<string>) => void;
}

export function BranchFilter({ filter, onChange }: BranchFilterProps) {
  const [open, setOpen] = useState(false);

  const {
    data: branchList,
    isLoading,
    error,
  } = useListBranchQuery({
    // companyId: TODO set active company
  } as ListBranchRequest);

  if (isLoading) {
    return (
      <Button variant="outline" disabled className="w-[200px] justify-between">
        <span>{t("Niederlassungen")}</span>
        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    );
  }

  if (error) {
    return <div />;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          <span>{t("Niederlassungen")}</span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={t("Niederlassung suchen")} />
          <CommandEmpty>{t("Niederlassung nicht gefunden")}</CommandEmpty>
          <CommandGroup>
            {branchList!.branches.map((branch) => (
              <CommandItem
                key={branch.id}
                value={branch.id}
                onSelect={(currentValue) => {
                  onChange({
                    active: true,
                    values: filter.values!.includes(currentValue)
                      ? filter.values!.filter((v) => v !== currentValue)
                      : [...filter.values!, currentValue],
                  });
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    filter.values!.includes(branch.id)
                      ? "opacity-100"
                      : "opacity-0",
                  )}
                />
                {branch.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
