import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { ActiveAhuComponentCatalogCheckbox } from "@/routes/base-data/processes/htz/ahu-component-catalogs/_components/active-ahu-component-catalog-checkbox";
import { DeleteComponentCatalogDialog } from "@/routes/base-data/processes/htz/ahu-component-catalogs/_components/delete-component-catalog-dialog";
import { NewComponentCatalogDialog } from "@/routes/base-data/processes/htz/ahu-component-catalogs/_components/new-component-catalog-dialog";
import { useHtzAhuComponentCatalogListQuery } from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RefreshCw } from "lucide-react";
import { Button } from "@/shared/components/ui/button";

export function AhuComponentCatalogTable() {
  const {
    data: list,
    isLoading,
    error,
  } = useHtzAhuComponentCatalogListQuery({});

  const catalogs = list?.catalogs || [];

  const table = useDataTableController(tableColumns, catalogs);

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Kataloge für Anlagenkomponenten")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können Sie Kataloge mit Vorlagen zur Definition von Komponenten für RLT-Anlagen verwalten. Es gibt nur einen einheitlichen Komponentenkatalog für alle Unternehmen. Es wird nur ein Katalog benötigt.",
            )}
          </CardDescription>
        </div>
        <NewComponentCatalogDialog />
      </CardHeader>
      <DataTableContainer>
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </DataTableContainer>
    </Card>
  );
}

function Skeleton() {
  return (
    <Card>
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Kataloge für Anlagenkomponenten")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können Sie Kataloge mit Vorlagen zur Definition von Komponenten für RLT-Anlagen verwalten. Es gibt nur einen einheitlichen Komponentenkatalog für alle Unternehmen. Es wird nur ein Katalog benötigt.",
            )}
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent>
        <RefreshCw className="animate-spin" />
        <span>{t("Lade Daten...")}</span>
      </CardContent>
    </Card>
  );
}

const tableColumns: ColumnDef<AhuComponentCatalog>[] = [
  {
    header: t("Aktiv"),
    id: "activeCatalog",
    cell: ({ row }) => (
      <div className="ml-4">
        <ActiveAhuComponentCatalogCheckbox catalogId={row.original.id} />
      </div>
    ),
  },
  {
    header: t("Katalogname"),
    accessorKey: "name",
    cell: ({ row }) => {
      const path = `/base-data/processes/htz/ahu-component-catalogs/${row.original.id}`;
      return (
        <NavLink to={path}>
          <Button variant="link">{row.original.name}</Button>
        </NavLink>
      );
    },
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => (
      <div>
        <DeleteComponentCatalogDialog catalog={row.original} />
      </div>
    ),
  },
];
