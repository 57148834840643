import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  Requirement,
  UpdateRequirementRequest,
} from "@/services/backend/qpm/requirements/types";
import { Input } from "@/shared/components/ui/input";
import { Label } from "@/shared/components/ui/label";
import t from "@/lang/lang";
import { formatDateTimeLocal } from "@/shared/lib/utilities/date";
import { AddressInfoComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/address-info-component";
import { useOutletContext } from "react-router-dom";
import { CreateRequirementContext } from "@/routes/gemex/processes/qpm/requirements/requirement/create/create-requirement";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useQpmRequirementUpdateMutation } from "@/services/backend/qpm/requirements/service";

export function RequirementDetailsRoute() {
  const requirementContext = useOutletContext<CreateRequirementContext>();

  return (
    <RequirementDetailsComponent requirement={requirementContext.requirement} />
  );
}

export type RequirementDetailsComponentProps = {
  requirement: Requirement;
};

export function RequirementDetailsComponent({
  requirement,
}: RequirementDetailsComponentProps) {
  const [request, setRequest] = useState<UpdateRequirementRequest>({
    id: requirement.id,
    artefactMetaId: requirement.artefactMetaId,
    customerId: requirement.customerId,
    description: requirement.description,
    identifier: requirement.identifier,
    name: requirement.name,
    processId: requirement.processId,
  });
  const [setRequirement, { error, isSuccess, isLoading, reset }] =
    useQpmRequirementUpdateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setRequirement,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-xl">{t("Details")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-3">
          <div className="grid grid-cols-2 gap-3">
            <div className="grid gap-1.5">
              <Label htmlFor="name">{t("Thema")}</Label>
              <Input
                id="name"
                type="text"
                className="w-full"
                defaultValue={requirement.name}
                onChange={(e) => {
                  setRequest({ ...request, name: e.target.value });
                }}
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="identifier">{t("Kennung")}</Label>
              <Input
                id="identifier"
                type="text"
                className="w-full"
                defaultValue={requirement.identifier}
                onChange={(e) => {
                  setRequest({ ...request, identifier: e.target.value });
                }}
                disabled
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="description">{t("Beschreibung")}</Label>
              <Textarea
                id="description"
                defaultValue={requirement.description}
                className="min-h-10"
                onChange={(e) => {
                  setRequest({ ...request, description: e.target.value });
                }}
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="date">{t("Erstellt am")}</Label>
              <Input
                id="date"
                type="datetime-local"
                className="w-full"
                value={formatDateTimeLocal(requirement.createdAt)}
                disabled
              />
            </div>
          </div>
          <div className="">
            <AddressInfoComponent />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
