import t from "@/lang/lang";
import { Outlet } from "react-router-dom";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";

export function QpmBaseDataRoute() {
  return (
    <BaseDataPage>
      <BaseDataPageHeader>
        <BaseDataPageTitle>{t("QPM")}</BaseDataPageTitle>
        <BaseDataPageDescription>
          {t(
            "Hier können die Basisdaten für Vorgänge von QPM Dienstleistungen konfiguriert werden.",
          )}
        </BaseDataPageDescription>
      </BaseDataPageHeader>
      <BaseDataPageContent>
        <Outlet />
      </BaseDataPageContent>
    </BaseDataPage>
  );
}
