import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";
import {
  fieldErrors,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";

interface InputErrorMessagesProps extends HTMLAttributes<HTMLDivElement> {
  errors: string[];
}

export function InputErrorMessages({
  errors,
  className,
  ...props
}: InputErrorMessagesProps) {
  if (errors.length === 0) {
    return null;
  }
  return (
    <div className={cn("", className)} {...props}>
      {errors.map((error) => (
        <p className="text-sm text-destructive" key={error}>
          {error}
        </p>
      ))}
    </div>
  );
}

/**
 * InputValidationErrors is a helper component to directly
 * pass a ValidationError or rtk query error into the
 * InputErrorMessages component.
 *
 * @param error - the error object
 * @param field - the field to extract the errors for
 */
export function InputValidationErrors({
  error,
  field,
}: {
  error: unknown;
  field: string;
}) {
  if (!error) {
    return null;
  }

  // this allows to pass a rtk query error
  // or a validation error directly to the
  // component.
  const validationError = () => {
    // extract the validation error from the rtk query error
    if (rtkErrIsValidationError(error)) {
      return error.data;
    }

    // assume the error is a validation error
    return error;
  };

  const errors = fieldErrors(validationError(), field);
  if (!errors) {
    return null;
  }

  return <InputErrorMessages errors={errors} />;
}
