import { ConfirmationListTable } from "@/routes/gesec/processes/[processId]/rlt/confirmations/_components/confirmation-list-table";
import { Card } from "@/shared/components/ui/card";
import { useParams } from "react-router-dom";

export function ConfirmationIndexRoute() {
  const { processId } = useParams();
  return (
    <Card>
      <ConfirmationListTable processId={processId!} />
    </Card>
  );
}
