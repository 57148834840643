import { useParams } from "react-router-dom";
import { Card } from "@/shared/components/ui/card";
import { OfferTable } from "@/routes/gesec/processes/[processId]/rlt/offers/_components/offer-table";

export function OfferIndexRoute() {
  const { processId } = useParams();

  return (
    <Card>
      <OfferTable processId={processId!} />
    </Card>
  );
}
