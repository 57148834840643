import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

interface SearchContextInterface {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SearchContext = createContext<SearchContextInterface>({
  open: false,
  setOpen: () => {},
});

export function useSearchContext() {
  return useContext(SearchContext);
}

export function SearchContextProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  const value = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open, setOpen],
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
