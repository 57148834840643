import { backendApi } from "@/services/backend/api";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { AirMicrobialSampleSet } from "@/services/backend/samples/sample/air-microbial-sample";
import { Filter } from "@/shared/lib/filter/filter";
import { BaseSamplePatchRequest } from "@/services/backend/samples/sample/sample";
import { EntityId } from "@/shared/nidavellir/types/entity-id";

const labApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesAirMicrobialSampleSet"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesAirMicrobialSampleSetPut: build.mutation<
        AirMicrobialSampleSet,
        PutRequest
      >({
        query: (request) => ({
          url: `/samples/air-microbial`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesAirMicrobialSampleSet", id: request.id },
          "SamplesAirMicrobialSampleSet",
        ],
      }),
      samplesAirMicrobialSampleSetUpdateLab: build.mutation<
        AirMicrobialSampleSet,
        UpdateLabRequest
      >({
        query: (request) => ({
          url: `/samples/air-microbial/lab`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesAirMicrobialSampleSet", id: request.id },
          "SamplesAirMicrobialSampleSet",
        ],
      }),
      samplesAirMicrobialSampleSetShow: build.query<
        AirMicrobialSampleSet,
        ShowRequest
      >({
        query: (request) => ({
          url: `/samples/air-microbial`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("SamplesAirMicrobialSampleSet"),
      }),
      samplesAirMicrobialSampleSetList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/air-microbial/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.sampleSets, "SamplesAirMicrobialSampleSet"),
      }),
      samplesAirMicrobialSampleSetDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/samples/air-microbial`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["SamplesAirMicrobialSampleSet"],
      }),
    }),
  });

export const {
  useSamplesAirMicrobialSampleSetPutMutation,
  useSamplesAirMicrobialSampleSetUpdateLabMutation,
  useSamplesAirMicrobialSampleSetShowQuery,
  useSamplesAirMicrobialSampleSetListQuery,
  useSamplesAirMicrobialSampleSetDeleteMutation,
} = labApi;

export interface PutRequest extends AirMicrobialSampleSet {}

export interface UpdateLabRequest {
  id: string;
  labId: EntityId;
}

export interface AirMicrobialSamplePatchRequest extends BaseSamplePatchRequest {
  samplingVolume?: number;
  deviceId?: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  lab?: Filter<string>;
  artefact?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  sampleSets: AirMicrobialSampleSet[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
