import { ChangeEvent, useState } from "react";
import { useParams } from "react-router-dom";
import { ProcessServiceAddressCardById } from "@/shared/components/domain/addresses/service-address-card";
import { ProcessCustomerAddressCardById } from "@/shared/components/domain/addresses/customer-address-card";
import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  UpdateBeginDateRequest,
  UpdateCommentRequest,
  UpdateEndDateRequest,
  UpdateTeamLeaderRequest,
  useRltWorkOrderShowQuery,
  useRltWorkOrderUpdateBeginDateMutation,
  useRltWorkOrderUpdateCommentMutation,
  useRltWorkOrderUpdateEndDateMutation,
  useRltWorkOrderUpdateTeamLeaderMutation,
} from "@/services/backend/rlt/work-orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { rfc3339toDateString } from "@/shared/lib/utilities/date";
import { ServiceContactCardById } from "@/shared/components/domain/contacts/contact-card";
import { ProcessRepresentativeCardById } from "@/shared/components/domain/representatives/representative-card";
import t from "@/lang/lang";
import {
  ErrArtefactNotReady,
  errorsFor,
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { AlertCircle } from "lucide-react";
import { Separator } from "@/shared/components/ui/separator";
import { Textarea } from "@/shared/components/ui/textarea";
import { Input } from "@/shared/components/ui/input";
import { Card } from "@/shared/components/ui/card";

export function WorkOrderDataRoute() {
  const { workOrderId } = useParams();
  const {
    data: workSlip,
    isLoading,
    error,
  } = useRltWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!workSlip) {
    return <div />;
  }

  return <WorkOrderData workOrder={workSlip} />;
}

export function WorkOrderData(props: { workOrder: WorkOrder }) {
  const { workOrder } = props;
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="space-y-4 bg-white">
      <div className="w-full rounded-lg border pb-2 shadow">
        <div className="divide-y">
          <BeginDate workSlip={workOrder} />
          <EndDate workSlip={workOrder} />
          <TeamLeader workSlip={workOrder} />
          <Comment workSlip={workOrder} />
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 md:gap-4 ">
        <ServiceContactCardById
          id={workOrder.serviceContactId}
          fieldErrors={fieldErrorsForPrefix(notReadyError, "contact.")}
          resetFieldError={(field) =>
            resetNotReadyErrorField(`contact.${field}`)
          }
        />
        <ProcessRepresentativeCardById
          id={workOrder.representativeId}
          fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
          resetFieldError={(field) =>
            resetNotReadyErrorField(`representative.${field}`)
          }
        />
      </div>
      <ProcessServiceAddressCardById
        id={workOrder.serviceAddressId}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
      <ProcessCustomerAddressCardById
        id={workOrder.customerAddressId}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "customerAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`customerAddress.${field}`)
        }
      />
    </div>
  );
}

function BeginDate(props: { workSlip: WorkOrder }) {
  const { workSlip } = props;
  const [request, setRequest] = useState<UpdateBeginDateRequest>({
    id: workSlip.id,
    beginDate: workSlip.beginDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useRltWorkOrderUpdateBeginDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="flex justify-between rounded-t-lg px-6 py-3 font-bold text-gray-700">
      <span>{t("Beginn der Arbeiten")}</span>
      <input
        type="date"
        value={rfc3339toDateString(request.beginDate)}
        onChange={(e) => {
          const date = new Date(e.target.valueAsNumber);
          resetNotReadyErrorField("beginDate");
          setRequest({
            ...request,
            beginDate: date.toISOString(),
          });
        }}
      />
      <WorkOrderFieldNotReadyWarning field="beginDate" error={notReadyError} />
    </div>
  );
}

function WorkOrderFieldNotReadyWarning({
  field,
  error,
}: {
  field: string;
  error: ErrArtefactNotReady | null;
}) {
  if (!error) {
    return null;
  }

  const fieldErrors = errorsFor(error, field);

  if (fieldErrors) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <AlertCircle className="inline-flex h-4 w-4 text-red-500" />
          </TooltipTrigger>
          <TooltipContent align="end">
            <div className="px-2 py-1">
              <div>{t("Angebot nicht fertig:")}</div>
              <Separator className="mb-2" />
              {fieldErrors.map((msg) => (
                <div key={msg} className="text-sm text-red-700">
                  {msg}
                </div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
}

function EndDate(props: { workSlip: WorkOrder }) {
  const { workSlip } = props;
  const [request, setRequest] = useState<UpdateEndDateRequest>({
    id: workSlip.id,
    endDate: workSlip.endDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useRltWorkOrderUpdateEndDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="flex justify-between px-6 py-3 font-bold text-gray-700">
      <span>{t("Ende der Arbeiten")}</span>
      <input
        type="date"
        value={rfc3339toDateString(request.endDate)}
        onChange={(e) => {
          const date = new Date(e.target.valueAsNumber);
          resetNotReadyErrorField("endDate");
          setRequest({
            ...request,
            endDate: date.toISOString(),
          });
        }}
      />
      <WorkOrderFieldNotReadyWarning field="endDate" error={notReadyError} />
    </div>
  );
}

function TeamLeader(props: { workSlip: WorkOrder }) {
  const { workSlip } = props;
  const [request, setRequest] = useState<UpdateTeamLeaderRequest>({
    id: workSlip.id,
    teamLeaderId: workSlip.teamLeaderId,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltWorkOrderUpdateTeamLeaderMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { notReadyError } = useErrArtefactNotReady();

  const updateTeamleader = (e: ChangeEvent<HTMLInputElement>) => {
    setRequest({ ...request, teamLeaderId: e.target.value });
  };

  return (
    <div className="px-6 py-3">
      <div className="flex items-center justify-between">
        <span className="font-bold text-gray-700">{t("Teamleiter")}</span>
        <div className="flex items-center space-x-2">
          <Input
            value={request.teamLeaderId}
            onChange={updateTeamleader}
            className="h-8 min-w-[300px]"
          />
          <WorkOrderFieldNotReadyWarning
            field="teamLeaderId"
            error={notReadyError}
          />
        </div>
      </div>
    </div>
  );
}

function Comment(props: { workSlip: WorkOrder }) {
  const { workSlip } = props;
  const [request, setRequest] = useState<UpdateCommentRequest>({
    id: workSlip.id,
    comment: workSlip.comment,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltWorkOrderUpdateCommentMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const updateComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRequest({ ...request, comment: e.target.value });
  };

  return (
    <div className="space-y-2 px-6 py-3">
      <div className="font-bold text-gray-700">{t("Kommentar")}</div>
      <Textarea value={request.comment} onChange={updateComment} />
    </div>
  );
}
