import { Position } from "@/services/backend/rlt/treatments/position";
import { useRltTreatmentUpdatePositionCompletionMutation } from "@/services/backend/rlt/treatments/service";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Checkbox } from "@/shared/components/ui/checkbox";

export function PositionCompleted(props: {
  treatmentId: string;
  position: Position;
}) {
  const { treatmentId, position } = props;

  const [update, { isLoading, error, reset }] =
    useRltTreatmentUpdatePositionCompletionMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      reset();
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [error, reset, toast]);

  const onCheckedChange = (status: CheckedState) => {
    if (isLoading) {
      return;
    }
    if (status === "indeterminate") {
      return;
    }

    update({
      id: treatmentId,
      positionId: position.id,
      completed: status,
      forceCompletion: false,
    });
  };

  return (
    <Checkbox
      className="h-7 w-7"
      checked={position.completed}
      onCheckedChange={onCheckedChange}
    />
  );
}
