import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { RefreshCw, Trash2 } from "lucide-react";
import { useHtzAirHandlingUnitDeleteMutation } from "@/services/backend/htz/ahu/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function DeleteAhuDialog({ ahu }: { ahu: AirHandlingUnit }) {
  const [open, setOpen] = useState(false);
  const [deleteAhu, { isLoading, error, isSuccess, reset }] =
    useHtzAirHandlingUnitDeleteMutation();
  const navigate = useNavigate();

  const doDelete = () => {
    if (isLoading) {
      return;
    }

    deleteAhu({ id: ahu.id });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("..");
      reset();
    }
  }, [isSuccess, reset, navigate]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("RLT-Anlage löschen?")}</DialogTitle>
          <DialogDescription>
            {t("Löscht Anlage: ")}
            {ahu.name}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDelete} disabled={isLoading} variant="destructive">
            {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
