import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLSpanElement> {
  userId: string;
}

export function Username({ userId, ...props }: Props) {
  // TODO MS Graph call
  return <span {...props}>{userId}</span>;
}

export function useUsername(userId: string) {
  // TODO MS Graph call
  return userId;
}
