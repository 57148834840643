import { H4 } from "@/shared/components/ui/typography";
import { Textarea } from "@/shared/components/ui/textarea";
import { useState } from "react";

interface AdditionalCommentsComponentProps {
  comments: string;
  onChange: (comment: string) => void;
}

export function AdditionalCommentsComponent({
  comments,
  onChange,
}: AdditionalCommentsComponentProps) {
  const [additionalComment, setAdditionalComment] = useState<string>(comments);

  const onChangeAdditionalInfoComment = (value: string) => {
    setAdditionalComment(value);
    onChange(value);
  };

  return (
    <div className="flex flex-col gap-2">
      <H4>Bemerkungen</H4>
      <div className="flex">
        <Textarea
          placeholder="Weitere Anmerkungen..."
          value={additionalComment}
          onChange={(e) => {
            onChangeAdditionalInfoComment(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
