import t from "@/lang/lang";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@/shared/components/ui/table";
import { Button } from "@/shared/components/ui/button";
import { formatDate } from "@/shared/lib/utilities/date";
import {
  ScrollBodyTable,
  ScrollBodyTableHeader,
} from "@/shared/components/ui/table-scroll-body";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { useShowCompanyQuery } from "@/services/backend/company/company";
import { NavLink } from "react-router-dom";
import { Star } from "lucide-react";

export interface ProcessResult {
  id: string;
  kind: ProcessKind;
  number: string;
  customerId: string;
  customerName: string;
  companyId: string;
  companyName: string;
  topic: string;
  createdAt: string;
  topResult: boolean;
}

export function ProcessResults({ processes }: { processes: ProcessResult[] }) {
  const enableScroll = processes.length > 4;

  return (
    <div>
      <div className="flex justify-between">
        <h5 className="text-lg font-bold">{t("Vorgänge")}</h5>
        <span className="text-sm text-muted-foreground">
          {processes.length} {t("gefunden")}
        </span>
      </div>
      <ScrollArea className={enableScroll ? "h-[20vh] border-b" : ""}>
        <ScrollBodyTable>
          <ScrollBodyTableHeader>
            <TableRow>
              <TableHead>{t("Top")}</TableHead>
              <TableHead>{t("Nummer")}</TableHead>
              <TableHead className="w-full">{t("Thema")}</TableHead>
              <TableHead>{t("Kunde")}</TableHead>
              <TableHead>{t("Datum")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </ScrollBodyTableHeader>
          <TableBody>
            {processes.map((process) => (
              <ProcessResultTableRow key={process.id} process={process} />
            ))}
            {processes.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} className="py-2 text-center">
                  {t("Keine Vorgänge gefunden")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </ScrollBodyTable>
        {enableScroll && <ScrollBar forceMount />}
      </ScrollArea>
    </div>
  );
}

function ProcessResultTableRow({ process }: { process: ProcessResult }) {
  return (
    <TableRow>
      <TableCell className="whitespace-nowrap py-0">
        {process.topResult && <Star className="h-5 w-5" />}
      </TableCell>
      <TableCell className="whitespace-nowrap py-0">{process.number}</TableCell>
      <TableCell className="whitespace-nowrap py-0">{process.topic}</TableCell>
      <TableCell className="whitespace-nowrap py-0">
        {process.customerName}
      </TableCell>
      <TableCell className="whitespace-nowrap py-0">
        {formatDate(process.createdAt)}
      </TableCell>
      <TableCell className="whitespace-nowrap py-0">
        <NavigateToProcess process={process} />
      </TableCell>
    </TableRow>
  );
}

function NavigateToProcess({ process }: { process: ProcessResult }) {
  const { id, kind, companyId } = process;
  const { data: company } = useShowCompanyQuery({ id: companyId });
  if (!company) {
    return null;
  }

  const tenant = company.portalRoute;
  const path = `/tenant/${tenant}/processes/${kind}/${id}`;

  return (
    <NavLink to={path}>
      <Button variant="ghost" size="sm">
        <span>{t("Navigieren")}</span>
      </Button>
    </NavLink>
  );
}
