import { backendApi } from "@/services/backend/api";
import {
  AddressList,
  Area,
  AreaList,
  Building,
  BuildingAddress,
  BuildingList,
  CreateAreaRequest,
  CreateBuildingAddressRequest,
  CreateBuildingRequest,
  CreateLevelRequest,
  DeleteAreaRequest,
  DeleteBuildingRequest,
  DeleteLevelRequest,
  GetAddressesRequest,
  GetAreaRequest,
  GetBuildingRequest,
  GetLevelRequest,
  Level,
  LevelList,
} from "@/services/backend/qpm/buildings/types";

export function providesList<
  R extends { id: string | number }[],
  T extends string,
>(resultsWithIds: R | undefined, tagType: T) {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}

const qpmBuildingServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["QpmAddress", "QpmBuilding", "QpmLevel", "QpmArea"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmBuildingCreate: build.mutation<Building, CreateBuildingRequest>({
        query: (request) => ({
          url: `/qpm/building`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmBuilding"],
      }),
      qpmBuildingGet: build.query<Building, GetBuildingRequest>({
        query: (request) => ({
          url: `/qpm/building`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmBuilding", id: result.id }] : [],
      }),
      qpmBuildingList: build.query<BuildingList, GetBuildingRequest>({
        query: (request) => ({
          url: `/qpm/building/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [
                { type: "QpmBuilding", id: "LIST" },
                ...result.buildings.map(({ id }) => ({
                  type: "QpmBuilding" as const,
                  id,
                })),
              ]
            : [{ type: "QpmBuilding", id: "LIST" }],
      }),
      qpmBuildingAddressList: build.query<AddressList, GetAddressesRequest>({
        query: (request) => ({
          url: `/qpm/building/address/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [
                { type: "QpmAddress", id: "LIST" },
                ...result.addresses.map(({ id }) => ({
                  type: "QpmAddress" as const,
                  id,
                })),
              ]
            : [{ type: "QpmAddress", id: "LIST" }],
      }),
      qpmBuildingAddressAdd: build.mutation<
        BuildingAddress,
        CreateBuildingAddressRequest
      >({
        query: (request) => ({
          url: `/qpm/building/address`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmAddress"],
      }),
      qpmLevelAdd: build.mutation<Level, CreateLevelRequest>({
        query: (request) => ({
          url: `/qpm/building/level`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmLevel", "QpmBuilding"],
      }),
      qpmLevelList: build.query<LevelList, GetLevelRequest>({
        query: (request) => ({
          url: `/qpm/building/level/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [
                { type: "QpmLevel", id: "LIST" },
                ...result.map(({ base }) => ({
                  type: "QpmLevel" as const,
                  id: base.id,
                })),
              ]
            : [{ type: "QpmLevel", id: "LIST" }],
      }),
      qpmAreaAdd: build.mutation<Area, CreateAreaRequest>({
        query: (request) => ({
          url: `/qpm/building/area`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmArea", "QpmLevel", "QpmBuilding"],
      }),
      qpmAreaList: build.query<AreaList, GetAreaRequest>({
        query: (request) => ({
          url: `/qpm/building/area/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [
                { type: "QpmArea", id: "LIST" },
                ...result.map(({ base }) => ({
                  type: "QpmArea" as const,
                  id: base.id,
                })),
              ]
            : [{ type: "QpmArea", id: "LIST" }],
      }),
      qpmBuildingDelete: build.mutation<null, DeleteBuildingRequest>({
        query: (request) => ({
          url: `/qpm/building`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: "QpmBuilding", id: "LIST" },
          { type: "QpmLevel", id },
        ],
      }),
      qpmLevelDelete: build.mutation<null, DeleteLevelRequest>({
        query: (request) => ({
          url: `/qpm/building/level`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: "QpmBuilding", id: "LIST" },
          { type: "QpmLevel", id },
        ],
      }),
      qpmAreaDelete: build.mutation<null, DeleteAreaRequest>({
        query: (request) => ({
          url: `/qpm/building/area`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: "QpmBuilding", id: "LIST" },
          { type: "QpmArea", id },
        ],
      }),
    }),
  });

export const {
  useQpmBuildingCreateMutation,
  useQpmBuildingGetQuery,
  useQpmBuildingListQuery,
  useQpmBuildingAddressListQuery,
  useQpmBuildingAddressAddMutation,
  useQpmAreaAddMutation,
  useQpmLevelAddMutation,
  useQpmAreaListQuery,
  useQpmLevelListQuery,
  useQpmBuildingDeleteMutation,
  useQpmLevelDeleteMutation,
  useQpmAreaDeleteMutation,
} = qpmBuildingServiceApi;
