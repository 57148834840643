import { useEffect, useState } from "react";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { Button } from "@/shared/components/ui/button";
import { Copy } from "lucide-react";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { Position } from "@/services/backend/rlt/treatments/position";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { v4 } from "uuid";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import {
  CopyPositionRequest,
  useRltTreatmentCopyPositionMutation,
  useRltTreatmentShowQuery,
} from "@/services/backend/rlt/treatments/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function CopyPositionDialog({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<CopyPositionRequest>({
    id: treatmentId,
    oldPositionId: position.id,
    newPositionId: "",
    newArea: "",
  });
  const [copyPosition, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentCopyPositionMutation();

  if (isSuccess) {
    setOpen(false);
    setRequest({ ...request, newPositionId: "", newArea: "" });
    reset();
  }

  const doCopy = () => {
    if (isLoading) {
      return;
    }
    copyPosition({
      ...request,
      newPositionId: v4(),
    });
  };

  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    if (isLoading) {
      setPersistenceState(PersistenceState.SAVING);
    } else if (error) {
      setPersistenceState(PersistenceState.ERROR);
    } else {
      setPersistenceState(PersistenceState.SAVED);
    }
  }, [isLoading, error, setPersistenceState]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant="outline">
          <Copy className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t(`Position ${position.number} wirklich kopieren?`)}
          </DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-1 gap-1.5">
          <Label>{t("Bereich")}</Label>
          <AreaSelect
            area={request.newArea}
            onAreaChange={(newArea) => setRequest({ ...request, newArea })}
            treatmentId={treatmentId}
          />
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doCopy}>{t("Kopieren")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function AreaSelect({
  area,
  onAreaChange,
  treatmentId,
}: {
  area: string;
  onAreaChange: (area: string) => void;
  treatmentId: string;
}) {
  const { data } = useRltTreatmentShowQuery({
    id: treatmentId,
  });

  const areas = [
    ...new Set(
      (data?.segments ?? [])
        .map((segment) => segment.areas.map((segArea) => segArea.area))
        .flat(1),
    ),
  ];

  return (
    <Select value={area} onValueChange={onAreaChange}>
      <SelectTrigger>
        <SelectValue placeholder={t("Bereich auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {areas.map((a) => (
            <SelectItem value={a}>{a}</SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
