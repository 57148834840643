import { Contract } from "@/services/backend/vbs/contracts/contract";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Comments } from "@/shared/components/domain/comment/comments";

export function ContractComments({ contract }: { contract: Contract }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Interne Kommentare")}</CardTitle>
        <CardDescription>
          {t(
            "Hier können interne Kommentare zum Vertrag erstellt und nachgeschlagen werden.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Comments ownerId={contract.id} ownerType="contract.Contract" />
      </CardContent>
    </Card>
  );
}
