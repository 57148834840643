import {
  addPhoto,
  deletePhoto,
  Position,
  updatePhoto,
} from "@/services/backend/htz/work-order/work-order";
import { useEffect, useState } from "react";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import { Button } from "@/shared/components/ui/button";
import { PlusCircle, RefreshCw, Trash, Trash2 } from "lucide-react";
import { v4 } from "uuid";
import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { Card } from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";
import { Photo } from "@/services/backend/htz/inspection/photo";
import { H4 } from "@/shared/components/ui/typography";
import { Separator } from "@/shared/components/ui/separator";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useHtzWorkOrderPhotoDocumentationDeleteImageMutation,
  useHtzWorkOrderPhotoDocumentationShowImageQuery,
  useHtzWorkOrderPhotoDocumentationUploadImageMutation,
} from "@/services/backend/htz/work-order/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import imageCompression from "browser-image-compression";
import { usePositionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/position-context";

export function PhotoDocumentation() {
  const [photoId, setPhotoId] = useState<string | null>(null);

  return (
    <ScrollArea className="h-[65vh] space-y-2">
      <PhotoTable photoId={photoId} onPhotoSelect={(p) => setPhotoId(p)} />
      <div className="p-2 py-4">
        <PhotoForm selectedPhotoId={photoId} />
      </div>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}

export function AddPhotoButton({ position }: { position: Position }) {
  const { workOrder } = useWorkOrderContext();
  const { onPositionChange } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const doAdd = () => {
    onPositionChange(addPhoto(position, activeComponent!.id, v4()));
  };

  const disabled = workOrder.immutable || activeComponent === null;

  return (
    <Button onClick={doAdd} disabled={disabled}>
      <PlusCircle />
    </Button>
  );
}

function PhotoTable({
  photoId,
  onPhotoSelect,
}: {
  photoId: string | null;
  onPhotoSelect: (id: string | null) => void;
}) {
  const { position } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const toggle = (photo: Photo) => {
    if (photo.id === photoId) {
      onPhotoSelect(null);
    } else {
      onPhotoSelect(photo.id);
    }
  };

  return (
    <Card className="shadow-none">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="h-8">{t("Element")}</TableHead>
            <TableHead className="h-8 whitespace-nowrap">
              {t("Foto ?")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {position.photos
            ?.filter((p) => p.componentId === activeComponent?.id)
            .map((photo) => (
              <TableRow
                key={photo.id}
                className={cn(
                  "cursor-pointer",
                  photo.id === photoId ? "bg-accent" : "",
                )}
              >
                <TableCell
                  className="w-full py-1"
                  onClick={() => toggle(photo)}
                >
                  {photo.element}
                </TableCell>
                <TableCell
                  className="w-full py-1"
                  onClick={() => toggle(photo)}
                >
                  {photo.imageId === null ? t("fehlt") : t("ja")}
                </TableCell>
                <TableCell className="py-1 text-right">
                  <DeletePhotoButton photoId={photo.id} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function DeletePhotoButton({ photoId }: { photoId: string }) {
  const { position, onPositionChange } = usePositionContext();

  const doDelete = () => {
    const wo = deletePhoto(position, photoId);
    onPositionChange(wo);
  };

  return (
    <Button
      size="sm"
      variant="destructive"
      onClick={doDelete}
      className="h-8 w-8 p-0"
    >
      <Trash2 className="h-4 w-4" />
    </Button>
  );
}

function PhotoForm({ selectedPhotoId }: { selectedPhotoId: string | null }) {
  const { position } = usePositionContext();
  const { workOrder } = useWorkOrderContext();
  const { onPositionChange, validationError } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const photo = position.photos?.find(
    (a) => a.id === selectedPhotoId && a.componentId === activeComponent?.id,
  );

  if (!photo) {
    return null;
  }

  const errorPrefix = `photos.${photo.id}.`;

  const onChange = (p: Photo) => onPositionChange(updatePhoto(position, p));

  return (
    <div className="h-full">
      <H4>{t("Foto")}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid content-start gap-1.5">
          <Label>{t("Element")}</Label>
          <Input
            type="text"
            value={photo.element}
            onChange={(e) => onChange({ ...photo, element: e.target.value })}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={validationError}
            field={`${errorPrefix}element`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Bemerkung")}</Label>
          <Textarea
            value={photo.comment}
            onChange={(e) => onChange({ ...photo, comment: e.target.value })}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={validationError}
            field={`${errorPrefix}comment`}
          />
        </div>
        <PhotoUpload position={position} photo={photo} />
        <PhotoDisplay position={position} photo={photo} />
      </div>
    </div>
  );
}

function PhotoUpload({
  position,
  photo,
}: {
  position: Position;
  photo: Photo;
}) {
  const { workOrder } = useWorkOrderContext();
  const { onPositionChange } = usePositionContext();
  // TODO, the upload must work offline
  //  * Idea would be to store them with their id in the index db
  //  * After coming online -> clear one after the other, like the ahus.
  //  * There should be a loading state when online
  //  * Save only images in cache, that where added offline. Old ones
  //    are just shown as offline / not available.

  const [upload, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPhotoDocumentationUploadImageMutation();

  const { toast } = useToast();

  const [imageId, setImageId] = useState<string | null>(null);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Bild hochgeladen"),
        description: t("Das Bild wurde erfolgreich hochgeladen."),
        variant: "success",
      });
      const p = { ...photo, imageId };
      onPositionChange(updatePhoto(position, p));
      reset();
      setImageId(null);
    }
  }, [
    imageId,
    isSuccess,
    onPositionChange,
    photo,
    position,
    position.id,
    reset,
    toast,
    workOrder,
  ]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const disabled = workOrder.immutable;

  return (
    <Label
      className={cn(
        "inline-flex w-full items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
        "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        "h-9 rounded-md px-3",
      )}
    >
      <Input
        type="file"
        className="hidden"
        accept="image/jpeg,image/png"
        onChange={async (e) => {
          if (e.target.files && e.target.files[0]) {
            // compress image
            const options = {
              maxSizeMB: 0.3,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            try {
              const compressedFile = await imageCompression(
                e.target.files[0],
                options,
              );
              const id = v4();
              // upload compressed image
              setImageId(id);
              upload({
                positionId: position.id,
                photoId: photo.id,
                imageId: id,
                file: compressedFile,
              });
              e.target.value = "";
              e.target.files = null;
            } catch (err) {
              const parse = parseRTKQueryError(err as FetchBaseQueryError);
              toast({
                title: parse?.title,
                description: parse?.description,
                variant: "destructive",
              });
            }
          }
        }}
        disabled={disabled}
      />
      {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
      <span>{t("Foto hinzufügen")}</span>
    </Label>
  );
}

function PhotoDisplay({
  photo,
  position,
}: {
  photo: Photo;
  position: Position;
}) {
  const {
    data: image,
    isLoading,
    error,
  } = useHtzWorkOrderPhotoDocumentationShowImageQuery(
    {
      imageId: photo.imageId!,
    },
    {
      skip: photo.imageId === null,
    },
  );

  if (photo.imageId === null) {
    return null;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    // TODO, if offline, attempt to load from cache / store?
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="relative">
      <img
        className="w-full rounded-lg"
        src={image!.objectURL}
        alt={photo.element}
      />
      <DeleteImageDialog photo={photo} position={position} />
    </div>
  );
}

function DeleteImageDialog({
  photo,
  position,
}: {
  photo: Photo;
  position: Position;
}) {
  const { workOrder } = useWorkOrderContext();
  const { onPositionChange } = usePositionContext();
  // TODO disable when offline, and only after image cache was synced
  const disabled = photo.imageId === null;

  const [open, setOpen] = useState(false);
  const [deleteImage, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPhotoDocumentationDeleteImageMutation();

  useEffect(() => {
    if (isSuccess) {
      reset();
      const p = { ...photo, imageId: null };
      onPositionChange(updatePhoto(position, p));
      setOpen(false);
    }
  }, [
    isSuccess,
    setOpen,
    reset,
    photo,
    onPositionChange,
    workOrder,
    position.id,
    position,
  ]);

  const onClick = () => {
    if (isLoading || photo.imageId === null) {
      return;
    }

    deleteImage({
      positionId: position.id,
      photoId: photo.id,
      imageId: photo.imageId,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={disabled}>
        <Button className="absolute right-4 top-4" variant="destructive">
          <Trash className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t("Bild wirklich löschen?")}</DialogTitle>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
