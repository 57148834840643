import { backendApi } from "../api";

const branchApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    putBranch: build.mutation<Branch, PutBranchRequest>({
      query: (request) => ({
        url: `branches/branch`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Branch"],
    }),
    branchesBranchShow: build.query<Branch, ShowBranchRequest>({
      query: (request) => ({
        url: `branches/branch`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "Branch", id: result.id }] : [],
    }),
    listBranch: build.query<List, ListBranchRequest>({
      query: (request) => ({
        url: `branches/branch/list`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result
          ? result.branches?.map((p) => ({ type: "Branch", id: p.id }))
          : [{ type: "Branch", id: "LIST" }],
    }),
    deleteBranch: build.mutation<Branch, DeleteBranchRequest>({
      query: (request) => ({
        url: `branches/branch`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["Branch"],
    }),
  }),
});

export const {
  usePutBranchMutation,
  useBranchesBranchShowQuery,
  useListBranchQuery,
  useDeleteBranchMutation,
} = branchApi;

export interface Branch {
  id: string;
  name: string;
  companyId: string;
}

export interface List {
  branches: Branch[];
  count: number;
}

export interface PutBranchRequest {
  id: string;
  name: string;
  companyId: string;
}

export interface ShowBranchRequest {
  id: string;
}

export interface DeleteBranchRequest {
  id: string;
}

export interface ListBranchRequest {
  companyId: string | undefined;
}
