import { Outlet, useOutletContext, useParams } from "react-router-dom";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { RequirementContextType } from "@/routes/gemex/processes/qpm/requirements/requirements-route";
import t from "@/lang/lang";
import { useQpmRequirementGetQuery } from "@/services/backend/qpm/requirements/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { Requirement } from "@/services/backend/qpm/requirements/types";
import { useEffect, useState } from "react";
import { useQpmBuildingAddressListQuery } from "@/services/backend/qpm/buildings/service";
import { Loader } from "lucide-react";
import { BuildingAddress } from "@/services/backend/qpm/buildings/types";

export type URLParams = {
  tenantId: string;
  processId: string;
  requirementId: string;
};

export type CreateRequirementContext = RequirementContextType & {
  requirement: Requirement;
  currentAddress: BuildingAddress;
};

export default function CreateRequirement() {
  const { requirementId, processId } = useParams<URLParams>();
  const requirementContext = useOutletContext<RequirementContextType>();
  const [currentAddress, setCurrentAddress] = useState<BuildingAddress>();
  const {
    data: requirement,
    error: errorRequirement,
    isLoading: isLoadingRequirement,
  } = useQpmRequirementGetQuery({
    id: requirementId ?? "",
  });

  const {
    data: addressList,
    error,
    isLoading: addressIsLoading,
  } = useQpmBuildingAddressListQuery({
    customerId: requirementContext.customerId,
  });

  useEffect(() => {
    if (addressList && addressList.addresses.length > 0) {
      setCurrentAddress(addressList.addresses[0]);
    }
  }, [addressList, setCurrentAddress]);

  if (addressIsLoading) {
    return <Loader />;
  }

  if (error || !currentAddress) {
    return <div>Fehler beim Laden der Addresse</div>;
  }

  if (errorRequirement) {
    return <RTKQueryErrorAlert error={errorRequirement} />;
  }

  if (isLoadingRequirement) {
    return <CreateRequirementSkeleton />;
  }

  if (!requirement || !processId) {
    return <div>Error loading Requirement!</div>;
  }

  return (
    <PersistenceContextProvider>
      <MilestonePageContent>
        <MilestonePageContentMenu>
          <div className="flex w-full flex-col space-y-3">
            <div className="flex w-full items-center justify-between">
              <TabsNavList>
                <TabsNavLink to="info">{t("Details")}</TabsNavLink>
                <TabsNavLink to="inventory">
                  {t("Bestandsaufnahme")}
                </TabsNavLink>
                <TabsNavLink to="additional-info">
                  {t("Zusätzliche Informationen")}
                </TabsNavLink>
                <TabsNavLink to="total">{t("Gesamtübersicht")}</TabsNavLink>
              </TabsNavList>
              <div className="mr-2">
                <PersistenceStateUsingContext />
              </div>
            </div>
          </div>
        </MilestonePageContentMenu>
        <Outlet
          context={{
            ...requirementContext,
            addressId: currentAddress.id,
            currentAddress,
            requirement,
          }}
        />
      </MilestonePageContent>
    </PersistenceContextProvider>
  );
}

function CreateRequirementSkeleton() {
  return (
    <div className="overflow-clip rounded-lg border shadow">
      <Tabs defaultValue="requirement-recording" className="">
        <TabsList className="grid h-12 w-full grid-cols-3 rounded-b-none border-b px-2">
          <TabsTrigger value="requirement-recording">
            {t("Materialaufnahme")}
          </TabsTrigger>
          <TabsTrigger value="additional-information">
            {t("Zusätzliche Informationen")}
          </TabsTrigger>
          <TabsTrigger value="complete-overview">
            {t("Gesamtübersicht")}
          </TabsTrigger>
        </TabsList>
        <TabsContent className="mt-0 p-2" value="requirement-recording">
          <Skeleton className="mb-2 h-24 w-full" />
          <Skeleton className="mb-2 h-24 w-full" />
          <Skeleton className="h-24 w-full" />
        </TabsContent>
        <TabsContent className="mt-0 p-2" value="additional-information">
          <Skeleton className="mb-2 h-24 w-full" />
          <Skeleton className="mb-2 h-24 w-full" />
          <Skeleton className="h-24 w-full" />
        </TabsContent>
        <TabsContent className="mt-0 p-2" value="complete-overview">
          <Skeleton className="mb-2 h-24 w-full" />
          <Skeleton className="mb-2 h-24 w-full" />
          <Skeleton className="h-24 w-full" />
        </TabsContent>
      </Tabs>
    </div>
  );
}
