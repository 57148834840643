import {
  createBrowserRouter,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { RootErrorBoundary } from "@/routes/root-error-boundary";
import React from "react";
import GemexRoot from "@/routes/gemex/gemex-root";
import GesecRoot from "@/routes/gesec/gesec-root";
import Root from "@/routes/root";
import { baseDataRoutes } from "@/routes/base-data/base-data-routes";
import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import {
  Company,
  useListCompanyQuery,
  useShowCompanyQuery,
} from "@/services/backend/company/company";
import { useUserCompanies } from "@/shared/lib/authorization/useUserCompanies";
import BaseDataRoute from "@/routes/base-data/base-data";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/shared/components/ui/dialog";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { Terminal } from "lucide-react";
import { customersRoutes } from "@/routes/customers/customers-routes";
import { CustomersRoute } from "@/routes/customers/customers-route";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "base-data",
        element: <BaseDataRoute />,
        children: baseDataRoutes,
      },
      {
        path: "customers",
        element: <CustomersRoute />,
        children: customersRoutes,
      },
      {
        path: "tenant/:tenant/*",
        element: <TenantRoute />,
      },
    ],
    errorElement: <RootErrorBoundary />,
  },
]);

export default router;

function TenantRoute() {
  const { tenant } = useParams<"tenant">();
  const { tenantCompany, isLoading, error } = useTenantCompany(tenant);
  const userCanAccess = useUserCanAccess(tenantCompany);
  const { companyId, setCompanyId } = useActiveCompany();
  const { data: activeCompany } = useShowCompanyQuery({ id: companyId });
  const navigate = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!userCanAccess) {
    return <Alert403 />;
  }

  const updateActiveCompany = () => {
    setCompanyId(tenantCompany!.id);
  };

  const updateRoute = () => {
    if (!activeCompany) {
      return;
    }
    const postfix = location.pathname.split("/").slice(3).join("/");
    const newRoute = `/tenant/${activeCompany.portalRoute}/${postfix}`;
    navigate(newRoute, { replace: true });
  };

  if (companyId !== tenantCompany!.id) {
    return (
      <ResolveConflictDialog
        updateActiveCompany={updateActiveCompany}
        updateRoute={updateRoute}
      />
    );
  }

  const tenantComponents: { [key: string]: React.JSX.Element } = {
    gemex: <GemexRoot />,
    gesec: <GesecRoot />,
  };

  return tenantComponents[tenant!] || <Alert404 />;
}

function ResolveConflictDialog({
  updateActiveCompany,
  updateRoute,
}: {
  updateActiveCompany: () => void;
  updateRoute: () => void;
}) {
  return (
    <Dialog open>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Unternehmenswechsel")}</DialogTitle>
        </DialogHeader>
        <div className="text-muted-foreground">
          {t(
            "Sie sind dabei das Unternehmen zu wechseln. Dadurch wird die aktuelle Ansicht verlassen.",
          )}
        </div>
        <DialogFooter>
          <Button onClick={updateActiveCompany}>
            {t("In Ansicht bleiben")}
          </Button>
          <Button onClick={updateRoute}>{t("Unternehmen wechseln")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function Alert404() {
  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>404</AlertTitle>
      <AlertDescription>{t("Nicht gefunden")}</AlertDescription>
    </Alert>
  );
}

function Alert403() {
  return (
    <Alert variant="destructive">
      <Terminal className="h-4 w-4" />
      <AlertTitle>403</AlertTitle>
      <AlertDescription>{t("Nicht erlaubt")}</AlertDescription>
    </Alert>
  );
}

function useTenantCompany(tenant: string | undefined) {
  const { data, isLoading, error } = useListCompanyQuery({});

  const company = data?.companies.find((c) => c.portalRoute === tenant);

  return {
    tenantCompany: company,
    isLoading,
    error,
  };
}

function useUserCanAccess(company: Company | undefined): boolean | undefined {
  const { companyIds } = useUserCompanies();
  return companyIds?.some((id) => id === company?.id);
}
