import { Filter } from "@/shared/lib/filter/filter";
import { backendApi } from "@/services/backend/api";
import { Order } from "./order";

const orderService = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltOrderPut: build.mutation<Order, PutRequest>({
      query: (request) => ({
        url: `/rlt/order`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (result, _e, request) => [
        { type: "RltOrder", id: request.id },
        { type: "RltOrder", id: "LIST" },
        { type: "RltTreatment", id: result?.treatmentId },
        { type: "RltCalculation", id: result?.calculationId },
      ],
    }),
    rltOrderShow: build.query<Order, ShowRequest>({
      query: (request) => ({
        url: `/rlt/order`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltOrder", id: result.id }] : [],
    }),
    rltOrderList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/rlt/order/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...(result.orders?.map(({ id }) => ({
                type: "RltOrder" as const,
                id,
              })) ?? []),
              { type: "RltOrder", id: "LIST" },
            ]
          : [{ type: "RltOrder", id: "LIST" }],
    }),
    rltOrderUploadFile: build.mutation<Order, UploadFileRequest>({
      query: (request) => {
        const body = new FormData();
        body.append("orderId", request.orderId);
        body.append("fileId", request.fileId);
        body.append("file", request.file, request.file.name);
        body.append("filename", request.filename);
        return {
          url: `/rlt/order/files`,
          method: "POST",
          headers: {
            //  this is needed, otherwise a 415 error is returned
            Accept: "application/json",
          },
          body,
          formData: true,
        };
      },
      invalidatesTags: (_r, _e, request) => [
        { type: "RltOrder", id: request.orderId },
      ],
    }),
    rltOrderDelete: build.mutation({
      query: (request) => ({
        url: `rlt/order`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltOrder", id: "LIST" },
        { type: "RltOrder", id: request.id },
      ],
    }),
    rltOrderShowFile: build.query<{ objectURL: string }, ShowFileRequest>({
      query: (request) => ({
        url: `/rlt/order/files`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
    }),
    rltOrderDeleteFile: build.mutation<null, DeleteFileRequest>({
      query: (request) => ({
        url: `/rlt/order/files`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltOrder", id: request.orderId },
      ],
    }),
  }),
});

export const {
  useRltOrderPutMutation,
  useRltOrderShowQuery,
  useRltOrderListQuery,
  useRltOrderUploadFileMutation,
  useRltOrderDeleteMutation,
  useRltOrderShowFileQuery,
  useRltOrderDeleteFileMutation,
} = orderService;

export interface PutRequest {
  id: string;
  processId: string | null;
  offerId: string | null;
  date: string | null;
  channel: string | null;
  customerReference: string | null;
  comment: string | null;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  date?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  orders: Order[];
  count: number;
}

export interface UploadFileRequest {
  orderId: string;
  fileId: string;
  filename: string;
  file: File;
}

export interface ShowFileRequest {
  orderId: string;
  fileId: string;
}

export interface DeleteFileRequest {
  orderId: string;
  fileId: string;
}
