import {
  Context,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { DustDensitySample } from "@/services/backend/samples/sample/dust-density-sample";
import {
  useSamplesDustDensitySampleDeleteMutation,
  useSamplesDustDensitySamplePutMutation,
} from "@/services/backend/samples/sample/dust-density-sample-service";

interface DustDensitySampleContextInterface {
  // what the context was initialized with
  initialSamples: DustDensitySample[];
  // what was added from within the context
  newSamples: DustDensitySample[];
  addSample: (set: DustDensitySample) => unknown;
  removeNewSample: (setId: EntityId) => unknown;
  // what was deleted from within the context
  deletedSamples: EntityId[];
  deleteSample: (setId: EntityId) => unknown;
  removeDeletedSample: (setId: EntityId) => unknown;
  // what was updated from within the context
  updatedSamples: DustDensitySample[];
  updateSample: (set: DustDensitySample) => unknown;
  removeUpdatedSample: (setId: EntityId) => unknown;
  // the current state of the context
  samples: DustDensitySample[];
  // setSets: Dispatch<SetStateAction<DustDensitySample[]>>;
  showError: (setId: string) => unknown;
}

const DustDensitySamplesContext: Context<DustDensitySampleContextInterface> =
  createContext<DustDensitySampleContextInterface>({
    initialSamples: [],
    newSamples: [],
    addSample: () => null,
    removeNewSample: () => null,
    deletedSamples: [],
    deleteSample: () => null,
    removeDeletedSample: () => null,
    updatedSamples: [],
    updateSample: () => null,
    removeUpdatedSample: () => null,
    samples: [],
    showError: () => null,
  });

export function useDustDensitySamplesContext() {
  return useContext(DustDensitySamplesContext);
}

export function DustDensitySamplesContextProvider({
  initialSamples,
  children,
}: {
  initialSamples: DustDensitySample[];
  children: ReactNode;
}) {
  const [initialSamplesState, setInitialSamplesState] =
    useState<DustDensitySample[]>(initialSamples);
  const [newSamples, setNewSamples] = useState<DustDensitySample[]>([]);
  const [deletedSamples, setDeletedSamples] = useState<EntityId[]>([]);
  const [updatedSamples, setUpdatedSamples] = useState<DustDensitySample[]>([]);

  // update runs after successful sync with the backend
  useEffect(() => {
    setInitialSamplesState(initialSamples);
  }, [initialSamples]);

  // The current state of sets is computed.
  const samples = useMemo(() => {
    const allSamples = [...initialSamplesState, ...newSamples];
    const updated = allSamples.map((sample) => {
      const updatedSample = updatedSamples.find((u) => u.id === sample.id);
      return updatedSample ?? sample;
    });
    return updated.filter((set) => !deletedSamples.includes(set.id));
  }, [initialSamplesState, newSamples, deletedSamples, updatedSamples]);

  const value = useMemo(
    () => ({
      initialSamples: initialSamplesState,
      newSamples,
      addSample: (sample: DustDensitySample) =>
        setNewSamples((prev) => putSet(prev, sample)),
      removeNewSample: (setId: EntityId) =>
        setNewSamples((prev) => prev.filter((s) => s.id !== setId)),
      deletedSamples,
      deleteSample: (setId: EntityId) =>
        setDeletedSamples((prev) => [...prev, setId]),
      removeDeletedSample: (setId: EntityId) =>
        setDeletedSamples((prev) => prev.filter((id) => id !== setId)),
      updatedSamples,
      updateSample: (set: DustDensitySample) =>
        setUpdatedSamples((prev) => putSet(prev, set)),
      removeUpdatedSample: (setId: EntityId) =>
        setUpdatedSamples((prev) => prev.filter((s) => s.id !== setId)),
      samples,
      showError: () => null, // TODO, implement after backend sync
    }),
    [initialSamplesState, newSamples, deletedSamples, updatedSamples, samples],
  );

  return (
    <DustDensitySamplesContext.Provider value={value}>
      <Updater>
        <Deleter>{children}</Deleter>
      </Updater>
    </DustDensitySamplesContext.Provider>
  );
}

function Updater({ children }: PropsWithChildren) {
  const {
    newSamples,
    updatedSamples,
    deletedSamples,
    removeUpdatedSample,
    removeNewSample,
  } = useDustDensitySamplesContext();

  // "update" is the set to be updated next.
  // It will be picked up by the debounced mutation below.
  const [nextUpdate, setNextUpdate] = useState<DustDensitySample>(
    {} as DustDensitySample, // initial set will be ignored by the mutation
  );

  // schedule the next update
  useEffect(() => {
    if (newSamples.length > 0) {
      const candidate = newSamples[0];
      if (!deletedSamples.includes(candidate.id)) {
        setNextUpdate(newSamples[0]);
      }
    }

    if (updatedSamples.length > 0) {
      const candidate = updatedSamples[0];
      if (!deletedSamples.includes(candidate.id)) {
        setNextUpdate(updatedSamples[0]);
      }
    }
  }, [deletedSamples, newSamples, removeUpdatedSample, updatedSamples]);

  const [put, { isLoading, error, isSuccess, reset }] =
    useSamplesDustDensitySamplePutMutation();

  const decoratedReset = () => {
    reset();
    if (nextUpdate.id) {
      removeNewSample(nextUpdate.id);
      removeUpdatedSample(nextUpdate.id);
    }
  };

  useDebouncedMutationWithPersistenceStateContextUpdate(
    nextUpdate,
    put,
    isLoading,
    error,
    isSuccess,
    decoratedReset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}

function Deleter({ children }: PropsWithChildren) {
  const { deletedSamples, removeDeletedSample } =
    useDustDensitySamplesContext();

  const [nextDelete, setNextDelete] = useState<EntityId>("");

  // schedule the next delete
  useEffect(() => {
    if (deletedSamples.length > 0) {
      setNextDelete(deletedSamples[0]);
    }
  }, [deletedSamples]);

  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useSamplesDustDensitySampleDeleteMutation();

  const request = useMemo(() => ({ id: String(nextDelete) }), [nextDelete]);

  const decoratedReset = () => {
    reset();
    if (nextDelete) {
      removeDeletedSample(nextDelete);
    }
  };

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    doDelete,
    isLoading,
    error,
    isSuccess,
    decoratedReset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}

export function getDustDensitySample(
  sets: DustDensitySample[],
  setId: EntityId,
): DustDensitySample | null {
  return sets.find((s) => s.id === setId) ?? null;
}

function putSet(sets: DustDensitySample[], set: DustDensitySample) {
  const idx = sets.findIndex((s) => s.id === set.id);
  if (idx === -1) {
    return [...sets, set];
  }
  return sets.map((s, i) => (i === idx ? set : s));
}
