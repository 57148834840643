import { backendApi } from "@/services/backend/api";
import { List } from "./default-contact";

const contactsDefaultContactApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    contactsDefaultContactList: build.query<List, ListRequest>({
      query: (request) => ({
        url: "/contacts/default-contact/list",
        method: "GET",
        params: request,
      }),
    }),
  }),
});

export const { useContactsDefaultContactListQuery } = contactsDefaultContactApi;

export interface ListRequest {
  processId: string;
}
