import {
  ComplementaryServiceType,
  PropertyType,
  ServicePropertyUtil,
} from "@/services/backend/qpm/complementary-services/types";
import {
  useQpmRequirementComplementaryServiceAddMutation,
  useQpmRequirementComplementaryServiceListQuery,
  useQpmRequirementSelectedMonitorSummaryQuery,
} from "@/services/backend/qpm/requirements/service";
import { useCallback, useEffect } from "react";
import {
  QPMRequirementComplementaryServiceProperty,
  QpmRequirementServiceProperties,
} from "@/services/backend/qpm/requirements/types";
import { H4 } from "@/shared/components/ui/typography";
import { Checkbox } from "@/shared/components/ui/checkbox";
import {
  PropertyFields,
  PropertyTimeField,
} from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/additional-information/property-fields";
import { useToast } from "@/shared/hooks/use-toast";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function ComplementaryServiceComponent({
  requirementId,
}: {
  requirementId: string;
}) {
  const { toast } = useToast();
  const {
    data: qpmServices,
    error: qpmCompServiceError,
    isLoading: isLoadingReqCompServices,
  } = useQpmRequirementComplementaryServiceListQuery({
    requirementId: requirementId || "",
  });
  const {
    data: monitorSummary,
    error: errorMonitorSummary,
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isLoadingMonitorSummary,
  } = useQpmRequirementSelectedMonitorSummaryQuery({
    requirementId: {
      active: true,
      values: [requirementId],
    },
  });

  // TODO
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [add, { error: addError, isLoading: isLoadingAdd, reset }] =
    useQpmRequirementComplementaryServiceAddMutation();

  const addPropertyHandler = useCallback(
    (property: QPMRequirementComplementaryServiceProperty, value: number) => {
      if (value) {
        const requirementProperty: QpmRequirementServiceProperties = {
          requirementId: requirementId!,
          properties: [
            {
              property: { id: property.property.id },
              value,
            },
          ],
        };
        add(requirementProperty);
      }
    },
    [add, requirementId],
  );

  useEffect(() => {
    if (addError) {
      toast({
        ...parseRTKQueryError(addError),
      });
      reset();
    }
  }, [addError, reset, toast]);

  if (qpmCompServiceError || !qpmServices) {
    return <div>An error occurred while fetching complementary services</div>;
  }

  if (errorMonitorSummary) {
    return <RTKQueryErrorAlert error={errorMonitorSummary} />;
  }

  if (isLoadingReqCompServices || isLoadingReqCompServices) {
    return <ComplementaryServiceSkeleton />;
  }

  return (
    <div className="flex flex-col border-b py-2">
      <H4>Leistungen und Zeit</H4>
      <div className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-4">
        {qpmServices.services.map((service) => (
          <div
            key={service.complementaryService.key}
            className="flex justify-between gap-2"
          >
            <div className="flex flex-nowrap items-center gap-2">
              <Checkbox className="" />
              <div className="flex-nowrap font-semibold">
                {service.complementaryService.name}
              </div>
              {service.properties.find(
                (property) => property.property.type === PropertyType.TimeAfter,
              ) && <span className="">nach</span>}
            </div>
            {service.complementaryService.key ===
              ComplementaryServiceType.InstallationQPM &&
              monitorSummary &&
              service.properties.map((property) => (
                <PropertyTimeField
                  key={property.property.id}
                  onValueChange={(value) => {
                    addPropertyHandler(property, value);
                  }}
                  displayValue={monitorSummary.totalInstallationTimeSeconds}
                  displayUnit={ServicePropertyUtil.toString(
                    property.property.unit,
                  )}
                  immutable
                />
              ))}
            {service.complementaryService.key ===
              ComplementaryServiceType.ServiceQPM &&
              monitorSummary &&
              service.properties.map((property) => (
                <PropertyTimeField
                  key={property.property.id}
                  onValueChange={(value) => {
                    addPropertyHandler(property, value);
                  }}
                  displayValue={monitorSummary.totalServiceTimeSeconds}
                  displayUnit={ServicePropertyUtil.toString(
                    property.property.unit,
                  )}
                  immutable
                />
              ))}
            {service.complementaryService.key ===
              ComplementaryServiceType.ServiceRFID &&
              monitorSummary &&
              service.properties.map((property) => (
                <PropertyTimeField
                  key={property.property.id}
                  onValueChange={(value) => {
                    addPropertyHandler(property, value);
                  }}
                  displayValue={property.value}
                  displayUnit={ServicePropertyUtil.toString(
                    property.property.unit,
                  )}
                  immutable={false}
                />
              ))}
            {service.complementaryService.key !==
              ComplementaryServiceType.InstallationQPM &&
              service.complementaryService.key !==
                ComplementaryServiceType.ServiceQPM &&
              service.complementaryService.key !==
                ComplementaryServiceType.ServiceRFID &&
              service.properties.map((property) => (
                <PropertyFields
                  value={property.value}
                  displayUnit={ServicePropertyUtil.toString(
                    property.property.unit,
                  )}
                  key={property.property.id}
                  step={ServicePropertyUtil.inputStep(property.property.unit)}
                  onValueChange={(value) => {
                    addPropertyHandler(property, value);
                  }}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function ComplementaryServiceSkeleton() {
  const skeletons = [];

  for (let i = 0; i < 6; i += 1) {
    skeletons.push(<Skeleton className="h-10" key={i} />);
  }

  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>Leistungen und Zeit</H4>
      <div className="flex flex-col flex-wrap gap-x-2 gap-y-1.5 xl:grid xl:grid-cols-2">
        {skeletons}
        {/* {skeletons.map((skeleton) => ( */}
        {/*   <div className="w-full">{skeleton}</div> */}
        {/* ))} */}
      </div>
    </div>
  );
}
