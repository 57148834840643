import { Position } from "@/services/backend/vbs/treatments/position";
import { H4 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import {
  AccessPanel,
  AerosolSeparator,
  AirHandlingUnit,
  AluminiumMeshGreaseFilter,
  Anemostat,
  BuildUp,
  Construction,
  DummyPlate,
  ExhaustDuctAngular,
  ExhaustDuctRound,
  ExhaustHood,
  ExhaustHoodSystem,
  ExtraHours,
  FreeText,
  Kind,
  Lighting,
  PassiveArea,
  SupplyDuctAngular,
  SupplyDuctRound,
  VentilatedCeiling,
  VentilatedCeilingSystem,
} from "@/services/backend/vbs/treatments/component";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  UpdateActuallyTreatedRequest,
  useVbsTreatmentUpdateActuallyTreatedMutation,
} from "@/services/backend/vbs/treatments/service";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function ComponentData({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  return (
    <div>
      <div>
        <H4>{t("Basisdaten")}</H4>
        <div className="mb-2 text-sm text-muted-foreground">
          {t(
            "Wenn notwendig können hier Abweichungen von von den beauftragten Arbeiten erfasst werden.",
          )}
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="h-10">{t("Komponente")}</TableHead>
              <TableHead className="h-10">{t("Laut Aufnahme")}</TableHead>
              <TableHead className="h-10">
                {t("Tatsächlich erledigt")}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Data treatmentId={treatmentId} position={position} />
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

function Data({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const [request, setRequest] = useState<UpdateActuallyTreatedRequest>({
    id: treatmentId,
    positionId: position.id,
    component: position.actuallyTreated,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentUpdateActuallyTreatedMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  switch (position.component.kind) {
    case Kind.AccessPanel:
      return (
        <AccessPanelData
          data={position.component.data as AccessPanel}
          actual={request.component.data as AccessPanel}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.AerosolSeparator:
      return (
        <AerosolSeparatorData
          data={position.component.data as AerosolSeparator}
          actual={request.component.data as AerosolSeparator}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.AirHandlingUnit:
      return (
        <AirHandlingUnitData
          data={position.component.data as AirHandlingUnit}
          actual={request.component.data as AirHandlingUnit}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.AluminiumMeshGreaseFilter:
      return (
        <AluminiumMeshGreaseFilterData
          data={position.component.data as AluminiumMeshGreaseFilter}
          actual={request.component.data as AluminiumMeshGreaseFilter}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.Anemostat:
      return (
        <AnemostatData
          data={position.component.data as Anemostat}
          actual={request.component.data as Anemostat}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.DummyPlate:
      return (
        <DummyPlateData
          data={position.component.data as DummyPlate}
          actual={request.component.data as DummyPlate}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.ExhaustDuctAngular:
      return (
        <ExhaustDuctAngularData
          data={position.component.data as ExhaustDuctAngular}
          actual={request.component.data as ExhaustDuctAngular}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.ExhaustDuctRound:
      return (
        <ExhaustDuctRoundData
          data={position.component.data as ExhaustDuctRound}
          actual={request.component.data as ExhaustDuctRound}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.ExhaustHood:
      return (
        <ExhaustHoodData
          data={position.component.data as ExhaustHood}
          actual={request.component.data as ExhaustHood}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.ExhaustHoodSystem:
      return (
        <ExhaustHoodSystemData
          data={position.component.data as ExhaustHoodSystem}
          actual={request.component.data as ExhaustHoodSystem}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.ExtraHours:
      return (
        <ExtaHoursData
          data={position.component.data as ExtraHours}
          actual={request.component.data as ExtraHours}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.FreeText:
      return (
        <FreeTextData
          data={position.component.data as FreeText}
          actual={request.component.data as FreeText}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.Lighting:
      return (
        <LightingData
          data={position.component.data as Lighting}
          actual={request.component.data as Lighting}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.PassiveArea:
      return (
        <PassiveAreaData
          data={position.component.data as PassiveArea}
          actual={request.component.data as PassiveArea}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.SupplyDuctAngular:
      return (
        <SupplyDuctAngularData
          data={position.component.data as SupplyDuctAngular}
          actual={request.component.data as SupplyDuctAngular}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.SupplyDuctRound:
      return (
        <SupplyDuctRoundData
          data={position.component.data as SupplyDuctRound}
          actual={request.component.data as SupplyDuctRound}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.VentilatedCeiling:
      return (
        <VentilatedCeilingData
          data={position.component.data as VentilatedCeiling}
          actual={request.component.data as VentilatedCeiling}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    case Kind.VentilatedCeilingSystem:
      return (
        <VentilatedCeilingSystemData
          data={position.component.data as VentilatedCeilingSystem}
          actual={request.component.data as VentilatedCeilingSystem}
          onActualChange={(data) => {
            setRequest((prev) => ({
              ...prev,
              component: {
                ...prev.component,
                data,
              },
            }));
          }}
        />
      );
    default:
      return <div />;
  }
}

function AccessPanelData({
  data,
  actual,
  onActualChange,
}: {
  data: AccessPanel;
  actual: AccessPanel;
  onActualChange: (data: AccessPanel) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AccessPanel)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={data.count}
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                count: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AerosolSeparatorData({
  data,
  actual,
  onActualChange,
}: {
  data: AerosolSeparator;
  actual: AerosolSeparator;
  onActualChange: (data: AerosolSeparator) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AerosolSeparator)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={data.count}
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                count: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AirHandlingUnitData({
  data,
  actual,
  onActualChange,
}: {
  data: AirHandlingUnit;
  actual: AirHandlingUnit;
  onActualChange: (data: AirHandlingUnit) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.AirHandlingUnit)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={data.cleanTime}
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={actual.cleanTime}
            onChange={(e) =>
              onActualChange({
                ...actual,
                cleanTime: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AluminiumMeshGreaseFilterData({
  data,
  actual,
  onActualChange,
}: {
  data: AluminiumMeshGreaseFilter;
  actual: AluminiumMeshGreaseFilter;
  onActualChange: (data: AluminiumMeshGreaseFilter) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">
        {t(Kind.AluminiumMeshGreaseFilter)}
      </TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={data.count}
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                count: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function AnemostatData({
  data,
  actual,
  onActualChange,
}: {
  data: Anemostat;
  actual: Anemostat;
  onActualChange: (data: Anemostat) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.Anemostat)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={data.count}
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                count: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function DummyPlateData({
  data,
  actual,
  onActualChange,
}: {
  data: DummyPlate;
  actual: DummyPlate;
  onActualChange: (data: DummyPlate) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.DummyPlate)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label htmlFor="length">{t("Anzahl:")}</Label>
          <Input
            value={data.count}
            className="h-8"
            type="number"
            id="length"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label htmlFor="length">{t("Anzahl:")}</Label>
          <Input
            value={actual.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                count: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            id="length"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function ExhaustDuctAngularData({
  data,
  actual,
  onActualChange,
}: {
  data: ExhaustDuctAngular;
  actual: ExhaustDuctAngular;
  onActualChange: (e: ExhaustDuctAngular) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExhaustDuctAngular)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="width">{t("Verschmutzung:")}</Label>
            <Input
              value={t(data.buildUp)}
              className="h-8"
              type="text"
              id="width"
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Höhe (mm):")}</Label>
            <Input
              value={data.height}
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (mm):")}</Label>
            <Input
              value={data.width}
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="width">{t("Verschmutzung:")}</Label>
            <BuildUpSelect
              value={actual.buildUp}
              onChange={(buildUp) =>
                onActualChange({
                  ...actual,
                  buildUp,
                })
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Höhe (mm):")}</Label>
            <Input
              value={actual.height}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  height: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (mm):")}</Label>
            <Input
              value={actual.width}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  width: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function BuildUpSelect({
  value,
  onChange,
}: {
  value: BuildUp;
  onChange: (e: BuildUp) => void;
}) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="h-8">
        <SelectValue>{t(value)}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t("Verschmutzung")}</SelectLabel>
          {Object.values(BuildUp).map((buildUp) => (
            <SelectItem value={buildUp} key={buildUp}>
              {t(buildUp)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function ExhaustDuctRoundData({
  data,
  actual,
  onActualChange,
}: {
  data: ExhaustDuctRound;
  actual: ExhaustDuctRound;
  onActualChange: (e: ExhaustDuctRound) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExhaustDuctRound)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="width">{t("Versch-mutzung:")}</Label>
            <Input
              value={data.buildUp}
              className="h-8"
              type="text"
              id="width"
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Durchmesser (mm):")}</Label>
            <Input
              value={data.diameter}
              className="h-8"
              type="number"
              placeholder={t("Durchmesser")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label htmlFor="width">{t("Versch-mutzung:")}</Label>
            <BuildUpSelect
              value={actual.buildUp}
              onChange={(buildUp) =>
                onActualChange({
                  ...actual,
                  buildUp,
                })
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Durchmesser (mm):")}</Label>
            <Input
              value={actual.diameter}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  diameter: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Durchmesser")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function ExhaustHoodData({
  data,
  actual,
  onActualChange,
}: {
  data: ExhaustHood;
  actual: ExhaustHood;
  onActualChange: (e: ExhaustHood) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExhaustHood)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              placeholder={t("Länge")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (m):")}</Label>
            <Input
              value={data.width}
              className="h-8"
              type="number"
              placeholder={t("Breite")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Länge")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (m):")}</Label>
            <Input
              value={actual.width}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  width: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Breite")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function ExhaustHoodSystemData({
  data,
  actual,
  onActualChange,
}: {
  data: ExhaustHoodSystem;
  actual: ExhaustHoodSystem;
  onActualChange: (e: ExhaustHoodSystem) => void;
}) {
  return (
    <>
      <ExhaustHoodData
        data={data.exhaustHood.data as ExhaustHood}
        actual={actual.exhaustHood.data as ExhaustHood}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            exhaustHood: {
              ...actual.exhaustHood,
              data: e,
            },
          });
        }}
      />
      <AerosolSeparatorData
        data={data.aerosolSeparator.data as AerosolSeparator}
        actual={actual.aerosolSeparator.data as AerosolSeparator}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            aerosolSeparator: {
              ...actual.aerosolSeparator,
              data: e,
            },
          });
        }}
      />
      <AluminiumMeshGreaseFilterData
        data={data.aluminiumMeshGreaseFilter.data as AluminiumMeshGreaseFilter}
        actual={
          actual.aluminiumMeshGreaseFilter.data as AluminiumMeshGreaseFilter
        }
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            aluminiumMeshGreaseFilter: {
              ...actual.aluminiumMeshGreaseFilter,
              data: e,
            },
          });
        }}
      />
      <LightingData
        data={data.lighting.data as Lighting}
        actual={actual.lighting.data as Lighting}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            lighting: {
              ...actual.lighting,
              data: e,
            },
          });
        }}
      />
      <DummyPlateData
        data={data.dummyPlate.data as DummyPlate}
        actual={actual.dummyPlate.data as DummyPlate}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            dummyPlate: {
              ...actual.dummyPlate,
              data: e,
            },
          });
        }}
      />
    </>
  );
}

function ExtaHoursData({
  data,
  actual,
  onActualChange,
}: {
  data: ExtraHours;
  actual: ExtraHours;
  onActualChange: (data: ExtraHours) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.ExtraHours)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={data.hours}
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Stunden:")}</Label>
          <Input
            value={actual.hours}
            onChange={(e) =>
              onActualChange({
                ...actual,
                hours: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Stunden")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function FreeTextData({
  data,
  actual,
  onActualChange,
}: {
  data: FreeText;
  actual: FreeText;
  onActualChange: (data: FreeText) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.FreeText)}</TableCell>
      <TableCell className="py-1">
        <div className="grid items-center gap-1.5">
          <Input
            value={data.text}
            className="h-8"
            type="text"
            placeholder={t("Beschreibung")}
            disabled
          />
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Euro:")}</Label>
            <Input
              value={data.price}
              className="h-8"
              type="number"
              placeholder={t("Euro")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid items-center gap-1.5">
          <Input
            value={actual.text}
            onChange={(e) =>
              onActualChange({
                ...actual,
                text: e.target.value,
              })
            }
            className="h-8"
            type="text"
            placeholder={t("Beschreibung")}
          />
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Euro:")}</Label>
            <Input
              value={actual.price}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  price: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Euro")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function LightingData({
  data,
  actual,
  onActualChange,
}: {
  data: Lighting;
  actual: Lighting;
  onActualChange: (data: Lighting) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.Lighting)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={data.count}
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
            disabled
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Anzahl:")}</Label>
          <Input
            value={actual.count}
            onChange={(e) =>
              onActualChange({
                ...actual,
                count: Number(e.target.value) || 0,
              })
            }
            className="h-8"
            type="number"
            placeholder={t("Anzahl")}
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function PassiveAreaData({
  data,
  actual,
  onActualChange,
}: {
  data: PassiveArea;
  actual: PassiveArea;
  onActualChange: (data: PassiveArea) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.PassiveArea)}</TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              placeholder={t("Länge")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (m):")}</Label>
            <Input
              value={data.width}
              className="h-8"
              type="number"
              placeholder={t("Breite")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Länge")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (m):")}</Label>
            <Input
              value={actual.width}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  width: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Breite")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function SupplyDuctAngularData({
  data,
  actual,
  onActualChange,
}: {
  data: SupplyDuctAngular;
  actual: SupplyDuctAngular;
  onActualChange: (data: SupplyDuctAngular) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.SupplyDuctAngular)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Höhe (mm):")}</Label>
            <Input
              value={data.height}
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (mm):")}</Label>
            <Input
              value={data.width}
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Höhe (mm):")}</Label>
            <Input
              value={actual.height}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  height: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (mm):")}</Label>
            <Input
              value={actual.width}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  width: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Höhe")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function SupplyDuctRoundData({
  data,
  actual,
  onActualChange,
}: {
  data: SupplyDuctRound;
  actual: SupplyDuctRound;
  onActualChange: (data: SupplyDuctRound) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.SupplyDuctRound)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
              disabled
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Durchmesser (mm):")}</Label>
            <Input
              value={data.diameter}
              className="h-8"
              type="number"
              placeholder={t("Durchmesser")}
              disabled
            />
          </div>
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label htmlFor="length">{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              id="length"
              placeholder={t("Länge")}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Durchmesser (mm):")}</Label>
            <Input
              value={actual.diameter}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  diameter: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Durchmesser")}
            />
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

function VentilatedCeilingData({
  data,
  actual,
  onActualChange,
}: {
  data: VentilatedCeiling;
  actual: VentilatedCeiling;
  onActualChange: (data: VentilatedCeiling) => void;
}) {
  return (
    <TableRow>
      <TableCell className="py-1">{t(Kind.VentilatedCeiling)}</TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-cols-2 items-center gap-1.5">
            <Label>{t("Länge (m):")}</Label>
            <Input
              value={data.length}
              className="h-8"
              type="number"
              placeholder={t("Länge")}
              disabled
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (m):")}</Label>
            <Input
              value={data.width}
              className="h-8"
              type="number"
              placeholder={t("Breite")}
              disabled
            />
          </div>
        </div>
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Bauart:")}</Label>
          <Input value={t(actual.construction)} className="h-8" disabled />
        </div>
      </TableCell>
      <TableCell className="space-y-1 py-1">
        <div className="grid grid-cols-2 gap-4">
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Länge (m):")}</Label>
            <Input
              value={actual.length}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  length: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Länge")}
            />
          </div>
          <div className="grid w-full grid-cols-2 items-center gap-1.5">
            <Label>{t("Breite (m):")}</Label>
            <Input
              value={actual.width}
              onChange={(e) =>
                onActualChange({
                  ...actual,
                  width: Number(e.target.value) || 0,
                })
              }
              className="h-8"
              type="number"
              placeholder={t("Breite")}
            />
          </div>
        </div>
        <div className="grid w-full grid-cols-2 items-center gap-1.5">
          <Label>{t("Bauart:")}</Label>
          <ConstructionSelect
            value={actual.construction}
            onChange={(construction) =>
              onActualChange({
                ...actual,
                construction,
              })
            }
          />
        </div>
      </TableCell>
    </TableRow>
  );
}

function ConstructionSelect({
  value,
  onChange,
}: {
  value: Construction;
  onChange: (e: Construction) => void;
}) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="h-8">
        <SelectValue>{t(value)}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t("Bauart")}</SelectLabel>
          {Object.values(Construction).map((construction) => (
            <SelectItem value={construction} key={construction}>
              {t(construction)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function VentilatedCeilingSystemData({
  data,
  actual,
  onActualChange,
}: {
  data: VentilatedCeilingSystem;
  actual: VentilatedCeilingSystem;
  onActualChange: (data: VentilatedCeilingSystem) => void;
}) {
  return (
    <>
      <VentilatedCeilingData
        data={data.ventilatedCeiling.data as VentilatedCeiling}
        actual={actual.ventilatedCeiling.data as VentilatedCeiling}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            ventilatedCeiling: {
              ...actual.ventilatedCeiling,
              data: e,
            },
          });
        }}
      />
      <AerosolSeparatorData
        data={data.aerosolSeparator.data as AerosolSeparator}
        actual={actual.aerosolSeparator.data as AerosolSeparator}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            aerosolSeparator: {
              ...actual.aerosolSeparator,
              data: e,
            },
          });
        }}
      />
      <LightingData
        data={data.lighting.data as Lighting}
        actual={actual.lighting.data as Lighting}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            lighting: {
              ...actual.lighting,
              data: e,
            },
          });
        }}
      />
      <DummyPlateData
        data={data.dummyPlate.data as DummyPlate}
        actual={actual.dummyPlate.data as DummyPlate}
        onActualChange={(e) => {
          onActualChange({
            ...actual,
            dummyPlate: {
              ...actual.dummyPlate,
              data: e,
            },
          });
        }}
      />
    </>
  );
}
