export enum ProcessKind {
  HTZ = "HTZ",
  QPM = "QPM",
  RLT = "RLT",
  UNDEFINED = "UNDEFINED",
  VBS = "VBS",
}

export const ProcessKindList: ProcessKind[] = [
  ProcessKind.HTZ,
  ProcessKind.QPM,
  ProcessKind.RLT,
  ProcessKind.VBS,
];

export function ParseProcessKind(str: string): ProcessKind | undefined {
  switch (str.toLowerCase()) {
    case "htz":
      return ProcessKind.HTZ;
    case "vbs":
      return ProcessKind.VBS;
    case "qpm":
      return ProcessKind.QPM;
    default:
      return ProcessKind.UNDEFINED;
  }
}
