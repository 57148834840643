import { Navigate, RouteObject } from "react-router-dom";
import { CalculationRoute } from "@/routes/gesec/processes/[processId]/rlt/calculations/[calculationId]/calculation-route";
import { CalculationTreatmentRoute } from "@/routes/gesec/processes/[processId]/rlt/calculations/[calculationId]/treatment/treatment-route";
import { CalculationCalculationRoute } from "@/routes/gesec/processes/[processId]/rlt/calculations/[calculationId]/calculation/calculation-route";
import { CalculationIndexRoute } from "@/routes/gesec/processes/[processId]/rlt/calculations/_components/calculation-index-route";

export const calculationsRoutes: RouteObject[] = [
  {
    index: true,
    element: <CalculationIndexRoute />,
  },
  {
    path: ":calculationId",
    element: <CalculationRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="treatment" replace />,
      },
      {
        path: "treatment",
        element: <CalculationTreatmentRoute />,
      },
      {
        path: "calculation",
        element: <CalculationCalculationRoute />,
      },
    ],
  },
];
