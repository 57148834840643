export interface PositionCatalog {
  id: string;
  name: string;
  companyId: string;
  items: Item[];
}

export function addItem(
  catalog: PositionCatalog,
  position: Item,
): PositionCatalog {
  if (catalog.items.some((pos) => pos.id === position.id)) {
    return catalog;
  }

  return {
    ...catalog,
    items: [...catalog.items, position],
  };
}

export function updateItem(
  catalog: PositionCatalog,
  position: Item,
): PositionCatalog {
  return {
    ...catalog,
    items: catalog.items.map((pos) => {
      if (pos.id === position.id) {
        return position;
      }
      return pos;
    }),
  };
}

export function deleteItem(
  catalog: PositionCatalog,
  positionId: string,
): PositionCatalog {
  return {
    ...catalog,
    items: catalog.items.filter((pos) => pos.id !== positionId),
  };
}

export interface Item {
  id: string;
  catalogId: string;
  category: string;
  title: string;
  copyTitle: boolean;
  description: string;
  copyDescription: boolean;
  unitPrice: number;
  copyUnitPrice: boolean;
  structuralInspectionRequired: boolean;
  visualInspectionRequired: boolean;
  microbiologicalInspectionRequired: boolean;
  photoDocumentationRequired: boolean;
}

export function newCatalogItem(id: string, catalogId: string): Item {
  return {
    id,
    catalogId,
    category: "",
    title: "",
    copyTitle: true,
    description: "",
    copyDescription: true,
    unitPrice: 0,
    copyUnitPrice: true,
    structuralInspectionRequired: false,
    visualInspectionRequired: false,
    microbiologicalInspectionRequired: false,
    photoDocumentationRequired: false,
  };
}
