import { Defaults, Key } from "@/services/backend/rlt/defaults/defaults";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { Input } from "@/shared/components/ui/input";
import { Calculation } from "@/services/backend/rlt/defaults/calculations";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Button } from "@/shared/components/ui/button";
import {
  AccessPanelFormula,
  AirHandlingUnitFormula,
  AirHandlingUnitSchwerpunktFormula,
  AwayAirDuctAngularFormula,
  AwayAirDuctRoundFormula,
  CrossFlowHeatExchangerFormula,
  ExhaustDuctAngularFormula,
  ExhaustDuctRoundFormula,
  ExtraHoursFormula,
  Kind,
  OutdoorAirDuctAngularFormula,
  OutdoorAirDuctRoundFormula,
  RecirculatingSprayHumidifierFormula,
  RotaryHeatExchangerFormula,
  SplitUnitFormula,
  SupplyDuctAngularFormula,
  SupplyDuctRoundFormula,
} from "@/services/backend/rlt/treatments/component";
import {
  PutRequest,
  useRltDefaultsPutMutation,
  useRltDefaultsShowQuery,
} from "@/services/backend/rlt/defaults/service";

export function CalculationRoute() {
  const { data, isLoading, error } = useRltDefaultsShowQuery({
    key: Key.Calculation,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading || !data) {
    return (
      <Card className="animate-pulse">
        <CardHeader>
          <CardTitle>{t("Allgemeine Daten")}</CardTitle>
          <CardDescription>
            {t(
              "Festlegung von allgemeinen Daten die für RLT Kalkulationen genutzt werden sollen.",
            )}
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
      <GeneralDataCard defaults={data} />
      <ExpensesCard defaults={data} />
      <div className="xl:col-span-2">
        <FormulaCard defaults={data} />
      </div>
    </div>
  );
}

function GeneralDataCard({ defaults }: { defaults: Defaults }) {
  const [request, setRequest] = useState<PutRequest>(defaults);
  const [put, { isLoading, error, isSuccess, reset }] =
    useRltDefaultsPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    150,
  );
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const data = request.data as Calculation;
  const setData = (calc: Calculation) => {
    setRequest({
      ...request,
      data: calc,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Allgemeine Daten")}</CardTitle>
        <CardDescription>
          {t(
            "Festlegung von allgemeinen Daten die für RLT Kalkulationen genutzt werden sollen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-full pl-6">{t("Parameter")}</TableHead>
              <TableHead>{t("Einheit")}</TableHead>
              <TableHead className="pr-6">{t("Standardwert")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="pl-6">{t("Stunden pro Manntag")}</TableCell>
              <TableCell className="whitespace-nowrap text-right">
                {t("Std / Tag")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.hoursInManDay}
                  onChange={(e) =>
                    setData({
                      ...data,
                      hoursInManDay: e.target.valueAsNumber,
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pl-6">{t("Manntagessatz")}</TableCell>
              <TableCell className="whitespace-nowrap text-right">
                {t("€ / Tag")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.manDayRate}
                  onChange={(e) =>
                    setData({ ...data, manDayRate: e.target.valueAsNumber })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pl-6">{t("Materialpauschale")}</TableCell>
              <TableCell className="whitespace-nowrap text-right">
                {t("%")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.materialFlatRate}
                  onChange={(e) =>
                    setData({
                      ...data,
                      materialFlatRate: e.target.valueAsNumber,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function ExpensesCard({ defaults }: { defaults: Defaults }) {
  const [request, setRequest] = useState<PutRequest>(defaults);
  const [put, { isLoading, error, isSuccess, reset }] =
    useRltDefaultsPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    150,
  );
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const data = request.data as Calculation;
  const setData = (calc: Calculation) => {
    setRequest({
      ...request,
      data: calc,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Spesen")}</CardTitle>
        <CardDescription>
          {t(
            "Festlegung von Standardwerten für Spesen die für RLT Kalkulationen genutzt werden sollen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-full pl-6">{t("Parameter")}</TableHead>
              <TableHead>{t("Einheit")}</TableHead>
              <TableHead className="pr-6">{t("Standardwert")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="pl-6">
                {t("Verpflegungspauschale Übernachtungsspesen")}
              </TableCell>
              <TableCell className="whitespace-nowrap text-right">
                {t("€")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.expenses.overnightAllowance}
                  onChange={(e) =>
                    setData({
                      ...data,
                      expenses: {
                        ...data.expenses,
                        overnightAllowance: e.target.valueAsNumber,
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pl-6">{t("Übernachtungskosten")}</TableCell>
              <TableCell className="whitespace-nowrap text-right">
                {t("€")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.expenses.overnightHotelRate}
                  onChange={(e) =>
                    setData({
                      ...data,
                      expenses: {
                        ...data.expenses,
                        overnightHotelRate: e.target.valueAsNumber,
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="pl-6">
                {t("Verpflegungspauschale Tagesspesen")}
              </TableCell>
              <TableCell className="whitespace-nowrap text-right">
                {t("€")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.expenses.perDiemAllowance}
                  onChange={(e) =>
                    setData({
                      ...data,
                      expenses: {
                        ...data.expenses,
                        perDiemAllowance: e.target.valueAsNumber,
                      },
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function FormulaCard({ defaults }: { defaults: Defaults }) {
  const [request, setRequest] = useState<PutRequest>(defaults);
  const [put, { isLoading, error, isSuccess, reset }] =
    useRltDefaultsPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    150,
  );
  const { toast } = useToast();

  useEffect(() => {
    setRequest((prevRequest) => {
      if (defaults !== prevRequest) {
        return defaults;
      }
      return prevRequest;
    });
  }, [defaults, setRequest]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const data = request.data as Calculation;
  const setData = (calc: Calculation) => {
    setRequest({
      ...request,
      data: calc,
    });
  };

  const updateFormulaParameters = <Parameter,>(
    formula: string,
    parameters: Parameter,
  ) => {
    setData({
      ...data,
      formulas: {
        ...data.formulas,
        [formula]: parameters,
      },
    });
  };

  return (
    <Card>
      <div className="flex items-start justify-between">
        <CardHeader>
          <CardTitle>{t("Formeln für Komponenten")}</CardTitle>
          <CardDescription>
            {t(
              "Festlegung von Standardwerten für die Formeln zur Berechnung der Stundensätze und Preise der Komponenten.",
            )}
          </CardDescription>
        </CardHeader>
        <div className="flex justify-end px-6 pt-6">
          <CopyHourlyRateButton defaults={defaults} />
        </div>
      </div>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-full pl-6">{t("Parameter")}</TableHead>
              <TableHead>{t("Einheit")}</TableHead>
              <TableHead className="pr-6">{t("Standardwert")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <ExhaustDuctAngularRow
              exhaustDuctAngular={data.formulas.exhaustDuctAngular}
              onChange={(exhaustDuctAngular) =>
                updateFormulaParameters(
                  "exhaustDuctAngular",
                  exhaustDuctAngular,
                )
              }
            />
            <ExhaustDuctRoundRow
              exhaustDuctRound={data.formulas.exhaustDuctRound}
              onChange={(exhaustDuctRound) =>
                updateFormulaParameters("exhaustDuctRound", exhaustDuctRound)
              }
            />
            <OutdoorAirDuctAngularRow
              outdoorAirDuctAngular={data.formulas.outdoorAirDuctAngular}
              onChange={(outdoorAirDuctAngular) =>
                updateFormulaParameters(
                  "outdoorAirDuctAngular",
                  outdoorAirDuctAngular,
                )
              }
            />
            <OutdoorAirDuctRoundRow
              outdoorDuctRound={data.formulas.outdoorAirDuctRound}
              onChange={(outdoorAirDuctRound) =>
                updateFormulaParameters(
                  "outdoorAirDuctRound",
                  outdoorAirDuctRound,
                )
              }
            />
            <AwayAirDuctAngularRow
              awayAirDuctAngular={data.formulas.awayAirDuctAngular}
              onChange={(awayAirDuctAngular) =>
                updateFormulaParameters(
                  "awayAirDuctAngular",
                  awayAirDuctAngular,
                )
              }
            />
            <AwayAirDuctRoundRow
              awayAirDuctRound={data.formulas.awayAirDuctRound}
              onChange={(awayAirDuctRound) =>
                updateFormulaParameters("awayAirDuctRound", awayAirDuctRound)
              }
            />
            <CrossFlowHeatExchangerRow
              crossFlowHeatExchanger={data.formulas.crossFlowHeatExchanger}
              onChange={(crossFlowHeatExchanger) =>
                updateFormulaParameters(
                  "crossFlowHeatExchanger",
                  crossFlowHeatExchanger,
                )
              }
            />
            <AccessPanelRow
              accessPanel={data.formulas.accessPanel}
              onChange={(accessPanel) =>
                updateFormulaParameters("accessPanel", accessPanel)
              }
            />
            <AirHandlingUnitRow
              airHandlingUnit={data.formulas.airHandlingUnit}
              onChange={(airHandlingUnit) =>
                updateFormulaParameters("airHandlingUnit", airHandlingUnit)
              }
            />
            <AirHandlingUnitSchwerpunktRow
              airHandlingUnit={data.formulas.airHandlingUnit}
              onChange={(airHandlingUnitSchwerpunkt) =>
                updateFormulaParameters(
                  "airHandlingUnitSchwerpunkt",
                  airHandlingUnitSchwerpunkt,
                )
              }
            />
            <RotaryHeatExchangerRow
              rotaryHeatExchanger={data.formulas.rotaryHeatExchanger}
              onChange={(rotaryHeatExchanger) =>
                updateFormulaParameters(
                  "rotaryHeatExchanger",
                  rotaryHeatExchanger,
                )
              }
            />
            <SplitUnitRow
              splitUnitFormula={data.formulas.splitUnit}
              onChange={(splitUnit) =>
                updateFormulaParameters("splitUnit", splitUnit)
              }
            />
            <RecirculatingSprayHumidifier
              recirculatingSprayHumidifier={
                data.formulas.recirculatingSprayHumidifier
              }
              onChange={(recirculatingSprayHumidifier) =>
                updateFormulaParameters(
                  "recirculatingSprayHumidifier",
                  recirculatingSprayHumidifier,
                )
              }
            />
            <SupplyDuctAngularRow
              supplyDuctAngular={data.formulas.supplyDuctAngular}
              onChange={(supplyDuctAngular) =>
                updateFormulaParameters("supplyDuctAngular", supplyDuctAngular)
              }
            />
            <SupplyDuctRoundRow
              supplyDuctRound={data.formulas.supplyDuctRound}
              onChange={(supplyDuctRound) =>
                updateFormulaParameters("supplyDuctRound", supplyDuctRound)
              }
            />
            <ExtraHoursRow
              extraHours={data.formulas.extraHours}
              onChange={(extraHours) =>
                updateFormulaParameters("extraHours", extraHours)
              }
            />
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function CopyHourlyRateButton({ defaults }: { defaults: Defaults }) {
  const [put, { isLoading, error, reset }] = useRltDefaultsPutMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (isLoading) {
      return;
    }

    const data = defaults.data as Calculation;
    if (data.hoursInManDay === 0) {
      return;
    }

    const hourlyRate = parseFloat(
      (data.manDayRate / data.hoursInManDay).toFixed(2),
    );

    put({
      ...defaults,
      data: {
        ...data,
        formulas: {
          accessPanel: {
            ...data.formulas.accessPanel,
          },
          airHandlingUnit: {
            ...data.formulas.airHandlingUnit,
            hourlyRate,
          },
          airHandlingUnitSchwerpunkt: {
            ...data.formulas.airHandlingUnit,
            hourlyRate,
          },
          awayAirDuctAngular: {
            ...data.formulas.awayAirDuctAngular,
            hourlyRate,
          },
          awayAirDuctRound: {
            ...data.formulas.awayAirDuctRound,
            hourlyRate,
          },
          crossFlowHeatExchanger: {
            ...data.formulas.crossFlowHeatExchanger,
            hourlyRate,
          },
          exhaustDuctAngular: {
            ...data.formulas.exhaustDuctAngular,
            hourlyRate,
          },
          exhaustDuctRound: {
            ...data.formulas.exhaustDuctRound,
            hourlyRate,
          },
          extraHours: {
            // extra hours always have an individual rate
            // as of 03.2024.
            ...data.formulas.extraHours,
          },
          freeText: {
            ...data.formulas.freeText,
          },
          outdoorAirDuctAngular: {
            ...data.formulas.outdoorAirDuctAngular,
            hourlyRate,
          },
          outdoorAirDuctRound: {
            ...data.formulas.outdoorAirDuctRound,
            hourlyRate,
          },
          recirculatingSprayHumidifier: {
            ...data.formulas.recirculatingSprayHumidifier,
            hourlyRate,
          },
          rotaryHeatExchanger: {
            ...data.formulas.rotaryHeatExchanger,
            hourlyRate,
          },
          splitUnit: {
            ...data.formulas.splitUnit,
            hourlyRate,
          },
          supplyDuctAngular: {
            ...data.formulas.supplyDuctAngular,
            hourlyRate,
          },
          supplyDuctRound: {
            ...data.formulas.supplyDuctRound,
            hourlyRate,
          },
        },
      },
    });
  };

  return (
    <Button variant="outline" onClick={onClick} disabled={isLoading}>
      {t("Stundensatz aus Allgemeinen Daten übernehmen")}
    </Button>
  );
}

function AccessPanelRow({
  accessPanel,
  onChange,
}: {
  accessPanel: AccessPanelFormula;
  onChange: (accessPanel: AccessPanelFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6">{t(Kind.AccessPanel)}</TableCell>
      <TableCell className="whitespace-nowrap text-right">
        {t("€ / Stück")}
      </TableCell>
      <TableCell className="pr-6">
        <Input
          className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={accessPanel.pricePerPanel}
          onChange={(e) =>
            onChange({
              ...accessPanel,
              pricePerPanel: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function AirHandlingUnitRow({
  airHandlingUnit,
  onChange,
}: {
  airHandlingUnit: AirHandlingUnitFormula;
  onChange: (accessPanel: AirHandlingUnitFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6">{t(Kind.AirHandlingUnit)}</TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Kubikmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={airHandlingUnit.minutesPerCubicMeter}
          onChange={(e) =>
            onChange({
              ...airHandlingUnit,
              minutesPerCubicMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={airHandlingUnit.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...airHandlingUnit,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={airHandlingUnit.hourlyRate}
          onChange={(e) =>
            onChange({
              ...airHandlingUnit,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function AirHandlingUnitSchwerpunktRow({
  airHandlingUnit,
  onChange,
}: {
  airHandlingUnit: AirHandlingUnitSchwerpunktFormula;
  onChange: (accessPanel: AirHandlingUnitSchwerpunktFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6">
        {t(Kind.AirHandlingUnitSchwerpunkt)}
      </TableCell>
      <TableCell className="whitespace-nowrap text-right">
        {t("€ / Stunde")}
      </TableCell>
      <TableCell className="pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={airHandlingUnit.hourlyRate}
          onChange={(e) =>
            onChange({
              ...airHandlingUnit,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function AwayAirDuctAngularRow({
  awayAirDuctAngular,
  onChange,
}: {
  awayAirDuctAngular: AwayAirDuctAngularFormula;
  onChange: (awayAirDuctAngularFormula: AwayAirDuctAngularFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.AwayAirDuctAngular)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={awayAirDuctAngular.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...awayAirDuctAngular,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={awayAirDuctAngular.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...awayAirDuctAngular,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={awayAirDuctAngular.hourlyRate}
          onChange={(e) =>
            onChange({
              ...awayAirDuctAngular,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function AwayAirDuctRoundRow({
  awayAirDuctRound,
  onChange,
}: {
  awayAirDuctRound: AwayAirDuctRoundFormula;
  onChange: (awayAirDuctRound: AwayAirDuctRoundFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.AwayAirDuctRound)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={awayAirDuctRound.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...awayAirDuctRound,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={awayAirDuctRound.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...awayAirDuctRound,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={awayAirDuctRound.hourlyRate}
          onChange={(e) =>
            onChange({
              ...awayAirDuctRound,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function CrossFlowHeatExchangerRow({
  crossFlowHeatExchanger,
  onChange,
}: {
  crossFlowHeatExchanger: CrossFlowHeatExchangerFormula;
  onChange: (crossFlowHeatExchanger: CrossFlowHeatExchangerFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.CrossFlowHeatExchanger)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={crossFlowHeatExchanger.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...crossFlowHeatExchanger,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={crossFlowHeatExchanger.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...crossFlowHeatExchanger,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={crossFlowHeatExchanger.hourlyRate}
          onChange={(e) =>
            onChange({
              ...crossFlowHeatExchanger,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function ExhaustDuctAngularRow({
  exhaustDuctAngular,
  onChange,
}: {
  exhaustDuctAngular: ExhaustDuctAngularFormula;
  onChange: (exhaustDuctAngular: ExhaustDuctAngularFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.ExhaustDuctAngular)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={exhaustDuctAngular.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...exhaustDuctAngular,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={exhaustDuctAngular.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...exhaustDuctAngular,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={exhaustDuctAngular.hourlyRate}
          onChange={(e) =>
            onChange({
              ...exhaustDuctAngular,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function ExhaustDuctRoundRow({
  exhaustDuctRound,
  onChange,
}: {
  exhaustDuctRound: ExhaustDuctRoundFormula;
  onChange: (exhaustDuctRound: ExhaustDuctRoundFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.ExhaustDuctRound)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={exhaustDuctRound.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...exhaustDuctRound,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={exhaustDuctRound.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...exhaustDuctRound,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={exhaustDuctRound.hourlyRate}
          onChange={(e) =>
            onChange({
              ...exhaustDuctRound,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function ExtraHoursRow({
  extraHours,
  onChange,
}: {
  extraHours: ExtraHoursFormula;
  onChange: (extraHours: ExtraHoursFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6">{t(Kind.ExtraHours)}</TableCell>
      <TableCell className="whitespace-nowrap text-right">
        {t("€ / Stunde")}
      </TableCell>
      <TableCell className="pr-6">
        <Input
          className="-my-2 h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={extraHours.hourlyRate}
          onChange={(e) =>
            onChange({ ...extraHours, hourlyRate: e.target.valueAsNumber })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function OutdoorAirDuctAngularRow({
  outdoorAirDuctAngular,
  onChange,
}: {
  outdoorAirDuctAngular: OutdoorAirDuctAngularFormula;
  onChange: (outdoorAirDuctAngular: OutdoorAirDuctAngularFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.OutdoorAirDuctAngular)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={outdoorAirDuctAngular.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...outdoorAirDuctAngular,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={outdoorAirDuctAngular.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...outdoorAirDuctAngular,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={outdoorAirDuctAngular.hourlyRate}
          onChange={(e) =>
            onChange({
              ...outdoorAirDuctAngular,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function OutdoorAirDuctRoundRow({
  outdoorDuctRound,
  onChange,
}: {
  outdoorDuctRound: OutdoorAirDuctRoundFormula;
  onChange: (exhaustDuctRound: OutdoorAirDuctRoundFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.OutdoorAirDuctRound)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={outdoorDuctRound.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...outdoorDuctRound,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={outdoorDuctRound.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...outdoorDuctRound,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={outdoorDuctRound.hourlyRate}
          onChange={(e) =>
            onChange({
              ...outdoorDuctRound,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function RecirculatingSprayHumidifier({
  recirculatingSprayHumidifier,
  onChange,
}: {
  recirculatingSprayHumidifier: RecirculatingSprayHumidifierFormula;
  onChange: (
    recirculatingSprayHumidifier: RecirculatingSprayHumidifierFormula,
  ) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.RecirculatingSprayHumidifier)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Minuten / Düse")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={recirculatingSprayHumidifier.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...recirculatingSprayHumidifier,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={recirculatingSprayHumidifier.minutesPerNozzle}
          onChange={(e) =>
            onChange({
              ...recirculatingSprayHumidifier,
              minutesPerNozzle: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={recirculatingSprayHumidifier.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...recirculatingSprayHumidifier,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={recirculatingSprayHumidifier.hourlyRate}
          onChange={(e) =>
            onChange({
              ...recirculatingSprayHumidifier,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function RotaryHeatExchangerRow({
  rotaryHeatExchanger,
  onChange,
}: {
  rotaryHeatExchanger: RotaryHeatExchangerFormula;
  onChange: (exhaustDuctRound: RotaryHeatExchangerFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.RotaryHeatExchanger)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Durchmesser (m)")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={rotaryHeatExchanger.minutesPerDiameter}
          onChange={(e) =>
            onChange({
              ...rotaryHeatExchanger,
              minutesPerDiameter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={rotaryHeatExchanger.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...rotaryHeatExchanger,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={rotaryHeatExchanger.hourlyRate}
          onChange={(e) =>
            onChange({
              ...rotaryHeatExchanger,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function SplitUnitRow({
  splitUnitFormula,
  onChange,
}: {
  splitUnitFormula: SplitUnitFormula;
  onChange: (splitUnitFormula: SplitUnitFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">{t(Kind.SplitUnit)}</TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Stunden / Einheit")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={splitUnitFormula.hoursPerUnit}
          onChange={(e) =>
            onChange({
              ...splitUnitFormula,
              hoursPerUnit: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={splitUnitFormula.hourlyRate}
          onChange={(e) =>
            onChange({
              ...splitUnitFormula,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function SupplyDuctAngularRow({
  supplyDuctAngular,
  onChange,
}: {
  supplyDuctAngular: SupplyDuctAngularFormula;
  onChange: (supplyDuctAngular: SupplyDuctAngularFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.SupplyDuctAngular)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={supplyDuctAngular.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...supplyDuctAngular,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={supplyDuctAngular.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...supplyDuctAngular,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={supplyDuctAngular.hourlyRate}
          onChange={(e) =>
            onChange({
              ...supplyDuctAngular,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}

function SupplyDuctRoundRow({
  supplyDuctRound,
  onChange,
}: {
  supplyDuctRound: SupplyDuctRoundFormula;
  onChange: (supplyDuctRoundFormula: SupplyDuctRoundFormula) => void;
}) {
  return (
    <TableRow>
      <TableCell className="pl-6 align-text-top">
        {t(Kind.SupplyDuctRound)}
      </TableCell>
      <TableCell className="space-y-2 whitespace-nowrap text-right">
        <div className="h-8 pt-1">{t("Minuten / Quadratmeter")}</div>
        <div className="h-8 pt-1">{t("Sanierungsfaktor")}</div>
        <div className="h-8 pt-1">{t("€ / Stunde")}</div>
      </TableCell>
      <TableCell className="space-y-2 pr-6">
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={supplyDuctRound.minutesPerSquareMeter}
          onChange={(e) =>
            onChange({
              ...supplyDuctRound,
              minutesPerSquareMeter: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={supplyDuctRound.sanierungFactor}
          onChange={(e) =>
            onChange({
              ...supplyDuctRound,
              sanierungFactor: e.target.valueAsNumber,
            })
          }
        />
        <Input
          className="h-8 text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          type="number"
          value={supplyDuctRound.hourlyRate}
          onChange={(e) =>
            onChange({
              ...supplyDuctRound,
              hourlyRate: e.target.valueAsNumber,
            })
          }
        />
      </TableCell>
    </TableRow>
  );
}
