import { backendApi } from "@/services/backend/api";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { Device } from "@/services/backend/samples/device/device";

const labApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesDevice"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesDevicePut: build.mutation<Device, PutRequest>({
        query: (request) => ({
          url: `/samples/device`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesDevice", id: request.id },
          "SamplesDevice",
        ],
      }),
      samplesDeviceShow: build.query<Device, ShowRequest>({
        query: (request) => ({
          url: `/samples/device`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("SamplesDevice"),
      }),
      samplesDeviceList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/device/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.devices, "SamplesDevice"),
      }),
      samplesDeviceDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/samples/device`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["SamplesDevice"],
      }),
    }),
  });

export const {
  useSamplesDevicePutMutation,
  useSamplesDeviceShowQuery,
  useSamplesDeviceListQuery,
  useSamplesDeviceDeleteMutation,
} = labApi;

export interface PutRequest extends Device {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  limit?: number;
  offset?: number;
}

export interface List {
  devices: Device[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
