import { backendApi } from "@/services/backend/api";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { WaterSample } from "@/services/backend/samples/sample/water-sample";
import { Filter } from "@/shared/lib/filter/filter";
import { EntityId } from "@/shared/nidavellir/types/entity-id";

const waterSampleApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["SamplesWaterSample"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      samplesWaterSamplePut: build.mutation<WaterSample, PutRequest>({
        query: (request) => ({
          url: `/samples/water`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesWaterSample", id: request.id },
          "SamplesWaterSample",
        ],
      }),
      samplesWaterSampleUpdateLab: build.mutation<
        WaterSample,
        UpdateLabRequest
      >({
        query: (request) => ({
          url: `/samples/water/lab`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "SamplesWaterSample", id: request.id },
          "SamplesWaterSample",
        ],
      }),
      samplesWaterSampleShow: build.query<WaterSample, ShowRequest>({
        query: (request) => ({
          url: `/samples/water`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("SamplesWaterSample"),
      }),
      samplesWaterSampleList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/samples/water/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.samples, "SamplesWaterSample"),
      }),
      samplesWaterSampleDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/samples/water`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["SamplesWaterSample"],
      }),
    }),
  });

export const {
  useSamplesWaterSamplePutMutation,
  useSamplesWaterSampleUpdateLabMutation,
  useSamplesWaterSampleShowQuery,
  useSamplesWaterSampleListQuery,
  useSamplesWaterSampleDeleteMutation,
} = waterSampleApi;

export interface PutRequest extends WaterSample {}

export interface UpdateLabRequest {
  id: string;
  labId: EntityId;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  lab?: Filter<string>;
  artefact?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  samples: WaterSample[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}
