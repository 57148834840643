import { useVbsTreatmentShowQuery } from "@/services/backend/vbs/treatments/service";
import { Treatment } from "@/services/backend/vbs/treatments/treatment";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import { SegmentComponent } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/segment-component";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { SegmentKind } from "@/services/backend/vbs/treatments/segmentKind";
import { TreatmentComponentStateContextProvider } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state-provider";
import {
  ALL_COLUMNS,
  Column,
} from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";

export interface TreatmentComponentProps
  extends HTMLAttributes<HTMLDivElement> {
  treatmentId: string;
  availableColumns?: Column[];
  defaultColumns?: Column[];
}

export function TreatmentComponent({
  treatmentId,
  availableColumns = ALL_COLUMNS,
  defaultColumns = ALL_COLUMNS,
  className,
}: TreatmentComponentProps) {
  const {
    data: treatment,
    isLoading,
    error,
  } = useVbsTreatmentShowQuery({
    id: treatmentId,
  });

  if (isLoading) {
    return <TreatmentComponentSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <TreatmentComponentStateContextProvider
      availableColumns={availableColumns!}
      defaultColumns={defaultColumns!}
    >
      <TreatmentCard treatment={treatment!} className={className} />
    </TreatmentComponentStateContextProvider>
  );
}

interface TreatmentCardProps extends HTMLAttributes<HTMLDivElement> {
  treatment: Treatment;
}

function TreatmentCard({ treatment, className }: TreatmentCardProps) {
  return (
    <div className={cn("rounded-lg border shadow", className)}>
      <Tabs defaultValue={SegmentKind.ExhaustHoods}>
        <TabsList className="grid h-12 w-full grid-cols-5 rounded-b-none border-b px-2">
          {treatment.segments.map((segment) => (
            <TabsTrigger value={segment.segmentKind} key={segment.segmentKind}>
              {t(segment.segmentKind)}
            </TabsTrigger>
          ))}
        </TabsList>
        {treatment.segments.map((segment) => (
          <TabsContent value={segment.segmentKind} key={segment.segmentKind}>
            <SegmentComponent
              treatmentId={treatment.id}
              processId={treatment.processId}
              segment={segment}
              positions={treatment.positions}
            />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}

interface TreatmentComponentSkeletonProps
  extends HTMLAttributes<HTMLDivElement> {}

export function TreatmentComponentSkeleton({
  className,
}: TreatmentComponentSkeletonProps) {
  return (
    <div className={cn("rounded-lg border p-6 shadow", className)}>
      <span className="mx-auto animate-pulse text-muted-foreground">
        {t("Lade Leistungen... ")}
      </span>
    </div>
  );
}
