import { Item } from "@/services/backend/htz/position-catalog/position-catalog";
import { v4 } from "uuid";

export interface Position {
  id: string;
  number: string;
  airHandlingUnits: string[] | null;
  title: string;
  description: string;
  unitCount: number;
  unitPrice: number;
  offerPrice: number;
  optionalOffer: boolean;
}

export function newPositionFrom(position: Position): Position {
  return {
    ...position,
    id: v4(),
  };
}

export function newPositionsFrom(positions: Position[]): Position[] {
  return positions.map((pos) => newPositionFrom(pos));
}

export function newPosition(): Position {
  return {
    id: v4(),
    number: "",
    airHandlingUnits: null,
    title: "",
    description: "",
    unitCount: 0,
    unitPrice: 0,
    offerPrice: 0,
    optionalOffer: false,
  };
}

export function newFromCatalog(catPosition: Item): Position {
  return {
    id: v4(),
    number: "",
    airHandlingUnits: null,
    title: catPosition.copyTitle ? catPosition.title : "",
    description: catPosition.copyDescription ? catPosition.description : "",
    unitCount: 0,
    unitPrice: catPosition.unitPrice ? catPosition.unitPrice : 0,
    offerPrice: 0,
    optionalOffer: false,
  };
}

export function hasAirHandlingUnit(position: Position, ahuId: string): boolean {
  if (!position.airHandlingUnits) {
    return false;
  }

  return position.airHandlingUnits.some((id) => id === ahuId);
}

export function removeAirHandlingUnit(
  position: Position,
  ahuId: string,
): Position {
  if (!position.airHandlingUnits) {
    return position;
  }

  return {
    ...position,
    airHandlingUnits: position.airHandlingUnits.filter((id) => id !== ahuId),
  };
}

export function addAirHandlingUnit(
  position: Position,
  ahuId: string,
): Position {
  if (hasAirHandlingUnit(position, ahuId)) {
    return position;
  }

  const ahus = position.airHandlingUnits ?? [];

  return {
    ...position,
    airHandlingUnits: [...ahus, ahuId],
  };
}

export function updateTitle(position: Position, title: string): Position {
  return {
    ...position,
    title,
  };
}

export function updateDescription(
  position: Position,
  description: string,
): Position {
  return {
    ...position,
    description,
  };
}

export function updateUnitCount(
  position: Position,
  unitCount: number,
): Position {
  return {
    ...position,
    unitCount,
    offerPrice: unitCount * position.unitPrice,
  };
}

export function updateUnitPrice(
  position: Position,
  unitPrice: number,
): Position {
  return {
    ...position,
    unitPrice,
    offerPrice: unitPrice * position.unitCount,
  };
}

export function updateOptionalOffer(
  position: Position,
  optionalOffer: boolean,
): Position {
  return {
    ...position,
    optionalOffer,
  };
}

export function addPosition(
  positions: Position[],
  position: Position,
): Position[] {
  return [
    ...positions,
    {
      ...position,
      number: (positions.length + 1).toString(),
    },
  ];
}

export function updatePosition(
  positions: Position[],
  position: Position,
): Position[] {
  return positions.map((pos) => {
    if (pos.id === position.id) {
      return position;
    }
    return pos;
  });
}

export function deletePosition(
  positions: Position[],
  positionId: string,
): Position[] {
  return positions
    .filter((pos) => pos.id !== positionId)
    .map((pos, idx) => ({
      ...pos,
      number: (idx + 1).toString(),
    }));
}

export function positionErrorKey(position: Position, field: string): string {
  return `positions.${position.id}.${field}`;
}
