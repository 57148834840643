import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { VbsCalculationPreview } from "@/shared/components/domain/artefact/preview/vbs/vbs-calculation-preview";
import { VbsCertificatePreview } from "@/shared/components/domain/artefact/preview/vbs/vbs-certificate-preview";
import { VbsConfirmationPreview } from "@/shared/components/domain/artefact/preview/vbs/vbs-confirmation-preview";
import { VbsOfferPreview } from "@/shared/components/domain/artefact/preview/vbs/vbs-offer-preview";
import { VbsOrderPreview } from "@/shared/components/domain/artefact/preview/vbs/vbs-order-preview";
import { VbsWorkOrderPreview } from "@/shared/components/domain/artefact/preview/vbs/vbs-work-order-preview";
import { RltCalculationPreview } from "@/shared/components/domain/artefact/preview/rlt/rlt-calculation-preview";
import { RltCertificatePreview } from "@/shared/components/domain/artefact/preview/rlt/rlt-certificate-preview";
import { RltConfirmationPreview } from "@/shared/components/domain/artefact/preview/rlt/rlt-confirmation-preview";
import { RltOfferPreview } from "@/shared/components/domain/artefact/preview/rlt/rlt-offer-preview";
import { RltOrderPreview } from "@/shared/components/domain/artefact/preview/rlt/rlt-order-preview";
import { RltWorkOrderPreview } from "@/shared/components/domain/artefact/preview/rlt/rlt-work-order-preview";

export interface ArtefactPreviewProps {
  artefactId: string;
  artefactKind: ArtefactKind;
  processKind: ProcessKind;
}

export function ArtefactPreview(props: ArtefactPreviewProps) {
  const { artefactId, artefactKind, processKind } = props;
  switch (processKind) {
    case ProcessKind.VBS:
      return (
        <VbsArtefactPreview
          artefactId={artefactId}
          artefactKind={artefactKind}
        />
      );
    case ProcessKind.RLT:
      return (
        <RltArtefactPreview
          artefactId={artefactId}
          artefactKind={artefactKind}
        />
      );
    default:
      // Just show nothing if not implemented.
      return null;
  }
}

function VbsArtefactPreview({
  artefactId,
  artefactKind,
}: {
  artefactId: string;
  artefactKind: ArtefactKind;
}) {
  switch (artefactKind) {
    case ArtefactKind.Calculation:
      return <VbsCalculationPreview calculationId={artefactId} />;
    case ArtefactKind.Certificate:
      return <VbsCertificatePreview certificateId={artefactId} />;
    case ArtefactKind.Confirmation:
      return <VbsConfirmationPreview confirmationId={artefactId} />;
    case ArtefactKind.Contract:
      // As of 25.07.2024 it was clear that contracts will change significantly
      // form the modelling to date. Therefore, previews where not implemented.
      break;
    case ArtefactKind.Documentation:
      // can be found via the work order
      break;
    case ArtefactKind.LastMinuteRiskAnalysis:
      // can be found via the work order
      break;
    case ArtefactKind.Offer:
      return <VbsOfferPreview offerId={artefactId} />;
    case ArtefactKind.Order:
      return <VbsOrderPreview orderId={artefactId} />;
    case ArtefactKind.Requirement:
      // does not apply to VBS
      return null;
    case ArtefactKind.Treatment:
      // It makes no sense to search for since a VBS treatment is always
      // coupled to another artefact / not standing on its own from
      // a user perspektive.
      break;
    case ArtefactKind.WorkOrder:
      return <VbsWorkOrderPreview workOrderId={artefactId} />;
    default:
      // Just show nothing if not implemented.
      return null;
  }
}

function RltArtefactPreview({
  artefactId,
  artefactKind,
}: {
  artefactId: string;
  artefactKind: ArtefactKind;
}) {
  switch (artefactKind) {
    case ArtefactKind.Calculation:
      return <RltCalculationPreview calculationId={artefactId} />;
    case ArtefactKind.Certificate:
      return <RltCertificatePreview certificateId={artefactId} />;
    case ArtefactKind.Confirmation:
      return <RltConfirmationPreview confirmationId={artefactId} />;
    case ArtefactKind.Contract:
      // As of 25.07.2024 it was clear that contracts will change significantly
      // form the modelling to date. Therefore, previews where not implemented.
      break;
    case ArtefactKind.Documentation:
      // can be found via the work order
      break;
    case ArtefactKind.LastMinuteRiskAnalysis:
      // can be found via the work order
      break;
    case ArtefactKind.Offer:
      return <RltOfferPreview offerId={artefactId} />;
    case ArtefactKind.Order:
      return <RltOrderPreview orderId={artefactId} />;
    case ArtefactKind.Requirement:
      // does not apply to RLT
      return null;
    case ArtefactKind.Treatment:
      // It makes no sense to search for since a VBS treatment is always
      // coupled to another artefact / not standing on its own from
      // a user perspektive.
      break;
    case ArtefactKind.WorkOrder:
      return <RltWorkOrderPreview workOrderId={artefactId} />;
    default:
      // Just show nothing if not implemented.
      return null;
  }
}
