import { NavLink, useParams } from "react-router-dom";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { useVbsCertificateShowQuery } from "@/services/backend/vbs/certificates/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import t from "@/lang/lang";
import { ErrArtefactNotReadyContextProvider } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/shared/components/ui/button";
import { CertificatePdf } from "@/routes/gesec/processes/[processId]/vbs/certificates/[certificateId]/_components/certificate-pdf";
import { CertificateImmutablePage } from "@/routes/gesec/processes/[processId]/vbs/certificates/[certificateId]/_components/certificate-immutable-page";
import { Card } from "@/shared/components/ui/card";
import { CertificateComponent } from "./_components/certificate-component";

export function CertificateRoute() {
  const { certificateId } = useParams();
  const {
    data: certificate,
    isLoading,
    error,
  } = useVbsCertificateShowQuery({
    id: certificateId!,
  });

  const certificateWasIssued = certificate?.immutable;

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (certificateWasIssued) {
    return <CertificateImmutablePage certificate={certificate!} />;
  }

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <MilestonePageContent>
          <MilestonePageContentMenu>
            <div className="flex items-center space-x-4">
              <Button variant="outline">
                <NavLink to=".." className="flex items-center space-x-2">
                  <ArrowLeft className="h-4 w-4" />
                  <span>{t("Zurück zur Übersicht")}</span>
                </NavLink>
              </Button>
            </div>
            <div className="flex items-center space-x-2">
              <PersistenceStateUsingContext className="mr-2" />
              <CertificatePdf certificateId={certificateId!} />
            </div>
          </MilestonePageContentMenu>
          <CertificateComponent certificateId={certificateId!} />
          <RTKQueryErrorAlert error={error} />
        </MilestonePageContent>
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}
