import {
  Comment,
  useCommentsDeleteMutation,
  useCommentsListQuery,
  useCommentsPutMutation,
} from "@/services/backend/comments/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { HTMLAttributes, useState } from "react";
import { Card } from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";
import { Edit, Save, Trash, X } from "lucide-react";
import t from "@/lang/lang";
import { Username } from "@/shared/components/domain/username/username";
import { cn } from "@/shared/lib/utils";
import { Textarea } from "@/shared/components/ui/textarea";
import { Separator } from "@/shared/components/ui/separator";
import { v4 } from "uuid";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";

interface Props extends HTMLAttributes<HTMLDivElement> {
  ownerId: string;
  ownerType: string;
}

export function Comments({ ownerId, ownerType, className, ...props }: Props) {
  const {
    data: list,
    isLoading,
    error,
  } = useCommentsListQuery({
    ownerId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <div className={cn("space-y-4", className)} {...props}>
      {list?.comments.map((comment) => (
        <CommentShow key={comment.id} comment={comment} />
      ))}
      {list?.comments.length !== 0 && <Separator />}
      <NewCommentCard ownerId={ownerId} ownerType={ownerType} />
    </div>
  );
}

function Skeleton() {
  return (
    <div className="animate-pulse">
      <div className="space-y-2">
        <Textarea
          placeholder={t("Füge einen neuen Kommentar hinzu...")}
          disabled
        />
        <div className="flex justify-end">
          <Button disabled>{t("Kommentar hinzufügen")}</Button>
        </div>
      </div>
    </div>
  );
}

function CommentShow({ comment }: { comment: Comment }) {
  const [commentText, setCommentText] = useState<string>(comment.comment);
  const [edit, setEdit] = useState<boolean>(false);
  const [put, { isLoading, error, isSuccess, reset }] =
    useCommentsPutMutation();
  const { user } = useAuth();

  if (isSuccess) {
    setEdit(false);
    reset();
  }

  const onSave = () => {
    if (isLoading) {
      return;
    }

    if (!user) {
      // this should never happen
      return;
    }

    put({
      ...comment,
      comment: commentText,
      editedBy: user.id,
      editedAt: new Date().toISOString(),
    });
  };

  return (
    <Card className="px-3 py-2 shadow-none">
      <div className="flex items-start justify-between">
        <Username userId={comment.createdBy} className="text-sm font-medium" />
        <div className="text-sm">
          {new Date(comment.createdAt).toLocaleString()}
        </div>
      </div>
      <Separator className="mt-2" />
      {edit ? (
        <Textarea
          className="my-2"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
      ) : (
        <div className="mt-2 whitespace-pre text-muted-foreground">
          {comment.comment}
        </div>
      )}
      <RTKQueryErrorAlert error={error} className="mb-2" />
      <div className="flex justify-end space-x-2">
        {comment.editedBy !== "" && (
          <span className="inline-flex w-full items-end text-xs text-muted-foreground">
            <span>
              {t("Zuletzt bearbeitet am ")}
              {new Date(comment.editedAt).toLocaleString()} {t(" von ")}
            </span>
            <Username userId={comment.editedBy} className="ml-1" />
          </span>
        )}
        {edit ? (
          <>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setEdit(false)}
            >
              <X className="h-4 w-4" />
            </Button>
            <Button size="sm" onClick={onSave}>
              <Save className="h-4 w-4" />
            </Button>
          </>
        ) : (
          <>
            <DeleteDialogButton comment={comment} />
            <Button variant="ghost" size="sm" onClick={() => setEdit(true)}>
              <Edit className="h-4 w-4" />
            </Button>
          </>
        )}
      </div>
    </Card>
  );
}

function NewCommentCard({
  ownerId,
  ownerType,
}: {
  ownerId: string;
  ownerType: string;
}) {
  const [comment, setComment] = useState<string>("");
  const [put, { isLoading, error, isSuccess, reset }] =
    useCommentsPutMutation();
  const { user } = useAuth();

  if (isSuccess) {
    setComment("");
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    if (!user) {
      // this should never happen
      return;
    }

    put({
      id: v4(),
      ownerId,
      ownerType,
      comment,
      createdBy: user.id,
      createdAt: new Date().toISOString(),
    });
  };

  return (
    <div className="space-y-2">
      <Textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder={t("Füge einen neuen Kommentar hinzu...")}
      />
      <RTKQueryErrorAlert error={error} />
      <div className="flex justify-end">
        <Button onClick={onClick} disabled={isLoading}>
          {t("Kommentar hinzufügen")}
        </Button>
      </div>
    </div>
  );
}

function DeleteDialogButton({ comment }: { comment: Comment }) {
  const [open, setOpen] = useState(false);
  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useCommentsDeleteMutation();

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }
    doDelete({
      id: comment.id,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm">
          <Trash className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Kommentar wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
