import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { ProcessAddress } from "@/services/backend/addresses/processaddress/process-address";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  useAddressesProcessAddressPutMutation,
  useAddressesProcessAddressShowQuery,
} from "@/services/backend/addresses/processaddress/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { Address } from "@/services/backend/addresses/address/address";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";

interface ServiceAddressCardProps {
  serviceAddress: Address;
  onServiceAddressChange: (serviceAddress: Address) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}

export function ServiceAddressCard({
  serviceAddress,
  onServiceAddressChange,
  title = t("Serviceadresse"),
  description = t("Adresse an der der Service durchgeführt werden soll."),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: ServiceAddressCardProps) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? fieldErrors[field] ?? [] : [];
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${serviceAddress.street}+${serviceAddress.postalCode}+${serviceAddress.city}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="outline">{t("Navigation starten")}</Button>
        </a>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="comment">{t("Kommentar")}</Label>
          <Textarea
            id="comment"
            placeholder={t("Kommentar")}
            value={serviceAddress.comment}
            className={
              errorMessages("comment").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("comment");
              }
              onServiceAddressChange({
                ...serviceAddress,
                comment: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("comment")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="company">{t("Firma")}</Label>
          <Input
            type="text"
            id="company"
            className={
              errorMessages("company").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Firma")}
            value={serviceAddress.company}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("company");
              }
              onServiceAddressChange({
                ...serviceAddress,
                company: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("company")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="street">{t("Straße")}</Label>
          <Input
            type="text"
            id="street"
            className={
              errorMessages("street").length > 0
                ? "border-red-500 shadow-md"
                : ""
            }
            placeholder={t("Straße")}
            value={serviceAddress.street}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("street");
              }
              onServiceAddressChange({
                ...serviceAddress,
                street: e.target.value,
              });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("street")} />
        </div>
        <div className="flex space-x-4">
          <div className="grid w-full max-w-xs items-center gap-1.5">
            <Label htmlFor="postalCode">{t("PLZ")}</Label>
            <Input
              type="text"
              id="postalCode"
              className={
                errorMessages("postalCode").length > 0
                  ? "border-red-500 shadow-md"
                  : ""
              }
              placeholder={t("PLZ")}
              value={serviceAddress.postalCode}
              onChange={(e) => {
                if (resetFieldError) {
                  resetFieldError("postalCode");
                }
                onServiceAddressChange({
                  ...serviceAddress,
                  postalCode: e.target.value,
                });
              }}
              disabled={disabled}
            />
            <InputErrorMessages errors={errorMessages("postalCode")} />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="city">{t("Stadt")}</Label>
            <Input
              type="text"
              id="city"
              className={
                errorMessages("city").length > 0
                  ? "border-red-500 shadow-md"
                  : ""
              }
              placeholder={t("Stadt")}
              value={serviceAddress.city}
              onChange={(e) => {
                if (resetFieldError) {
                  resetFieldError("city");
                }
                onServiceAddressChange({
                  ...serviceAddress,
                  city: e.target.value,
                });
              }}
              disabled={disabled}
            />
            <InputErrorMessages errors={errorMessages("city")} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export function ProcessServiceAddressCardById({
  id,
  title = t("Serviceadresse"),
  description = t("Adresse an der der Service durchgeführt werden soll."),
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  id: string;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const {
    data: serviceAddress,
    isLoading,
    error,
  } = useAddressesProcessAddressShowQuery({
    id,
  });

  if (isLoading) {
    return (
      <Skeleton
        title={t("Service Adresse")}
        description={t("Adresse an der der Service durchgeführt werden soll.")}
      />
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ProcessServiceAddressCard
      serviceAddress={serviceAddress!}
      title={title}
      description={description}
      fieldErrors={fieldErrors}
      resetFieldError={resetFieldError}
    />
  );
}

export function ProcessServiceAddressCard({
  serviceAddress,
  title = t("Serviceadresse"),
  description = t("Adresse an der der Service durchgeführt werden soll."),
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  serviceAddress: ProcessAddress;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const [request, setRequest] = useState<ProcessAddress>({
    ...serviceAddress,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useAddressesProcessAddressPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <ServiceAddressCard
      serviceAddress={request}
      onServiceAddressChange={(address) =>
        setRequest({ ...request, ...address })
      }
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      disabled={serviceAddress.immutable}
    />
  );
}

function Skeleton(props: { title: string; description: string }) {
  const { title, description } = props;
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}
