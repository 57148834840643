import { Position } from "@/services/backend/rlt/treatments/position";
import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import { PositionCompleted } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/_components/position-completed";
import t from "@/lang/lang";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { ComponentData } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/_components/component-data";
import { PositionComment } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/_components/position-comment";
import { PositionDocumentation } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/_components/position-documentation";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { Separator } from "@/shared/components/ui/separator";

export function PositionListItem({
  treatmentId,
  workOrder,
  position,
}: {
  treatmentId: string;
  workOrder: WorkOrder;
  position: Position;
}) {
  return (
    <Dialog>
      <div className="flex items-center px-2 py-2 hover:bg-accent">
        <PositionCompleted treatmentId={treatmentId} position={position} />
        <DialogTrigger className="ml-4 flex w-full text-xl font-semibold tracking-tight">
          <span className="w-12">{position.number}</span>
          <span>{t(position.component.kind)}</span>
        </DialogTrigger>
        <PositionDialogContent
          treatmentId={treatmentId}
          workOrder={workOrder}
          position={position}
        />
      </div>
    </Dialog>
  );
}

function PositionDialogContent({
  treatmentId,
  workOrder,
  position,
}: {
  treatmentId: string;
  workOrder: WorkOrder;
  position: Position;
}) {
  return (
    <DialogContent className="min-w-[75vw]">
      <DialogHeader>
        <DialogTitle className="flex flex-row items-center justify-between pr-8">
          <div className="flex items-center">
            <PositionCompleted treatmentId={treatmentId} position={position} />
            <div className="ml-4 flex">
              <span className="w-12">{position.number}</span>
              <span>{t(position.component.kind)}</span>
            </div>
          </div>
          <PersistenceStateUsingContext className="ml-4" />
        </DialogTitle>
      </DialogHeader>
      <Separator />
      <div className="max-h-[80vh] space-y-4 overflow-y-auto px-2 pb-2">
        <ComponentData treatmentId={treatmentId} position={position} />
        <PositionComment treatmentId={treatmentId} position={position} />
        <PositionDocumentation workOrder={workOrder} position={position} />
      </div>
    </DialogContent>
  );
}
