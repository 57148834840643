import { backendApi } from "@/services/backend/api";
import { VisualAssessmentCatalog } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";

const visualAssessmentCatalogApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "HtzVisualAssessmentCatalog",
      "HtzActiveVisualAssessmentCatalog",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzVisualAssessmentCatalogPut: build.mutation<
        VisualAssessmentCatalog,
        PutRequest
      >({
        query: (request) => ({
          url: `/htz/inspection/visual-assessment-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzVisualAssessmentCatalog", id: request.id },
          "HtzVisualAssessmentCatalog",
        ],
      }),
      htzVisualAssessmentCatalogShow: build.query<
        VisualAssessmentCatalog,
        ShowRequest
      >({
        query: (request) => ({
          url: `/htz/inspection/visual-assessment-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzVisualAssessmentCatalog"),
      }),
      htzVisualAssessmentCatalogList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/inspection/visual-assessment-catalog/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.catalogs, "HtzVisualAssessmentCatalog"),
      }),
      htzVisualAssessmentCatalogDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/inspection/visual-assessment-catalog`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzVisualAssessmentCatalog"],
      }),
      htzVisualAssessmentCatalogPutActiveCatalog: build.mutation<
        ActiveCatalog,
        ActiveCatalog
      >({
        query: (request) => ({
          url: `htz/inspection/visual-assessment-catalog/active-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["HtzActiveVisualAssessmentCatalog"],
      }),
      htzVisualAssessmentCatalogShowActiveCatalog: build.query<
        ActiveCatalog,
        ShowActiveCatalogRequest
      >({
        query: (request) => ({
          url: `htz/inspection/visual-assessment-catalog/active-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["HtzActiveVisualAssessmentCatalog"],
      }),
    }),
  });

export const {
  useHtzVisualAssessmentCatalogPutMutation,
  useHtzVisualAssessmentCatalogShowQuery,
  useHtzVisualAssessmentCatalogListQuery,
  useHtzVisualAssessmentCatalogDeleteMutation,
  useHtzVisualAssessmentCatalogPutActiveCatalogMutation,
  useHtzVisualAssessmentCatalogShowActiveCatalogQuery,
} = visualAssessmentCatalogApi;

export interface PutRequest extends VisualAssessmentCatalog {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  catalogs: VisualAssessmentCatalog[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface ActiveCatalog {
  catalogId: string | null;
}

export interface ShowActiveCatalogRequest {}
