import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useRltWorkOrderCreateMutation } from "@/services/backend/rlt/work-orders/service";
import { ChevronDown, Plus } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { useRltConfirmationListQuery } from "@/services/backend/rlt/confirmations/service";
import { useRltOfferListQuery } from "@/services/backend/rlt/offers/service";
import {
  useRltTreatmentCopyBaseDataMutation,
  useRltTreatmentShowProcessTreatmentQuery,
} from "@/services/backend/rlt/treatments/service";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { useRltContractListQuery } from "@/services/backend/rlt/contracts/service";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { State } from "@/services/backend/vbs/contracts/contract";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function WorkOrderOverviewRoute() {
  const { processId } = useParams();
  const { pathname } = useLocation();
  const isList = pathname.endsWith("list");
  const isTreatment = pathname.endsWith("treatment");

  return (
    <PersistenceContextProvider>
      <MilestonePageContent>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabsNavList>
              <TabsNavLink to="treatment">{t("Leistungen")}</TabsNavLink>
              <TabsNavLink to="list">{t("Arbeitsscheine")}</TabsNavLink>
            </TabsNavList>
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            {isList && <AddWorkOrderButton processId={processId!} />}
            {isTreatment && <CopyTreatmentsDropdown processId={processId!} />}
          </div>
        </MilestonePageContentMenu>
        <Outlet />
      </MilestonePageContent>
    </PersistenceContextProvider>
  );
}

function AddWorkOrderButton(props: { processId: string }) {
  const { processId } = props;
  const [create, { isLoading, error, reset }] = useRltWorkOrderCreateMutation();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const onClick = () => {
    if (isLoading) {
      return;
    }
    const id = v4();
    create({
      id,
      processId,
    })
      .unwrap()
      .then(() => {
        navigate(`../${id}`);
      })
      .catch(() => null);
  };

  return (
    <Button onClick={onClick}>
      <div className="flex items-center space-x-2">
        <Plus className="h-5 w-5" />
        <span>{t("Arbeitsschein hinzufügen")}</span>
      </div>
    </Button>
  );
}

function CopyTreatmentsDropdown(props: { processId: string }) {
  const { processId } = props;
  const { data: process } = useProcessesProcessShowQuery({ id: processId });
  const { data: processTreatment, isLoading } =
    useRltTreatmentShowProcessTreatmentQuery({
      processId,
    });
  const { data: offers } = useRltOfferListQuery({
    process: { active: true, values: [processId] },
    immutable: { active: true, values: [true] },
  });
  const { data: confirmations } = useRltConfirmationListQuery({
    process: { active: true, values: [processId] },
    immutable: { active: true, values: [true] },
  });
  const { data: contracts } = useRltContractListQuery({
    customer: { active: true, values: [process?.customerId || ""] },
    state: { active: true, values: [State.Signed] },
  });

  if (isLoading) {
    return (
      <Button className="animate-pulse" disabled>
        <span>Leistungen übernehmen</span>
        <ChevronDown className="ml-2 h-4 w-4" />
      </Button>
    );
  }

  // This can only happen if the process treatment query fails,
  // in which case the whole page will be replaced by an error.
  // Displaying an error here would duplicate the error message.
  if (!processTreatment) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button>
          <span>Leistungen übernehmen</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Aus Angebot")}</DropdownMenuLabel>
        <DropdownMenuGroup>
          {offers?.offers.map((offer) => (
            <DropdownMenuItem
              key={offer.id}
              className="font-mono tracking-wide"
            >
              <CopyTreatmentButton
                destinationId={processTreatment.treatmentId}
                sourceId={offer.treatmentId}
              >
                <ArtefactNumberById artefactId={offer.id} />
              </CopyTreatmentButton>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Aus Auftragsbestätigung")}</DropdownMenuLabel>
        <DropdownMenuGroup>
          {confirmations?.confirmations.map((confirmation) => (
            <DropdownMenuItem
              key={confirmation.id}
              className="font-mono tracking-wide"
            >
              <CopyTreatmentButton
                destinationId={processTreatment.treatmentId}
                sourceId={confirmation.treatmentId}
              >
                <ArtefactNumberById artefactId={confirmation.id} />
              </CopyTreatmentButton>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Aus Vertrag")}</DropdownMenuLabel>
        <DropdownMenuGroup>
          {contracts?.contracts.map((contract) => (
            <DropdownMenuItem
              key={contract.id}
              className="font-mono tracking-wide"
            >
              <CopyTreatmentButton
                destinationId={processTreatment.treatmentId}
                sourceId={contract.treatmentId}
              >
                <ArtefactNumberById artefactId={contract.id} />
              </CopyTreatmentButton>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function CopyTreatmentButton(props: {
  destinationId: string;
  sourceId: string;
  children: ReactNode;
}) {
  const { destinationId, sourceId, children } = props;
  const [copy, { isLoading, error, reset }] =
    useRltTreatmentCopyBaseDataMutation();
  const { toast } = useToast();

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }
    copy({
      destinationId,
      sourceId,
    });
  };

  return (
    <Button onClick={onClick} variant="ghost" size="sm" className="h-6">
      {children}
    </Button>
  );
}
