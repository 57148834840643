import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useVbsWorkOrderListQuery } from "@/services/backend/vbs/work-orders/service";
import { WorkOrder } from "@/services/backend/vbs/work-orders/work-order";
import { Button } from "@/shared/components/ui/button";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function WorkOrdersRoute() {
  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Arbeitsscheine")}</MilestonePageTitle>
        <MilestonePageActions>
          <SubNavigation />
        </MilestonePageActions>
      </MilestonePageHeader>
      <Outlet />
    </MilestonePage>
  );
}

function SubNavigation() {
  const { processId } = useParams();
  const { pathname } = useLocation();
  const page = pathname.split("/").pop();

  const { data: list } = useVbsWorkOrderListQuery({
    process: {
      active: true,
      values: [processId!],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel(page)}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Übersicht")}</DropdownMenuLabel>
        <DropdownMenuGroup>
          <NavLink to="../work-orders/overview/list">
            <DropdownMenuItem>{t("Liste der Arbeitsscheine")}</DropdownMenuItem>
          </NavLink>
          <NavLink to="../work-orders/overview/treatment">
            <DropdownMenuItem>{t("Leistungen")}</DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Arbeitsscheine")}</DropdownMenuLabel>
        {[...(list?.workOrders ?? [])].sort(byBeginDate).map((workOrder) => (
          <NavLink to={workOrder.id} key={workOrder.id}>
            <DropdownMenuItem>
              <ArtefactNumberById artefactId={workOrder.id} />
            </DropdownMenuItem>
          </NavLink>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byBeginDate(a: WorkOrder, b: WorkOrder) {
  return a.beginDate.localeCompare(b.beginDate);
}

const currentPageLabel = (p: string | undefined) => {
  switch (p) {
    case "treatment":
      return t("Leistungen");
    case "list":
      return t("Liste der Arbeitsscheine");
    default:
      return t("Arbeitsschein");
  }
};
