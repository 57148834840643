import { UUID } from "@/shared/nidavellir/types/entity-id";
import { useHtzAirHandlingUnitShowQuery } from "@/services/backend/htz/ahu/service";

export function AirHandlingUnitName({ id }: { id: UUID }) {
  const { data } = useHtzAirHandlingUnitShowQuery({ id });

  if (!data) {
    return null;
  }

  return <span>{data.name}</span>;
}

export function AirHandlingUnitComponentName({
  id,
  componentId,
}: {
  id: UUID;
  componentId: UUID;
}) {
  const { data } = useHtzAirHandlingUnitShowQuery({ id });

  if (!data) {
    return null;
  }

  const componentName = data.components.find((c) => c.id === componentId)?.name;

  return componentName ?? null;
}
