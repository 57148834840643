import { backendApi } from "@/services/backend/api";
import { ProcessAddress } from "@/services/backend/addresses/processaddress/process-address";

const processAddressApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    addressesProcessAddressPut: build.mutation<ProcessAddress, ProcessAddress>({
      query: (request) => ({
        url: "/addresses/process-address",
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessAddress", id: request.id },
        { type: "ProcessAddress", id: "LIST" },
      ],
    }),
    addressesProcessAddressShow: build.query<ProcessAddress, ShowRequest>({
      query: (request) => ({
        url: "/addresses/process-address",
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _error, request) => [
        { type: "ProcessAddress", id: request.id },
      ],
    }),
    addressesProcessAddressDelete: build.mutation<
      ProcessAddress,
      DeleteRequest
    >({
      query: (request) => ({
        url: "/addresses/process-address",
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessAddress", id: request.id },
        { type: "ProcessAddress", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useAddressesProcessAddressPutMutation,
  useAddressesProcessAddressShowQuery,
  useAddressesProcessAddressDeleteMutation,
} = processAddressApi;

export interface ShowRequest {
  id: string;
}

export interface DeleteRequest {
  id: string;
}
