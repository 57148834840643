import { Position } from "@/services/backend/htz/work-order/work-order";
import {
  rtkErrIsValidationError,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHtzWorkOrderPutPositionMutation } from "@/services/backend/htz/work-order/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";

interface PositionContextInterface {
  position: Position;
  onPositionChange: (position: Position) => void;
  validationError: ValidationError | null;
  setValidationError: (error: ValidationError | null) => void;
}

export const PositionContext: Context<PositionContextInterface> =
  createContext<PositionContextInterface>({
    position: {} as Position,
    onPositionChange: () => null,
    validationError: null,
    setValidationError: () => null,
  });

export function usePositionContext() {
  return useContext(PositionContext);
}

export function PositionContextProvider({
  children,
  position: propPosition,
}: {
  children: ReactNode;
  position: Position;
}) {
  const [position, setPosition] = useState<Position>(propPosition);
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  // TODO
  // useEffect(() => {
  //   // This should be controlled by vector clocks.
  //   // Maybe not needed, since we have slip positions now.
  //   setPosition(propPosition);
  // }, [propPosition]);

  const onPositionChange = useCallback((pos: Position) => {
    setPosition(pos);
  }, []);

  const value = useMemo(
    () => ({
      position,
      onPositionChange,
      validationError,
      setValidationError,
    }),
    [position, onPositionChange, validationError],
  );

  return (
    <PositionContext.Provider value={value}>
      <PositionUpdater>{children}</PositionUpdater>
    </PositionContext.Provider>
  );
}

function PositionUpdater({ children }: { children: ReactNode }) {
  const { position, setValidationError } = usePositionContext();

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPutPositionMutation();

  useEffect(() => {
    if (rtkErrIsValidationError(error)) {
      setValidationError(error.data);
    }
  }, [error, setValidationError]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    position,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}
