import * as SheetPrimitive from "@radix-ui/react-dialog";
import {
  Sheet,
  SheetContent,
  SheetContentProps,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/shared/components/ui/sheet";
import { Button } from "@/shared/components/ui/button";
import { AlertCircle, Download, Lock, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { H3, H4 } from "@/shared/components/ui/typography";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { Separator } from "@/shared/components/ui/separator";
import React, {
  createContext,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { cn } from "@/shared/lib/utils";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { ErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function ArtefactSheetContextProvider({ children }: PropsWithChildren) {
  const [open, setOpen] = useState(false);

  const value = useMemo(
    () => ({
      open,
      setOpen,
    }),
    [open, setOpen],
  );

  return (
    <ArtefactSheetContext.Provider value={value}>
      {children}
    </ArtefactSheetContext.Provider>
  );
}

export interface ArtefactSheetContextInterface {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ArtefactSheetContext =
  createContext<ArtefactSheetContextInterface>({
    open: false,
    setOpen: () => {},
  });

export function useArtefactSheetContext() {
  return useContext(ArtefactSheetContext);
}

export const ArtefactSheet = Sheet;

export const ArtefactSheetTrigger = SheetTrigger;

export const ArtefactSheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ side = "bottom", ...props }, ref) => (
  <SheetContent ref={ref} side={side} {...props} />
));

export const ArtefactSheetHeader = SheetHeader;

export const ArtefactSheetTitle = SheetTitle;

interface ArtefactSheetMainProps extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactSheetMain({
  children,
  className,
  ...props
}: ArtefactSheetMainProps) {
  return (
    <div className={cn("mt-4 grid gap-8 md:grid-cols-5", className)} {...props}>
      {children}
    </div>
  );
}

export function ArtefactSheetPreview({
  isLoading,
  objectURL = undefined,
  error = undefined,
  fallbackText = t("PDF konnte nicht gerendert werden."),
  className = "",
}: {
  isLoading: boolean;
  objectURL?: string;
  error?: unknown;
  fallbackText?: string;
  className?: string;
}) {
  if (isLoading) {
    return <ArtefactPreviewSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} className="md:col-span-3" />;
  }

  return (
    <div className={cn("md:col-span-3", className)}>
      {/* 
        iframe and not object was used, since object caused problems in Safari. 
        For some reason, scrolling was not possible. 
       */}
      <iframe
        title="PDF Preview"
        src={`${objectURL}#toolbar=0`}
        className="h-[80vh] w-full rounded-lg"
      >
        {fallbackText}
      </iframe>
    </div>
  );
}

export function ArtefactPreviewSkeleton() {
  return <Skeleton className="h-[80vh] w-full rounded-lg md:col-span-3" />;
}

interface ArtefactActionsProps extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactSheetActions({
  children,
  className,
  ...props
}: ArtefactActionsProps) {
  return (
    <div className={cn("space-y-4 md:col-span-2", className)} {...props}>
      {children}
    </div>
  );
}

interface ArtefactActionsHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  artefactNumber: ReactNode;
}

export function ArtefactSheetActionsHeader({
  title,
  artefactNumber,
  children,
  className,
  ...props
}: ArtefactActionsHeaderProps) {
  return (
    <div className={cn("", className)} {...props}>
      <H3>{title}</H3>
      <div>{artefactNumber}</div>
      {children}
      <Separator className="mt-4" />
    </div>
  );
}

export function ArtefactNotReadyAlert({
  error,
  artefact,
}: {
  error: ErrArtefactNotReady | null;
  artefact: string;
}) {
  if (!error) {
    return null;
  }

  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{t(`${artefact} ist nicht vollständig:`)}</AlertTitle>
      <AlertDescription className="mt-2 flex flex-col space-y-2">
        {Object.entries(error.errors).map(([key, value]) => (
          <div key={key}>
            <div className="font-medium">{key}</div>
            <ul className="ml-6 list-disc [&>li]:mt-1">
              {value.map((msg) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          </div>
        ))}
      </AlertDescription>
    </Alert>
  );
}

interface ArtefactSheetActionsContentProps
  extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactSheetActionsContent({
  children,
  className,
  ...props
}: ArtefactSheetActionsContentProps) {
  return (
    <div
      className={cn("h-[73vh] space-y-4 overflow-auto", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function ArtefactSheetImmutabilityWarning({
  title = t("Hinweis"),
  description = t(
    "Wenn ein Artefact heruntergeladen wird, wird es festgeschrieben. Es kann dann nicht mehr geändert werden.",
  ),
}: {
  title?: string;
  description?: string;
}) {
  return (
    <Alert>
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
}

export function ArtefactSheetActionsDownload({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <div className="grid gap-1">
      <H4 className="mb-2">{t("Herunterladen")}</H4>
      {children}
    </div>
  );
}

interface ArtefactSheetActionsDownloadButtonProps
  extends HTMLAttributes<HTMLButtonElement> {
  isLoading: boolean;
}

export function ArtefactSheetActionsDownloadButton({
  children,
  className,
  isLoading,
  ...props
}: ArtefactSheetActionsDownloadButtonProps) {
  return (
    <Button
      className={cn("justify-start", className)}
      variant="outline"
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <Download className="mr-4 h-5 w-5 animate-bounce" />
      ) : (
        <Download className="mr-4 h-5 w-5" />
      )}
      <span>{children}</span>
    </Button>
  );
}

export function ArtefactSheetActionsLock<T>({
  request,
  mutation,
}: {
  request: T;
  mutation: () => readonly [
    (request: T) => void,
    {
      isLoading: boolean;
      error?: unknown;
      isSuccess: boolean;
      reset: () => void;
    },
  ];
}) {
  const [open, setOpen] = useState(false);
  const [mutate, { isLoading, error, isSuccess, reset }] = mutation();

  const doLock = () => {
    if (isLoading) {
      return;
    }

    mutate(request);
  };

  if (isSuccess) {
    reset();
    setOpen(false);
  }

  return (
    <div className="grid gap-1">
      <H4 className="mb-2">Festschreiben</H4>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" className="justify-start">
            <Lock className="mr-4 h-4 w-4" />
            <span>{t("Festschreiben")}</span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("Festschreiben")}</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            {t("Durch bestätigen wird das Artefakt festgeschrieben.")}
          </DialogDescription>
          <RTKQueryErrorAlert error={error} />
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" disabled={isLoading}>
                {t("Abbrechen")}
              </Button>
            </DialogClose>
            <Button onClick={doLock} disabled={isLoading}>
              {isLoading && <RefreshCw className="mr-2 h-4 w-4" />}
              <span>{t("Festschreiben")}</span>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
