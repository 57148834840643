import { Outlet, useParams } from "react-router-dom";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import t from "@/lang/lang";
import { NewCertificateButton } from "./_components/new-certificate-button";

export function CertificatesRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Zertifikate")}</MilestonePageTitle>
        <MilestonePageActions>
          <NewCertificateButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <Outlet />
    </MilestonePage>
  );
}
