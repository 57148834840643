import { useNumbersProcessNumberShowQuery } from "@/services/backend/numbers/process-number/service";
import { HTMLAttributes } from "react";
import { Skeleton } from "@/shared/components/ui/skeleton";

export interface ProcessNumberProps extends HTMLAttributes<HTMLSpanElement> {
  processId: string;
}

export function ProcessNumber({
  processId,
  className,
  ...props
}: ProcessNumberProps) {
  const {
    data: processNumber,
    isLoading,
    error,
  } = useNumbersProcessNumberShowQuery({ processId });

  if (isLoading) {
    return <Skeleton className="h-2 w-52" />;
  }

  if (error != null) {
    return <span className={className} {...props} />;
  }

  return (
    <span className={className} {...props}>
      {processNumber?.string}
    </span>
  );
}
