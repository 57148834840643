import { useState } from "react";
import {
  AccessPanel,
  AerosolSeparator as AerosolSeparatorFormula,
  AluminiumMeshGreaseFilter as AluminiumMeshGreaseFilterFormula,
  Anemostat as AnemostatFormula,
  DummyPlate as DummyPlateFormula,
  ExhaustDuctAngular as ExhaustDuctAngularFormula,
  ExhaustDuctRound as ExhaustDuctRoundFormula,
  ExhaustHood as ExhaustHoodFormula,
  ExhaustHoodSystem as ExhaustHoodSystemFormula,
  ExtraHours,
  Formula,
  Lighting as LightingFormula,
  PassiveArea as PassiveAreaFormula,
  SupplyDuctAngular as SupplyDuctAngularFormula,
  SupplyDuctRound as SupplyDuctRoundFormula,
  VentilatedCeiling,
  VentilatedCeiling as VentilatedCeilingFormula,
  VentilatedCeilingSystem as VentilatedCeilingSystemFormula,
} from "@/services/backend/vbs/treatments/formula";
import { Kind } from "@/services/backend/vbs/treatments/component";
import {
  UpdateFormulaRequest,
  useVbsTreatmentUpdateFormulaMutation,
} from "@/services/backend/vbs/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import t from "@/lang/lang";
import { FloatNumberInput } from "@/routes/gesec/processes/_components/ui/number-input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { ChevronDown } from "lucide-react";

export function FormulaCell(props: {
  treatmentId: string;
  positionId: string;
  formula: Formula;
}) {
  const { treatmentId, positionId, formula: propsFormula } = props;

  const [request, setRequest] = useState<UpdateFormulaRequest>({
    id: treatmentId,
    positionId,
    formula: propsFormula,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentUpdateFormulaMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  switch (propsFormula.componentKind) {
    case Kind.AccessPanel:
      return (
        <FormulaAccessPanel
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.AerosolSeparator:
      return (
        <FormulaAerosolSeparator
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.AirHandlingUnit:
      return <FormulaAirHandlingUnit />;
    case Kind.AluminiumMeshGreaseFilter:
      return (
        <FormulaAluminiumMeshGreaseFilter
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.Anemostat:
      return (
        <FormulaAnemostat
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.DummyPlate:
      return (
        <FormulaDummyPlate
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.ExhaustDuctAngular:
      return (
        <FormulaExhaustDuctAngular
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.ExhaustDuctRound:
      return (
        <FormulaExhaustDuctRound
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.ExhaustHoodSystem:
      return (
        <FormulaExhaustHoodSystem
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.ExhaustHood:
      return (
        <FormulaExhaustHood
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.ExtraHours:
      return (
        <FormulaExtraHours
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.FreeText:
      return null;
    case Kind.Lighting:
      return (
        <FormulaLighting
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.PassiveArea:
      return (
        <FormulaPassiveArea
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.SupplyDuctAngular:
      return (
        <FormulaSupplyDuctAngular
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.SupplyDuctRound:
      return (
        <FormulaSupplyDuctRound
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.VentilatedCeiling:
      return (
        <FormulaVentilatedCeiling
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    case Kind.VentilatedCeilingSystem:
      return (
        <FormulaVentilatedCeilingSystem
          formula={request.formula}
          onChange={(formula) => setRequest({ ...request, formula })}
        />
      );
    default:
      return null;
  }
}

function FormulaAccessPanel(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as AccessPanel;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("€ / Stk")}</span>
      </div>
      <FloatNumberInput
        value={parameters.pricePerPanel}
        onChange={(pricePerPanel) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              pricePerPanel,
            },
          })
        }
      />
    </div>
  );
}

function FormulaAerosolSeparator(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as AerosolSeparatorFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / Stk")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerCount}
        onChange={(minutesPerCount) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerCount,
            },
          })
        }
      />
    </div>
  );
}

function FormulaAirHandlingUnit() {
  // The air handling unit has no formula.
  // The clean time is directly specified by the user in the
  // component data.
  return <div />;
}

function FormulaAluminiumMeshGreaseFilter(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as AluminiumMeshGreaseFilterFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / Stk")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerCount}
        onChange={(minutesPerCount) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerCount,
            },
          })
        }
      />
    </div>
  );
}

function FormulaAnemostat(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as AnemostatFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / Stk")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerCount}
        onChange={(minutesPerCount) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerCount,
            },
          })
        }
      />
    </div>
  );
}

function FormulaDummyPlate(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as DummyPlateFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / Stk")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerCount}
        onChange={(minutesPerCount) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerCount,
            },
          })
        }
      />
    </div>
  );
}

function FormulaExhaustDuctAngular(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as ExhaustDuctAngularFormula;

  return (
    <div className="flex justify-between space-x-4">
      <div className="flex h-6 items-center whitespace-nowrap">
        <span>{t("Min. / m")}</span>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="h-6 px-2">
            <span>{t("Eingabe")}</span>
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60" align="end">
          <div className="grid gap-4">
            <div className="grid gap-2">
              {parameters.buildUps?.map(({ buildUp, minutesPerMeter }) => (
                <div
                  className="grid grid-cols-2 items-center gap-4"
                  key={buildUp}
                >
                  <Label>{t(buildUp)}</Label>
                  <Input
                    type="number"
                    className="h-8"
                    value={minutesPerMeter}
                    onChange={(e) => {
                      onChange({
                        ...formula,
                        parameters: {
                          ...parameters,
                          buildUps: parameters.buildUps.map((v) => {
                            if (v.buildUp === buildUp) {
                              return {
                                buildUp,
                                minutesPerMeter: Number(e.target.value),
                              };
                            }
                            return v;
                          }),
                        } as ExhaustDuctAngularFormula,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function FormulaExhaustDuctRound(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as ExhaustDuctRoundFormula;

  return (
    <div className="flex justify-between space-x-4">
      <div className="flex h-6 items-center whitespace-nowrap">
        <span>{t("Min. / m")}</span>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="h-6 px-2">
            <span>{t("Eingabe")}</span>
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60" align="end">
          <div className="grid gap-4">
            <div className="grid gap-2">
              {parameters.buildUps?.map(({ buildUp, minutesPerMeter }) => (
                <div
                  className="grid grid-cols-2 items-center gap-4"
                  key={buildUp}
                >
                  <Label>{t(buildUp)}</Label>
                  <Input
                    className="h-8"
                    value={minutesPerMeter}
                    onChange={(e) =>
                      onChange({
                        ...formula,
                        parameters: {
                          ...parameters,
                          buildUps: parameters.buildUps.map((v) => {
                            if (v.buildUp === buildUp) {
                              return {
                                buildUp,
                                minutesPerMeter: Number(e.target.value),
                              };
                            }
                            return v;
                          }),
                        } as ExhaustDuctRoundFormula,
                      })
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function FormulaExhaustHood(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as ExhaustHoodFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerSquareMeter,
            },
          })
        }
      />
    </div>
  );
}

function FormulaExhaustHoodSystem(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as ExhaustHoodSystemFormula;

  return (
    <div>
      <FormulaExhaustHood
        formula={parameters.exhaustHood}
        onChange={(exhaustHood) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              exhaustHood,
            },
          })
        }
      />
      <FormulaAerosolSeparator
        formula={parameters.aerosolSeparator}
        onChange={(aerosolSeparator) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              aerosolSeparator,
            },
          })
        }
      />
      <FormulaAluminiumMeshGreaseFilter
        formula={parameters.aluminiumMeshGreaseFilter}
        onChange={(aluminiumMeshGreaseFilter) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              aluminiumMeshGreaseFilter,
            },
          })
        }
      />
      <FormulaLighting
        formula={parameters.lighting}
        onChange={(lighting) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              lighting,
            },
          })
        }
      />
      <FormulaDummyPlate
        formula={parameters.dummyPlate}
        onChange={(dummyPlate) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              dummyPlate,
            },
          })
        }
      />
    </div>
  );
}

function FormulaExtraHours(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as ExtraHours;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("€ / Std")}</span>
      </div>
      <FloatNumberInput
        value={parameters.hourlyRate}
        onChange={(hourlyRate) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              hourlyRate,
            },
          })
        }
      />
    </div>
  );
}

function FormulaLighting(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as LightingFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / Stk")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerCount}
        onChange={(minutesPerCount) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerCount,
            },
          })
        }
      />
    </div>
  );
}

function FormulaPassiveArea(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as PassiveAreaFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / m²")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerSquareMeter}
        onChange={(minutesPerSquareMeter) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerSquareMeter,
            },
          })
        }
      />
    </div>
  );
}

function FormulaSupplyDuctAngular(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as SupplyDuctAngularFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / m")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerMeter}
        onChange={(minutesPerMeter) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerMeter,
            },
          })
        }
      />
    </div>
  );
}

function FormulaSupplyDuctRound(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as SupplyDuctRoundFormula;

  return (
    <div className="flex justify-end space-x-4">
      <div className="flex h-6 w-20 items-center">
        <span>{t("Min. / m")}</span>
      </div>
      <FloatNumberInput
        value={parameters.minutesPerMeter}
        onChange={(minutesPerMeter) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              minutesPerMeter,
            },
          })
        }
      />
    </div>
  );
}

function FormulaVentilatedCeiling(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as VentilatedCeilingFormula;

  return (
    <div className="flex justify-between space-x-1">
      <div className="flex h-6 items-center">
        <span>{t("Min / m²")}</span>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="ghost" className="h-6 px-0">
            <span>{t("Eingabe")}</span>
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60" align="end">
          <div className="grid gap-4">
            <div className="grid gap-2">
              {parameters.constructions?.map(
                ({ construction, minutesPerSquareMeter }) => (
                  <div
                    className="grid grid-cols-2 items-center gap-4"
                    key={construction}
                  >
                    <Label>{t(construction)}</Label>
                    <Input
                      type="number"
                      className="h-8"
                      value={minutesPerSquareMeter}
                      onChange={(e) => {
                        onChange({
                          ...formula,
                          parameters: {
                            ...parameters,
                            constructions: parameters.constructions.map((v) => {
                              if (v.construction === construction) {
                                return {
                                  construction,
                                  minutesPerSquareMeter: Number(e.target.value),
                                };
                              }
                              return v;
                            }),
                          } as VentilatedCeiling,
                        });
                      }}
                    />
                  </div>
                ),
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function FormulaVentilatedCeilingSystem(props: {
  formula: Formula;
  onChange: (formula: Formula) => void;
}) {
  const { onChange, formula } = props;
  const parameters = formula.parameters as VentilatedCeilingSystemFormula;

  return (
    <div>
      <FormulaVentilatedCeiling
        formula={parameters.ventilatedCeiling}
        onChange={(ventilatedCeiling) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              ventilatedCeiling,
            },
          })
        }
      />
      <FormulaAerosolSeparator
        formula={parameters.aerosolSeparator}
        onChange={(aerosolSeparator) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              aerosolSeparator,
            },
          })
        }
      />
      <FormulaLighting
        formula={parameters.lighting}
        onChange={(lighting) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              lighting,
            },
          })
        }
      />
      <FormulaDummyPlate
        formula={parameters.dummyPlate}
        onChange={(dummyPlate) =>
          onChange({
            ...formula,
            parameters: {
              ...parameters,
              dummyPlate,
            },
          })
        }
      />
    </div>
  );
}
