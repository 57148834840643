import { useParams } from "react-router-dom";
import { useVbsOfferShowQuery } from "@/services/backend/vbs/offers/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { CalculationComponent } from "@/routes/gesec/processes/[processId]/vbs/calculations/[calculationId]/calculation/calculation-route";

export function OfferCalculationRoute() {
  const { offerId } = useParams();
  const {
    data: offer,
    isLoading,
    error,
  } = useVbsOfferShowQuery({ id: offerId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return null;
  }

  return <CalculationComponent calculationId={offer!.calculationId} />;
}
