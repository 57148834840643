import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";
import { Component } from "@/services/backend/htz/ahu/air-handling-unit";

interface InspectionContextInterface {
  initialized: boolean;
  active: boolean;
  setActive: (value: boolean) => void;
  activeComponent: Component | null;
  setActiveComponent: (c: Component | null) => void;
}

const InspectionContext = createContext<InspectionContextInterface>({
  initialized: false,
  active: false,
  setActive: () => null,
  activeComponent: null,
  setActiveComponent: () => null,
});

export function useInspectionContext() {
  return useContext(InspectionContext);
}

export function InspectionContextProvider({ children }: PropsWithChildren) {
  const [active, setActive] = useState<boolean>(false);
  const [activeComponent, setActiveComponent] = useState<Component | null>(
    null,
  );

  const value = useMemo(
    () => ({
      initialized: true,
      active,
      setActive,
      activeComponent,
      setActiveComponent,
    }),
    [activeComponent, setActiveComponent, active, setActive],
  );

  return (
    <InspectionContext.Provider value={value}>
      {children}
    </InspectionContext.Provider>
  );
}
