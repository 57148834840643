import { backendApi } from "../../api";

const stateApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    processesStateShow: build.query<State, ShowStateRequest>({
      query: (request) => ({
        url: `/processes/state`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "ProcessState", id: result.processId }] : [],
    }),
    processesStatePut: build.mutation<State, PutStateRequest>({
      query: (request) => ({
        url: `/processes/state`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "ProcessState", id: result.processId }] : [],
    }),
    processesStateForceRunning: build.mutation<State, ForceRunningRequest>({
      query: (request) => ({
        url: `/processes/state/force-running`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: "ProcessState", id: result.processId }] : [],
    }),
  }),
});

export const {
  useProcessesStateShowQuery,
  useProcessesStatePutMutation,
  useProcessesStateForceRunningMutation,
} = stateApi;

export interface State {
  processId: string;
  state: Label;
}

export enum Label {
  Unknown = "",
  Running = "running",
  Archived = "archived",
  Done = "done",
}

export interface ShowStateRequest {
  processId: string;
}

export interface PutStateRequest extends State {}

export interface ForceRunningRequest {
  processId: string;
}
