import { Outlet, useParams } from "react-router-dom";
import { useRltOrderShowQuery } from "@/services/backend/rlt/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Order } from "@/services/backend/rlt/orders/order";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteOrderDialog } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/_components/delete-order-dialog";
import { OrderCardSkeleton } from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/data/_components/order-card";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";

export function OrderRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading,
    error,
  } = useRltOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <PageSkeleton />;
  }

  return <Page order={order!} />;
}

export function Page({ order }: { order: Order }) {
  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <MilestonePageContent>
          <MilestonePageContentMenu>
            <div className="flex items-center space-x-4">
              <TabNavigation />
            </div>
            <div className="flex items-center space-x-2">
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteOrderDialog orderId={order.id} />
            </div>
          </MilestonePageContentMenu>
          <Outlet />
        </MilestonePageContent>
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

export function PageSkeleton() {
  return (
    <div className="animate-pulse">
      <MilestonePageContent>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabNavigation />
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            <Button variant="outline" disabled>
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </MilestonePageContentMenu>
        <OrderCardSkeleton />
      </MilestonePageContent>
    </div>
  );
}

function TabNavigation() {
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <TabsNavList>
      <TabsNavLink to="data" hasValidationError={hasOrderError(notReadyError)}>
        {t("Auftragseingang")}
      </TabsNavLink>
      <TabsNavLink to="treatment">{t("Leistungen")}</TabsNavLink>
      <TabsNavLink
        to="customer-address"
        hasValidationError={hasAddressError(notReadyError)}
      >
        {t("Anschrift")}
      </TabsNavLink>
      <TabsNavLink
        to="representative"
        hasValidationError={hasRepresentativeError(notReadyError)}
      >
        {t("Betreuer")}
      </TabsNavLink>
    </TabsNavList>
  );
}

function hasOrderError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "date",
    "treatment.offerPrice",
    "pricesForExtras.ratePerAccessPanel",
    "pricesForExtras.hourlyRate",
  ];

  return fields.some((field) => field in error.errors);
}

function hasAddressError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "customerAddress.city",
  ];

  return fields.some((field) => field in error.errors);
}

function hasRepresentativeError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}
