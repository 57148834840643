import { Button } from "@/shared/components/ui/button";
import { ArrowLeft, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { NavLink, useParams } from "react-router-dom";
import { useHtzAirHandlingUnitShowQuery } from "@/services/backend/htz/ahu/service";
import { Card, CardHeader } from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { AhuDataCard } from "@/routes/customers/[customerId]/ahu-units/[ahuId]/_components/ahu-data-card";
import { LinkedAhuDataCard } from "@/routes/customers/[customerId]/ahu-units/[ahuId]/_components/linked-ahu-data-card";
import { DeleteAhuDialog } from "@/routes/customers/[customerId]/ahu-units/[ahuId]/_components/delete-ahu-dialog";
import { AhuComponents } from "@/routes/customers/[customerId]/ahu-units/[ahuId]/_components/ahu-components";

export function AhuUnitRoute() {
  const { ahuId } = useParams();

  const {
    data: ahu,
    isLoading,
    error,
  } = useHtzAirHandlingUnitShowQuery({ id: ahuId! });

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <OverviewNavLink />
        <DeleteAhuDialog ahu={ahu!} />
      </div>
      <AhuDataCard ahu={ahu!} />
      <AhuComponents ahu={ahu!} />
      <LinkedAhuDataCard ahu={ahu!} />
    </div>
  );
}

function Skeleton() {
  return (
    <div className="space-y-2">
      <div>
        <OverviewNavLink />
      </div>
      <Card>
        <CardHeader>
          <div className="flex">
            <RefreshCw className="mr-2 h-5 w-5 animate-spin" />
            <span>{t("Lade ...")}</span>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
}

function OverviewNavLink() {
  return (
    <NavLink to="..">
      <Button variant="outline" className="items-center">
        <ArrowLeft className="mr-2 h-5 w-5" />
        <span>{t("Übersicht")}</span>
      </Button>
    </NavLink>
  );
}
