import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state-context";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { Check, Columns3 } from "lucide-react";
import t from "@/lang/lang";

export function SelectColumnsDropdown() {
  const { state, setState } = useTreatmentComponentState();

  // not all columns are available for all users and
  // in all artefact types
  const { availableColumns } = state;

  const toggleColumn = (column: Column) => {
    if (state.columns.includes(column)) {
      setState({
        ...state,
        columns: state.columns.filter((c) => c !== column),
      });
    } else {
      setState({
        ...state,
        columns: [...state.columns, column],
      });
    }
  };

  const selectAll = () => {
    setState({
      ...state,
      columns: availableColumns,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <Columns3 className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Spalten")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {availableColumns.map((column) => (
            <DropdownMenuItem key={column} onClick={() => toggleColumn(column)}>
              <span className="w-8">
                {state.columns.includes(column) ? (
                  <Check className="h-5 w-5" />
                ) : null}
              </span>
              <span>{t(column)}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={selectAll}>
            {t("Alle auswählen")}
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
