import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useParams } from "react-router-dom";
import { ProcessCustomerAddressCardById } from "@/shared/components/domain/addresses/customer-address-card";
import { ProcessServiceAddressCardById } from "@/shared/components/domain/addresses/service-address-card";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { useVbsOrderShowQuery } from "@/services/backend/vbs/orders/service";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";

export function OrderCustomerAddressRoute() {
  const { orderId } = useParams();
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: order,
    isLoading,
    error,
  } = useVbsOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <ProcessCustomerAddressCardById
        id={order?.customerAddressId ?? ""}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "customerAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`customerAddress.${field}`)
        }
      />
      <ProcessServiceAddressCardById
        id={order?.serviceAddressId ?? ""}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}
