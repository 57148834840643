import { backendApi } from "@/services/backend/api";
import { Confirmation } from "@/services/backend/htz/confirmations/confirmation";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import {
  downloadResponseHandler,
  previewResponseHandler,
} from "@/services/backend/pdf-helpers";

const confirmationApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzConfirmation"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzConfirmationPut: build.mutation<Confirmation, PutRequest>({
        query: (request) => ({
          url: `/htz/confirmation`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzConfirmation", id: request.id },
          "HtzConfirmation",
        ],
      }),
      htzConfirmationShow: build.query<Confirmation, ShowRequest>({
        query: (request) => ({
          url: `/htz/confirmation`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzConfirmation"),
      }),
      htzConfirmationList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/confirmation/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.confirmations, "HtzConfirmation"),
      }),
      htzConfirmationDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/confirmation`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzConfirmation"],
      }),
      htzConfirmationPdfPreview: build.query<
        { objectURL: string },
        PreviewPDFRequest
      >({
        query: (request) => ({
          url: `/htz/confirmation/pdf`,
          method: "PATCH",
          body: request,
          // headers: {
          //   Accept: "application/pdf",
          // },
          responseHandler: previewResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        providesTags: (_r, _e, request) => [
          { type: "HtzConfirmation", id: request.confirmationId },
        ],
      }),
      htzConfirmationPdfDownload: build.mutation<
        { filename: string; objectURL: string },
        PDFRequest
      >({
        query: (request) => ({
          url: `/htz/confirmation/pdf`,
          method: "PATCH",
          body: {
            ...request,
            download: true,
          },
          responseHandler: downloadResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        invalidatesTags: (_r, _e, request) => [
          { type: "HtzConfirmation", id: request.confirmationId },
        ],
      }),
    }),
  });

export const {
  useHtzConfirmationPutMutation,
  useHtzConfirmationShowQuery,
  useHtzConfirmationListQuery,
  useHtzConfirmationDeleteMutation,
  useHtzConfirmationPdfPreviewQuery,
  useHtzConfirmationPdfDownloadMutation,
} = confirmationApi;

export interface PutRequest extends Confirmation {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  date?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  confirmations: Confirmation[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export enum ConfirmationDocument {
  ALL = "all",
  CONFIRMATION = "confirmation",
}

export interface PreviewPDFRequest {
  confirmationId: string;
  document: ConfirmationDocument;
}

export interface PDFRequest {
  confirmationId: string;
  document: ConfirmationDocument;
  download: boolean;
  downloadBy: string;
}
