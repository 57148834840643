import {
  AirHandlingUnit,
  Component,
  moveDown,
  moveUp,
  removeComponent,
} from "@/services/backend/htz/ahu/air-handling-unit";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { Card } from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, ChevronUp, Trash2 } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { cn } from "@/shared/lib/utils";
import { InspectionIndicator } from "@/shared/components/domain/htz/ahu/indicators";

export function AhuComponentsTable({
  ahu,
  onAhuChange,
}: {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
}) {
  const { active, initialized } = useInspectionContext();

  const inspectionMode = initialized && active;

  return (
    <Card className="h-full overflow-auto">
      {inspectionMode ? (
        <InspectionModeTable ahu={ahu} />
      ) : (
        <BuildModeTable ahu={ahu} onAhuChange={onAhuChange} />
      )}
    </Card>
  );
}

function InspectionModeTable({ ahu }: { ahu: AirHandlingUnit }) {
  const { activeComponent, setActiveComponent } = useInspectionContext();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("")}</TableHead>
          <TableHead>{t("Name")}</TableHead>
          <TableHead>{t("Details")}</TableHead>
          <TableHead>{t("Inspektionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...ahu.components].sort(byOrder).map((component) => (
          <TableRow
            key={component.id}
            className={cn(
              "cursor-pointer",
              component.id === activeComponent?.id && "bg-muted",
            )}
            onClick={() => {
              if (component.id === activeComponent?.id) {
                setActiveComponent(null);
              } else {
                setActiveComponent(component);
              }
            }}
          >
            <TableCell>
              <Checkbox
                checked={component.id === activeComponent?.id}
                onCheckedChange={(checked) => {
                  if (checked === true) {
                    setActiveComponent(component);
                  } else {
                    setActiveComponent(null);
                  }
                }}
              />
            </TableCell>
            <TableCell>{component.name}</TableCell>
            <TableCell>{component.data.details}</TableCell>
            <TableCell className="py-0">
              <InspectionIndicator componentId={component.id} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function BuildModeTable({
  ahu,
  onAhuChange,
}: {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
}) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Abfolge")}</TableHead>
          <TableHead>{t("Name")}</TableHead>
          <TableHead>{t("Details")}</TableHead>
          <TableHead>{t("")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...ahu.components].sort(byOrder).map((component) => (
          <TableRow key={component.id}>
            <TableCell className="py-0">
              <div className="flex w-12 flex-col space-y-1">
                <Button
                  className="h-4"
                  variant="outline"
                  onClick={() => onAhuChange(moveUp(ahu, component))}
                >
                  <ChevronUp />
                </Button>
                <Button
                  className="h-4"
                  variant="outline"
                  onClick={() => onAhuChange(moveDown(ahu, component))}
                >
                  <ChevronDown />
                </Button>
              </div>
            </TableCell>
            <TableCell>{component.name}</TableCell>
            <TableCell>{component.data.details}</TableCell>
            <TableCell>
              <DeleteAhuComponentDialog
                component={component}
                onDelete={() => {
                  onAhuChange(removeComponent(ahu, component.id));
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function byOrder(a: Component, b: Component) {
  const diff = a.order - b.order;
  switch (true) {
    case diff < 0:
      return -1;
    case diff > 0:
      return 1;
    default:
      return 0;
  }
}

function DeleteAhuComponentDialog({
  component,
  onDelete,
}: {
  component: Component;
  onDelete: () => void;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive">
          <Trash2 className="h-4 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {component.name} {t("löschen?")}
          </DialogTitle>
          <DialogDescription>
            {t("Bestätigen entfernt die Komponente von der Anlage.")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
