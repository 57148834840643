import { Filter } from "@/shared/lib/filter/filter";
import { providesList } from "@/services/backend/tag-helpers";
import { backendApi } from "../../api";
import { IncidentalCost, Offer } from "./offer";
import { Current } from "./current";
import t from "../../../../lang/lang";

const offerService = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltOfferCreate: build.mutation<Offer, CreateRequest>({
      query: (request) => ({
        url: `/rlt/offer`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
        "RltOfferCurrent",
      ],
    }),
    rltOfferCreateFromOld: build.mutation<Offer, CreateFromOldRequest>({
      query: (request) => ({
        url: `/rlt/offer/create-from-old`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "RltOffer", id: "LIST" },
        "RltOffer",
        "RltOfferCurrent",
      ],
    }),
    rltOfferCreateFromCalculation: build.mutation<
      Offer,
      CreateFromCalculationRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/create-from-calculation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [
        { type: "RltOffer", id: "LIST" },
        "RltOffer",
        "RltOfferCurrent",
      ],
    }),
    rltOfferShow: build.query<Offer, ShowRequest>({
      query: (request) => ({
        url: `/rlt/offer`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltOffer", id: result.id }] : [],
    }),
    rltOfferList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/rlt/offer/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) => providesList(result?.offers, "RltOffer"),
    }),
    rltOfferUpdateDate: build.mutation<Offer, UpdateDateRequest>({
      query: (request) => ({
        url: `/rlt/offer/offer-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateTreatmentBasePrice: build.mutation<
      Offer,
      UpdateTreatmentBasePriceRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/treatment/base-price`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateTreatmentSalesDiscount: build.mutation<
      Offer,
      UpdateTreatmentSalesDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/treatment/sales-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateTreatmentAbsoluteDiscount: build.mutation<
      Offer,
      UpdateTreatmentAbsoluteDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/treatment/absolute-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateTreatmentUseCalculation: build.mutation<
      Offer,
      UpdateTreatmentUseCalculationRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/treatment-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateIncidentalCost: build.mutation<
      Offer,
      UpdateIncidentalCostRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/incidental-cost`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateIncidentalCostUseCalculation: build.mutation<
      Offer,
      UpdateIncidentalCostUseCalculationRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/incidental-cost-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateRatePerAccessPanel: build.mutation<
      Offer,
      UpdateRatePerAccessPanelRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/prices-for-extras/rate-per-access-panel`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferUpdateHourlyRate: build.mutation<Offer, UpdateHourlyRateRequest>({
      query: (request) => ({
        url: `/rlt/offer/prices-for-extras/hourly-rate`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferPreviewPDF: build.query<{ objectURL: string }, PreviewPDFRequest>({
      query: (request) => ({
        url: `/rlt/offer/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_result, _error, request) => [
        { type: "RltOffer", id: request.id },
      ],
    }),
    rltOfferDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/offer/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          // fallback filename
          let filename = t("Angebot.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
      ],
    }),
    rltOfferDelete: build.mutation<Offer, DeleteRequest>({
      query: (request) => ({
        url: `/rlt/offer`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOffer", id: request.id },
        "RltOffer",
        "RltOfferCurrent",
      ],
    }),
    rltOfferShowCurrent: build.query<Current, ShowCurrentRequest>({
      query: (request) => ({
        url: `/rlt/offer/current`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltOfferCurrent", id: result.processId }] : [],
    }),
    rltOfferSetCurrent: build.mutation<Current, Current>({
      query: (request) => ({
        url: `/rlt/offer/current`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltOfferCurrent", id: request.processId },
        "RltOffer",
      ],
    }),
  }),
});

export const {
  useRltOfferCreateMutation,
  useRltOfferCreateFromOldMutation,
  useRltOfferCreateFromCalculationMutation,
  useRltOfferShowQuery,
  useRltOfferListQuery,
  useRltOfferUpdateDateMutation,
  useRltOfferUpdateTreatmentBasePriceMutation,
  useRltOfferUpdateTreatmentSalesDiscountMutation,
  useRltOfferUpdateTreatmentAbsoluteDiscountMutation,
  useRltOfferUpdateTreatmentUseCalculationMutation,
  useRltOfferUpdateIncidentalCostMutation,
  useRltOfferUpdateIncidentalCostUseCalculationMutation,
  useRltOfferUpdateRatePerAccessPanelMutation,
  useRltOfferUpdateHourlyRateMutation,
  useRltOfferDownloadPDFMutation,
  useRltOfferPreviewPDFQuery,
  useRltOfferDeleteMutation,
  useRltOfferShowCurrentQuery,
  useRltOfferSetCurrentMutation,
} = offerService;

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  offerDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface DeleteRequest {
  id: string;
}

export interface CreateRequest {
  id: string;
  processId: string;
}

export interface CreateFromOldRequest {
  oldOfferId: string;
  newOfferId: string;
  newProcessId: string;
}

export interface CreateFromCalculationRequest {
  id: string;
  processId: string;
  calculationId: string;
}

export interface UpdateDateRequest {
  id: string;
  date: string;
}

export interface UpdateTreatmentBasePriceRequest {
  id: string;
  price: number;
}

export interface UpdateTreatmentSalesDiscountRequest {
  id: string;
  active: boolean;
  percentage: number;
}

export interface UpdateTreatmentAbsoluteDiscountRequest {
  id: string;
  active: boolean;
  amount: number;
}

export interface UpdateTreatmentUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateIncidentalCostRequest {
  id: string;
  incidentalCost: IncidentalCost;
}

export interface UpdateIncidentalCostUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateRatePerAccessPanelRequest {
  id: string;
  rate: number;
}

export interface UpdateHourlyRateRequest {
  id: string;
  rate: number;
}

export interface DownloadPDFRequest {
  id: string;
  document: OfferDocument;
  userId: string;
}

export enum OfferDocument {
  ALL = "all",
  OFFER = "offer",
  ORDER = "order",
  TREATMENT = "treatment",
}

export interface PreviewPDFRequest {
  id: string;
  document: OfferDocument;
}

export interface List {
  offers: Offer[];
  count: number;
}

export interface ShowCurrentRequest {
  processId: string;
}
