import { useParams } from "react-router-dom";
import { useVbsCalculationShowQuery } from "@/services/backend/vbs/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  TreatmentComponent,
  TreatmentComponentSkeleton,
} from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";

export function CalculationTreatmentRoute() {
  const { calculationId } = useParams();
  const {
    data: calculation,
    isLoading,
    error,
  } = useVbsCalculationShowQuery({ id: calculationId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <TreatmentComponentSkeleton />;
  }

  return (
    <TreatmentComponent
      treatmentId={calculation!.treatmentId}
      availableColumns={[
        Column.Position,
        Column.ComponentKind,
        Column.ComponentData,
        Column.Cycle,
        Column.Formula,
        Column.CleanTime,
        Column.TipCommission,
        Column.Comment,
        Column.Actions,
      ]}
      defaultColumns={[
        Column.Position,
        Column.ComponentKind,
        Column.ComponentData,
        Column.Cycle,
        Column.CleanTime,
        Column.TipCommission,
        Column.Comment,
        Column.Actions,
      ]}
    />
  );
}
