import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";
import { Process } from "@/services/backend/processes/process/process";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  newPositionFrom,
  Position,
} from "@/services/backend/htz/position/position";
import { v4 } from "uuid";
import {
  Address,
  newAddress,
} from "@/services/backend/addresses/address/address";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { Representative } from "@/services/backend/representatives/representative/representative";

export interface Offer extends Artefact, Immutability {
  processId: string;
  companyId: string;
  customerId: string;
  date: string | null;
  positions: Position[];
  specialAgreements: string;
  postalAddress: Address;
  serviceAddress: Address;
  contact: Contact;
  representative: Representative;
  createdAt: string;
  updatedAt: string;
}

export function newOffer(process: Process): Offer {
  return {
    id: v4(),
    artefactKind: ArtefactKind.Offer,
    immutable: false,
    immutabilityReason: "",
    immutabilitySetAt: null,
    immutabilitySetBy: "",
    processId: process.id,
    companyId: process.companyId,
    customerId: process.customerId,
    date: new Date().toISOString(),
    positions: [],
    specialAgreements: "",
    postalAddress: newAddress(),
    serviceAddress: newAddress(),
    contact: {
      salutation: "",
      title: "",
      name: "",
      function: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Contact,
    representative: {
      name: "",
      email: "",
      phone: "",
      mobilephone: "",
    } as Representative,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function newOfferFrom(
  offer: Offer,
  processId: string,
  companyId: string,
  customerId: string,
): Offer {
  return {
    id: v4(),
    artefactKind: ArtefactKind.Offer,
    immutable: false,
    immutabilityReason: "",
    immutabilitySetAt: null,
    immutabilitySetBy: "",
    processId,
    companyId,
    customerId,
    date: new Date().toISOString(),
    positions: offer.positions.map((pos) => newPositionFrom(pos)),
    specialAgreements: offer.specialAgreements,
    postalAddress: offer.postalAddress,
    serviceAddress: offer.serviceAddress,
    contact: offer.contact,
    representative: offer.representative,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

export function updateDate(offer: Offer, date: string | null): Offer {
  return {
    ...offer,
    date,
  };
}

export function updateSpecialAgreements(
  offer: Offer,
  agreements: string,
): Offer {
  return {
    ...offer,
    specialAgreements: agreements,
  };
}

export function addPosition(offer: Offer, position: Position): Offer {
  if (offer.positions.some((pos) => pos.id === position.id)) {
    return offer;
  }

  return {
    ...offer,
    positions: [
      ...offer.positions,
      {
        ...position,
        number: (offer.positions.length + 1).toString(),
      },
    ],
  };
}

export function updatePosition(offer: Offer, position: Position): Offer {
  return {
    ...offer,
    positions: offer.positions.map((pos) => {
      if (pos.id === position.id) {
        return position;
      }
      return pos;
    }),
  };
}

export function deletePosition(offer: Offer, positionId: string): Offer {
  return {
    ...offer,
    positions: offer.positions
      .filter((pos) => pos.id !== positionId)
      .map((pos, idx) => ({
        ...pos,
        number: (idx + 1).toString(),
      })),
  };
}

export function updatePostalAddress(offer: Offer, address: Address): Offer {
  return {
    ...offer,
    postalAddress: address,
  };
}

export function updateServiceAddress(offer: Offer, address: Address): Offer {
  return {
    ...offer,
    serviceAddress: address,
  };
}

export function updateContact(offer: Offer, contact: Contact): Offer {
  return {
    ...offer,
    contact,
  };
}

export function updateRepresentative(
  offer: Offer,
  representative: Representative,
): Offer {
  return {
    ...offer,
    representative,
  };
}
