import { Button } from "@/shared/components/ui/button";
import { Trash } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import t from "@/lang/lang";
import { useEffect, useState } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useRltDocumentationDeleteItemMutation } from "@/services/backend/rlt/work-orders/documentation-service";

export function DeleteItemButton(props: {
  documentationId: string;
  itemId: string;
}) {
  const { documentationId, itemId } = props;
  const [open, setOpen] = useState(false);
  const [deleteItem, { isLoading, error, isSuccess, reset }] =
    useRltDocumentationDeleteItemMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteItem({
      id: documentationId,
      itemId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpen(false);
    }
  }, [isSuccess, setOpen, reset]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive">
          <Trash className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick} disabled={isLoading}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
