import { Button } from "@/shared/components/ui/button";
import { Copy } from "lucide-react";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useRltOfferCreateFromOldMutation } from "@/services/backend/rlt/offers/service";
import { useEffect } from "react";
import t from "@/lang/lang";

interface Props {
  offerId: string;
  processId: string;
  onCopy: (offerId: string) => void;
}

export function CopyOfferButton({ offerId, processId, onCopy }: Props) {
  const [copy, { isLoading, error, reset }] =
    useRltOfferCreateFromOldMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (isLoading) {
      return;
    }

    const id = v4();
    copy({
      oldOfferId: offerId,
      newOfferId: id,
      newProcessId: processId,
    })
      .unwrap()
      .then(() => onCopy(id));
  };

  return (
    <Button onClick={onClick} size="sm">
      <Copy className="mr-2 h-4 w-4" />
      <span>{t("Daten übernehmen")}</span>
    </Button>
  );
}
