import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { useShowCompanyQuery } from "@/services/backend/company/company";

export interface UseArtefactPathParams {
  artefactId: string;
  artefactKind: ArtefactKind;
  processKind: ProcessKind;
  processId: string;
  companyId: string;
}

export interface UseArtefactPathReturn {
  path: string | null;
}

export function useArtefactPath({
  artefactId,
  artefactKind,
  processKind,
  processId,
  companyId,
}: UseArtefactPathParams): UseArtefactPathReturn {
  const { data: company } = useShowCompanyQuery({ id: companyId });
  if (!company) {
    return { path: null };
  }

  const tenant = company.portalRoute;
  const akPath = artefactKindPath(artefactKind);
  const path = `/tenant/${tenant}/processes/${processKind}/${processId}/${akPath}/${artefactId}`;

  return { path };
}

function artefactKindPath(artefactKind: ArtefactKind): string {
  if (artefactKind === ArtefactKind.WorkOrder) {
    return "work-orders";
  }

  return `${artefactKind}s`;
}
