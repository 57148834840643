import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import {
  rtkErrIsValidationError,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { Order } from "@/services/backend/htz/orders/order";
import { Offer } from "@/services/backend/htz/offers/offer";
import { WorkOrder } from "@/services/backend/htz/work-order/work-order";
import { useHtzWorkOrderPutMutation } from "@/services/backend/htz/work-order/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";
import { useHtzAirHandlingUnitPutMutation } from "@/services/backend/htz/ahu/service";
import { StructuralAssessmentCatalog } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { VisualAssessmentCatalog } from "@/services/backend/htz/inspection/vacatalog/visual-assessment-catalog";
import { Laboratory } from "@/services/backend/samples/lab/laboratory";
import { Device } from "@/services/backend/samples/device/device";
import { deepEqual } from "@/shared/lib/utilities/deep-equal";

// TODO
//  * Offline sync: better to sync positions, not the work order itself.
//  * Positions are vastly independent. They can be merged and then deleted
//    when online.
//  * also: consider making copies or versions of the air handling unit.
//    We can then ask if the ahu already exists if the copy should be used.
//  * Slicing the aggregates correctly will be key for persistence.

// TODO
//  * consider: offline sync strategy. if we can scope a list of entites
//    e.g. by a foreign key the have, then we can sync them by deleting all
//         and re-inserting -> only works if one client is offline.

interface WorkOrderContextInterface {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
  airHandlingUnits: AirHandlingUnit[];
  onAirHandlingUnitChange: (ahu: AirHandlingUnit) => void;
  positionCatalog: PositionCatalog;
  ahuComponentCatalog: AhuComponentCatalog;
  structuralAssessmentCatalog: StructuralAssessmentCatalog;
  visualAssessmentCatalog: VisualAssessmentCatalog;
  orders: Order[];
  offers: Offer[];
  laboratories: Laboratory[];
  devices: Device[];
}

export const WorkOrderContext = createContext<WorkOrderContextInterface>({
  workOrder: {} as WorkOrder,
  onWorkOrderChange: () => null,
  validationError: null,
  setValidationError: () => null,
  positionCatalog: {} as PositionCatalog,
  ahuComponentCatalog: {} as AhuComponentCatalog,
  structuralAssessmentCatalog: {} as StructuralAssessmentCatalog,
  visualAssessmentCatalog: {} as VisualAssessmentCatalog,
  airHandlingUnits: [],
  onAirHandlingUnitChange: () => null,
  orders: [],
  offers: [],
  laboratories: [],
  devices: [],
});

export function useWorkOrderContext() {
  return useContext(WorkOrderContext);
}

export function WorkOrderContextProvider({
  children,
  workOrder: propWorkOrder,
  positionCatalog: propPositionCatalog,
  ahuComponentCatalog: propAhuComponentCatalog,
  structuralAssessmentCatalog: propStructuralAssessmentCatalog,
  visualAssessmentCatalog: propVisualAssessmentCatalog,
  airHandlingUnits: propAirHandlingUnits,
  offers: propOffers,
  orders: propsOrders,
  laboratories: propLaboratories,
  devices: propDevices,
}: {
  children: ReactNode;
  workOrder: WorkOrder;
  positionCatalog: PositionCatalog;
  ahuComponentCatalog: AhuComponentCatalog;
  structuralAssessmentCatalog: StructuralAssessmentCatalog;
  visualAssessmentCatalog: VisualAssessmentCatalog;
  airHandlingUnits: AirHandlingUnit[];
  offers: Offer[];
  orders: Order[];
  laboratories: Laboratory[];
  devices: Device[];
}) {
  // idea would be to use index db here for offline availability
  const [workOrder, setWorkOrder] = useState<WorkOrder>();
  const [positionCatalog, setPositionCatalog] = useState<PositionCatalog>();
  const [ahuComponentCatalog, setAhuComponentCatalog] =
    useState<AhuComponentCatalog>();
  const [structuralAssessmentCatalog, setStructuralAssessmentCatalog] =
    useState<StructuralAssessmentCatalog>();
  const [visualAssessmentCatalog, setVisualAssessmentCatalog] =
    useState<VisualAssessmentCatalog>();
  const [airHandlingUnits, setAirHandlingUnits] =
    useState<AirHandlingUnit[]>(propAirHandlingUnits);
  const [offers, setOffers] = useState<Offer[]>();
  const [orders, setOrders] = useState<Order[]>();
  const [laboratories] = useState<Laboratory[]>(propLaboratories);
  const [devices] = useState<Device[]>(propDevices);
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  // after vector clocks are added, they can be used to
  // update the work order here.
  if (!workOrder || workOrder.id !== propWorkOrder.id) {
    setWorkOrder(propWorkOrder);
  }

  // Neither, the catalog nor the air handling units are mutated within the
  // confirmation context. Any value coming is must be an updated server value.
  if (!positionCatalog || positionCatalog !== propPositionCatalog) {
    setPositionCatalog(propPositionCatalog);
  }
  if (!ahuComponentCatalog || ahuComponentCatalog !== propAhuComponentCatalog) {
    setAhuComponentCatalog(propAhuComponentCatalog);
  }
  if (
    !structuralAssessmentCatalog ||
    structuralAssessmentCatalog !== propStructuralAssessmentCatalog
  ) {
    setStructuralAssessmentCatalog(propStructuralAssessmentCatalog);
  }
  if (
    !visualAssessmentCatalog ||
    visualAssessmentCatalog !== propVisualAssessmentCatalog
  ) {
    setVisualAssessmentCatalog(propVisualAssessmentCatalog);
  }

  useEffect(() => {
    setAirHandlingUnits(propAirHandlingUnits);
  }, [propAirHandlingUnits]);

  // TODO needs vector clocks, also updated from within
  // if (!airHandlingUnits || airHandlingUnits !== propAirHandlingUnits) {
  //   setAirHandlingUnits(propAirHandlingUnits);
  // }

  if (!offers || offers !== propOffers) {
    setOffers(propOffers);
  }
  if (!orders || orders !== propsOrders) {
    setOrders(propsOrders);
  }

  const onWorkOrderChange = (changedWorkOrder: WorkOrder) => {
    setWorkOrder(changedWorkOrder);
  };

  const onAirHandlingUnitChange = useCallback(
    (ahu: AirHandlingUnit) => {
      setAirHandlingUnits(
        airHandlingUnits?.map((a) => (a.id === ahu.id ? ahu : a)),
      );
    },
    [airHandlingUnits, setAirHandlingUnits],
  );

  const value = useMemo(
    () => ({
      workOrder: workOrder!,
      onWorkOrderChange,
      validationError,
      setValidationError,
      airHandlingUnits: airHandlingUnits!,
      onAirHandlingUnitChange,
      positionCatalog: positionCatalog!,
      ahuComponentCatalog: ahuComponentCatalog!,
      structuralAssessmentCatalog: structuralAssessmentCatalog!,
      visualAssessmentCatalog: visualAssessmentCatalog!,
      offers: offers!,
      orders: orders!,
      laboratories,
      devices,
    }),
    [
      workOrder,
      validationError,
      airHandlingUnits,
      onAirHandlingUnitChange,
      positionCatalog,
      ahuComponentCatalog,
      structuralAssessmentCatalog,
      visualAssessmentCatalog,
      offers,
      orders,
      laboratories,
      devices,
    ],
  );

  return (
    <WorkOrderContext.Provider value={value}>
      <WorkOrderUpdater>
        <AirHandlingUnitUpdater>{children}</AirHandlingUnitUpdater>
      </WorkOrderUpdater>
    </WorkOrderContext.Provider>
  );
}

function WorkOrderUpdater({ children }: PropsWithChildren) {
  // this should only run when online
  const { workOrder, setValidationError } = useWorkOrderContext();

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPutMutation();

  useEffect(() => {
    if (rtkErrIsValidationError(error)) {
      setValidationError(error.data);
    }
  }, [error, setValidationError]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    workOrder,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}

function AirHandlingUnitUpdater({ children }: PropsWithChildren) {
  const { airHandlingUnits } = useWorkOrderContext();

  const [oldState, setOldState] = useState<AirHandlingUnit[]>(airHandlingUnits);

  const [ahu, setAhu] = useState<AirHandlingUnit>({} as AirHandlingUnit);

  useEffect(() => {
    // find the first one different from old
    const updated = airHandlingUnits.find((a) =>
      oldState?.some((oldA) => a.id === oldA.id && !deepEqual(a, oldA))
        ? a
        : null,
    );

    if (updated != null) {
      // setAhu, to be picked up by the debounced mutation below.
      setAhu(updated);
      // update the oldState with the updated ahu.
      // TODO: this is optimistic, we should wait for the mutation to succeed.
      //  -> use the return value of the mutation to update the oldState.
      setOldState(
        oldState?.map((oldA) => (oldA.id === updated.id ? updated : oldA)),
      );
    }
  }, [airHandlingUnits, oldState]);

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzAirHandlingUnitPutMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    ahu,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    // Since the air handling unit may change when moved in the
    // ReactFlow component, a longer debounce prevents too
    // frequent requests.
    1000,
    {
      toastError: true,
    },
  );

  return children;
}
