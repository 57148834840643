import {
  addMicrobialAssessment,
  deleteMicrobialAssessment,
  HTZ_WORK_ORDER_NAMESPACE,
  Position,
} from "@/services/backend/htz/work-order/work-order";
import { useMemo, useState } from "react";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { Card } from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";
import { Button } from "@/shared/components/ui/button";
import { Plus, PlusCircle, Trash2 } from "lucide-react";
import { H4 } from "@/shared/components/ui/typography";
import { v4 } from "uuid";
import { MicrobialAssessment } from "@/services/backend/htz/inspection/microbial-assessment";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Kind } from "@/services/backend/samples/sample/kind";
import { Separator } from "@/shared/components/ui/separator";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import {
  getAirMicrobialSet,
  useAirMicrobialSamplesContext,
} from "@/shared/components/domain/samples/air-microbial-samples-context";
import {
  activateAirMicrobialSetSample,
  AirMicrobialSample,
  airMicrobialSampleIsActivated,
  AirMicrobialSampleSet,
  deactivateAirMicrobialSetSample,
  newAirMicrobialSampleSet,
  updateAirMicrobialSetCaso,
  updateAirMicrobialSetComment,
  updateAirMicrobialSetDg18,
  updateAirMicrobialSetLab,
  updateAirMicrobialSetMalt,
  updateAirMicrobialSetNumber,
  updateDeviceId,
  updateSamplingVolume,
} from "@/services/backend/samples/sample/air-microbial-sample";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { GrowthMedium } from "@/services/backend/samples/sample/growth-medium";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import {
  Sample,
  updateSampleComment,
  updateSampleCreatedAt,
  updateSampleLab,
  updateSampleNumber,
} from "@/services/backend/samples/sample/sample";
import { Textarea } from "@/shared/components/ui/textarea";
import { DateStringPicker } from "@/shared/components/ui/date-picker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import {
  getSurfaceMicrobialSet,
  useSurfaceMicrobialSamplesContext,
} from "@/shared/components/domain/samples/surface-microbial-samples-context";
import {
  activateSurfaceMicrobialSetSample,
  deactivateSurfaceMicrobialSetSample,
  newSurfaceMicrobialSampleSet,
  SurfaceMicrobialSample,
  surfaceMicrobialSampleIsActivated,
  SurfaceMicrobialSampleSet,
  updateSurfaceMicrobialSetCaso,
  updateSurfaceMicrobialSetComment,
  updateSurfaceMicrobialSetDg18,
  updateSurfaceMicrobialSetLab,
  updateSurfaceMicrobialSetMalt,
  updateSurfaceMicrobialSetNumber,
} from "@/services/backend/samples/sample/surface-microbial-sample";
import {
  getDustDensitySample,
  useDustDensitySamplesContext,
} from "@/shared/components/domain/samples/dust-density-samples-context";
import {
  getWaterSample,
  useWaterSamplesContext,
} from "@/shared/components/domain/samples/water-samples-context";
import {
  DustDensitySample,
  newDustDensitySample,
} from "@/services/backend/samples/sample/dust-density-sample";
import {
  newWaterSample,
  updateTemperature,
  WaterSample,
} from "@/services/backend/samples/sample/water-sample";
import { usePositionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/position-context";

export function MicrobialInspection() {
  const [assessmentId, setAssessmentId] = useState<string | null>(null);

  return (
    <ScrollArea className="h-[65vh] space-y-2">
      <MicrobialAssessmentTable
        selectedAssessmentId={assessmentId}
        onAssessmentSelectedId={(a) => setAssessmentId(a)}
      />
      <div className="p-2 py-4">
        <MicrobialAssessmentForm selectedAssessmentId={assessmentId} />
      </div>
    </ScrollArea>
  );
}

export function AddMicrobialAssessmentButton({
  position,
}: {
  position: Position;
}) {
  const { workOrder } = useWorkOrderContext();
  const { onPositionChange } = usePositionContext();

  const { addSet } = useAirMicrobialSamplesContext();
  const { addSet: addSurfaceMicrobialSet } =
    useSurfaceMicrobialSamplesContext();
  const { addSample } = useDustDensitySamplesContext();
  const { addSample: addWaterSample } = useWaterSamplesContext();
  const { activeComponent } = useInspectionContext();

  const doAdd = (kind: Kind) => () => {
    // A new assessment needs a new sample or sample set depending on the kind.
    const sampleId = v4();
    const assessmentId = v4();

    switch (kind) {
      case Kind.AirMicrobial:
        addSet(
          newAirMicrobialSampleSet(
            sampleId,
            workOrder.id,
            HTZ_WORK_ORDER_NAMESPACE,
          ),
        );
        break;
      case Kind.SurfaceMicrobial:
        addSurfaceMicrobialSet(
          newSurfaceMicrobialSampleSet(
            sampleId,
            workOrder.id,
            HTZ_WORK_ORDER_NAMESPACE,
          ),
        );
        break;
      case Kind.DustDensity:
        addSample(
          newDustDensitySample(
            sampleId,
            workOrder.id,
            HTZ_WORK_ORDER_NAMESPACE,
          ),
        );
        break;
      case Kind.Water:
        addWaterSample(
          newWaterSample(sampleId, workOrder.id, HTZ_WORK_ORDER_NAMESPACE),
        );
        break;
      default:
        throw new Error(`Unknown kind: ${kind}`);
    }

    onPositionChange(
      addMicrobialAssessment(
        position,
        activeComponent!.id,
        assessmentId,
        kind,
        sampleId,
      ),
    );
  };

  const disabled = workOrder.immutable || activeComponent === null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button>
          <PlusCircle />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Probe hinzufügen")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={doAdd(Kind.AirMicrobial)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Luftkeimprobe")}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={doAdd(Kind.SurfaceMicrobial)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Oberflächenkeimprobe")}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={doAdd(Kind.DustDensity)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Staubdichtemessung")}</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={doAdd(Kind.Water)}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Wasserprobe")}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function MicrobialAssessmentTable({
  selectedAssessmentId,
  onAssessmentSelectedId,
}: {
  selectedAssessmentId: string | null;
  onAssessmentSelectedId: (a: string | null) => void;
}) {
  const { position } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const toggle = (assessment: MicrobialAssessment) => {
    if (assessment.id === selectedAssessmentId) {
      onAssessmentSelectedId(null);
    } else {
      onAssessmentSelectedId(assessment.id);
    }
  };

  return (
    <Card className="shadow-none">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="h-8">{t("Probenart")}</TableHead>
            <TableHead className="h-8">{t("")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {position.microbialAssessments
            ?.filter((a) => a.componentId === activeComponent?.id)
            .map((assessment) => (
              <TableRow
                key={assessment.id}
                className={cn(
                  "cursor-pointer",
                  assessment.id === selectedAssessmentId ? "bg-accent" : "",
                )}
              >
                <TableCell className="py-1" onClick={() => toggle(assessment)}>
                  {t(assessment.kind)}
                </TableCell>
                <TableCell className="py-1 text-right">
                  <DeleteAssessmentButton assessmentId={assessment.id} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function DeleteAssessmentButton({ assessmentId }: { assessmentId: string }) {
  const { position, onPositionChange } = usePositionContext();
  const { deleteSet } = useAirMicrobialSamplesContext();
  const { deleteSet: deleteSurfaceMicrobialSet } =
    useSurfaceMicrobialSamplesContext();
  const { deleteSample: deleteDustSample } = useDustDensitySamplesContext();
  const { deleteSample: deleteWaterSample } = useWaterSamplesContext();

  const doDelete = () => {
    // Remove the sample sets from the context.
    const sampleId =
      position.microbialAssessments?.find((a) => a.id === assessmentId)
        ?.sampleId ?? null;
    deleteSet(sampleId);
    deleteSurfaceMicrobialSet(sampleId);
    deleteDustSample(sampleId);
    deleteWaterSample(sampleId);

    // Remove the assessment from the work order.
    const wo = deleteMicrobialAssessment(position, assessmentId);
    onPositionChange(wo);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive" className="h-8 w-8 p-0">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Beurteilung löschen?")}</DialogTitle>
          <DialogDescription>
            {t(
              "Das Löschen der Beurteilung wird auch die zugeordneten Proben entfernen.",
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function MicrobialAssessmentForm({
  selectedAssessmentId,
}: {
  selectedAssessmentId: string | null;
}) {
  const { position } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const assessment = position.microbialAssessments?.find(
    (a) =>
      a.id === selectedAssessmentId && a.componentId === activeComponent?.id,
  );

  if (!assessment) {
    return null;
  }

  switch (assessment.kind) {
    case Kind.AirMicrobial:
      return <AirMicrobialSampleForm id={assessment.sampleId} />;
    case Kind.SurfaceMicrobial:
      return <SurfaceMicrobialSampleForm id={assessment.sampleId} />;
    case Kind.DustDensity:
      return <DustDensitySampleForm id={assessment.sampleId} />;
    case Kind.Water:
      return <WaterSampleForm id={assessment.sampleId} />;
    default:
      return null;
  }
}

function AirMicrobialSampleForm({ id }: { id: EntityId }) {
  const { sets, updateSet, showError } = useAirMicrobialSamplesContext();

  const set = useMemo(() => getAirMicrobialSet(sets, id), [sets, id]);

  if (!set) {
    return null;
  }

  const onSetChange = (s: AirMicrobialSampleSet) => {
    updateSet(s);
  };

  const error = showError(id!);

  return (
    <div className="h-full">
      <H4>{t(Kind.AirMicrobial)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={set.number}
            onNumberChange={(number) =>
              onSetChange(updateAirMicrobialSetNumber(set, number))
            }
            error={error}
          />
          <LaboratorySelect
            labId={set.labId}
            onLabIdChange={(labId) =>
              onSetChange(updateAirMicrobialSetLab(set, labId))
            }
            error={error}
          />
        </div>
        <CommentInput
          comment={set.comment}
          onCommentChange={(comment) =>
            onSetChange(updateAirMicrobialSetComment(set, comment))
          }
          error={error}
        />
        <Tabs defaultValue={GrowthMedium.CASO}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value={GrowthMedium.CASO}>
              {t(GrowthMedium.CASO)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.MALT}>
              {t(GrowthMedium.MALT)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.DG18} className="uppercase">
              {t(GrowthMedium.DG18)}
            </TabsTrigger>
          </TabsList>
          <div className="p-2">
            <TabsContent value={GrowthMedium.CASO}>
              <AirMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.CASO}
                set={set}
                onSetChange={onSetChange}
                error={error}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.MALT}>
              <AirMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.MALT}
                set={set}
                onSetChange={onSetChange}
                error={error}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.DG18}>
              <AirMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.DG18}
                set={set}
                onSetChange={onSetChange}
                error={error}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

function SurfaceMicrobialSampleForm({ id }: { id: EntityId }) {
  const { sets, updateSet, showError } = useSurfaceMicrobialSamplesContext();

  const set = useMemo(() => getSurfaceMicrobialSet(sets, id), [sets, id]);

  if (!set) {
    return null;
  }

  const onSetChange = (s: SurfaceMicrobialSampleSet) => {
    updateSet(s);
  };

  const error = showError(id!);

  return (
    <div className="h-full">
      <H4>{t(Kind.SurfaceMicrobial)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={set.number}
            onNumberChange={(number) =>
              onSetChange(updateSurfaceMicrobialSetNumber(set, number))
            }
            error={error}
          />
          <LaboratorySelect
            labId={set.labId}
            onLabIdChange={(labId) =>
              onSetChange(updateSurfaceMicrobialSetLab(set, labId))
            }
            error={error}
          />
        </div>
        <CommentInput
          comment={set.comment}
          onCommentChange={(comment) =>
            onSetChange(updateSurfaceMicrobialSetComment(set, comment))
          }
          error={error}
        />
        <Tabs defaultValue={GrowthMedium.CASO}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value={GrowthMedium.CASO}>
              {t(GrowthMedium.CASO)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.MALT}>
              {t(GrowthMedium.MALT)}
            </TabsTrigger>
            <TabsTrigger value={GrowthMedium.DG18} className="uppercase">
              {t(GrowthMedium.DG18)}
            </TabsTrigger>
          </TabsList>
          <div className="p-2">
            <TabsContent value={GrowthMedium.CASO}>
              <SurfaceMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.CASO}
                set={set}
                onSetChange={onSetChange}
                error={error}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.MALT}>
              <SurfaceMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.MALT}
                set={set}
                onSetChange={onSetChange}
                error={error}
              />
            </TabsContent>
            <TabsContent value={GrowthMedium.DG18}>
              <SurfaceMicrobialSampleSetSampleFields
                growthMedium={GrowthMedium.DG18}
                set={set}
                onSetChange={onSetChange}
                error={error}
              />
            </TabsContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
}

function DustDensitySampleForm({ id }: { id: EntityId }) {
  const { samples, updateSample, showError } = useDustDensitySamplesContext();

  const sample = useMemo(
    () => getDustDensitySample(samples, id),
    [samples, id],
  );

  if (!sample) {
    return null;
  }

  const onSetChange = (s: DustDensitySample) => {
    updateSample(s);
  };

  const error = showError(id!);

  return (
    <div className="h-full">
      <H4>{t(Kind.DustDensity)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={sample.number}
            onNumberChange={(number) =>
              onSetChange(updateSampleNumber(sample, number))
            }
            error={error}
          />
          <CreatedAtInput
            createdAt={sample.createdAt}
            onCreatedAtChange={(createdAt) =>
              onSetChange(updateSampleCreatedAt(sample, createdAt))
            }
            error={error}
          />
        </div>
        <LaboratorySelect
          labId={sample.labId}
          onLabIdChange={(labId) => onSetChange(updateSampleLab(sample, labId))}
          error={error}
        />
        <CommentInput
          comment={sample.comment}
          onCommentChange={(comment) =>
            onSetChange(updateSampleComment(sample, comment))
          }
          error={error}
        />
      </div>
    </div>
  );
}

function WaterSampleForm({ id }: { id: EntityId }) {
  const { samples, updateSample, showError } = useWaterSamplesContext();

  const sample = useMemo(() => getWaterSample(samples, id), [samples, id]);

  if (!sample) {
    return null;
  }

  const onSetChange = (s: WaterSample) => {
    updateSample(s);
  };

  const error = showError(id!);

  return (
    <div className="h-full">
      <H4>{t(Kind.Water)}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <NumberInput
            number={sample.number}
            onNumberChange={(number) =>
              onSetChange(updateSampleNumber(sample, number))
            }
            error={error}
          />
          <CreatedAtInput
            createdAt={sample.createdAt}
            onCreatedAtChange={(createdAt) =>
              onSetChange(updateSampleCreatedAt(sample, createdAt))
            }
            error={error}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <TemperatureInput
            temperature={sample.temperature}
            onTemperatureChange={(temperature) =>
              onSetChange(updateTemperature(sample, temperature))
            }
            error={error}
          />
          <LaboratorySelect
            labId={sample.labId}
            onLabIdChange={(labId) =>
              onSetChange(updateSampleLab(sample, labId))
            }
            error={error}
          />
        </div>
        <CommentInput
          comment={sample.comment}
          onCommentChange={(comment) =>
            onSetChange(updateSampleComment(sample, comment))
          }
          error={error}
        />
      </div>
    </div>
  );
}

function NumberInput({
  number,
  onNumberChange,
  error,
  disabled,
}: {
  number: string;
  onNumberChange: (number: string) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Probennummer")}</Label>
      <Input
        type="text"
        value={number}
        onChange={(e) => onNumberChange(e.target.value)}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="number" />
    </div>
  );
}

function TemperatureInput({
  temperature,
  onTemperatureChange,
  error,
  disabled,
}: {
  temperature: number;
  onTemperatureChange: (temperature: number) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Temperatur")}</Label>
      <Input
        type="number"
        value={temperature}
        onChange={(e) => onTemperatureChange(Number(e.target.value))}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="temperature" />
    </div>
  );
}

function LaboratorySelect({
  labId,
  onLabIdChange,
  error,
  disabled,
}: {
  labId: EntityId;
  onLabIdChange: (labId: EntityId) => void;
  error: unknown;
  disabled?: boolean;
}) {
  const { laboratories } = useWorkOrderContext();

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Labor")}</Label>
      <Select
        value={String(labId)}
        onValueChange={onLabIdChange}
        disabled={disabled ?? false}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Labor auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {laboratories.map((lab) => (
            <SelectItem key={lab.id} value={lab.id}>
              {lab.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="labId" />
    </div>
  );
}

function AirMicrobialSampleSetSampleFields({
  growthMedium,
  set,
  onSetChange,
  error,
}: {
  growthMedium: GrowthMedium;
  set: AirMicrobialSampleSet;
  onSetChange: (set: AirMicrobialSampleSet) => void;
  error: unknown;
}) {
  return (
    <div className="grid gap-4">
      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={airMicrobialSampleIsActivated(set, growthMedium)}
          onCheckedChange={(state: CheckedState) => {
            switch (state) {
              case true: {
                onSetChange(activateAirMicrobialSetSample(set, growthMedium));
                break;
              }
              case false: {
                onSetChange(deactivateAirMicrobialSetSample(set, growthMedium));
                break;
              }
              default:
                break;
            }
          }}
        />
        <Label htmlFor="terms">{`Hat ${t(growthMedium)} Probe`}</Label>
      </div>
      {airMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.CASO && (
          <AirMicrobialSampleFields
            sample={set.caso!}
            onSampleChange={(sample) =>
              onSetChange(updateAirMicrobialSetCaso(set, sample))
            }
            error={error}
          />
        )}
      {airMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.MALT && (
          <AirMicrobialSampleFields
            sample={set.malt!}
            onSampleChange={(sample) =>
              onSetChange(updateAirMicrobialSetMalt(set, sample))
            }
            error={error}
          />
        )}
      {airMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.DG18 && (
          <AirMicrobialSampleFields
            sample={set.dg18!}
            onSampleChange={(sample) =>
              onSetChange(updateAirMicrobialSetDg18(set, sample))
            }
            error={error}
          />
        )}
    </div>
  );
}

function AirMicrobialSampleFields({
  sample,
  onSampleChange,
  error,
}: {
  sample: AirMicrobialSample;
  onSampleChange: (sample: AirMicrobialSample) => void;
  error: unknown;
}) {
  return (
    <div className="grid gap-4">
      <BaseSampleFields
        sample={sample}
        onSampleChange={onSampleChange}
        error={error}
        disableSetBoundFields
      />
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          <SamplingVolumeInput
            volume={sample.samplingVolume}
            onVolumeChange={(volume) =>
              onSampleChange(updateSamplingVolume(sample, volume))
            }
            error={error}
          />
        </div>
        <div className="col-span-2">
          <DeviceSelect
            deviceId={sample.deviceId}
            onDeviceIdChange={(deviceId) =>
              onSampleChange(updateDeviceId(sample, deviceId))
            }
            error={error}
            disabled
          />
        </div>
      </div>
    </div>
  );
}

function SamplingVolumeInput({
  volume,
  onVolumeChange,
  error,
}: {
  volume: number;
  onVolumeChange: (volume: number) => void;
  error: unknown;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Ansaugvolumen")}</Label>
      <Input
        type="number"
        value={volume}
        onChange={(e) => onVolumeChange(parseFloat(e.target.value))}
      />
      <InputValidationErrors error={error} field="samplingVolume" />
    </div>
  );
}

function DeviceSelect({
  deviceId,
  onDeviceIdChange,
  error,
  disabled,
}: {
  deviceId: EntityId;
  onDeviceIdChange: (deviceId: EntityId) => void;
  error: unknown;
  disabled?: boolean;
}) {
  const { devices } = useWorkOrderContext();

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Verwendetes Gerät")}</Label>
      <Select
        value={String(deviceId)}
        onValueChange={onDeviceIdChange}
        disabled={disabled ?? false}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Gerät auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {devices.map((device) => (
            <SelectItem key={device.id} value={device.id}>
              {device.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="deviceId" />
    </div>
  );
}

function SurfaceMicrobialSampleSetSampleFields({
  growthMedium,
  set,
  onSetChange,
  error,
}: {
  growthMedium: GrowthMedium;
  set: SurfaceMicrobialSampleSet;
  onSetChange: (set: SurfaceMicrobialSampleSet) => void;
  error: unknown;
}) {
  return (
    <div className="grid gap-4">
      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={surfaceMicrobialSampleIsActivated(set, growthMedium)}
          onCheckedChange={(state: CheckedState) => {
            switch (state) {
              case true: {
                onSetChange(
                  activateSurfaceMicrobialSetSample(set, growthMedium),
                );
                break;
              }
              case false: {
                onSetChange(
                  deactivateSurfaceMicrobialSetSample(set, growthMedium),
                );
                break;
              }
              default:
                break;
            }
          }}
        />
        <Label htmlFor="terms">{`Hat ${t(growthMedium)} Probe`}</Label>
      </div>
      {surfaceMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.CASO && (
          <SurfaceMicrobialSampleFields
            sample={set.caso!}
            onSampleChange={(sample) =>
              onSetChange(updateSurfaceMicrobialSetCaso(set, sample))
            }
            error={error}
          />
        )}
      {surfaceMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.MALT && (
          <SurfaceMicrobialSampleFields
            sample={set.malt!}
            onSampleChange={(sample) =>
              onSetChange(updateSurfaceMicrobialSetMalt(set, sample))
            }
            error={error}
          />
        )}
      {surfaceMicrobialSampleIsActivated(set, growthMedium) &&
        growthMedium === GrowthMedium.DG18 && (
          <SurfaceMicrobialSampleFields
            sample={set.dg18!}
            onSampleChange={(sample) =>
              onSetChange(updateSurfaceMicrobialSetDg18(set, sample))
            }
            error={error}
          />
        )}
    </div>
  );
}

function SurfaceMicrobialSampleFields({
  sample,
  onSampleChange,
  error,
}: {
  sample: SurfaceMicrobialSample;
  onSampleChange: (sample: SurfaceMicrobialSample) => void;
  error: unknown;
}) {
  return (
    <div className="grid gap-4">
      <BaseSampleFields
        sample={sample}
        onSampleChange={onSampleChange}
        error={error}
        disableSetBoundFields
      />
    </div>
  );
}

function BaseSampleFields<S extends Sample>({
  sample,
  onSampleChange,
  error,
  disableSetBoundFields,
}: {
  sample: S;
  onSampleChange: (sample: S) => void;
  error: unknown;
  disableSetBoundFields?: boolean;
}) {
  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-2 gap-4">
        <NumberInput
          number={sample.number}
          onNumberChange={(number) =>
            onSampleChange(updateSampleNumber(sample, number))
          }
          error={error}
          disabled={disableSetBoundFields}
        />
        <CreatedAtInput
          createdAt={sample.createdAt}
          onCreatedAtChange={(createdAt) =>
            onSampleChange(updateSampleCreatedAt(sample, createdAt))
          }
          error={error}
        />
      </div>
      <LaboratorySelect
        labId={sample.labId}
        onLabIdChange={(labId) =>
          onSampleChange(updateSampleLab(sample, labId))
        }
        error={error}
        disabled={disableSetBoundFields}
      />
      <CommentInput
        comment={sample.comment}
        onCommentChange={(comment) =>
          onSampleChange(updateSampleComment(sample, comment))
        }
        error={error}
      />
    </div>
  );
}

function CommentInput({
  comment,
  onCommentChange,
  error,
  disabled,
}: {
  comment: string;
  onCommentChange: (comment: string) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Kommentar")}</Label>
      <Textarea
        value={comment}
        onChange={(e) => onCommentChange(e.target.value)}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="comment" />
    </div>
  );
}

function CreatedAtInput({
  createdAt,
  onCreatedAtChange,
  error,
  disabled,
}: {
  createdAt: string | null;
  onCreatedAtChange: (createdAt: string | null) => void;
  error: unknown;
  disabled?: boolean;
}) {
  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Erstellt am")}</Label>
      <DateStringPicker
        date={createdAt}
        onDateChange={onCreatedAtChange}
        disabled={disabled ?? false}
      />
      <InputValidationErrors error={error} field="createdAt" />
    </div>
  );
}
