import { createContext, ReactNode, useContext, useMemo, useState } from "react";

export const useActiveCompany = () => useContext(ActiveCompanyContext);

interface ActiveCompanyContextInterface {
  companyId: string;
  setCompanyId: (companyId: string) => void;
}

export const ActiveCompanyContext =
  createContext<ActiveCompanyContextInterface>({
    companyId: "",
    setCompanyId: () => {},
  });

export function ActiveCompanyProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [companyId, setCompanyId] = useState("");

  const value = useMemo(
    () => ({ companyId, setCompanyId }),
    [companyId, setCompanyId],
  );

  return (
    <ActiveCompanyContext.Provider value={value}>
      {children}
    </ActiveCompanyContext.Provider>
  );
}
