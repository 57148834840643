import {
  addComponent,
  AirHandlingUnit,
  changeNodes,
  Component,
  newFromTemplate,
} from "@/services/backend/htz/ahu/air-handling-unit";
import {
  Background,
  BackgroundVariant,
  Controls,
  NodeChange,
  NodeProps,
  Panel,
  ReactFlow,
  useReactFlow,
} from "@xyflow/react";
import { createContext, useContext, useMemo, useState } from "react";
import {
  AhuComponentCatalog,
  Item,
} from "@/services/backend/htz/ahu/ahu-component-catalog";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger,
} from "@/shared/components/ui/context-menu";
import { ChevronDown, Trash2 } from "lucide-react";
import t from "@/lang/lang";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { Separator } from "@/shared/components/ui/separator";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { InspectionIndicator } from "@/shared/components/domain/htz/ahu/indicators";
import { cn } from "@/shared/lib/utils";

export interface AhuComponentsSchemaProps {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
  ahuComponentCatalog: AhuComponentCatalog;
}

export function AhuComponentsSchema({
  ahu,
  onAhuChange,
  ahuComponentCatalog,
}: AhuComponentsSchemaProps) {
  const value = useMemo(
    () => ({
      ahu,
      onAhuChange,
      ahuComponentCatalog,
      immutable: false,
    }),
    [ahu, onAhuChange, ahuComponentCatalog],
  );

  const { active, initialized } = useInspectionContext();
  const inspectionMode = initialized && active;

  // We use a context, since it is not possible to pass down
  // properties to nodes in the schema. Using a context allows
  // to define actions on the node or from a context menu (right click).
  return (
    <AhuSchemaContext.Provider value={value}>
      {inspectionMode ? <InspectionModeSchema /> : <AhuSchema />}
    </AhuSchemaContext.Provider>
  );
}

interface AhuSchemaContextInterface {
  ahu: AirHandlingUnit;
  onAhuChange: (ahu: AirHandlingUnit) => void;
  ahuComponentCatalog: AhuComponentCatalog;
  immutable: boolean;
}

const AhuSchemaContext = createContext<AhuSchemaContextInterface>({
  ahu: {} as AirHandlingUnit,
  onAhuChange: () => null,
  ahuComponentCatalog: {} as AhuComponentCatalog,
  immutable: false,
});

function useAhuSchemaContext() {
  return useContext(AhuSchemaContext);
}

// 12 is a value working well together with tailwind sizes
const gridGap = 12;

function InspectionModeSchema() {
  const { ahu, ahuComponentCatalog } = useAhuSchemaContext();
  const { components: nodes } = ahu;

  const nodeTypes = useMemo(
    () =>
      ahuComponentCatalog.items.reduce(
        (acc, template) => ({
          ...acc,
          // btw, TS is crazy...
          [template.type]: InspectionModeSchemaComponent,
        }),
        {},
      ),
    [ahuComponentCatalog],
  );

  return (
    <div className="h-full rounded-lg border">
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        snapGrid={[gridGap, gridGap]}
        fitView
        fitViewOptions={{
          padding: 0.25,
        }}
      >
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={gridGap} size={1} />
      </ReactFlow>
    </div>
  );
}

function AhuSchema() {
  const { ahu, onAhuChange, ahuComponentCatalog } = useAhuSchemaContext();
  const { components: nodes } = ahu;

  const onNodesChange = (changes: NodeChange<Component>[]) =>
    onAhuChange(changeNodes(ahu, changes));

  const nodeTypes = useMemo(
    () =>
      ahuComponentCatalog.items.reduce(
        (acc, template) => ({
          ...acc,
          // btw, TS is crazy...
          [template.type]: SchemaComponent,
        }),
        {},
      ),
    [ahuComponentCatalog],
  );

  return (
    <div className="h-full rounded-lg border">
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        onNodesChange={onNodesChange}
        snapToGrid
        snapGrid={[gridGap, gridGap]}
        fitView
        fitViewOptions={{
          padding: 1,
        }}
      >
        <AddComponentBar />
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={gridGap} size={1} />
      </ReactFlow>
    </div>
  );
}

function AddComponentBar() {
  const { ahu, onAhuChange, ahuComponentCatalog } = useAhuSchemaContext();

  const onNodeAdd = (node: Component) => onAhuChange(addComponent(ahu, node));

  const withoutGroup = ahuComponentCatalog.items.filter(
    (temp) => temp.group === "",
  );
  const groups = ahuComponentCatalog.items.reduce((prev, cur) => {
    // if without group, do nothing
    if (cur.group === "") {
      return prev;
    }

    // init group
    if (!prev.has(cur.group)) {
      prev.set(cur.group, []);
    }

    // add template
    prev.set(cur.group, [...prev.get(cur.group)!, cur]);

    return prev;
  }, new Map<string, Item[]>());

  // this roughly fits well, for 1-4 rows, with is the expected range
  const rows = Math.ceil(withoutGroup.length / 12);
  const separatorHeight = rows * 2 + rows * 8;

  return (
    <Panel
      position="top-center"
      className="flex min-w-max items-start gap-4 space-x-2 rounded-none border bg-background px-3 py-2 shadow-sm"
    >
      <div className="grid grid-cols-4 gap-2 md:grid-cols-6 lg:grid-cols-12">
        {withoutGroup.map((template) => (
          <AddComponentButton
            template={template}
            onAdd={onNodeAdd}
            key={template.type}
          />
        ))}
      </div>
      <Separator orientation="vertical" className={`h-${separatorHeight}`} />
      <div className="flex flex-col space-y-2">
        {Array.from(groups.keys()).map((group) => (
          <AddComponentGroupPopover
            templates={groups.get(group)!}
            onAdd={onNodeAdd}
            key={group}
          />
        ))}
      </div>
    </Panel>
  );
}

function AddComponentGroupPopover({
  templates,
  onAdd,
}: {
  templates: Item[];
  onAdd: (c: Component) => void;
}) {
  const [open, setOpen] = useState(false);

  if (templates.length === 0) {
    return null;
  }

  const groupName = templates[0].group;

  const doAdd = (component: Component) => {
    onAdd(component);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm">
          <span>{groupName}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="min-w-max" align="end">
        <div className="mb-4 text-sm font-bold">{groupName}</div>
        <div className="grid grid-cols-6 gap-2 bg-background">
          {templates.map((template) => (
            <div key={template.id}>
              <AddComponentButton template={template} onAdd={doAdd} />
              <div className="text-sm tracking-tighter">{template.details}</div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function AddComponentButton({
  template,
  onAdd,
}: {
  template: Item;
  onAdd: (c: Component) => void;
}) {
  const doAdd = () => {
    onAdd(newFromTemplate(template));
  };

  return (
    <button
      type="button"
      onClick={doAdd}
      className="flex items-center justify-center bg-background p-0 hover:bg-background"
    >
      <img
        src={`data:image/svg+xml;base64,${template.img}`}
        alt={template.name}
        className="h-9 max-w-9"
      />
    </button>
  );
}

function InspectionModeSchemaComponent({ id, data }: NodeProps<Component>) {
  const { activeComponent, setActiveComponent } = useInspectionContext();
  const { ahu } = useAhuSchemaContext();
  const component = ahu.components.find((c) => c.id === id);

  // Components (the imgs) are expected to be defined in a
  // fixed aspect ratio and same size.
  // The sizeFactor is used to scale them as configured.
  const width = 12 * data.sizeFactor;

  const active = id === activeComponent?.id;

  const onClick = () => {
    if (active) {
      setActiveComponent(null);
    } else {
      setActiveComponent(component!);
    }
  };

  return (
    <div className="relative">
      <button onClick={onClick} type="button">
        <img
          src={`data:image/svg+xml;base64,${data.img}`}
          alt={data.name}
          className={cn(`w-${width} bg-white`, active && "bg-muted-foreground")}
        />
      </button>
      <div className="absolute left-2 top-12 text-xs font-bold tracking-tight">
        {data.details}
      </div>
      <div className="absolute left-1 top-1">
        <InspectionIndicator componentId={id} />
      </div>
    </div>
  );
}

function SchemaComponent({ id, data }: NodeProps<Component>) {
  const { deleteElements } = useReactFlow();

  // Components (the imgs) are expected to be defined in a
  // fixed aspect ratio and same size.
  // The sizeFactor is used to scale them as configured.
  const width = 12 * data.sizeFactor;

  const onDelete = async () => {
    await deleteElements({
      nodes: [{ id }],
    });
  };

  return (
    <div>
      <ContextMenu>
        <ContextMenuTrigger>
          <div className="relative">
            <img
              src={`data:image/svg+xml;base64,${data.img}`}
              alt={data.name}
              className={`w-${width} bg-white`}
            />
            <div className="absolute left-2 top-12 text-xs font-bold tracking-tight">
              {data.details}
            </div>
          </div>
        </ContextMenuTrigger>
        <ContextMenuContent>
          <ContextMenuLabel>{data.name}</ContextMenuLabel>
          <ContextMenuItem onClick={onDelete}>
            <Trash2 className="mr-2 h-4 w-4" />
            <span>{t("Löschen")}</span>
          </ContextMenuItem>
        </ContextMenuContent>
      </ContextMenu>
    </div>
  );
}
