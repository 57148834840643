import t from "@/lang/lang";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function ProcessTopic(props: { processId: string }) {
  const { processId } = props;
  const {
    data: process,
    isLoading,
    error,
  } = useProcessesProcessShowQuery({ id: processId });

  if (isLoading) {
    return <Skeleton className="h-2 w-52" />;
  }

  if (error != null) {
    return <span>{t("Fehler beim Laden des Themas des Vorgangs")}</span>;
  }

  return <span>{process?.topic}</span>;
}
