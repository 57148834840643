export function providesEntity<R extends { id: string }, T extends string>(
  tagType: T,
) {
  return (result: R | undefined) =>
    result ? [{ type: tagType, id: result.id }] : [];
}

export function providesList<R extends { id: string }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}
