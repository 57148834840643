import { NavLink, Outlet, useParams } from "react-router-dom";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, PanelsTopLeft } from "lucide-react";
import { useVbsOrderListQuery } from "@/services/backend/vbs/orders/service";
import { Order } from "@/services/backend/vbs/orders/order";
import { CreateOrderButton } from "@/routes/gesec/processes/[processId]/vbs/orders/_components/create-order-button";
import { OrderIdentifier } from "@/shared/components/domain/vbs/order/order-identifier";

export default function OrdersRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Auftragseingänge")}</MilestonePageTitle>
        <MilestonePageActions>
          <Subnavigation />
          <CreateOrderButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  );
}

function Subnavigation() {
  const { processId, orderId } = useParams();

  const { data: list } = useVbsOrderListQuery({
    process: {
      active: true,
      values: [processId!],
    },
  });

  const currentPageLabel = () => {
    if (orderId) {
      return <OrderIdentifier orderId={orderId} />;
    }

    return t("Übersicht");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel()}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <NavLink to="">
            <DropdownMenuItem>
              <PanelsTopLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Auftragseingänge")}</DropdownMenuLabel>
        {[...(list?.orders ?? [])].sort(byDate).map((order) => (
          <NavLink to={order.id} key={order.id}>
            <DropdownMenuItem>
              <OrderIdentifier orderId={order.id} />
            </DropdownMenuItem>
          </NavLink>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byDate(a: Order, b: Order) {
  return a.date.localeCompare(b.date);
}
