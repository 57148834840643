import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Plus, RefreshCw } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { useEffect } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useVbsOrderPutMutation } from "@/services/backend/vbs/orders/service";

interface Props {
  processId: string;
}

export function CreateOrderButton({ processId }: Props) {
  const navigate = useNavigate();

  const [create, { error, isLoading }] = useVbsOrderPutMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
    }
  }, [error, toast]);

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    const id = v4();

    create({
      id,
      processId,
      offerId: null,
      date: null,
      channel: null,
      customerReference: null,
      comment: null,
    })
      .unwrap()
      .then(() => {
        navigate(`${id}`);
      });
  };

  return (
    <Button onClick={doCreate} disabled={isLoading}>
      {isLoading ? (
        <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
      ) : (
        <Plus className="mr-2 h-5 w-5" />
      )}
      <span>{t("Auftragseingang")}</span>
    </Button>
  );
}
