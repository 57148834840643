import {
  ProcessPage,
  ProcessPageMain,
  ProcessPageNavigation,
  ProcessPageNavLink,
  ProcessPageNavLinkGroup,
  ProcessPageNavLinkGroupTitle,
  ProcessPageNavLinkList,
} from "@/shared/components/layout/process-page";
import t from "@/lang/lang";
import {
  BadgeEuro,
  Building2Icon,
  Calculator,
  ClipboardPenLine,
} from "lucide-react";
import { Outlet, useParams } from "react-router-dom";
import { ProcessInformation } from "@/shared/components/domain/process/process-information";

export default function QPMRouter() {
  const { processId } = useParams();

  return (
    <ProcessPage>
      <Navigation />
      <ProcessPageMain>
        <ProcessInformation processId={processId!} />
        <Outlet />
      </ProcessPageMain>
    </ProcessPage>
  );
}

function Navigation() {
  return (
    <ProcessPageNavigation>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          {t("Allgemein")}
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="manage-building">
            <Building2Icon className="mr-2 h-5 w-5" />
            {t("Gebäudeverwaltung")}
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          {t("Meilensteine")}
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="requirements">
            <ClipboardPenLine className="mr-2 h-5 w-5" />
            {t("Bestandsaufnahme")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="calculations">
            <Calculator className="mr-2 h-5 w-5" />
            {t("Kalkulation")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="offers">
            <BadgeEuro className="mr-2 h-5 w-5" />
            {t("Angebote")}
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
    </ProcessPageNavigation>
  );
}
