import { RiskLevelKey } from "@/services/backend/qpm/shared/enums";

export class RiskLevelUtil {
  private static descriptions: { [key in RiskLevelKey]: string } = {
    [RiskLevelKey.None]: "kein Risiko",
    [RiskLevelKey.Low]: "geringes Risiko",
    [RiskLevelKey.Medium]: "mittleres Risiko",
    [RiskLevelKey.High]: "hohes Risiko",
  };

  static toLongString(type: RiskLevelKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
