import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { AhuComponentsSchema } from "@/shared/components/domain/htz/ahu/ahu-components-schema";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { useState } from "react";
import {
  PutRequest,
  useHtzAirHandlingUnitPutMutation,
} from "@/services/backend/htz/ahu/service";
import { Button } from "@/shared/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { AhuComponentsTable } from "@/shared/components/domain/htz/ahu/ahu-components-table";
import {
  useHtzAhuComponentCatalogShowActiveCatalogQuery,
  useHtzAhuComponentCatalogShowQuery,
} from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import { RefreshCw } from "lucide-react";
import { AddComponentDropdown } from "@/shared/components/domain/htz/ahu/add-component-dropdown";

export function AhuComponents({ ahu }: { ahu: AirHandlingUnit }) {
  const [tabValue, setTabValue] = useState<string>("schema");
  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzAhuComponentCatalogShowActiveCatalogQuery({});
  const {
    data: catalog,
    isLoading: catalogIsLoading,
    error: catalogError,
  } = useHtzAhuComponentCatalogShowQuery(
    { id: activeCatalog?.catalogId ?? "" },
    { skip: !activeCatalog || activeCatalog.catalogId === null },
  );

  const [request, setRequest] = useState<PutRequest>({ ...ahu });
  const [put, { isLoading, error }] = useHtzAirHandlingUnitPutMutation();

  if (activeCatalogError || catalogError) {
    return <RTKQueryErrorAlert error={activeCatalogError || catalogError} />;
  }

  if (activeCatalogIsLoading || catalogIsLoading) {
    return <Skeleton />;
  }

  const doUpdate = () => {
    if (isLoading) {
      return;
    }

    put(request);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Komponenten")}</CardTitle>
        <CardDescription>
          {t(
            "Die Komponenten der RLT-Anlage können als Liste, wie auch als Schema verwaltet werden. Bitte beachten Sie, dass wenn eine Komponente in der Liste hinzugefügt wird, diese im Schema noch an die passende Position platziert werden muss.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Tabs value={tabValue} onValueChange={setTabValue}>
          <div className="flex justify-between">
            <TabsList>
              <TabsTrigger value="schema">{t("Schema")}</TabsTrigger>
              <TabsTrigger value="list">{t("Liste")}</TabsTrigger>
            </TabsList>
            {tabValue === "list" && (
              <AddComponentDropdown
                ahu={request}
                onAhuChange={setRequest}
                catalog={catalog!}
              />
            )}
          </div>
          <TabsContent value="schema" className="h-[600px]">
            <AhuComponentsSchema
              ahu={request}
              onAhuChange={setRequest}
              ahuComponentCatalog={catalog!}
            />
          </TabsContent>
          <TabsContent value="list">
            <AhuComponentsTable ahu={request} onAhuChange={setRequest} />
          </TabsContent>
        </Tabs>
        <RTKQueryErrorAlert
          error={error}
          ignoreValidationError={false}
          className="mt-2"
        />
      </CardContent>
      <CardFooter className="justify-end">
        <Button onClick={doUpdate}>{t("Speichern")}</Button>
      </CardFooter>
    </Card>
  );
}

function Skeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Komponenten")}</CardTitle>
        <CardDescription>
          {t(
            "Die Komponenten der RLT-Anlage können als Liste, wie auch als Schema verwaltet werden. Bitte beachten Sie, dass wenn eine Komponente in der Liste hinzugefügt wird, diese im Schema noch an die passende Position platziert werden muss.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RefreshCw className="animate-spin" />
      </CardContent>
    </Card>
  );
}
