import {
  addStructuralAssessment,
  deleteStructuralAssessment,
  Position,
  updateStructuralAssessment,
} from "@/services/backend/htz/work-order/work-order";
import { useState } from "react";
import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { Button } from "@/shared/components/ui/button";
import { Plus, PlusCircle, Trash2 } from "lucide-react";
import t from "@/lang/lang";
import {
  fromCatalogItem,
  StructuralAssessment,
} from "@/services/backend/htz/inspection/structural-assessment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { cn } from "@/shared/lib/utils";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import { Card } from "@/shared/components/ui/card";
import { H4 } from "@/shared/components/ui/typography";
import { Separator } from "@/shared/components/ui/separator";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import {
  Item,
  itemsForComponentKind,
} from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { v4 } from "uuid";
import {
  Dot,
  SeveritySelect,
} from "@/services/backend/htz/inspection/severity";
import { errorsFor } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { usePositionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/position-context";

export function StructuralInspection() {
  const [assessmentId, setAssessmentId] = useState<string | null>(null);

  return (
    <ScrollArea className="h-[65vh] space-y-2">
      <StructuralAssessmentTable
        selectedAssessmentId={assessmentId}
        onAssessmentSelectedId={(a) => setAssessmentId(a)}
      />
      <div className="p-2 py-4">
        <StructuralAssessmentForm selectedAssessmentId={assessmentId} />
      </div>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}

export function AddStructuralAssessmentButton({
  position,
}: {
  position: Position;
}) {
  const { workOrder, structuralAssessmentCatalog } = useWorkOrderContext();
  const { onPositionChange } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const doAdd = () => {
    onPositionChange(
      addStructuralAssessment(position, activeComponent!.id, v4()),
    );
  };

  const doAddFromCatalog = (item: Item) => {
    const assessment = fromCatalogItem(
      v4(),
      position.airHandlingUnitId,
      activeComponent!.id,
      item,
    );

    // TODO shorten to one function
    const posWithAssessment = addStructuralAssessment(
      position,
      activeComponent!.id,
      assessment.id,
    );
    const posWithAss = updateStructuralAssessment(
      posWithAssessment,
      assessment,
    );
    onPositionChange(posWithAss);
  };

  const disabled = workOrder.immutable || activeComponent === null;

  const items = itemsForComponentKind(
    structuralAssessmentCatalog,
    activeComponent?.componentKindId ?? "",
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button>
          <PlusCircle />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Beurteilung hinzufügen")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={doAdd}>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Blanko")}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Aus Vorlage:")}</DropdownMenuLabel>
        {items.map((item) => (
          <DropdownMenuItem
            onClick={() => doAddFromCatalog(item)}
            key={item.id}
          >
            <Plus className="mr-2 h-4 w-4" />
            <span>{`${item.shorthand} (${item.element}) ${item.deficiency}`}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function StructuralAssessmentTable({
  selectedAssessmentId,
  onAssessmentSelectedId,
}: {
  selectedAssessmentId: string | null;
  onAssessmentSelectedId: (a: string | null) => void;
}) {
  const { position } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const toggle = (assessment: StructuralAssessment) => {
    if (assessment.id === selectedAssessmentId) {
      onAssessmentSelectedId(null);
    } else {
      onAssessmentSelectedId(assessment.id);
    }
  };

  return (
    <Card className="shadow-none">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="h-8">{t("Kürzel")}</TableHead>
            <TableHead className="h-8">{t("Element")}</TableHead>
            <TableHead className="h-8">{t("Mängel")}</TableHead>
            <TableHead className="h-8">{t("")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {position.structuralAssessments
            ?.filter((a) => a.componentId === activeComponent?.id)
            .map((assessment) => (
              <TableRow
                key={assessment.id}
                className={cn(
                  "cursor-pointer",
                  assessment.id === selectedAssessmentId ? "bg-accent" : "",
                )}
              >
                <TableCell className="py-1" onClick={() => toggle(assessment)}>
                  {assessment.shorthand}
                </TableCell>
                <TableCell
                  className="w-full py-1"
                  onClick={() => toggle(assessment)}
                >
                  {assessment.element}
                </TableCell>
                <TableCell className="py-1" onClick={() => toggle(assessment)}>
                  <Dot severity={assessment.severity} />
                </TableCell>
                <TableCell className="py-1 text-right">
                  <DeleteAssessmentButton assessmentId={assessment.id} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function DeleteAssessmentButton({ assessmentId }: { assessmentId: string }) {
  const { position, onPositionChange } = usePositionContext();

  const doDelete = () => {
    onPositionChange(deleteStructuralAssessment(position, assessmentId));
  };

  return (
    <Button
      size="sm"
      variant="destructive"
      onClick={doDelete}
      className="h-8 w-8 p-0"
    >
      <Trash2 className="h-4 w-4" />
    </Button>
  );
}

function StructuralAssessmentForm({
  selectedAssessmentId,
}: {
  selectedAssessmentId: string | null;
}) {
  const { workOrder } = useWorkOrderContext();
  const { position, onPositionChange, validationError } = usePositionContext();
  const { activeComponent } = useInspectionContext();

  const assessment = position.structuralAssessments?.find(
    (a) =>
      a.id === selectedAssessmentId && a.componentId === activeComponent?.id,
  );

  if (!assessment) {
    return null;
  }

  const errorPrefix = `structuralAssessments.${assessment.id}.`;

  const onChange = (a: StructuralAssessment) =>
    onPositionChange(updateStructuralAssessment(position, a));

  return (
    <div className="h-full">
      <H4>{t("Beurteilung")}</H4>
      <Separator className="mb-4 mt-1" />
      <div className="grid gap-4">
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-1 grid content-start gap-1.5">
            <Label>{t("Kürzel")}</Label>
            <Input
              type="text"
              value={assessment.shorthand}
              onChange={(e) =>
                onChange({ ...assessment, shorthand: e.target.value })
              }
              disabled={workOrder.immutable}
            />
            <InputValidationErrors
              error={validationError}
              field={`${errorPrefix}shorthand`}
            />
          </div>
          <div className="col-span-3 grid content-start gap-1.5">
            <Label>{t("Element")}</Label>
            <Input
              type="text"
              value={assessment.element}
              onChange={(e) =>
                onChange({ ...assessment, element: e.target.value })
              }
              disabled={workOrder.immutable}
            />
            <InputValidationErrors
              error={validationError}
              field={`${errorPrefix}element`}
            />
          </div>
          <div className="col-span-2 content-start">
            <SeveritySelect
              severity={assessment.severity}
              onSeverityChange={(severity) =>
                onChange({ ...assessment, severity })
              }
              errors={
                errorsFor(validationError, `${errorPrefix}severity`) ?? []
              }
            />
          </div>
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Anforderung")}</Label>
          <Textarea
            value={assessment.requirement}
            onChange={(e) =>
              onChange({ ...assessment, requirement: e.target.value })
            }
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={validationError}
            field={`${errorPrefix}requirement`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Mangel")}</Label>
          <Textarea
            value={assessment.deficiency}
            onChange={(e) =>
              onChange({ ...assessment, deficiency: e.target.value })
            }
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={validationError}
            field={`${errorPrefix}deficiency`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Handlungsempfehlung")}</Label>
          <Textarea
            value={assessment.recommendation}
            onChange={(e) =>
              onChange({ ...assessment, recommendation: e.target.value })
            }
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={validationError}
            field={`${errorPrefix}recommendation`}
          />
        </div>
        <div className="grid content-start gap-1.5">
          <Label>{t("Bemerkung")}</Label>
          <Textarea
            value={assessment.comment}
            onChange={(e) =>
              onChange({ ...assessment, comment: e.target.value })
            }
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={validationError}
            field={`${errorPrefix}comment`}
          />
        </div>
      </div>
    </div>
  );
}
