import {
  Building,
  BuildingList,
  CreateBuildingRequest,
} from "@/services/backend/qpm/buildings/types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/shared/components/ui/command";
import { useEffect, useState } from "react";
import { useQpmBuildingCreateMutation } from "@/services/backend/qpm/buildings/service";
import { v4 } from "uuid";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useToast } from "@/shared/hooks/use-toast";
import { Label } from "@/shared/components/ui/label";

type BuildingSelectorProps = {
  buildingList?: BuildingList;
  selectedBuilding: Building | undefined;
  handleBuildingSelect: (value: string) => void;
  customerId: string;
  addressId: string;
  onBuildingCreateCallback?: (value: Building) => void;
};

export function BuildingSelector({
  buildingList = { buildings: [] },
  selectedBuilding,
  handleBuildingSelect,
  customerId,
  addressId,
  onBuildingCreateCallback = undefined,
}: BuildingSelectorProps) {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [newBuildingName, setNewBuildingName] = useState<string>("");
  const [createNewBuilding, { error, data, reset }] =
    useQpmBuildingCreateMutation();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error) });
      reset();
    }
  }, [error, reset, toast]);

  useEffect(() => {
    if (data && onBuildingCreateCallback) {
      setNewBuildingName("");
      setOpen(false);
      onBuildingCreateCallback(data);
      setNewBuildingName("");
      reset();
    }
  }, [data, onBuildingCreateCallback, reset]);

  const handleInputChange = (value: string) => {
    setNewBuildingName(value);
  };

  const handleSubmit = () => {
    if (!newBuildingName) {
      return;
    }
    const newBuildingRequest: CreateBuildingRequest = {
      id: v4(),
      name: newBuildingName.trim(),
      customerId,
      addressId,
    };
    createNewBuilding(newBuildingRequest);
  };

  const handleSelect = (buildingId: string) => {
    handleBuildingSelect(buildingId);
    setOpen(false);
  };

  return (
    <div className="grid items-start gap-1.5">
      <Label html-for="building-selctor">{t("Gebäude")}</Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            id="building-selctor"
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[250px] justify-between"
          >
            {selectedBuilding?.name || t("Gebäude auswählen...")}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-0" side="bottom" align="start">
          <Command>
            <CommandInput
              onValueChange={handleInputChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
              placeholder={t("Neues Gebäude anlegen...")}
            />
            <CommandList>
              <CommandEmpty>
                <div className="flex flex-col gap-3 px-6 text-sm">
                  <span>{t("Gebäude nicht vorhanden.")}</span>
                  <Button size="sm" onClick={handleSubmit} variant="default">
                    {t("Hinzufügen")}
                  </Button>
                </div>
              </CommandEmpty>
              <CommandGroup heading={t("Existierende Gebäude")}>
                {buildingList &&
                  buildingList.buildings.map((building) => (
                    <CommandItem
                      onSelect={() => handleSelect(building.id)}
                      key={building.id}
                    >
                      {building.name}
                    </CommandItem>
                  ))}
              </CommandGroup>
              <CommandSeparator />
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
