import { ContactKindCard } from "@/routes/base-data/contacts/contact-kind/components/contact-kind-card";
import { VbsProcessContactKindMatchCard } from "@/routes/base-data/contacts/contact-kind/components/vbs-process-contact-kind-match-card";
import { RltProcessContactKindMatchCard } from "@/routes/base-data/contacts/contact-kind/components/rlt-process-contact-kind-match-card";

export function ContactKindRoute() {
  return (
    <div>
      <ContactKindCard />
      <div className="mt-4 grid gap-4 xl:grid-cols-2">
        <VbsProcessContactKindMatchCard />
        <RltProcessContactKindMatchCard />
      </div>
    </div>
  );
}
