import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import t from "@/lang/lang";
import { Position } from "@/services/backend/htz/work-order/work-order";
import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import { usePositionsContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/positions-context";

export function DeletePositionDialog({
  position,
  disabled: propDisabled,
}: {
  position: Position;
  disabled?: boolean;
}) {
  const { workOrder } = useWorkOrderContext();
  const { deletePosition } = usePositionsContext();

  const doDelete = () => {
    deletePosition(position);
  };

  const disabled = workOrder.immutable || propDisabled;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive" className="h-8 w-8 p-0">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Position wirklich löschen?")}</DialogTitle>
          <DialogDescription>
            {position.title + t("wird unwiderruflich gelöscht.")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete} disabled={disabled}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
