import { Navigate, RouteObject } from "react-router-dom";
import { OrdersIndexRoute } from "@/routes/gesec/processes/[processId]/htz/orders/orders-index";
import { OrderRoute } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/order-route";
import { DataRoute } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/data/data-route";
import { AddressesRoute } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/addresses/addresses-route";
import { PositionsRoute } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/positions/positions-route";
import { RepresentativeRoute } from "@/routes/gesec/processes/[processId]/htz/orders/[orderId]/representative/representative-route";

export const htzOrdersRoutes: RouteObject[] = [
  {
    index: true,
    element: <OrdersIndexRoute />,
  },
  {
    path: ":orderId",
    element: <OrderRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <DataRoute />,
      },
      {
        path: "positions",
        element: <PositionsRoute />,
      },
      {
        path: "addresses",
        element: <AddressesRoute />,
      },
      {
        path: "representative",
        element: <RepresentativeRoute />,
      },
    ],
  },
];
