import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  useContactsContactKindListProcessContactKindsQuery,
  useContactsContactKindListQuery,
  useContactsContactKindPutProcessContactKindMatchMutation,
  useContactsContactKindShowProcessContactKindMatchQuery,
} from "@/services/backend/contacts/contact-kind/service";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Kind } from "@/services/backend/contacts/contact-kind/kind";
import { ProcessContactKind } from "@/services/backend/contacts/contact-kind/process-contact-kind";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { ProcessContactKindMatch } from "@/services/backend/contacts/contact-kind/process-contact-kind-match";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export function RltProcessContactKindMatchCard() {
  const {
    data: processContactKinds,
    isLoading,
    error,
  } = useContactsContactKindListProcessContactKindsQuery({
    processKind: ProcessKind.RLT,
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("RLT Kontakte")}</CardTitle>
        <CardDescription>
          {t(
            "RLT Vorgänge benötigen Kontakte des Kunden an mehreren Stellen. Hier kann konfiguriert werden mit welcher Kontaktart diese vorausgefüllt werden sollen. Dies funktioniert nur, wenn die Kontaktart mit MS Dynamics Verknüpft ist und dort ein Kontakt gepflegt ist.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ProcessContactTable
          processKind={ProcessKind.RLT}
          processContactKinds={processContactKinds!.items}
        />
      </CardContent>
    </Card>
  );
}

function Skeleton() {
  return (
    <Card className="animate-pulse">
      <CardHeader>
        <CardTitle>{t("RLT Kontakte")}</CardTitle>
        <CardDescription>
          {t(
            "RLT Vorgänge benötigen Kontakte an mehreren Stellen. Hier kann konfiguriert werden mit welcher Kontaktarte diese Vorausgefüllt werden sollen. Dies funktioniert nur, wenn die Kontaktart mit MS Dynamics Verknüpft ist und dort ein Kontakt gepflegt ist.",
          )}
        </CardDescription>
      </CardHeader>
    </Card>
  );
}

function ProcessContactTable(props: {
  processKind: ProcessKind;
  processContactKinds: ProcessContactKind[];
}) {
  const { processKind, processContactKinds } = props;
  const {
    data: contactKinds,
    isLoading,
    error,
  } = useContactsContactKindListQuery({});

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Kontakt im Vorgang")}</TableHead>
            <TableHead>{t("Kontaktart")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Kontakt im Vorgang")}</TableHead>
          <TableHead>{t("Kontaktart")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {processContactKinds.map((processContactKind) => (
          <TableRow key={processContactKind}>
            <TableCell>{t(processContactKind)}</TableCell>
            <TableCell>
              <SelectContactKindWrapper
                processKind={processKind}
                processContactKind={processContactKind}
                contactKinds={contactKinds!.items}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function SelectContactKindWrapper(props: {
  processKind: ProcessKind;
  processContactKind: ProcessContactKind;
  contactKinds: Kind[];
}) {
  const { processKind, processContactKind, contactKinds } = props;
  const {
    data: currentSelection,
    isLoading,
    error,
  } = useContactsContactKindShowProcessContactKindMatchQuery({
    processKind,
    processContactKind,
  });

  if (isLoading) {
    return (
      <Select>
        <SelectTrigger>
          <SelectValue placeholder={t("Lade...")} />
        </SelectTrigger>
      </Select>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <SelectContactKind
      processKind={processKind}
      processContactKind={processContactKind}
      contactKinds={contactKinds}
      currentSelection={currentSelection}
    />
  );
}

function SelectContactKind(props: {
  processKind: ProcessKind;
  processContactKind: ProcessContactKind;
  contactKinds: Kind[];
  currentSelection: ProcessContactKindMatch | undefined;
}) {
  const { processKind, processContactKind, contactKinds, currentSelection } =
    props;
  const [request, setRequest] = useState<ProcessContactKindMatch>({
    processKind,
    processContactKind,
    contactKindId: currentSelection?.contactKindId ?? "",
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useContactsContactKindPutProcessContactKindMatchMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    100,
  );
  const { toast } = useToast();

  if (error) {
    const err = parseRTKQueryError(error);
    toast({
      title: err?.title,
      description: err?.description,
    });
    reset();
  }

  if (isSuccess) {
    toast({
      title: t("Kontaktart aktualisiert"),
      variant: "success",
    });
    reset();
  }

  return (
    <Select
      value={request.contactKindId}
      onValueChange={(newSelection) =>
        setRequest({ ...request, contactKindId: newSelection })
      }
    >
      <SelectTrigger>
        <SelectValue placeholder={t("Kontaktart auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t("Kontaktarten")}</SelectLabel>
          {contactKinds.map((contactKind) => (
            <SelectItem value={contactKind.id} key={contactKind.id}>
              {contactKind.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
