import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import { PropsWithChildren, useEffect } from "react";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Button } from "@/shared/components/ui/button";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useUserCompanies } from "@/shared/lib/authorization/useUserCompanies";

// move up to tenant route
export function ActiveCompanyGuard({ children }: PropsWithChildren) {
  const { companyId } = useActiveCompany();

  if (companyId && companyId !== "") {
    return <div>{children}</div>;
  }

  return <ActiveCompanySelector />;
}

function ActiveCompanySelector() {
  const { setCompanyId } = useActiveCompany();
  const { companyIds, isLoading, error } = useUserCompanies();

  // If the user is only a member of one company, set the company
  useEffect(() => {
    if (companyIds?.length === 1) {
      const companyId = companyIds[0];
      setCompanyId(companyId);
    }
  }, [companyIds, setCompanyId]);

  if (isLoading) {
    // this is on purpose, otherwise the use would see a flicker
    return null;
  }

  if (error) {
    return (
      <div className="flex h-full min-h-screen w-full items-center justify-center">
        <Card className="animate-pulse">
          <CardHeader>
            <CardTitle>{t("Unternehmen auswählen")}</CardTitle>
          </CardHeader>
          <CardContent>
            <RTKQueryErrorAlert error={error} />
          </CardContent>
        </Card>
      </div>
    );
  }

  // If the user is a member of multiple companies, show a selection
  return (
    <div className="flex h-full min-h-screen w-full items-center justify-center">
      <Card>
        <CardHeader>
          <CardTitle>{t("Unternehmen auswählen")}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            {companyIds?.map((companyId) => (
              <Button
                variant="secondary"
                className="w-full"
                key={companyId}
                onClick={() => {
                  setCompanyId(companyId);
                }}
              >
                <CompanyName companyId={companyId} />
              </Button>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
