import { Item } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { Severity } from "@/services/backend/htz/inspection/severity";
import { EntityId } from "@/shared/nidavellir/types/entity-id";

export interface StructuralAssessment {
  id: string;
  airHandlingUnitId: EntityId;
  componentId: string;
  element: string;
  shorthand: string;
  severity: Severity;
  requirement: string;
  deficiency: string;
  recommendation: string;
  comment: string;
}

export function newStructural(
  id: string,
  airHandlingUnitId: string,
  componentId: string,
): StructuralAssessment {
  return {
    id,
    airHandlingUnitId,
    componentId,
    element: "",
    shorthand: "",
    severity: Severity.None,
    requirement: "",
    deficiency: "",
    recommendation: "",
    comment: "",
  };
}

export function fromCatalogItem(
  id: string,
  airHandlingUnitId: EntityId,
  componentId: string,
  item: Item,
): StructuralAssessment {
  return {
    ...item,
    id,
    airHandlingUnitId,
    componentId,
    comment: "",
  };
}
