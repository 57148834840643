import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { PositionCatalog } from "@/services/backend/htz/position-catalog/position-catalog";
import { Offer } from "@/services/backend/htz/offers/offer";
import { useHtzOfferPutMutation } from "@/services/backend/htz/offers/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  rtkErrIsValidationError,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";

interface OfferContextInterface {
  offer: Offer;
  onOfferChange: (offer: Offer) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
  airHandlingUnits: AirHandlingUnit[];
  positionCatalog: PositionCatalog;
}

export const OfferContext = createContext<OfferContextInterface>({
  offer: {} as Offer,
  onOfferChange: () => null,
  validationError: null,
  setValidationError: () => null,
  positionCatalog: {} as PositionCatalog,
  airHandlingUnits: [],
});

export function useOfferContext() {
  return useContext(OfferContext);
}

export function OfferContextProvider({
  children,
  offer: propOffer,
  catalog: propCatalog,
  airHandlingUnits: propAirHandlingUnits,
}: {
  children: ReactNode;
  offer: Offer;
  catalog: PositionCatalog;
  airHandlingUnits: AirHandlingUnit[];
}) {
  // idea would be to use index db here for offline availability
  const [offer, setOffer] = useState<Offer>();
  const [catalog, setCatalog] = useState<PositionCatalog>();
  const [airHandlingUnits, setAirHandlingUnits] = useState<AirHandlingUnit[]>();
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  // after vector clocks are added, they can be used to
  // update the offer here.
  if (!offer || offer.id !== propOffer.id) {
    setOffer(propOffer);
  }

  // Neither, the catalog nor the air handling units are mutated within the
  // offer context. Any value coming is must be an updated server value.
  if (!catalog || catalog !== propCatalog) {
    setCatalog(propCatalog);
  }
  if (!airHandlingUnits || airHandlingUnits !== propAirHandlingUnits) {
    setAirHandlingUnits(propAirHandlingUnits);
  }

  const onOfferChange = (changedOffer: Offer) => {
    setOffer(changedOffer);
  };

  const value = useMemo(
    () => ({
      offer: offer!,
      onOfferChange,
      validationError,
      setValidationError,
      airHandlingUnits: airHandlingUnits!,
      positionCatalog: catalog!,
    }),
    [airHandlingUnits, catalog, offer, validationError],
  );

  return (
    <OfferContext.Provider value={value}>
      <OfferUpdater>{children}</OfferUpdater>
    </OfferContext.Provider>
  );
}

function OfferUpdater({ children }: PropsWithChildren) {
  // this should only run when online
  const { offer, setValidationError } = useOfferContext();
  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzOfferPutMutation();

  useEffect(() => {
    if (rtkErrIsValidationError(error)) {
      setValidationError(error.data);
    }
  }, [error, setValidationError]);

  useDebouncedMutationWithPersistenceStateContextUpdate(
    offer,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return children;
}
