import {
  Label,
  useProcessesStateForceRunningMutation,
  useProcessesStatePutMutation,
  useProcessesStateShowQuery,
} from "@/services/backend/processes/state/state";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { AlertTriangle } from "lucide-react";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";

export function StateWarning({ processId }: { processId: string }) {
  const { data: state } = useProcessesStateShowQuery({ processId });

  if (!state) {
    return null;
  }

  switch (state.state) {
    case Label.Unknown:
    case Label.Running:
      return null;
    case Label.Archived:
      return <ArchivedWarning processId={processId} />;
    case Label.Done:
      return <DoneWarning processId={processId} />;
    default:
      return null;
  }
}

function ArchivedWarning({ processId }: { processId: string }) {
  const [put, { isLoading, error }] = useProcessesStatePutMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    put({
      processId,
      state: Label.Running,
    });
  };

  return (
    <Alert className="mb-4">
      <AlertTriangle className="h-4 w-4" />
      <AlertTitle>{t("Der Vorgang ist archiviert.")}</AlertTitle>
      <AlertDescription>
        <span>
          {t(
            'Der Vorgang ist archiviert, kann aber wieder in den Stand "In Bearbeitung" gesetzt werden.',
          )}
        </span>
        <Button
          className="mt-4 w-full"
          variant="secondary"
          onClick={onClick}
          disabled={isLoading}
        >
          {t('Vorgang in Stand "In Bearbeitung" setzen')}
        </Button>
        <RTKQueryErrorAlert error={error} className="mt-4" />
      </AlertDescription>
    </Alert>
  );
}

function DoneWarning({ processId }: { processId: string }) {
  const [forceRunning, { isLoading, error }] =
    useProcessesStateForceRunningMutation();

  const { canExecute } = useGuard("Processes.State.ForceRunningRequest");

  const onClick = () => {
    if (isLoading) {
      return;
    }

    forceRunning({ processId });
  };

  return (
    <Alert variant="destructive" className="mb-4">
      <AlertTriangle className="h-4 w-4" />
      <AlertTitle>{t("Der Vorgang ist beendet.")}</AlertTitle>
      <AlertDescription>
        <span>
          {t(
            "Der Vorgang ist beendet und darf nicht mehr bearbeitet werden. " +
              "Bitte erstellen Sie einen neuen Vorgang.",
          )}
        </span>
        {canExecute && (
          <Button
            className="mt-4 w-full"
            variant="secondary"
            onClick={onClick}
            disabled={isLoading}
          >
            {t('Stand "In Bearbeitung" erzwingen')}
          </Button>
        )}
        <RTKQueryErrorAlert error={error} className="mt-4" />
      </AlertDescription>
    </Alert>
  );
}
