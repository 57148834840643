import { cn } from "@/shared/lib/utils";
import { HTMLAttributes, ReactNode } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { H3, H4 } from "@/shared/components/ui/typography";
import { Separator } from "@/shared/components/ui/separator";

interface ArtefactImmutablePageProps extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactImmutablePage({
  children,
  className,
  ...props
}: ArtefactImmutablePageProps) {
  return (
    <div className={cn("mt-4 grid gap-8 md:grid-cols-5", className)} {...props}>
      {children}
    </div>
  );
}

export function ArtefactPreview({
  objectURL = undefined,
  isLoading = false,
  error = undefined,
  fallbackText = t("PDF konnte nicht gerendert werden."),
  className = "",
}: {
  objectURL?: string;
  isLoading: boolean;
  error?: unknown;
  fallbackText?: string;
  className?: string;
}) {
  if (isLoading) {
    return <ArtefactPreviewSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} className="md:col-span-3" />;
  }

  return (
    <div className={cn("md:col-span-3", className)}>
      {/* 
        iframe and not object was used, since object caused problems in Safari. 
        For some reason, scrolling was not possible. 
       */}
      <iframe
        title="PDF Preview"
        src={`${objectURL}#toolbar=0`}
        className="h-[80vh] w-full rounded-lg"
      >
        {fallbackText}
      </iframe>
    </div>
  );
}

export function ArtefactPreviewSkeleton() {
  return <Skeleton className="h-[80vh] w-full rounded-lg md:col-span-3" />;
}

interface ArtefactActionsProps extends HTMLAttributes<HTMLDivElement> {}

export function ArtefactActions({
  children,
  className,
  ...props
}: ArtefactActionsProps) {
  return (
    <div className={cn("space-y-4 md:col-span-2", className)} {...props}>
      {children}
    </div>
  );
}

interface ArtefactActionsHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  artefactNumber: ReactNode;
}

export function ArtefactActionsHeader({
  title,
  artefactNumber,
  children,
  className,
  ...props
}: ArtefactActionsHeaderProps) {
  return (
    <div className={cn("", className)} {...props}>
      <H3>{title}</H3>
      <div>{artefactNumber}</div>
      {children}
      <Separator className="mt-4" />
    </div>
  );
}

export function ArtefactActionsDownload({ children }: { children: ReactNode }) {
  return (
    <div className="grid gap-1">
      <H4 className="mb-2">{t("Herunterladen")}</H4>
      {children}
    </div>
  );
}
