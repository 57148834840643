import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/shared/components/ui/sheet";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Position } from "@/services/backend/htz/work-order/work-order";
import { AhuComponentsSchema } from "@/shared/components/domain/htz/ahu/ahu-components-schema";
import { useWorkOrderContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/work-order-context";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { AhuComponentsTable } from "@/shared/components/domain/htz/ahu/ahu-components-table";
import {
  InspectionContextProvider,
  useInspectionContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import {
  AddStructuralAssessmentButton,
  StructuralInspection,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/structural-inspection";
import { useState } from "react";
import { AddComponentDropdown } from "@/shared/components/domain/htz/ahu/add-component-dropdown";
import { Switch } from "@/shared/components/ui/switch";
import { Label } from "@/shared/components/ui/label";
import { Camera, CheckCircle, Eye, Tags } from "lucide-react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/shared/components/ui/resizable";
import {
  AddVisualAssessmentButton,
  VisualInspection,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/visual-inspection";
import {
  AddPhotoButton,
  PhotoDocumentation,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/photo-documentation";
import {
  AddMicrobialAssessmentButton,
  MicrobialInspection,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/microbial-inspection";

export function InspectionSheet({ position }: { position: Position }) {
  const { workOrder } = useWorkOrderContext();

  const disabled = !position.airHandlingUnitId || workOrder.immutable;

  return (
    <InspectionContextProvider>
      <Sheet>
        <SheetTrigger asChild disabled={disabled}>
          <Button size="sm" variant="secondary" className="h-8">
            {t("Inspektion")}
          </Button>
        </SheetTrigger>
        <SheetContent side="bottom" className="h-screen space-y-4">
          <InspectionSheetHeader position={position} />
          <InspectionSheetMain position={position} />
        </SheetContent>
      </Sheet>
    </InspectionContextProvider>
  );
}

function InspectionSheetHeader({ position }: { position: Position }) {
  const { airHandlingUnits } = useWorkOrderContext();

  return (
    <SheetHeader className="max-h-[10vh]">
      <SheetTitle className="flex justify-between pr-12">
        <span>
          {t("Inspektion für Position: ")}
          {position.number}
          {t(" - Anlage: ")}
          {findAhu(airHandlingUnits, position.airHandlingUnitId)?.name}
        </span>
        <PersistenceStateUsingContext />
        <InspectionModeSwitch />
      </SheetTitle>
      <SheetDescription>
        {t(
          "Im Modus Aufnahme, können die Komponenten der Anlage zusammengebaut werden. Im Modus Inspektion können Komponenten ausgewählt werden um für diese Beurteilungen zu erstellen.",
        )}
      </SheetDescription>
    </SheetHeader>
  );
}

function InspectionSheetMain({ position }: { position: Position }) {
  const { airHandlingUnits } = useWorkOrderContext();
  const { active } = useInspectionContext();

  const airHandlingUnit = findAhu(airHandlingUnits, position.airHandlingUnitId);

  if (!airHandlingUnit) {
    return null;
  }

  if (position.airHandlingUnitId !== airHandlingUnit.id) {
    throw new Error("passed wrong air handling unit for position.");
  }

  if (!active) {
    return <AhuCard airHandlingUnit={airHandlingUnit} />;
  }

  return (
    <ResizablePanelGroup direction="horizontal" className="space-x-4">
      <ResizablePanel minSize={24}>
        <AhuCard airHandlingUnit={airHandlingUnit} />
      </ResizablePanel>
      <ResizableHandle withHandle className="max-h-[80vh]" />
      <ResizablePanel minSize={24}>
        <AssessmentCard position={position} />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}

function findAhu(
  ahus: AirHandlingUnit[],
  id: string | null,
): AirHandlingUnit | undefined {
  return ahus.find((ahu) => ahu.id === id);
}

function AhuCard({ airHandlingUnit }: { airHandlingUnit: AirHandlingUnit }) {
  const [tabValue, setTabValue] = useState<string>("schema");
  const { onAirHandlingUnitChange, ahuComponentCatalog } =
    useWorkOrderContext();
  const { active } = useInspectionContext();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Anlage")}</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs value={tabValue} onValueChange={setTabValue}>
          <div className="flex justify-between">
            <TabsList>
              <TabsTrigger value="schema">{t("Schema")}</TabsTrigger>
              <TabsTrigger value="list">{t("Liste")}</TabsTrigger>
            </TabsList>
            {tabValue === "list" && !active && (
              <AddComponentDropdown
                ahu={airHandlingUnit}
                onAhuChange={onAirHandlingUnitChange}
                catalog={ahuComponentCatalog}
              />
            )}
          </div>
          <TabsContent value="schema" className="h-[65vh]">
            <AhuComponentsSchema
              ahu={airHandlingUnit}
              onAhuChange={onAirHandlingUnitChange}
              ahuComponentCatalog={ahuComponentCatalog}
            />
          </TabsContent>
          <TabsContent value="list" className="h-[65vh]">
            <AhuComponentsTable
              ahu={airHandlingUnit}
              onAhuChange={onAirHandlingUnitChange}
            />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}

function InspectionModeSwitch() {
  const { active, setActive } = useInspectionContext();

  return (
    <div className="flex items-center space-x-4">
      <Label>{t("Aufnahme")}</Label>
      <Switch checked={active} onCheckedChange={setActive} />
      <Label>{t("Inspektion")}</Label>
    </div>
  );
}

function AssessmentCard({ position }: { position: Position }) {
  const { activeComponent } = useInspectionContext();
  const [tabValue, setTabValue] = useState("structural");

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          {activeComponent && activeComponent.name}
          {!activeComponent && (
            <span className="font-normal italic text-muted-foreground">
              {t("- bitte Komponente auswählen -")}
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs value={tabValue} onValueChange={setTabValue}>
          <div className="flex justify-between">
            <TabsList>
              <TabsTrigger value="structural">
                <CheckCircle className="mr-2 h-4 w-4" />
                <span>{t("Konstruktiv")}</span>
              </TabsTrigger>
              <TabsTrigger value="visual">
                <Eye className="mr-2 h-4 w-4" />
                <span>{t("Visuell")}</span>
              </TabsTrigger>
              <TabsTrigger value="microbial">
                <Tags className="mr-2 h-4 w-4" />
                <span>{t("Proben")}</span>
              </TabsTrigger>
              <TabsTrigger value="photo">
                <Camera className="mr-2 h-4 w-4" />
                <span>{t("Fotos")}</span>
              </TabsTrigger>
            </TabsList>
            {tabValue === "structural" && (
              <AddStructuralAssessmentButton position={position} />
            )}
            {tabValue === "visual" && (
              <AddVisualAssessmentButton position={position} />
            )}
            {tabValue === "microbial" && (
              <AddMicrobialAssessmentButton position={position} />
            )}
            {tabValue === "photo" && <AddPhotoButton position={position} />}
          </div>
          <TabsContent value="structural" className="h-[65vh]">
            <StructuralInspection />
          </TabsContent>
          <TabsContent value="visual" className="h-[65vh]">
            <VisualInspection />
          </TabsContent>
          <TabsContent value="microbial" className="h-[65vh]">
            <MicrobialInspection />
          </TabsContent>
          <TabsContent value="photo" className="h-[65vh]">
            <PhotoDocumentation />
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
}
