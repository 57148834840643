import { ViewModel } from "@/services/backend/processes/process/process";
import { ColumnDef, Table as ReactTable } from "@tanstack/react-table";
import t from "@/lang/lang";
import {
  ListRequest,
  useProcessesProcessListQuery,
} from "@/services/backend/processes/process/service";
import { useState } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { ArrowUpDown, Search } from "lucide-react";
import { BranchFilter } from "@/routes/gesec/processes/_components/branch-filter";
import { ProcessKindFilter } from "@/routes/gesec/processes/_components/process-kind-filter";
import { ColumnFilter } from "@/routes/gesec/processes/_components/column-filter";
import { NewProcessDialog } from "@/routes/gesec/processes/_components/new-process-dialog";
import { CustomerName } from "@/shared/components/domain/customer/customer-name";
import { ProcessNumber } from "@/shared/components/domain/numbers/process-number";
import { BranchName } from "@/shared/components/domain/branch/branch-name";
import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  DataTableFilters,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { StateIcon } from "@/shared/components/domain/process/state/state-icon";
import { StateFilter } from "@/routes/gesec/processes/_components/state-filter";

const tableColumns: ColumnDef<ViewModel>[] = [
  {
    id: t("Dienstleistung"),
    accessorKey: "kind",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        <span>{t("DL")}</span>
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => {
      const processData = row.original;
      return <div className="ml-4">{t(processData.kind)}</div>;
    },
  },
  {
    id: t("Zustand"),
    accessorKey: "state",
    header: t("Zustand"),
    cell: ({ row }) => {
      const { state } = row.original;
      return <StateIcon label={state} className="ml-4" />;
    },
  },
  {
    id: t("Vorgangs-Nr."),
    header: t("Vorgangs-Nr."),
    cell: ({ row }) => {
      const processData = row.original;
      return (
        <span className="font-mono">
          <ProcessNumber processId={processData.id} />
        </span>
      );
    },
  },
  {
    id: t("Thema"),
    accessorKey: "topic",
    header: t("Thema"),
  },
  {
    id: t("Kunde"),
    accessorKey: "customerId",
    header: t("Kunde"),
    cell: ({ row }) => {
      const processData = row.original;
      return <CustomerName customerId={processData.customerId} />;
    },
  },
  {
    id: t("Niederlassung"),
    accessorKey: "branchId",
    header: t("Niederlassung"),
    cell: ({ row }) => {
      const processData = row.original;
      return <BranchName branchId={processData.branchId} />;
    },
  },
  {
    id: t("Aktionen"),
    header: t("Aktionen"),
    cell: ({ row }) => {
      const processData = row.original;

      return (
        <NavLink to={`${processData.kind.toLowerCase()}/${processData.id}`}>
          <Button variant="ghost" size="sm" className="-mb-2.5 -mt-1.5">
            <Search className="h-4 w-4" />
          </Button>
        </NavLink>
      );
    },
  },
];

export function ProcessListTable() {
  const [request, setRequest] = useState<ListRequest>({
    kind: {
      active: false,
      values: [],
    },
    topic: {
      active: false,
      values: [],
    },
    customer: {
      active: false,
      values: [],
    },
    company: {
      active: false,
      values: [],
    },
    branch: {
      active: false,
      values: [],
    },
    state: {
      active: false,
      values: [],
    },
    limit: 50,
    offset: 0,
  });
  const {
    data: list,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useProcessesProcessListQuery(request, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const table = useDataTableController(tableColumns, list?.processes || []);

  if (isLoading || isFetching) {
    return <Skeleton table={table} columns={tableColumns} />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const update = (newRequest: ListRequest) => {
    setRequest(newRequest);
    refetch();
  };

  return (
    <DataTableContainer>
      <DataTableFilters className="flex justify-between space-x-2">
        <div className="space-x-2">
          <ProcessKindFilter
            filter={request.kind!}
            onChange={(value) => update({ ...request, kind: value })}
          />
          <BranchFilter
            filter={request.branch!}
            onChange={(value) => update({ ...request, branch: value })}
          />
          <StateFilter
            filter={request.state!}
            onChange={(value) => update({ ...request, state: value })}
          />
        </div>
        <div className="flex items-center space-x-2">
          <ColumnFilter table={table} />
          <NewProcessDialog />
        </div>
      </DataTableFilters>
      <DataTable table={table} columns={tableColumns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  );
}

function Skeleton<TData>(props: {
  table: ReactTable<TData>;
  columns: ColumnDef<TData>[];
}) {
  const { table, columns } = props;
  return (
    <DataTableContainer className="animate-pulse">
      <DataTableFilters className="flex justify-between space-x-2">
        <div className="space-x-2">
          <ProcessKindFilter
            filter={{ active: false, values: [] }}
            onChange={() => null}
          />
          <BranchFilter
            filter={{ active: false, values: [] }}
            onChange={() => null}
          />
          <StateFilter
            filter={{ active: false, values: [] }}
            onChange={() => null}
          />
        </div>
        <ColumnFilter table={table} />
      </DataTableFilters>
      <DataTable table={table} columns={columns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  );
}
