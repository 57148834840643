import { Position } from "@/services/backend/vbs/treatments/position";
import { ChangeEvent, useState } from "react";
import {
  UpdateCommentRequest,
  useVbsTreatmentUpdateCommentMutation,
} from "@/services/backend/vbs/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { H4 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import { Textarea } from "@/shared/components/ui/textarea";

export function PositionComment(props: {
  treatmentId: string;
  position: Position;
}) {
  const { treatmentId, position } = props;
  const [request, setRequest] = useState<UpdateCommentRequest>({
    id: treatmentId,
    positionId: position.id,
    comment: position.comment,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentUpdateCommentMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRequest({ ...request, comment: e.target.value });
  };

  return (
    <div>
      <H4>{t("Bemerkung")}</H4>
      <div className="mb-2 text-sm text-muted-foreground">
        {t(
          "Die Bemerkung wurde während der Aufnahme erfasst und kann hier ergänzt werden.",
        )}
      </div>
      <Textarea value={request.comment} onChange={onChange} />
    </div>
  );
}
