import { Outlet, useParams } from "react-router-dom";
import {
  CalendarDays,
  CheckCircle2,
  ExternalLink,
  LayoutDashboard,
  Phone,
} from "lucide-react";
import {
  ProcessPage,
  ProcessPageMain,
  ProcessPageNavigation,
  ProcessPageNavLink,
  ProcessPageNavLinkGroup,
  ProcessPageNavLinkGroupTitle,
  ProcessPageNavLinkList,
  ProcessPageNavLinkSeparator,
} from "@/shared/components/layout/process-page";
import t from "@/lang/lang";
import { ProcessInformation } from "@/shared/components/domain/process/process-information";
import { StateWarning } from "@/shared/components/domain/process/state/state-warning";
import { ArtefactIcon } from "@/shared/components/domain/artefact/artefact-icon";
import { ArtefactKind } from "@/services/backend/artefacts/kind";

export default function VBSRoute() {
  const { processId } = useParams();

  return (
    <ProcessPage>
      <Navigation />
      <ProcessPageMain>
        <StateWarning processId={processId!} />
        <ProcessInformation processId={processId!} />
        <Outlet />
      </ProcessPageMain>
    </ProcessPage>
  );
}

function Navigation() {
  return (
    <ProcessPageNavigation>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          {t("Allgemein")}
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="" end>
            <LayoutDashboard className="mr-2 h-5 w-5" />
            {t("Übersicht")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="contact">
            <Phone className="mr-2 h-5 w-5" />
            {t("Ansprechpartner")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="appointments">
            <CalendarDays className="mr-2 h-5 w-5" />
            {t("Termine")}
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          {t("Meilensteine")}
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="calculations">
            <ArtefactIcon
              artefactKind={ArtefactKind.Calculation}
              className="mr-2"
            />
            {t("Kalkulationen")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="offers">
            <ArtefactIcon artefactKind={ArtefactKind.Offer} className="mr-2" />
            {t("Angebote")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="orders">
            <ArtefactIcon artefactKind={ArtefactKind.Order} className="mr-2" />
            {t("Auftragseingänge")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="confirmations">
            <ArtefactIcon
              artefactKind={ArtefactKind.Confirmation}
              className="mr-2"
            />
            {t("Auftragsbestätigungen")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="work-orders">
            <ArtefactIcon
              artefactKind={ArtefactKind.WorkOrder}
              className="mr-2"
            />
            {t("Arbeitsscheine")}
          </ProcessPageNavLink>
          <ProcessPageNavLink to="certificates">
            <ArtefactIcon
              artefactKind={ArtefactKind.Certificate}
              className="mr-2"
            />
            {t("Zertifikate")}
          </ProcessPageNavLink>
          <ProcessPageNavLinkSeparator />
          <ProcessPageNavLink to="close">
            <CheckCircle2 className="mr-2 h-5 w-5" />
            {t("Vorgang schließen")}
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
      <ProcessPageNavLinkGroup>
        <ProcessPageNavLinkGroupTitle>
          {t("Extern")}
        </ProcessPageNavLinkGroupTitle>
        <ProcessPageNavLinkList>
          <ProcessPageNavLink to="crm-opportunity">
            <span className="flex flex-row items-center gap-3">
              <span>{t("Verkaufschance")}</span>
              <ExternalLink className="h-4 w-4" />
            </span>
          </ProcessPageNavLink>
        </ProcessPageNavLinkList>
      </ProcessPageNavLinkGroup>
    </ProcessPageNavigation>
  );
}
