import {
  MilestonePage,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import { useParams } from "react-router-dom";
import t from "@/lang/lang";
import {
  Label,
  useProcessesStatePutMutation,
} from "@/services/backend/processes/state/state";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";

export function CloseRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Vorgang schließen")}</MilestonePageTitle>
      </MilestonePageHeader>
      <MilestonePageContent>
        <div className="grid w-full grid-cols-1 gap-4">
          <Archive processId={processId!} />
          <Done processId={processId!} />
        </div>
      </MilestonePageContent>
    </MilestonePage>
  );
}

function Archive({ processId }: { processId: string }) {
  const [put, { isLoading, error }] = useProcessesStatePutMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    put({
      processId,
      state: Label.Archived,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Vorgang archivieren")}</CardTitle>
        <CardDescription>
          {t(
            "Der Vorgang kann archiviert werden. Das macht deutlich, dass er nicht mehr in Bearbeitung ist, aber eventuell später noch benötigt wird. Er kann einfach wieder geöffnet werden.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button
          className="w-full"
          variant="secondary"
          onClick={onClick}
          disabled={isLoading}
        >
          {t("Vorgang archivieren")}
        </Button>
        <RTKQueryErrorAlert error={error} className="mt-4" />
      </CardContent>
    </Card>
  );
}

function Done({ processId }: { processId: string }) {
  const [put, { isLoading, error }] = useProcessesStatePutMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    put({
      processId,
      state: Label.Done,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Vorgang beenden")}</CardTitle>
        <CardDescription>
          {t(
            "Der Vorgang kann beendet werden. Das macht deutlich, dass er final abgeschlossen ist. Er kann nicht einfach wieder geöffnet werden.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button
          className="w-full"
          variant="secondary"
          onClick={onClick}
          disabled={isLoading}
        >
          {t("Vorgang beenden")}
        </Button>
        <RTKQueryErrorAlert error={error} className="mt-4" />
      </CardContent>
    </Card>
  );
}
