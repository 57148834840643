import { backendApi } from "@/services/backend/api";
import { Kind } from "@/services/backend/contacts/contact-kind/kind";
import { ProcessContactKindMatch } from "@/services/backend/contacts/contact-kind/process-contact-kind-match";
import { ProcessContactKind } from "@/services/backend/contacts/contact-kind/process-contact-kind";
import { ProcessKind } from "@/services/backend/processes/process/kinds";

const contactKindApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    contactsContactKindPut: build.mutation<Kind, Kind>({
      query: (kind) => ({
        url: "/contacts/contact-kind",
        method: "PUT",
        body: kind,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ContactKind", id: "LIST" },
        { type: "ContactKind", id: request.id },
      ],
    }),
    contactsContactKindShow: build.query<Kind, ShowRequest>({
      query: (request) => ({
        url: "/contacts/contact-kind",
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "ContactKind", id: request.id },
      ],
    }),
    contactsContactKindDelete: build.mutation<Kind, DeleteRequest>({
      query: (request) => ({
        url: "/contacts/contact-kind",
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ContactKind", id: request.id },
        { type: "ContactKind", id: "LIST" },
      ],
    }),
    contactsContactKindList: build.query<List, ListRequest>({
      query: (request) => ({
        url: "/contacts/contact-kind/list",
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map((kind) => ({
                type: "ContactKind" as const,
                id: kind.id,
              })),
              { type: "ContactKind", id: "LIST" },
            ]
          : [{ type: "ContactKind", id: "LIST" }],
    }),
    contactsContactKindPutProcessContactKindMatch: build.mutation<
      ProcessContactKindMatch,
      ProcessContactKindMatch
    >({
      query: (request) => ({
        url: "/contacts/contact-kind/process-contact-kind-match",
        method: "PUT",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessContactKindMatch", id: "LIST" },
        { type: "ProcessContactKindMatch", id: key(request) },
      ],
    }),
    contactsContactKindShowProcessContactKindMatch: build.query<
      ProcessContactKindMatch,
      ShowProcessContactKindMatchRequest
    >({
      query: (request) => ({
        url: "/contacts/contact-kind/process-contact-kind-match",
        method: "GET",
        params: request,
      }),
      providesTags: (_result, _e, request) => [
        { type: "ProcessContactKindMatch", id: key(request) },
      ],
    }),
    contactsContactKindDeleteProcessContactKindMatch: build.mutation<
      unknown,
      ProcessContactKindMatch
    >({
      query: (request) => ({
        url: "/contacts/contact-kind/process-contact-kind-match",
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "ProcessContactKindMatch", id: "LIST" },
        { type: "ProcessContactKindMatch", id: key(request) },
      ],
    }),
    contactsContactKindListProcessContactKinds: build.query<
      ProcessContactKindList,
      ListProcessContactKindsRequest
    >({
      query: (request) => ({
        url: "/contacts/contact-kind/process-contact-kind/list",
        method: "GET",
        params: request,
      }),
    }),
  }),
});

function key(request: {
  processKind: ProcessKind;
  processContactKind: ProcessContactKind;
}) {
  return request.processKind + request.processContactKind;
}

export const {
  useContactsContactKindPutMutation,
  useContactsContactKindShowQuery,
  useContactsContactKindDeleteMutation,
  useContactsContactKindListQuery,
  useContactsContactKindPutProcessContactKindMatchMutation,
  useContactsContactKindShowProcessContactKindMatchQuery,
  useContactsContactKindDeleteProcessContactKindMatchMutation,
  useContactsContactKindListProcessContactKindsQuery,
} = contactKindApi;

export interface ShowRequest {
  id: string;
}

export interface DeleteRequest {
  id: string;
}

export interface List {
  items: Kind[];
  count: number;
}

export interface ProcessContactKindList {
  items: ProcessContactKind[];
  count: number;
}

export interface ListRequest {}

export interface ShowProcessContactKindMatchRequest {
  processKind: ProcessKind;
  processContactKind: ProcessContactKind;
}

export interface ListProcessContactKindsRequest {
  processKind: string;
}
