import { UUID } from "@/shared/nidavellir/types/entity-id";

export interface Photo {
  id: UUID;
  airHandlingUnitId: UUID;
  componentId: UUID;
  element: string;
  comment: string;
  imageId: string | null;
}

export function newPhoto(
  id: UUID,
  airHandlingUnitId: UUID,
  componentId: UUID,
): Photo {
  return {
    id,
    airHandlingUnitId,
    componentId,
    element: "",
    comment: "",
    imageId: null,
  };
}
