import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { ExternalLink } from "lucide-react";
import { useArtefactSheetContext } from "@/shared/service-manager/artefact/artefact-sheet";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { useArtefactPath } from "@/shared/components/domain/artefact/use-artefact-path";

export interface NavigateToArtefactButtonProps {
  artefactId: string;
  artefactKind: ArtefactKind;
  processKind: ProcessKind;
  processId: string;
  companyId: string;
}

export function NavigateToArtefactButton({
  artefactId,
  artefactKind,
  processKind,
  processId,
  companyId,
}: NavigateToArtefactButtonProps) {
  const { setOpen } = useArtefactSheetContext();

  const { path } = useArtefactPath({
    artefactId,
    artefactKind,
    processKind,
    processId,
    companyId,
  });

  if (!path) {
    return null;
  }

  return (
    <NavLink to={path}>
      <Button className="w-full" onClick={() => setOpen(false)}>
        <span>{t("Zum Vorgang navigieren")}</span>
        <ExternalLink className="ml-2 h-4 w-4" />
      </Button>
    </NavLink>
  );
}
