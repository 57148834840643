import { backendApi } from "@/services/backend/api";
import { Order } from "@/services/backend/htz/orders/order";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";

const orderApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzOrder"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzOrderPut: build.mutation<Order, PutRequest>({
        query: (request) => ({
          url: `/htz/order`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzOrder", id: request.id },
          "HtzOrder",
        ],
      }),
      htzOrderShow: build.query<Order, ShowRequest>({
        query: (request) => ({
          url: `/htz/order`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzOrder"),
      }),
      htzOrderList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/order/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) => providesList(result?.orders, "HtzOrder"),
      }),
      htzOrderDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/order`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzOrder"],
      }),
      htzOrderAttachFile: build.mutation<Order, AttachFileRequest>({
        query: (request) => {
          const body = new FormData();
          body.append("orderId", request.orderId);
          body.append("fileId", request.fileId);
          body.append("file", request.file, request.file.name);
          body.append("filename", request.filename);
          return {
            url: `/htz/order/files`,
            method: "POST",
            headers: {
              //  this is needed, otherwise a 415 error is returned
              Accept: "application/json",
            },
            body,
            formData: true,
          };
        },
        invalidatesTags: (_r, _e, request) => [
          { type: "HtzOrder", id: request.orderId },
        ],
      }),
      htzOrderShowFile: build.query<{ objectURL: string }, ShowFileRequest>({
        query: (request) => ({
          url: `/htz/order/files`,
          method: "GET",
          params: request,
          responseHandler: async (response) => {
            if (response.status !== 200) {
              return response.json();
            }
            return {
              objectURL: window.URL.createObjectURL(await response.blob()),
            };
          },
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
      }),
      htzOrderDeleteFile: build.mutation<null, DeleteFileRequest>({
        query: (request) => ({
          url: `/htz/order/files`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (_r, _e, request) => [
          { type: "HtzOrder", id: request.orderId },
        ],
      }),
    }),
  });

export const {
  useHtzOrderPutMutation,
  useHtzOrderShowQuery,
  useHtzOrderListQuery,
  useHtzOrderDeleteMutation,
  useHtzOrderAttachFileMutation,
  useHtzOrderShowFileQuery,
  useHtzOrderDeleteFileMutation,
} = orderApi;

export interface PutRequest extends Order {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  date?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  orders: Order[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface AttachFileRequest {
  orderId: string;
  fileId: string;
  filename: string;
  file: File;
}

export interface ShowFileRequest {
  orderId: string;
  fileId: string;
}

export interface DeleteFileRequest {
  orderId: string;
  fileId: string;
}
