import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import {
  Company,
  usePutCompanyMutation,
} from "@/services/backend/company/company";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Edit, Plus } from "lucide-react";
import t from "@/lang/lang";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Label } from "@/shared/components/ui/label";
import { v4 } from "uuid";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function PutCompanyDialog({
  company = undefined,
}: {
  company?: Company;
}) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<Company>({
    ...(company ?? { id: "", name: "", shortName: "", portalRoute: "" }),
  });
  const [put, { isLoading, error, isSuccess, reset }] = usePutCompanyMutation();

  if (isSuccess) {
    setOpen(false);
    if (!company) {
      setRequest({ id: "", name: "", shortName: "", portalRoute: "" });
    }
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }
    put({
      ...request,
      id: company?.id ?? v4(),
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {company ? (
          <Button variant="outline" size="sm">
            <Edit className="h-5 w-5" />
          </Button>
        ) : (
          <Button size="sm">
            <Plus className="mr-2 h-5 w-5" />
            <span>{t("Hinzufügen")}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {company
              ? t("Unternehmen bearbeiten")
              : t("Unternehmen hinzufügen")}
          </DialogTitle>
          <DialogClose />
        </DialogHeader>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Name")}</Label>
          <Input
            type="text"
            placeholder={t("Name")}
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
          <InputValidationErrors error={error} field="name" />
        </div>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Kurzform")}</Label>
          <Input
            type="text"
            placeholder={t("Kurzform")}
            value={request.shortName}
            onChange={(e) =>
              setRequest({ ...request, shortName: e.target.value })
            }
          />
          <InputValidationErrors error={error} field="shortName" />
        </div>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Portal Route")}</Label>
          <Input
            type="text"
            placeholder={t("Portal Route")}
            value={request.portalRoute}
            onChange={(e) =>
              setRequest({ ...request, portalRoute: e.target.value })
            }
          />
          <InputValidationErrors error={error} field="portalRoute" />
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Speichern")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
