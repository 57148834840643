import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  TreatmentComponent,
  TreatmentComponentSkeleton,
} from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component";
import { useVbsTreatmentShowProcessTreatmentQuery } from "@/services/backend/vbs/treatments/service";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { AlertCircle } from "lucide-react";
import t from "@/lang/lang";

export function TreatmentRoute() {
  const { processId } = useParams();
  const {
    data: processTreatment,
    isLoading,
    error,
  } = useVbsTreatmentShowProcessTreatmentQuery({ processId: processId! });

  if (isLoading) {
    return (
      <div className="space-y-4">
        <Info />
        <TreatmentComponentSkeleton />
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="space-y-4">
      <Info />
      <TreatmentComponent
        treatmentId={processTreatment!.treatmentId}
        availableColumns={[
          Column.Position,
          Column.Completed,
          Column.AssignedWorkOrder,
          Column.ComponentKind,
          Column.ComponentData,
          Column.Cycle,
          Column.Comment,
          Column.Actions,
        ]}
        defaultColumns={[
          Column.Position,
          Column.Completed,
          Column.AssignedWorkOrder,
          Column.ComponentKind,
          Column.Comment,
          Column.Actions,
        ]}
      />
    </div>
  );
}

function Info() {
  return (
    <Alert>
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{t("Leistungen festlegen")}</AlertTitle>
      <AlertDescription>
        {t(
          "Hier können Sie die Behandlungen festlegen, welche für diesen Vorgang durchgeführt werden sollen. Üblicher Weise können Sie die Leistungen aus der Auftragsbestätigung oder einem Vertrag übernehmen. Die Leistungen können dann einem oder mehreren Arbeitsscheinen zugeordnet werden.",
        )}
      </AlertDescription>
    </Alert>
  );
}
