import { NavLink, Outlet, useParams } from "react-router-dom";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/components/layout/milestone-page";
import { useRltOfferListQuery } from "@/services/backend/rlt/offers/service";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, PanelsTopLeft } from "lucide-react";
import { Offer } from "@/services/backend/rlt/offers/offer";
import { CreateOfferDialog } from "@/routes/gesec/processes/[processId]/rlt/offers/_components/create-offer-dialog";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export default function OffersRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Angebote")}</MilestonePageTitle>
        <MilestonePageActions>
          <Subnavigation />
          <CreateOfferDialog processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Outlet />
      </MilestonePageContent>
    </MilestonePage>
  );
}

function Subnavigation() {
  const { processId, offerId } = useParams();

  const { data: list } = useRltOfferListQuery({
    process: {
      active: true,
      values: [processId!],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  const currentPageLabel = () => {
    if (offerId) {
      return <ArtefactNumberById artefactId={offerId} />;
    }

    return t("Übersicht");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          <span>{currentPageLabel()}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <NavLink to="">
            <DropdownMenuItem>
              <PanelsTopLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </DropdownMenuItem>
          </NavLink>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel>{t("Angebote")}</DropdownMenuLabel>
        {[...(list?.offers ?? [])].sort(byDate).map((offer) => (
          <NavLink to={offer.id} key={offer.id}>
            <DropdownMenuItem>
              <ArtefactNumberById artefactId={offer.id} />
            </DropdownMenuItem>
          </NavLink>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function byDate(a: Offer, b: Offer) {
  return a.offerDate.localeCompare(b.offerDate);
}
