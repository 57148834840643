import { RepresentativeCard } from "@/shared/components/domain/representatives/representative-card";
import { updateRepresentative } from "@/services/backend/htz/confirmations/confirmation";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { useConfirmationContext } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/_components/confirmation-context";
import {
  fieldErrorsForPrefix,
  resetField,
} from "@/shared/app-lib/errors/validation-error";

export function RepresentativeRoute() {
  const {
    confirmation,
    onConfirmationChange,
    validationError,
    setValidationError,
  } = useConfirmationContext();

  const onRepresentativeChange = (representative: Representative) => {
    onConfirmationChange(updateRepresentative(confirmation, representative));
  };

  const resetValidationErrorField = (prefix: string) => (field: string) => {
    const prefixedField = `${prefix}${field}`;
    setValidationError(resetField(validationError, prefixedField));
  };

  return (
    <div className="space-x-4">
      <RepresentativeCard
        representative={confirmation.representative}
        onRepresentativeChange={onRepresentativeChange}
        fieldErrors={fieldErrorsForPrefix(validationError, "representative.")}
        resetFieldError={resetValidationErrorField("representative.")}
        disabled={confirmation.immutable}
      />
    </div>
  );
}
