import { Navigate, useRoutes } from "react-router-dom";
import ProcessesRoute from "@/routes/gemex/processes/processes-route";
import OffersRoute from "@/routes/gemex/processes/qpm/offers/offers-route";
import CalculationsRoute from "@/routes/gemex/processes/qpm/calculations/calculations-route";
import QPMRouter from "@/routes/gemex/processes/qpm/router";
import RequirementsRoute from "@/routes/gemex/processes/qpm/requirements/requirements-route";
import TasksDashboard from "@/routes/gemex/tasks/tasks";
import ManageBuildingRoute from "@/routes/gemex/processes/qpm/building/manage-building-route";
import { requirementRoutes } from "@/routes/gemex/processes/qpm/requirements/requirement-routes";

export default function GemexRouter() {
  return useRoutes([
    {
      path: "tasks",
      element: <TasksDashboard />,
    },
    {
      path: "processes",
      element: <ProcessesRoute />,
      children: [],
    },
    {
      path: "processes/qpm/:processId",
      element: <QPMRouter />,
      children: [
        {
          index: true,
          element: <Navigate to="manage-building" replace />,
        },
        {
          path: "appointments",
          element: <div>Termine</div>,
        },
        {
          path: "manage-building",
          element: <ManageBuildingRoute />,
        },
        {
          path: "requirements",
          element: <RequirementsRoute />,
          children: requirementRoutes,
        },
        {
          path: "calculations",
          element: <CalculationsRoute />,
        },
        {
          path: "offers",
          element: <OffersRoute />,
        },
      ],
    },
  ]);
}
