import {
  OfferDocument,
  useVbsOfferPreviewPDFQuery,
  useVbsOfferShowQuery,
} from "@/services/backend/vbs/offers/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetContextProvider,
  ArtefactSheetHeader,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
  useArtefactSheetContext,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { OfferView } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/data/offer-data-route";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { CalculationComponent } from "@/routes/gesec/processes/[processId]/vbs/calculations/[calculationId]/calculation/calculation-route";
import { ProcessCustomerAddressCardById } from "@/shared/components/domain/addresses/customer-address-card";
import { ProcessServiceAddressCardById } from "@/shared/components/domain/addresses/service-address-card";
import { ProcessRepresentativeCardById } from "@/shared/components/domain/representatives/representative-card";
import { Button } from "@/shared/components/ui/button";
import { Offer } from "@/services/backend/vbs/offers/offer";
import {
  ArtefactActionsDownload,
  ArtefactActionsHeader,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import { ContractDocument } from "@/services/backend/rlt/contracts/service";
import {
  DownloadButton,
  DownloadContractButton,
} from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/_components/offer-immutable-page";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavigateToArtefactButton } from "@/shared/components/domain/artefact/preview/_components/navigate-to-artefact-button";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { RefreshCw } from "lucide-react";

export function VbsOfferPreview({ offerId }: { offerId: string }) {
  const {
    data: offer,
    isLoading,
    error,
  } = useVbsOfferShowQuery({ id: offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RefreshCw className="h-4 w-4" />;
  }

  return (
    <ArtefactSheetContextProvider>
      <OfferArtefactSheet offer={offer!} />
    </ArtefactSheetContextProvider>
  );
}

function OfferArtefactSheet({ offer }: { offer: Offer }) {
  const { open, setOpen } = useArtefactSheetContext();

  return (
    <ArtefactSheet open={open} onOpenChange={setOpen}>
      <ArtefactSheetTrigger asChild>
        <Button variant="ghost" size="sm">
          {t("Vorschau")}
        </Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>
            {t(offer!.artefactKind)} {t("Vorschau")}
          </ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          {offer!.immutable ? (
            <ImmutableOfferPreview offer={offer!} />
          ) : (
            <MutableOfferPreview offer={offer!} />
          )}
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function ImmutableOfferPreview({ offer }: { offer: Offer }) {
  const { open } = useArtefactSheetContext();

  const { data, isLoading, error } = useVbsOfferPreviewPDFQuery(
    {
      id: offer.id,
      document: OfferDocument.ALL,
    },
    {
      // This prevents request to the backend if the artefact sheet has
      // not been opened. Otherwise, whenever the preview component
      // is used, the PDF would be preloaded (and often unnecessarily so).
      skip: !open,
    },
  );

  return (
    <>
      <ArtefactSheetPreview
        isLoading={isLoading}
        objectURL={data?.objectURL}
        error={error}
      />
      <ImmutableOfferActions offer={offer} />
    </>
  );
}

function ImmutableOfferActions({ offer }: { offer: Offer }) {
  return (
    <ArtefactSheetActions>
      <ArtefactActionsHeader
        title={t("Angebot")}
        artefactNumber={<ArtefactNumberById artefactId={offer.id} />}
      />
      <ArtefactSheetActionsContent>
        <ArtefactActionsDownload>
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.ALL}
            text={t("Alle Dokumente")}
          />
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.OFFER}
            text={t("Angebot")}
          />
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.TREATMENT}
            text={t("Leistungsverzeichnis")}
          />
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.ORDER}
            text={t("Auftrag")}
          />
          <DownloadContractButton
            contractId={offer.contractId}
            document={ContractDocument.CONTRACT}
            text={t("Vertrag")}
          />
          <DownloadContractButton
            contractId={offer.contractId}
            document={ContractDocument.TREATMENT}
            text={t("Vertrag LV")}
          />
        </ArtefactActionsDownload>
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}

function MutableOfferPreview({ offer }: { offer: Offer }) {
  return (
    <>
      <MutableOfferView offer={offer} />
      <MutableOfferActions offer={offer} />
    </>
  );
}

function MutableOfferView({ offer }: { offer: Offer }) {
  return (
    <div className="md:col-span-4">
      <PersistenceContextProvider>
        <MilestonePageContent>
          <Tabs defaultValue="data">
            <MilestonePageContentMenu>
              <TabsList>
                <TabsTrigger value="data">{t("Angebot")}</TabsTrigger>
                <TabsTrigger value="treatment">{t("Leistungen")}</TabsTrigger>
                <TabsTrigger value="calculation">
                  {t("Kalkulation")}
                </TabsTrigger>
                <TabsTrigger value="customer-address">
                  {t("Anschrift")}
                </TabsTrigger>
                <TabsTrigger value="representative">
                  {t("Betreuer")}
                </TabsTrigger>
              </TabsList>
              <span className="ml-4 font-mono text-lg text-gray-900">
                <ArtefactNumberById artefactId={offer.id} />
              </span>
              <PersistenceStateUsingContext className="ml-auto mr-2" />
            </MilestonePageContentMenu>
            <div className="max-h-[75vh] overflow-auto">
              <TabsContent value="data">
                <OfferView offer={offer} />
              </TabsContent>
              <TabsContent value="treatment">
                <TreatmentComponent
                  treatmentId={offer.treatmentId}
                  availableColumns={[
                    Column.Position,
                    Column.ComponentKind,
                    Column.ComponentData,
                    Column.Cycle,
                    Column.Formula,
                    Column.CleanTime,
                    Column.BasePrice,
                    Column.TipCommission,
                    Column.OfferPrice,
                    Column.Comment,
                    Column.Actions,
                  ]}
                  defaultColumns={[
                    Column.Position,
                    Column.ComponentKind,
                    Column.BasePrice,
                    Column.OfferPrice,
                    Column.Comment,
                    Column.Actions,
                  ]}
                />
              </TabsContent>
              <TabsContent value="calculation">
                <CalculationComponent calculationId={offer.calculationId} />
              </TabsContent>
              <TabsContent value="customer-address">
                <div className="space-y-4">
                  <ProcessCustomerAddressCardById
                    id={offer.customerAddressId}
                  />
                  <ProcessServiceAddressCardById id={offer.serviceAddressId} />
                </div>
              </TabsContent>
              <TabsContent value="representative">
                <ProcessRepresentativeCardById id={offer.representativeId} />
              </TabsContent>
            </div>
          </Tabs>
        </MilestonePageContent>
      </PersistenceContextProvider>
    </div>
  );
}

function MutableOfferActions({ offer }: { offer: Offer }) {
  return (
    <ArtefactSheetActions className="md:col-span-1">
      <ArtefactSheetActionsHeader
        title={t(offer.artefactKind)}
        artefactNumber={<ArtefactNumberById artefactId={offer.id} />}
      />
      <ArtefactSheetActionsContent>
        <NavigateToArtefactButton
          artefactId={offer.id}
          artefactKind={offer.artefactKind}
          processId={offer.processId}
          processKind={ProcessKind.VBS}
          companyId={offer.companyId}
        />
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}
