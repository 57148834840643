import { Navigate, RouteObject } from "react-router-dom";
import { OfferIndexRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/_components/offer-index-route";
import { OfferRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/offer-route";
import { OfferDataRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/data/offer-data-route";
import { OfferTreatmentRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/treatment/offer-treatment-route";
import { OfferCalculationRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/calculation/offer-calculation-route";
import { OfferCustomerAddressRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/customer-address/offer-customer-address-route";
import { OfferRepresentativeRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/representative/offer-representative-route";
import { OfferContractRoute } from "@/routes/gesec/processes/[processId]/vbs/offers/[offerId]/contract/offer-contract-route";

export const vbsOffersRoutes: RouteObject[] = [
  {
    index: true,
    element: <OfferIndexRoute />,
  },
  {
    path: ":offerId",
    element: <OfferRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <OfferDataRoute />,
      },
      {
        path: "contract",
        element: <OfferContractRoute />,
      },
      {
        path: "treatment",
        element: <OfferTreatmentRoute />,
      },
      {
        path: "calculation",
        element: <OfferCalculationRoute />,
      },
      {
        path: "customer-address",
        element: <OfferCustomerAddressRoute />,
      },
      {
        path: "representative",
        element: <OfferRepresentativeRoute />,
      },
    ],
  },
];
