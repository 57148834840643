import { RefObject, useEffect } from "react";

export const useTextareaAutoResize = (
  ref: RefObject<HTMLTextAreaElement> | null,
  value: string | number | readonly string[] | undefined,
) => {
  useEffect(() => {
    if (ref) {
      const current = ref.current!;
      current.style.height = "0px";
      current.style.height = `${ref.current!.scrollHeight}px`;
    }
  }, [ref, value]);
};
