import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Item } from "@/services/backend/htz/position-catalog/position-catalog";
import {
  addPosition,
  newFromCatalog,
} from "@/services/backend/htz/position/position";
import { usePositionTableContext } from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table-context";

export function AddPositionButton() {
  const { positionCatalog, positions, onPositionsChange, immutable } =
    usePositionTableContext();
  const { items: catalogPositions } = positionCatalog;
  const categories = uniqueCategories(catalogPositions);

  const onAdd = (catPos: Item) => {
    const newPosition = newFromCatalog(catPos);
    onPositionsChange(addPosition(positions, newPosition));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={immutable}>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Position")}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {categories.map((category) => (
          <DropdownMenuGroup key={category}>
            <DropdownMenuLabel>{category}</DropdownMenuLabel>
            {catalogPositions.filter(catFltr(category)).map((pos) => (
              <DropdownMenuItem key={pos.id} onClick={() => onAdd(pos)}>
                {pos.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function uniqueCategories(positions: Item[]): string[] {
  return positions.reduce((acc, cur) => {
    if (acc.indexOf(cur.category) !== -1) {
      return acc;
    }
    return [...acc, cur.category];
  }, [] as string[]);
}

function catFltr(cat: string) {
  return (pos: Item) => pos.category === cat;
}
