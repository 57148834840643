import {
  Artefact,
  Immutability,
} from "@/shared/service-manager/artefact/artefact";
import { Address } from "@/services/backend/addresses/address/address";
import {
  AbsoluteDiscount,
  SalesDiscount,
} from "@/services/backend/vbs/offers/offer";

export interface Contract extends Artefact, Immutability {
  companyId: string;
  customerId: string;
  calculationId: string;
  treatmentId: string;
  lifetime: Lifetime;
  scan: Scan;
  state: State;
  terminationReason: string;
  customerAddress: Address;
  serviceAddress: Address;
  treatment: TreatmentPrices;
  incidentalCost: IncidentalCost;
  pricesForExtras: PricesForExtras;
  paymentTerms: string;
  specialAgreements: string;
}

export interface Lifetime {
  startDate: string;
  endDate: string | null;
  unlimited: boolean;
}

export interface Scan {
  fileIs: string;
  prefix: string;
  useCrypto: boolean;
  dataFormat: string;
  scannedAt: string;
  scannedBy: string;
}

export enum State {
  Draft = "draft",
  Signed = "signed",
  Terminated = "terminated",
}

export interface TreatmentPrices {
  basePrice: number;
  salesDiscount: SalesDiscount;
  absoluteDiscount: AbsoluteDiscount;
  contractPrice: number;
}

export interface IncidentalCost {
  materialFlatRate: number;
  travelTime: number;
  expenses: number;
  totalAmount: number;
}

export interface PricesForExtras {
  hourlyRate: number;
  ratePerAccessPanel: number;
}

export interface List {
  contracts: Contract[];
  count: number;
}
