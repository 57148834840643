import { backendApi } from "@/services/backend/api";
import { ComponentKind } from "@/services/backend/htz/ahu/component-kind";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";

const componentKindApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzAhuComponentKind"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzAhuComponentKindPut: build.mutation<ComponentKind, ComponentKind>({
        query: (request) => ({
          url: "htz/ahu-component-kind",
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzAhuComponentKind", id: request.id },
          "HtzAhuComponentKind",
        ],
      }),
      htzAhuComponentKindShow: build.query<ComponentKind, ShowRequest>({
        query: (request) => ({
          url: "htz/ahu-component-kind",
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzAhuComponentKind"),
      }),
      htzAhuComponentKindList: build.query<List, ListRequest>({
        query: () => ({
          url: "htz/ahu-component-kind/list",
          method: "GET",
        }),
        providesTags: (result) =>
          providesList(result?.kinds, "HtzAhuComponentKind"),
      }),
      htzAhuComponentKindDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: "htz/ahu-component-kind",
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzAhuComponentKind"],
      }),
    }),
  });

export const {
  useHtzAhuComponentKindPutMutation,
  useHtzAhuComponentKindShowQuery,
  useHtzAhuComponentKindListQuery,
  useHtzAhuComponentKindDeleteMutation,
} = componentKindApi;

export interface ShowRequest {
  id: string;
}

export interface List {
  kinds: ComponentKind[];
  count: number;
}

export interface ListRequest {}

export interface DeleteRequest {
  id: string;
}
