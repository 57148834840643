import { MonitorRecordList } from "@/services/backend/qpm/monitors/types";
import { AreaMonitor } from "@/services/backend/qpm/requirements/types";
import { useEffect, useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { ChevronDown, ChevronRight } from "lucide-react";
import { MonitoringComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/monitoring/monitoring-component";
import { Area } from "@/services/backend/qpm/buildings/types";
import { RiskAnalysisComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/create/components/monitoring/risk-analysis-component";
import {
  MonitorTypeKey,
  QPMSearchParams,
} from "@/services/backend/qpm/shared/enums";
import { useSearchParams } from "react-router-dom";

export type MonitorAreaSegmentProps = {
  requirementId: string;
  index: number;
  area: Area;
  initialMonitors: MonitorRecordList;
  selectedMonitors: AreaMonitor[];
  buildingName: string;
  levelName: string;
};

export function MonitoringAreaSegmentCollapsible({
  requirementId,
  index,
  area,
  initialMonitors,
  selectedMonitors,
  buildingName,
  levelName,
}: MonitorAreaSegmentProps) {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(true);
  const [amount, setAmount] = useState<{ [key in MonitorTypeKey]?: number }>({
    [MonitorTypeKey.FlyExterminator]: 0,
    [MonitorTypeKey.CheckPoint]: 0,
  });

  useEffect(() => {
    const collapsibleOpen = searchParams.get(
      QPMSearchParams.CollapsibleAreasOpen,
    );
    if (collapsibleOpen && collapsibleOpen === "false") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    let checkPointAmount = 0;
    let flyExterminatorAmount = 0;

    selectedMonitors.forEach((monitor) => {
      if (monitor.type.name === MonitorTypeKey.CheckPoint) {
        checkPointAmount += monitor.amount;
      }
      if (monitor.type.name === MonitorTypeKey.FlyExterminator) {
        flyExterminatorAmount += monitor.amount;
      }
      setAmount({
        [MonitorTypeKey.CheckPoint]: checkPointAmount,
        [MonitorTypeKey.FlyExterminator]: flyExterminatorAmount,
      });
    });
  }, [selectedMonitors]);

  const truncateString = (input: string): string => {
    if (input.length <= 12) {
      return input;
    }
    return `${input.slice(0, 5)}...${input.slice(-5)}`;
  };

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="overflow-auto-x">
        <div className="flex w-full flex-col justify-between bg-muted/50 md:flex-row">
          <div className="flex w-full items-center justify-between pe-2">
            <CollapsibleTrigger>
              <div className="flex items-center text-sm font-bold uppercase">
                <div className="py-3 pl-6">
                  {open ? (
                    <ChevronDown className="h-5 w-5" />
                  ) : (
                    <ChevronRight className="h-5 w-5" />
                  )}
                </div>
                <div className="flex-grow py-3 pl-3 pr-6">
                  {truncateString(buildingName)} - {truncateString(levelName)} -{" "}
                  {area.base.name}
                </div>
              </div>
            </CollapsibleTrigger>
            <RiskAnalysisComponent area={area} requirementId={requirementId} />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1">
              <span className="p-1 font-bold">PP:</span>
              <span className="m-1 min-w-[50px] bg-white p-1 text-end">
                {amount[MonitorTypeKey.CheckPoint]}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <span className="p-1 font-bold">FIV:</span>
              <span className="m-1 min-w-[50px] bg-white p-1 text-end">
                {amount[MonitorTypeKey.FlyExterminator]}
              </span>
            </div>
          </div>
        </div>
        <CollapsibleContent>
          <MonitoringComponent
            requirementId={requirementId}
            areaIndex={index}
            areaId={area.base.id}
            monitors={initialMonitors}
            selectedMonitors={selectedMonitors}
          />
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}
