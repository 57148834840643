import { RiskTypeKey } from "@/services/backend/qpm/shared/enums";

export class RiskTypeUtil {
  private static descriptions: { [key in RiskTypeKey]: string } = {
    [RiskTypeKey.UNSPECIFIED]: "Keine Schädlinge",
    [RiskTypeKey.Rodent]: "Schadnager",
    [RiskTypeKey.CrawlingInsect]: "kriechende Insekten",
    [RiskTypeKey.FlyingInsect]: "fliegende Insekten",
    [RiskTypeKey.Bird]: "Vögel",
  };

  static toLongString(type: RiskTypeKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
