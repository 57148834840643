import { useParams } from "react-router-dom";
import t from "@/lang/lang";
import { useShowCompanyQuery } from "@/services/backend/company/company";
import { H3, P } from "@/shared/components/ui/typography";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export default function CompanyRoute() {
  const { id } = useParams<{ id: string }>();
  const {
    data: company,
    error,
    isLoading,
  } = useShowCompanyQuery({ id: id ?? "" });

  if (isLoading) {
    return (
      <BaseDataPage className="animate-pulse">
        <BaseDataPageHeader>
          <BaseDataPageTitle>{t("Lade Daten...")}</BaseDataPageTitle>
        </BaseDataPageHeader>
      </BaseDataPage>
    );
  }

  if (error || !company) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <BaseDataPage>
      <BaseDataPageHeader>
        <BaseDataPageTitle>{company.name}</BaseDataPageTitle>
      </BaseDataPageHeader>
      <BaseDataPageContent>
        <OfferedServices />
        <DynamicsConnection />
        <AzureGroupMatching />
      </BaseDataPageContent>
    </BaseDataPage>
  );
}

function OfferedServices() {
  return (
    <div>
      <H3>{t("Dienstleistungen")}</H3>
      <P className="text-muted">
        Ermöglicht zu konfigurieren welche Dienstleistungen durch das
        Unternehmen angeboten werden.
      </P>
    </div>
  );
}

function DynamicsConnection() {
  return (
    <div>
      <H3>{t("Dynamics Verbindung")}</H3>
      <P className="text-muted">
        Ermöglicht die Konfiguration der Verbindung zu Dynamics
      </P>
    </div>
  );
}

function AzureGroupMatching() {
  return (
    <div>
      <H3>{t("Azure AD Gruppen Matching")}</H3>
      <P className="text-muted">
        Nutzer geben über ihre Gruppenzugehörigkeit an welchen Unternehmen sie
        zuzuordnen sind. Gruppen die hier nicht registriert sind finden keine
        Beachtung. Durch die Konfiguration ist es möglich, dass ein Nutzer einem
        oder mehreren Unternehmen zugeordnet ist.
      </P>
    </div>
  );
}
