import { Navigate, RouteObject } from "react-router-dom";
import { CalculationRoute } from "@/routes/base-data/processes/vbs/calculation/calculation-route";
import { OfferRoute } from "@/routes/base-data/processes/vbs/offer/offer-route";
import { ContractRoute } from "@/routes/base-data/processes/vbs/contract/contract-route";

export const vbsBaseDataRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="calculation" replace />,
  },
  {
    path: "calculation",
    element: <CalculationRoute />,
  },
  {
    path: "offer",
    element: <OfferRoute />,
  },
  {
    path: "contract",
    element: <ContractRoute />,
  },
];
