import {
  generateSampleNumber,
  newSample,
  Sample,
  updateSampleArtefact,
  updateSampleLab,
  updateSampleNumber,
} from "@/services/backend/samples/sample/sample";
import { GrowthMedium } from "@/services/backend/samples/sample/growth-medium";
import { Kind } from "@/services/backend/samples/sample/kind";
import { v4 } from "uuid";
import { EntityId } from "@/shared/nidavellir/types/entity-id";
import { ArtefactNamespace } from "@/shared/service-manager/artefact/artefact-namespace";

export interface AirMicrobialSampleSet {
  id: string;
  artefactId: EntityId;
  artefactNamespace: ArtefactNamespace | null;
  labId: EntityId;
  number: string;
  comment: string;
  caso: AirMicrobialSample | null;
  malt: AirMicrobialSample | null;
  dg18: AirMicrobialSample | null;
}

export function newAirMicrobialSampleSet(
  id: string,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
): AirMicrobialSampleSet {
  const number = generateSampleNumber();
  return {
    id,
    artefactId,
    artefactNamespace,
    labId: null,
    number,
    comment: "",
    // caso and malt are always present, dg18 is optional
    caso: newAirMicrobialSample(
      v4(),
      `${number}.CASO`,
      GrowthMedium.CASO,
      artefactId,
      artefactNamespace,
    ),
    malt: newAirMicrobialSample(
      v4(),
      `${number}.MALT`,
      GrowthMedium.MALT,
      artefactId,
      artefactNamespace,
    ),
    dg18: null,
  };
}

export function updateArtefact(
  set: AirMicrobialSampleSet,
  id: EntityId,
  namespace: ArtefactNamespace,
): AirMicrobialSampleSet {
  return {
    ...set,
    artefactId: id,
    artefactNamespace: namespace,
    caso: set.caso ? updateSampleArtefact(set.caso, id, namespace) : null,
    malt: set.malt ? updateSampleArtefact(set.malt, id, namespace) : null,
  };
}

export function updateAirMicrobialSetNumber(
  set: AirMicrobialSampleSet,
  number: string,
): AirMicrobialSampleSet {
  return {
    ...set,
    number,
    caso: set.caso ? updateSampleNumber(set.caso, `${number}-CASO`) : null,
    malt: set.malt ? updateSampleNumber(set.malt, `${number}-MALT`) : null,
    dg18: set.dg18 ? updateSampleNumber(set.dg18, `${number}-DG18`) : null,
  };
}

export function updateAirMicrobialSetLab(
  set: AirMicrobialSampleSet,
  labId: EntityId,
): AirMicrobialSampleSet {
  return {
    ...set,
    labId,
    caso: set.caso ? updateSampleLab(set.caso, labId) : null,
    malt: set.malt ? updateSampleLab(set.malt, labId) : null,
    dg18: set.dg18 ? updateSampleLab(set.dg18, labId) : null,
  };
}

export function updateAirMicrobialSetComment(
  set: AirMicrobialSampleSet,
  comment: string,
): AirMicrobialSampleSet {
  return {
    ...set,
    comment,
  };
}

export function updateAirMicrobialSetCaso(
  set: AirMicrobialSampleSet,
  sample: AirMicrobialSample,
): AirMicrobialSampleSet {
  if (sample.growthMedium !== GrowthMedium.CASO) {
    return set;
  }

  return {
    ...set,
    caso: sample,
  };
}

export function updateAirMicrobialSetMalt(
  set: AirMicrobialSampleSet,
  sample: AirMicrobialSample,
): AirMicrobialSampleSet {
  if (sample.growthMedium !== GrowthMedium.MALT) {
    return set;
  }

  return {
    ...set,
    malt: sample,
  };
}

export function updateAirMicrobialSetDg18(
  set: AirMicrobialSampleSet,
  sample: AirMicrobialSample,
): AirMicrobialSampleSet {
  if (sample.growthMedium !== GrowthMedium.DG18) {
    return set;
  }

  return {
    ...set,
    dg18: sample,
  };
}

export function activateAirMicrobialSetSample(
  set: AirMicrobialSampleSet,
  growthMedium: GrowthMedium,
): AirMicrobialSampleSet {
  switch (growthMedium) {
    case GrowthMedium.CASO:
      return {
        ...set,
        caso: set.caso ?? {
          ...newAirMicrobialSample(
            v4(),
            `${set.number}.CASO`,
            GrowthMedium.CASO,
            set.artefactId,
            set.artefactNamespace,
          ),
          labId: set.labId,
        },
      };
    case GrowthMedium.MALT:
      return {
        ...set,
        malt: set.malt ?? {
          ...newAirMicrobialSample(
            v4(),
            `${set.number}.MALT`,
            GrowthMedium.MALT,
            set.artefactId,
            set.artefactNamespace,
          ),
          labId: set.labId,
        },
      };
    case GrowthMedium.DG18:
      return {
        ...set,
        dg18: set.dg18 ?? {
          ...newAirMicrobialSample(
            v4(),
            `${set.number}.DG18`,
            GrowthMedium.DG18,
            set.artefactId,
            set.artefactNamespace,
          ),
          labId: set.labId,
        },
      };
    default:
      return set;
  }
}

export function deactivateAirMicrobialSetSample(
  set: AirMicrobialSampleSet,
  growthMedium: GrowthMedium,
): AirMicrobialSampleSet {
  switch (growthMedium) {
    case GrowthMedium.CASO:
      return {
        ...set,
        caso: null,
      };
    case GrowthMedium.MALT:
      return {
        ...set,
        malt: null,
      };
    case GrowthMedium.DG18:
      return {
        ...set,
        dg18: null,
      };
    default:
      return set;
  }
}

export function airMicrobialSampleIsActivated(
  set: AirMicrobialSampleSet,
  growthMedium: GrowthMedium,
): boolean {
  switch (growthMedium) {
    case GrowthMedium.CASO:
      return !!set.caso;
    case GrowthMedium.MALT:
      return !!set.malt;
    case GrowthMedium.DG18:
      return !!set.dg18;
    default:
      return false;
  }
}

export interface AirMicrobialSample extends Sample {
  growthMedium: GrowthMedium;
  samplingVolume: number;
  deviceId: EntityId;
}

function newAirMicrobialSample(
  id: string,
  number: string,
  growthMedium: GrowthMedium,
  artefactId: EntityId,
  artefactNamespace: ArtefactNamespace | null,
): AirMicrobialSample {
  const sample = newSample(
    id,
    number,
    Kind.AirMicrobial,
    artefactId,
    artefactNamespace,
  );

  return {
    ...sample,
    growthMedium,
    samplingVolume: 0,
    deviceId: null,
  };
}

export function updateSamplingVolume(
  sample: AirMicrobialSample,
  samplingVolume: number,
): AirMicrobialSample {
  return {
    ...sample,
    samplingVolume,
  };
}

export function updateDeviceId(
  sample: AirMicrobialSample,
  deviceId: EntityId,
): AirMicrobialSample {
  return {
    ...sample,
    deviceId,
  };
}
