import { EntityId } from "@/shared/nidavellir/types/entity-id";

export interface SampleDelivery {
  id: string;
  trackingId: string;
  description: string;
  labId: EntityId;
  airMicrobialSampleSetIds: string[];
  dustDensitySampleIds: string[];
  surfaceMicrobialSampleSetIds: string[];
  waterSampleIds: string[];
  sent: boolean;
  sentAt: string;
}

export function containsSampleId(
  delivery: SampleDelivery,
  sampleId: string,
): boolean {
  return (
    delivery.airMicrobialSampleSetIds.includes(sampleId) ||
    delivery.dustDensitySampleIds.includes(sampleId) ||
    delivery.surfaceMicrobialSampleSetIds.includes(sampleId) ||
    delivery.waterSampleIds.includes(sampleId)
  );
}
